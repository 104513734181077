import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";
import Select from "react-select";

import FeatherIcon from 'feather-icons-react';
import { GroupGetByModelRequest } from "store/properties/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ModelListGetRequest } from "store/auth/action";

const BasicInfo = ({setCreateInputs, createInputs, model}: any) => {  
    const [selectedObjectedType, SetSelectedObjectedType] = useState<any>(null);
    const modelList = useSelector((state: ApplicationState) => state.auth.models)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionList = useSelector((state: ApplicationState) => state.properties.groups); 
    const loading = useSelector((state: ApplicationState) => state.properties.loading); 
    const [options, setOptions] = useState([]);
    const [selectedGroup, setselectedGroup] = useState({});
    const handleselected = (option: any, name: string, ) => {
        switch(name) {
            case 'object-type': SetSelectedObjectedType(option)
                                setCreateInputs({...createInputs,objectType: option.value})
                                break
            case 'group': setselectedGroup(option)
                                setCreateInputs({...createInputs,groupId: option.value})
                                break
        }
    }
    
    const objectTypeOption = modelList && modelList.length ? 
    modelList.map((item: any) => {
        return { label: item.charAt(0).toUpperCase() + item.slice(1), value: item}
    }) : []

    // useEffect(() => {
    //     let newinputs = {...createInputs, objectType: model}
    //     console.log("newinputs", newinputs)
    //     setCreateInputs(newinputs)
    //     SetSelectedObjectedType({label: model.charAt(0).toUpperCase() + model.slice(1), value: model})
    // }, [])
    const onchange = (value: any) => {
        let key = value.replace(/ /g, '_').toLowerCase()
        setCreateInputs({...createInputs, label: value, key: key})
    }

    useEffect(() => {
        if (selectedObjectedType && selectedObjectedType.value) {
            const filterObject = {
                pageSize: 100
            }
            dispatch(GroupGetByModelRequest(selectedObjectedType.value, filterObject))
        }
    }, [selectedObjectedType])

    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            return { label: item.groupTitle, value: item.id }
        })
        setOptions(options)
    }, [optionList])

    useEffect(() => {         
        dispatch(ModelListGetRequest())  
    }, [])
    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-medium">Module <span className="fw-bold text-danger">{" "}*</span></Label>                            
                        <Select
                            defaultOptions
                            placeholder={'Select property type'}
                            className="fs-16 fw-medium"
                            value={selectedObjectedType}
                            onChange={(option : any) => handleselected(option, 'object-type')}
                            options={objectTypeOption}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        <Label className="fs-16 fw-medium">Field name <span  className="fw-bold text-danger">{" "}*</span></Label>                            
                        <Input
                            type={"text"}
                            name={"label"}
                            className="fs-16 fw-medium"
                            value={createInputs.label}
                            onChange={(e) => onchange(e.target.value)}                                                         
                        />
                    </div>
                    <div>
                        <Label className="fs-16 fw-medium">Section <span className="fw-bold text-danger">{" "}*</span></Label>                            
                        <Select
                            defaultOptions
                            placeholder={'Select Group'}
                            className="fs-16 fw-medium"
                            value={selectedGroup}
                            onChange={(option : any) => handleselected(option, 'group')}
                            options={options || []}
                            name="choices-single-default"
                        ></Select>
                        {selectedObjectedType && selectedObjectedType.value && !options.length && !loading ?
                         <span className="fw-bold text-danger fs-12">If you are not able to see options in dropdown, Please create sections for {selectedObjectedType.label}.</span>
                          : null}
                    </div>
                    <div>
                        <Label className="fs-16 fw-medium">Description</Label>  
                        <div>
                            <Input
                                type={"text"}
                                className="form-controlt fs-14 fw-medium"
                                name="description"
                                value={createInputs.description}
                                onChange={(e) => setCreateInputs({...createInputs,description: e.target.value})}                                                         
                            />                     
                        </div>  
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BasicInfo;
