import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordSuccessProps { 
    t: (key: string) => string;
}

const ResetPasswordSuccess: React.FC<ResetPasswordSuccessProps> = ({t}) => {
    document.title = "Confirmed Courses | Zilter";    
    const navigate = useNavigate()
    return (
        <div className="auth-page-content">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/#" className="d-inline-block auth-logo">
                                                    <img src={logoLight} alt="" height="50" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    <FeatherIcon icon="check-circle" className="icon-xxl icon-dual-success" />
                                    <h5 className="text-secondary fw-bold fs-28 mb-2 mt-4">Confirmed.</h5>
                                </div>
                                <div className="text-center mt-4 mb-2">
                                    <h5 className="text-secondary fw-bold fs-28">Thanks for confirming your choice, one of our team member will contact you soon to progress further.</h5>
                                </div>                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(ResetPasswordSuccess);