import { Reducer } from "redux";
import { DealActionTypes, DealsState } from "./types";


export const initialState: DealsState =
{
    dealsMetadata: [],
    deals: null,
    deal: null,
    pagination: null,
    deallist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    hierarchydeals: [],
    loading: false,
    dataloading: false,
    metaloading: false
}
const reducer: Reducer<DealsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case DealActionTypes.GET_DEALMETADATA_REQUEST: {
            return { ...state, metaloading: true };
        }
        case DealActionTypes.GET_DEALMETADATA_SUCCESS: {
            return {
                ...state,
                metaloading: false,
                dealsMetadata: action.payload.content
            };
        }
        case DealActionTypes.GET_DEALMETADATA_ERROR: {
            return { ...state, metaloading: false, errors: action.payload };
        }
        case DealActionTypes.GET_DEAL_REQUEST: {
            return { ...state, loading: true };
        }
        case DealActionTypes.GET_DEAL_SUCCESS: {
            return {
                ...state,
                loading: false,
                deal: action.payload
            };
        }
        case DealActionTypes.GET_DEAL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case DealActionTypes.POST_DEAL_REQUEST: {
            return { ...state, loading: true };
        }
        case DealActionTypes.POST_DEAL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case DealActionTypes.POST_DEAL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case DealActionTypes.GET_DEALS_REQUEST: {
            return { ...state, dataloading: true };
        }
        case DealActionTypes.GET_DEALS_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                deals: action.payload.content,
                pagination: {
                    page: action.payload?.number,
                    size: action.payload?.size,
                    pages: action.payload?.totalPages,
                    elements: action.payload?.totalElements,
                    first: action.payload?.first,
                    last: action.payload?.last,
                    offset: action.payload?.pageable?.offset
                }
            };
        }
        case DealActionTypes.GET_DEALS_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }

        
        case DealActionTypes.GET_DEALS_HIERARCHY_REQUEST: {
            return { ...state, loading: true };
        }
        case DealActionTypes.GET_DEALS_HIERARCHY_SUCCESS: {
            return {
                ...state,
                loading: false,
                hierarchydeals: action.payload,
            };
        }
        case DealActionTypes.GET_DEALS_HIERARCHY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case DealActionTypes.PUT_DEAL_REQUEST: {
            return { ...state, loading: true };
        }
        case DealActionTypes.PUT_DEAL_SUCCESS: {
            return {
                ...state,
                loading: false,
                deallist: action.payload.content
            };
        }
        case DealActionTypes.PUT_DEAL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case DealActionTypes.DELETE_DEAL_REQUEST: {
            return { ...state, loading: true };
        }
        case DealActionTypes.DELETE_DEAL_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DealActionTypes.DELETE_DEAL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as DealsReducer };
