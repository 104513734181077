import {ChannelActionTypes, Channel} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const channelGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_CHANNEL_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/channels/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_CHANNEL_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_CHANNEL_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const applicationsCommissions: AppThunk = (applicationId , handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/channels/commissions/${applicationId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

const createQueryLink = (filterObject: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_STUDENT}/channel-metadata?`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};

export const channelMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.GET_CHANNELMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ChannelActionTypes.GET_CHANNELMETADATA_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: ChannelActionTypes.GET_CHANNELMETADATA_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const channelUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
    console.log("channelUpdateRequest", data,"handleSuccess", handleSuccess)
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        console.log("token", token)
        dispatch({
            type: ChannelActionTypes.PUT_CHANNEL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/channels/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        console.log("response_1", response_1)
        if (!response_1.ok) {
            dispatch({
                type: ChannelActionTypes.PUT_CHANNEL_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ChannelActionTypes.PUT_CHANNEL_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const channelDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        console.log("token", token)

        dispatch({
            type: ChannelActionTypes.DELETE_CHANNEL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/channels/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: ChannelActionTypes.DELETE_CHANNEL_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: ChannelActionTypes.DELETE_CHANNEL_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: ChannelActionTypes.DELETE_CHANNEL_ERROR
            });
            handleSuccess();
        }
    };
};


const channelcreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/channels?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const ChannelListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.GET_CHANNELS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = channelcreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ChannelActionTypes.GET_CHANNELS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ChannelActionTypes.GET_CHANNELS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const channelCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: ChannelActionTypes.POST_CHANNEL_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/channels`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ChannelActionTypes.POST_CHANNEL_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ChannelActionTypes.POST_CHANNEL_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const InvoicesStudentsList: AppThunk = (channelId , handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/applications/invoices/${channelId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};




