import React, { useEffect, useState } from 'react'
import avatar7 from "../../../../src/assets/images/users/avatar-7.jpg";
import { Link, NavLink, useParams } from "react-router-dom";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Select from "react-select";
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { noteAddRequest, notesListGetRequest } from 'store/applications/action';
import SimpleBar from 'simplebar-react';

const Comments = ({t, record, noteType, transcribing, setTranscribing, studentId}: any) => {

    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const notes = useSelector((state: ApplicationState) => state.applications.notes);
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [noteData, setNoteData] = useState({ notes })
    // const [studentId, setSudentId] = useState<any>("");
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [replyNote, setReplyNote] = useState("")
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>({
      applicationId: id
    });
    const {
      transcript,
      listening,
      resetTranscript,
      browserSupportsSpeechRecognition
    } = useSpeechRecognition({transcribing: transcribing == "child"});

    useEffect(() => {
      setReplyNote(transcript)
    }, [transcript])
    
      if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
      }
    
      const startListening = () => {
        setTranscribing("child");
        SpeechRecognition.startListening({continuous: true});
      };
    
      const stopListening = () => {
        SpeechRecognition.stopListening();
      };

      const handleReset = () => {
        setReplyNote("");
        setSelectedNoteType(null)
        resetTranscript();
        setAddShortNoteValue('')
      };

      const handleReplyNote = () => {
        stopListening();
        const data =
        {
          applicationId: id,
          shortNote: shortNoteValue,
          studentId: "",
          parentId: replyNoteId,
          note: replyNote
        }
        const handleSuccess = (body: any): void => {
          const message = () => toast(`Reply sent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
          message();
          handleReset();
          dispatch(notesListGetRequest(studentId, {applicationId: applicationDetails?.id}));
        };
    
        const handleError = (body: any): void => {
          const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
          message();
        };
    
        dispatch(noteAddRequest(data, handleSuccess, handleError));
      };

      const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReplyNote(event.target.value);
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
      };
      const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        console.log("Note type", selectedOption.value)
        // You can also update the formData here if needed
      };

      const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
          setReplyNoteId(null);
        } else {
          setReplyNoteId(noteId);
        }
      };

      const renderComments = (notes: any) => {
        return (
          <React.Fragment>
            {notes && notes.map((comment: any, key: number) => (
              <div className="d-flex flex-column mb-4" key={key}>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="d-flex gap-2 fs-15 mb-1">
                      <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                      <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                      <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                    </h5>
                    <p className="text-muted mb-0">{comment?.detailedNote}</p>
                  </div>
                </div>
                <div className="ms-5 mb-2">
                  {replyNoteId === comment.id ? (
                    <div>
                      <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={t("applications.reply_to_this_note")}></textarea>
                      <div className="d-flex justify-content-end gap-1 mt-1">
                        <Select
                          placeholder="Select Note Type"
                          classNamePrefix="js-example-data-array"
                          isLoading={false}
                          options={noteType}
                          value={selectedNoteType}
                          onChange={handleNoteTypeChange}
                          menuPlacement="auto" // You can also try "bottom" or "top"
                          menuPosition="fixed"
                        />
                        {listening ? (
                          <>
                            <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                          </>
                        ) : (
                          <>
                            <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                          </>
                        )}
                        <button onClick={(e) => {
                          handleReplyNote();
                          e.preventDefault();
                          toggleReply(comment.id);
                        }}
                        disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                        className="btn btn-sm btn-success mt-1">{t("applications.reply")}</button>
                        <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{t("applications.cancel")}</button>
                      </div>
                    </div>
                  ) :
                    <>
                      <Link to="#" className="badge text-muted bg-light" onClick={() => {
                        // e.preventDefault(); 
                        toggleReply(comment.id)
                        setReplyNoteId(comment.id)
                      }}>
                        <i className="mdi mdi-reply"></i> {t("applications.reply")}
                      </Link>
                    </>
                  }
                </div>
                {/* Render nested comments */}
                {comment.replies && comment.replies.length > 0 && (
                  <div className="ms-5">
                    {renderComments(comment.replies)}
                  </div>
                )}
              </div>
            ))}
          </React.Fragment>
        );
      };

    return (
      <React.Fragment>
        <SimpleBar className="mt-3 mx-n2 px-4 file-menu-sidebar-scroll">
        {notes && notes.map((comment: any, key: number) => (
          <div className="d-flex mx-3 flex-column mb-4" key={key}>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="d-flex gap-2 fs-15 mb-1">
                  <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                  <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                  <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                </h5>
                <p className="text-muted mb-0">{comment?.detailedNote}</p>
              </div>
            </div>
            <div className="ms-5 mb-2">
              {replyNoteId === comment.id ? (
                <div>
                  <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={t("applications.reply_to_this_note")}></textarea>
                  <div className="d-flex justify-content-end gap-1 mt-1">
                    <Select
                      placeholder="Select Note Type"
                      classNamePrefix="js-example-data-array"
                      isLoading={false}
                      options={noteType}
                      value={selectedNoteType}
                      onChange={handleNoteTypeChange}
                      menuPlacement="auto" // You can also try "bottom" or "top"
                      menuPosition="fixed"
                    />
                    {listening ? (
                      <>
                        <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                      </>
                    ) : (
                      <>
                        <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                      </>
                    )}
                    <button onClick={(e) => {
                      handleReplyNote();
                      e.preventDefault();
                      toggleReply(comment.id);
                    }}
                    disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                    className="btn btn-sm btn-success mt-1">{t("applications.reply")}</button>
                    <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{t("applications.cancel")}</button>
                  </div>
                </div>
              ) :
                <>
                  <Link to="#" className="badge text-muted bg-light" onClick={() => {
                    // e.preventDefault(); 
                    toggleReply(comment.id)
                    setReplyNoteId(comment.id)
                  }}>
                    <i className="mdi mdi-reply"></i> {t("applications.reply")}
                  </Link>
                </>
              }
            </div>
            {/* Render nested comments */}
            {comment.replies && comment.replies.length > 0 && (
              <div className="ms-5">
                {renderComments(comment.replies)}
              </div>
            )}
          </div>
        ))}
        </SimpleBar>
      </React.Fragment>
    );
  };

  export default withTranslation()(Comments);