import {StudentActionTypes, Student} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const studentGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: StudentActionTypes.GET_STUDENT_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/students/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: StudentActionTypes.GET_STUDENT_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: StudentActionTypes.GET_STUDENT_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

// const createQueryLink = (filterObject: any) => {
//     const keys = Object.keys(filterObject);
//     const values = Object.values(filterObject);
//     let query = `${DTS_API_DOMAIN}/students-metadata?`;
//     for (let i = 0; i < keys.length; i++) {
//         if (values[i]) query += `${keys[i]}=${values[i]}&`;
//     }
//     return query;
// };

// export const studentsMetadataGetRequest: AppThunk = (filterObject) => {
//     return async (dispatch: Dispatch, getState): Promise<void> => {        
//         const { auth: { token } } = getState();
//         dispatch({
//             type: StudentActionTypes.GET_STUDENTMETADATA_REQUEST
//         });  
//         const headers = new Headers();
//         headers.append('Content-Type', 'application/json');
//         if (token) {
//             headers.append('token', token);
//         }   
//         const searchQuery =   createQueryLink(filterObject)    
//         const response = await fetch(searchQuery, {
//             method: "GET",
//             headers: headers
//         });
    
//         const body = await response.json();
//         const {response: response_1, body: body_1} = {response, body};
//         if (!response_1.ok) {
//             dispatch({
//                 type: StudentActionTypes.GET_STUDENTMETADATA_ERROR,
//                 payload: body_1
//             });
//             // handleError(body_1);
//         } else {
//             dispatch({
//                 type: StudentActionTypes.GET_STUDENTMETADATA_SUCCESS,
//                 payload: body_1
//             });
//             // handleSuccess("Metadata fetched successfully");
//         }
//     };
// };

const creategetmetadatQuery = (filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/properties?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const studentsMetadataGetRequest: AppThunk = (model, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }
        const searchQuery = creategetmetadatQuery(filterObject,page);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_STUDENTMETADATA_ERROR,
                payload: body_1
            });
            handleError && handleError(body_1);
        } else {
            dispatch({
                type: StudentActionTypes.GET_STUDENTMETADATA_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess("Metadata fetched successfully");
        }
    };
};

const creategetemailsQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/emails/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getStudentsEmails: AppThunk = (id, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_EMAILS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetemailsQuery(id,filterObject, page);     
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_EMAILS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_EMAILS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const studentUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.PUT_STUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/students/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );        
        const error = {
            204: {
                "message": "Student updated Successfully",
                "success": true
            },
            200: {
                "message": "Student updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, StudentActionTypes.PUT_STUDENT_SUCCESS, StudentActionTypes.PUT_STUDENT_ERROR, error, handleSuccess, handleError);

 
    };
};

export const SendEmail: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.POST_STUDENT_EMAIL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_DOMAIN}/emails`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.POST_STUDENT_EMAIL_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: StudentActionTypes.POST_STUDENT_EMAIL_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const studentDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.DELETE_STUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_DOMAIN}/students/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        const error = {
            204: {
                "message": "Student deleted Successfully",
                "success": true
            },
            200: {
                "message": "Student deleted Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, StudentActionTypes.DELETE_STUDENT_SUCCESS, StudentActionTypes.DELETE_STUDENT_ERROR, error, handleSuccess, handleError);

    };
};

export const StudentListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_DOMAIN}/students/get?page=${page}&pageSize=${size}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            // Your error is here!
            console.log(error)
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: StudentActionTypes.GET_STUDENTS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: StudentActionTypes.GET_STUDENTS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const studentCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: StudentActionTypes.POST_STUDENT_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/students`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );        
        const error = {
            208: {
                "message": "Student with this email address already exist",
                "success": false
            },
            201: {
                "message": "Student created Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, StudentActionTypes.POST_STUDENT_SUCCESS, StudentActionTypes.POST_STUDENT_ERROR, error, handleSuccess, handleError);
    };
};



const creategetactivityQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/activities/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getStudentsActivity: AppThunk = (id, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_ACTIVITY_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetactivityQuery(id,filterObject, page);         
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_ACTIVITY_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_ACTIVITY_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};

const applicationscreateQueryLink = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/applications/student/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const ApplicationsListGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_APPLICATIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = applicationscreateQueryLink(id, filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_APPLICATIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: StudentActionTypes.GET_APPLICATIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};
export const studentExternalApplication: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        // const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.POST_EXTERNALSTUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');  
        headers.append('tenantId', id);  
        const response = await fetch(`${DTS_API_DOMAIN}/students/forms`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.POST_EXTERNALSTUDENT_ERROR
            });
            handleError && handleError();
        } else {
            dispatch({
                type: StudentActionTypes.POST_EXTERNALSTUDENT_SUCCESS,
                payload: []
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};

