import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
    Accordion,
    AccordionItem,
    Collapse,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
    UncontrolledAlert,
    Table,
} from "reactstrap";
import classnames from "classnames";
import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import avatar10 from "../../../assets/images/users/user-dummy-img.jpg";
import { ApplicationState } from "store";
import ApplicationInfo from './ApplicationInfo';
import Notes from '../Modals/Notes'
import { Link, useParams } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { applicationGetRequest, applicationsMetadataGetRequest, notesListGetRequest } from "store/applications/action";
import SimpleBar from "simplebar-react";
import { WithTranslation, withTranslation } from "react-i18next";
interface PreviewModalProps {
    show ?: boolean;
    open ?: string;
    onCloseClick : () => void;
    ontoggle:  (e: any) => void;
    setrecord:  (e: any) => void;
    record: any;
    props: any;

  }
  interface PreviewModalProps extends WithTranslation { }
  
  const PreviewModal: React.FC<PreviewModalProps> = ( { show, onCloseClick, record ,ontoggle, open, setrecord, props}: any ) => {    
    const { id } = useParams() 
    const [filterObject, setFilterObject] = useState<any>({
        applicationId: id
    });
    const [info, setInfo] = useState<any>();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const notes = useSelector((state: ApplicationState) => state.applications.notes);
  
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    useEffect(() => {
        let info =  applicationsDetails && applicationsDetails.valuesJson  ? applicationsDetails.valuesJson : {}
        setInfo({...info,...applicationsDetails})
    }, [])
     useEffect(() => {
        if(applicationsDetails && applicationsDetails.id) {
            // setrecord(applicationsDetails)
            dispatch(applicationGetRequest(applicationsDetails?.id))
        }
        const filters = {pageSize: 100}
        dispatch(applicationsMetadataGetRequest("applications",filters));
    }, [])
    useEffect(() => {
        if(record && record.id) {
            let studentsId = record?.students?.id            
            let filters = { applicationId: record.id }
            dispatch(notesListGetRequest(studentsId, filters))   
        }
    }, [applicationsDetails])
   return ( <>
         {
                record ? 
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"                
                    backdrop={false}
                    toggle={onCloseClick}
                >
                    <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
                        {record.name}
                    </OffcanvasHeader>
                    {/* <form action="" className="d-flex flex-column justify-content-end h-100"> */}
                        <OffcanvasBody> 
                        <Col xxl={12} md={12} lg={12}>
                            <Card id="contact-view-detail">
                                <CardBody className="text-center">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto">
                                            <img
                                            src={avatar10}
                                            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                            alt="user-profile"
                                            />                                                
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                            <Input
                                                id="profile-img-file-input"
                                                type="file"
                                                className="profile-img-file-input"
                                            />
                                            <Label
                                                htmlFor="profile-img-file-input"
                                                className="profile-photo-edit avatar-xxs"
                                            >
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                <i className="ri-camera-fill"></i>
                                                </span>
                                            </Label>
                                            </div>
                                        </div>
                                        {/* <EditableLabel label={name} onSave={handleNameSave}/> */}
                                        {/* <h5 className="mt-4 mb-1">{info.course_name}</h5> */}
                                        <p className="text-muted">{info?.student?.name}</p>
                                        </div>
                                </CardBody>
                            </Card>
                            </Col>
                            <div>
                                <UncontrolledAccordion toggle={()=>{}} defaultOpen={["notes"]}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="about">
                                            <h6 className="fw-bold fs-16">{props.t("applications.about_this_application")}</h6>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="about">
                                            <ApplicationInfo record={info}/>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="notes" className="fw-bold">
                                            <h6 className="fw-bold  fs-16">{props.t("applications.notes")}</h6>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="notes">  
                                        <SimpleBar className="mt-3 mx-n2 px-4 file-menu-sidebar-scroll">
                                                <div style={{ display: 'inline-block', minWidth: '100%', whiteSpace: 'nowrap' }}>
                                                <Notes notes={notes} record={record} /> 
                                                </div>
                                            </SimpleBar>                                          
                                                                        
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                            <Button
                            color="primary"
                                className="btn w-100"
                                onClick={() => onCloseClick()}
                            >
                                Cancel
                            </Button>
                        </div>
                    {/* </form> */}
                </Offcanvas>
                : null
            }
        </>
    );
};

export default withTranslation()(PreviewModal);
