import React, { useState } from "react";
import { Input, Label } from "reactstrap";

const RulesEdit = () => {
  const [isMinChecked, setIsMinChecked] = useState(false);
  const [isMaxChecked, setIsMaxChecked] = useState(false);
  const [tealCounter, settealCounter] = useState<number>(5);
  const [tealCounter1, settealCounter1] = useState<number>(5);
  function countUP(id: any, prev_data_attr: any) {
    id(prev_data_attr + 1);
  }
  function countDown(id: any, prev_data_attr: any) {
    id(prev_data_attr - 1);
  }
  const handleMinCheckboxChange = () => {
    setIsMinChecked(!isMinChecked);
  };
  const handleMaxCheckboxChange = () => {
    setIsMaxChecked(!isMaxChecked);
  };
  return (
    <div className="text-dark">
      <div className="mb-2">
        <h5 className="hstack py-1 form-label">Select property rules</h5>
      </div>

      <div className="mt-3">
        <Label className="hstack py-1 form-label">Property visibility</Label>
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultIndeterminateCheck"
          />
          <text className="fs-16 fw-light form-check-label">
            Show in forms, pop-up forms and bots
          </text>
        </div>
      </div>

      <div className="mt-3">
        <Label className="hstack py-1 form-label">Validation rules</Label>
        <div className="form-check">
          <div>
            Specify what values are allowed for this property. These rules apply
            when creating, editing, and importing records with the CRM.
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultIndeterminateCheck"
          />
          <text className="fs-16 fw-light form-check-label">
            Required field
          </text>
        </div>
      </div>
      <div className="mt-3">
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultIndeterminateCheck"
          />
          <text className="fs-16 fw-light form-check-label">
            Require unique values for this propperty{" "}
            <strong className="text-secondary">(0 of 10)</strong>
          </text>
        </div>
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-check">
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultIndeterminateCheck"
              onClick={handleMinCheckboxChange}
              // checked={handleCheckboxChange}
            />
            <text className="fs-16 fw-light form-check-label">
              Set min character limit
            </text>
          </div>
          {isMinChecked ? (
            <div className="input-step step-info">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown(settealCounter, tealCounter);
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={tealCounter}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP(settealCounter, tealCounter);
                }}
              >
                +
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-check">
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultIndeterminateCheck"
              onClick={handleMaxCheckboxChange}
            />
            <text className="fs-16 fw-light form-check-label">
              Set max character limit
            </text>
          </div>
          {isMaxChecked ? (
            <div className="input-step step-info">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown(settealCounter1, tealCounter1);
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={tealCounter1}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP(settealCounter1, tealCounter1);
                }}
              >
                +
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-3">
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultIndeterminateCheck"
          />
          <div>
            <div>
              <text className="fs-16 fw-light form-check-label">
                Retrict to numeric values
              </text>
            </div>
            <div>
              <small>
                Don't allow alpha or special characters like a, @, or $ for this
                property
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultIndeterminateCheck"
          />
          <div>
            <div>
              <text className="fs-16 fw-light form-check-label">
                Don't allow special characters
              </text>
            </div>
            <div>
              <small>
                Don't allow special characters like @, #, or & for this property
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RulesEdit;
