import { Reducer } from "redux";
import { SourceActionTypes, SourceState } from "./types";


export const initialState: SourceState =
{
    sourcesMetadata: [],
    sources: null,
    source: null,
    pagination: null,
    sourcelist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    hierarchysources: [],
    loading: false,
    dataloading: false,
    metaloading: false,
}
const reducer: Reducer<SourceState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case SourceActionTypes.GET_SOURCEMETADATA_REQUEST: {
            return { ...state, metaloading: true };
        }
        case SourceActionTypes.GET_SOURCEMETADATA_SUCCESS: {
            return {
                ...state,
                metaloading: false,
                sourcesMetadata: action.payload.content
            };
        }
        case SourceActionTypes.GET_SOURCEMETADATA_ERROR: {
            return { ...state, metaloading: false, errors: action.payload };
        }
        case SourceActionTypes.GET_SOURCE_REQUEST: {
            return { ...state, loading: true };
        }
        case SourceActionTypes.GET_SOURCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                source: action.payload
            };
        }
        case SourceActionTypes.GET_SOURCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case SourceActionTypes.POST_SOURCE_REQUEST: {
            return { ...state, loading: true };
        }
        case SourceActionTypes.POST_SOURCE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case SourceActionTypes.POST_SOURCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case SourceActionTypes.GET_SOURCES_REQUEST: {
            return { ...state, dataloading: true };
        }
        case SourceActionTypes.GET_SOURCES_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                sources: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case SourceActionTypes.GET_SOURCES_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }

        
        case SourceActionTypes.GET_SOURCES_HIERARCHY_REQUEST: {
            return { ...state, loading: true };
        }
        case SourceActionTypes.GET_SOURCES_HIERARCHY_SUCCESS: {
            return {
                ...state,
                loading: false,
                hierarchysources: action.payload,
            };
        }
        case SourceActionTypes.GET_SOURCES_HIERARCHY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case SourceActionTypes.PUT_SOURCE_REQUEST: {
            return { ...state, loading: true };
        }
        case SourceActionTypes.PUT_SOURCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                sourcelist: action.payload.content
            };
        }
        case SourceActionTypes.PUT_SOURCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case SourceActionTypes.DELETE_SOURCE_REQUEST: {
            return { ...state, loading: true };
        }
        case SourceActionTypes.DELETE_SOURCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case SourceActionTypes.DELETE_SOURCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as SourceReducer };
