import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
import university from '../../../src/assets/images/dtsimg/svg/university.svg';
import OverviewTab from './OverviewTab';
import { useNavigate, useParams } from 'react-router';
import { useFetcher } from 'react-router-dom';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { productGetRequest } from 'store/products/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { propertyListGetRequest } from 'store/properties/action';
import UserAvatar from 'pages/SubAgentDetails/UserAvatar';


const Section = () => {
	const navigate = useNavigate();
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const ProductDetail = useSelector((state: ApplicationState) => state.products.product)
    const productsmetaData = useSelector((state: ApplicationState) => state.properties.list)
    const [activeTab, setActiveTab] = useState<string>('1');
    const [info, setinfo] = useState<any>({});
    const [metaData, setmetadata] = useState<any>({});
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(productGetRequest(id))
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("products",filters));
    },[]) 

    useEffect(() => {
       setmetadata(productsmetaData)
    },[productsmetaData])

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const goback = () => {
		navigate(-1);
	  };

    
    useEffect(() => {
        let details = ProductDetail?.valuesJson  ? ProductDetail.valuesJson : {}
        setinfo(details)
    },[ProductDetail]) 
    return (
        <React.Fragment>
             <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info.img} firstName={info?.name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        {info?.city ? <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.city}</div> : null}
                                                        <div className="vr"></div>
                                                        {ProductDetail?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(ProductDetail?.createdAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {ProductDetail?.updatedAt ?<div>Updated Date : <span className="fw-medium">{moment(ProductDetail?.updatedAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {/* <div className="badge rounded-pill bg-info fs-12">New</div>
                                                        <div className="badge rounded-pill bg-danger fs-12">High</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-bold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Finance
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row>
                <Col lg={12}>
                    <Button color="primary" className="w-auto btn-sm" onClick={() => goback()}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <OverviewTab info={info} metaData={metaData} />
                    </TabPane>
                    {/* <TabPane tabId="2">
                        <DocumentsTab />
                    </TabPane>
                    <TabPane tabId="3">
                        <ActivitiesTab />
                    </TabPane>
                    <TabPane tabId="4">
                        <TeamTab />
                    </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;