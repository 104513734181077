import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FeatherIcon from "feather-icons-react";

import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest, notesListGetRequest } from "store/note/action";

interface FProps {
  props: any;
  notesModals: boolean;
  setNotesModals: (event: boolean) => void;
  onAddNote: (content: string) => void;
  record: any
}

const NotesModal: React.FC<FProps> = ({
  props,
  notesModals,
  setNotesModals,
  onAddNote,
  record
}) => {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const {id} = useParams();
  const [noteInput, setNoteInput] = useState<string>("");
  const [newNote, setNewNote] = useState("");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteInput(e.target.value);
  };
  const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
  };
  const handleAddNote = () => {
    const data = 
      {
        applicationId:record.id,
        studentId: id,
        parentId: "",
        note: newNote
      }
    const handleSuccess = (body: any): void => {
      const message = () => toast(`Note added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      setNewNote("");
      setNotesModals(false)
      // dispatch(notesListGetRequest(id));
    };
  
    const handleError = (body: any): void => {
      const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      message();
    };
  
    dispatch(noteAddRequest(data, handleSuccess, handleError));
  };

  return (
    <Col lg={9}>
      <Modal  backdrop={"static"}  className="w-75" id="modalForm" isOpen={notesModals} toggle={() => {
            setNotesModals(false);
          }} centered={true} size="lg">
        <ModalHeader className="p-2 bg-info-subtle" toggle={() => {
            setNotesModals(false);
          }}>
          {"Add New Note"}
        </ModalHeader>
        <ModalBody className="my-2 p-1">
        <Col xs={12}>
                <textarea
                rows={3}
                  id="text"
                  className="form-control"
                  placeholder="Enter Note"
                  name="text"
                  onChange={handleNewNoteChange}
                  value={newNote}
                ></textarea>
              </Col>
              <Col xs={12} className="mt-1 text-end">
              {/* <button type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button> */}
              <Button className="btn btn-sm btn-success" disabled={!newNote} onClick={handleAddNote}>Add Note</Button>
            </Col>
        </ModalBody>
        </Modal>
    </Col>
  );
};

export default withTranslation()(NotesModal);
