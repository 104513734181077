import produce from 'immer';
import { Reducer } from "redux";
import { BanksActionTypes, BanksState } from "./types";


export const initialState: BanksState =
{
    banksMetadata: [],
    banks: null,
    bank: null,
    pagination: null,
    banklist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    hierarchybanks: [],
    loading: false,
}
const reducer: Reducer<BanksState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case BanksActionTypes.GET_BANKMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.GET_BANKMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                banksMetadata: action.payload.content
            };
        }
        case BanksActionTypes.GET_BANKMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case BanksActionTypes.GET_BANK_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.GET_BANK_SUCCESS: {
            return {
                ...state,
                loading: false,
                bank: action.payload
            };
        }
        case BanksActionTypes.GET_BANK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case BanksActionTypes.POST_BANK_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.POST_BANK_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case BanksActionTypes.POST_BANK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case BanksActionTypes.GET_BANKS_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.GET_BANKS_SUCCESS: {
            return {
                ...state,
                loading: false,
                banks: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case BanksActionTypes.GET_BANKS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        
        case BanksActionTypes.GET_BANKS_HIERARCHY_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.GET_BANKS_HIERARCHY_SUCCESS: {
            return {
                ...state,
                loading: false,
                hierarchybanks: action.payload,
            };
        }
        case BanksActionTypes.GET_BANKS_HIERARCHY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case BanksActionTypes.PUT_BANK_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.PUT_BANK_SUCCESS: {
            return {
                ...state,
                loading: false,
                banklist: action.payload.content
            };
        }
        case BanksActionTypes.PUT_BANK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case BanksActionTypes.DELETE_BANK_REQUEST: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.DELETE_BANK_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case BanksActionTypes.DELETE_BANK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as BanksReducer };
