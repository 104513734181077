import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FeatherIcon from "feather-icons-react";
import Flatpickr from "react-flatpickr";
import { Button, Input, Label, Modal, ModalBody } from "reactstrap";

interface FProps {
  props: any;
  meetingsModals: boolean;
  setMeetingsModals: (event: boolean) => void;
}

const MeetingsModal: React.FC<FProps> = ({
  props,
  meetingsModals,
  setMeetingsModals,
}) => {
  return (
    <div>
      <div>
        <Modal
          id="loginModals"
          tabIndex={1}
          isOpen={meetingsModals}
          toggle={() => {
            setMeetingsModals(false);
          }}
          centered
        >
          <div className="modal-content border-0 overflow-hidden">
            <ModalBody className="d-flex justify-content-between flex-row align-items-end login-modal p-2 h-0">
              <div>
                <h5 className="text-white fs-18 mt-n19">
                  {props.t("student.meetings")}
                </h5>
              </div>
              <div className="d-flex align-items-end">
                <h5 className="text-white fs-12 mt-n19">
                  <Button
                    color="danger"
                    className="btn-icon btn-sm mt-2"
                    onClick={() => {
                      setMeetingsModals(false);
                    }}
                  >
                    <FeatherIcon icon="x" className="icon-xs" />
                  </Button>
                </h5>
              </div>
            </ModalBody>
            <ModalBody className="p-3">
              <div>
                <Label htmlFor="disabledInput" className="form-label">
                  {props.t("student.host")}
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="disabledInput"
                  defaultValue="Anna Adame(annaadame@gmail.com)"
                  disabled
                />
              </div>
              <div className="mb-2">
                <Label htmlFor="disabledInput" className="form-label">
                  {props.t("student.title")}
                </Label>
                <Input type="text" className="form-control" defaultValue="" />
              </div>
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <div>
                  {props.t("student.start_date")}
                  <div>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                    />
                  </div>
                </div>
                <div>
                  {props.t("student.start_time")}
                  <div>
                    <Input
                      type="time"
                      className="form-control"
                      id="startTime"
                    />
                  </div>
                </div>
                <div>
                  {props.t("student.end_time")}
                  <div>
                    <Input type="time" className="form-control" id="endTime" />
                  </div>
                </div>
              </div>
              <CKEditor
                editor={ClassicEditor}
                data="<p>Hello from CKEditor 5!</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                // onChange={(editor) => {
                //   editor.getData();
                // }}
              />
              <div className="d-flex justify-content-start gap-3 mt-2">
                <Button className="btn-soft" size="sm">
                  {props.t("student.save")}
                </Button>
                <Button
                  className="btn-soft"
                  size="sm"
                  onClick={() => {
                    setMeetingsModals(false);
                  }}
                >
                  {props.t("student.cancel")}
                </Button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default withTranslation()(MeetingsModal);
