import { Reducer } from "redux";
import { InvoiceActionTypes, InvoiceState } from "./types";


export const initialState: InvoiceState =
{
    invoicesMetadata: [],
    invoices: null,
    invoice: null,
    pagination: null,
    invoicelist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    hierarchyinvoices: [],
    loading: false,
}
const reducer: Reducer<InvoiceState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case InvoiceActionTypes.GET_INVOICEMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.GET_INVOICEMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoicesMetadata: action.payload.content
            };
        }
        case InvoiceActionTypes.GET_INVOICEMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case InvoiceActionTypes.GET_INVOICE_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.GET_INVOICE_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoice: action.payload
            };
        }
        case InvoiceActionTypes.GET_INVOICE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case InvoiceActionTypes.POST_INVOICE_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.POST_INVOICE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case InvoiceActionTypes.POST_INVOICE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case InvoiceActionTypes.GET_INVOICES_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.GET_INVOICES_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoices: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case InvoiceActionTypes.GET_INVOICES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        
        case InvoiceActionTypes.GET_INVOICES_HIERARCHY_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.GET_INVOICES_HIERARCHY_SUCCESS: {
            return {
                ...state,
                loading: false,
                hierarchyinvoices: action.payload,
            };
        }
        case InvoiceActionTypes.GET_INVOICES_HIERARCHY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case InvoiceActionTypes.PUT_INVOICE_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.PUT_INVOICE_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoicelist: action.payload.content
            };
        }
        case InvoiceActionTypes.PUT_INVOICE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case InvoiceActionTypes.DELETE_INVOICE_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoiceActionTypes.DELETE_INVOICE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case InvoiceActionTypes.DELETE_INVOICE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as InvoiceReducer };
