import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { StudentListGetRequest, studentGetRequest, studentUpdateRequest, studentsMetadataGetRequest } from "store/student/action";
import SimpleBar from "simplebar-react";
import { propertyListGetRequest } from "store/properties/action";
import { templateListGetRequest, templateGetRequest, templateUpdateRequest } from "store/templates/action";
// Define types or interfaces
interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const TemplateInfo = () => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metadata = useSelector((state: ApplicationState) => state.properties.list);
    const template: any = useSelector((state: ApplicationState) => state.templates.template);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit]= useState<any>(false);

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setEdit(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.templates;
            if(access && access?.length) {
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                       }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const onUpdate = (formValues: any) => {
        let data = { ...template, valuesJson: formValues };
        const handleSuccess = (body: any) => {
            const message = () => toast(`Template updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(templateListGetRequest());
            dispatch(templateGetRequest(id))
        }
        dispatch(templateUpdateRequest(id, formValues, handleSuccess, () => { }))
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("templates", filters));
    }, [])

    const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => ( subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metadata && metadata.map((field: any) => {         
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {}            
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) :  true;                 
            if(isFieldviewaccess) {
                if(isFieldEditaccess) {                    
                    let newField = {...field, isEditAccess: true}
                    return newstatusMetaData.push(newField)
                } 
                return newstatusMetaData.push(field)   
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metadata])

    return (
        <Row className="justify-content-start">            
            <Col md={6} lg={6}>                
                <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={template} formfor={"templates"} isEditAccess={edit} />
            </Col>
        </Row>

    );
};

export default TemplateInfo;
