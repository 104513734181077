import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Badge } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableContainer';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { InvoiceListGetRequest, invoiceDelete, invoiceGetRequest, invoicesMetadataGetRequest } from 'store/invoices/action';
import { ToastContainer, toast } from 'react-toastify';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import AccessDenied from '../AccessDenied/Denied';


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Sources = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const invoiceMetadata = useSelector((state: ApplicationState) => state.invoices.invoicesMetadata);
    const invoiceList = useSelector((state: ApplicationState) => state.invoices.invoices);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.invoices.pagination); 
    const loading = useSelector((state: ApplicationState) => state.invoices.loading); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])  
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [metaData, setMetaData] = useState(invoiceMetadata);
    const [invoices, setInvoices] = useState([]);
    const [invoiceJson, setinvoiceJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [invoice, setInvoice] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [access, setAccess] = useState<any>([]);
    const [onAddNote, setOnAddNote] = useState<string>("");
	// Delete Multiple
	const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const model = "channels"
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

	const onClickDelete = (invoice: any) => {
		setInvoice(invoice);
		setDeleteModal(true);
	};

	const handleDeleteInvoice = () => {
		if (invoice) {
			setDeleteModal(false);
		}
	};


	const handleValidDate = (date: any) => {
		const date1 = moment(new Date(date)).format("DD MMM Y");
		return date1;
	};

	const handleValidTime = (time: any) => {
		const time1 = new Date(time);
		const getHour = time1.getUTCHours();
		const getMin = time1.getUTCMinutes();
		const getTime = `${getHour}:${getMin}`;
		var meridiem = "";
		if (getHour >= 12) {
			meridiem = "PM";
		} else {
			meridiem = "AM";
		}
		const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
		return updateTime;
	};

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(InvoiceListGetRequest(model, filters, 0));
        setinvoiceJson([])
    }

    useEffect(() => {
        const filters = {pageSize: 100}
        dispatch(invoicesMetadataGetRequest("invoices", filters));
        setMetaData(invoiceMetadata)
    }, [])

    useEffect(() => {
        setMetaData(invoiceMetadata);
    }, [invoiceMetadata]);


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        setData(lead)
        dispatch(invoiceGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(InvoiceListGetRequest(model, filterObject, newPageNo));
        setinvoiceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Invoice Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(InvoiceListGetRequest(model))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(invoiceDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const toggleEnable = (el1: any) => {
        let a: any = document.getElementById(el1.target.id)
        a.disabled = true;
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

	
	// Checked All
	const checkedAll = useCallback(() => {
		const checkall: any = document.getElementById("checkBoxAll");
		const ele = document.querySelectorAll(".invoiceCheckBox");

		if (checkall.checked) {
			ele.forEach((ele: any) => {
				ele.checked = true;
			});
		} else {
			ele.forEach((ele: any) => {
				ele.checked = false;
			});
		}
		deleteCheckbox();
	}, []);

	const deleteCheckbox = () => {
		const ele: any = document.querySelectorAll(".invoiceCheckBox:checked");
		ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
		setSelectedCheckBoxDelete(ele);
	};

	
    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {}
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}                
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;           
                if(fields.tablecolumn && fields.key != 'invoice_status') {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: true,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if(fields.type=='select' || fields.type=='radio' || fields.type=='checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            if(fields.key == 'payment_status') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                switch (rendervalue) {
                                    case "Paid":
                                      return <span className="badge text-uppercase bg-success-subtle text-success"> {rendervalue} </span>;
                                    case "Unpaid":
                                      return <span className="badge text-uppercase bg-warning-subtle text-warning"> {rendervalue} </span>;
                                    case "Partially Paid":
                                      return <span className="badge text-uppercase bg-danger-subtle text-danger"> {rendervalue} </span>;
                                      case "Advanced Paid":
                                        return <span className="badge text-uppercase bg-success-subtle text-danger"> {rendervalue} </span>;
                                      
                                      case "Cancel":
                                        return <span className="badge text-uppercase bg-danger-subtle text-danger"> {rendervalue} </span>;
                                    default:
                                      return <span className="badge text-uppercase bg-primary-subtle text-primary"> {rendervalue} </span>;
                                  }
                            }
                            return  <div className="d-flex align-items-center justify-content-between">                                      {
                                            fields.key == "channel_name" ? <Row style={{width: 250}} className='justfy-content-start align-items-center'>                                           
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                    <Link to={""} className="text-decoration-none text-ellipsis" >                                            
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                    {cellProps.row.original['invoice_status'] == true || cellProps.row.original['invoice_status'] == 'true' ? 
                                                    <Badge color='warning' pill>SENT</Badge>
                                                        : 
                                                        <Badge color='success' pill>SAVED</Badge>}
                                                </Col>                                           
                                            </Row> : 
                                            <div>
                                                {rendervalue}
                                            </div>
                                        }
                                    </div>
                        }
                    }
                    columns.push(newColumn)
                }   
            });
            const nameColumnIndex = columns.findIndex(column => column.accessor === "channel_name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            const index = columns.findIndex(column => column.accessor === "paidAmount");
            if(index == -1) {
                columns.push({
                    Header:"Paid Amount",
                    accessor: "paidAmount",
                    id: "paidAmount",
                    filterable: true,
                    isSortable: false,
                    disableFilters: true,
                    show: true,
                    Cell: (cellProps: any) => {
                        return  <div className="d-flex align-items-center justify-content-between">                                
                                   {cellProps.row.original["paidAmount"]}
                            </div>
                    }
                })
            }
            columns.push({
                Header:"Invoice Date",
                accessor: "invoiceDate",
                id: "invoiceDate",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["invoiceDate"] ? moment(cellProps.row.original["invoiceDate"]).format('DD, MMM, YYYY'):
                    ''}
                        </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile]);

    useEffect(() => {
        setMetaData(invoiceMetadata)
    }, [invoiceMetadata != undefined])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

   
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {       
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(InvoiceListGetRequest( model, filters));
            setinvoiceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          dispatch(InvoiceListGetRequest(model, filters));
            setinvoiceJson([])
        }
      };


    const ClearFilter = () => {
        setsearch("")
        setstatusvalue("")
        setApplyFilter(false);
        setFilterJson([])
        setFilterCount(0)
        setPageSize(pageSize)
        let filter= {pageSize: pageSize}
        setFilterObject(filter)
        setPagination({page: 0})
        dispatch(InvoiceListGetRequest(model, filter));
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'invoice_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a= isEqual(invoiceList, invoiceJson)
        return !a
     }

    useEffect(() => {
        const parsedData: any = invoiceList && invoiceList.length ? invoiceList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, id: item.id , createdAt: item.createdAt, paidAmount: valuesJson.paidAmount}
            return values
        }) : []
        let invoice = parsedData && parsedData.length && parsedData.reverse() 
        setInvoices(invoice)
        setinvoiceJson(invoiceList)
    }, [execute()])

    
    useEffect(() => {
        dispatch(InvoiceListGetRequest(model, filterObject,0))
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(InvoiceListGetRequest(model, filters, 0));
        setinvoiceJson([])
    }
    
    const onChangeIndex = (column: any,index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

	const invoiceWidgets = [
		{
			id: 1,
			label: "Invoices Sent",
			percentage: "+89.24 %",
			percentageClass: "success",
			icon: "ri-arrow-right-up-line",
			counter: 559.25,
			badge: "2,258",
			caption: "Invoices sent",
			feaIcon: "file-text",
			decimals: 1,
			prefix: "$",
			suffix: "k"
		},
		{
			id: 2,
			label: "Paid Invoices",
			percentage: "+8.09 %",
			percentageClass: "danger",
			icon: "ri-arrow-right-down-line",
			counter: 409.66,
			badge: "1,958",
			caption: "Paid by clients",
			feaIcon: "check-square",
			decimals: 2,
			prefix: "$",
			suffix: "k"
		},
		{
			id: 3,
			label: "Unpaid Invoices",
			percentage: "+9.01 %",
			percentageClass: "danger",
			icon: "ri-arrow-right-down-line",
			counter: 136.98,
			badge: "338",
			caption: "Unpaid by clients",
			feaIcon: "clock",
			decimals: 2,
			prefix: "$",
			suffix: "k"
		},
		{
			id: 4,
			label: "Cancelled Invoices",
			percentage: "+7.55 %",
			percentageClass: "success",
			icon: "ri-arrow-right-up-line",
			counter: 84.2,
			badge: "502",
			caption: "Cancelled by clients",
			feaIcon: "x-octagon",
			decimals: 1,
			prefix: "$",
			suffix: "k"
		},
	];

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}   
          setFilterObject(filters)       
          dispatch(InvoiceListGetRequest(model, filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(InvoiceListGetRequest(model, filters, pagination.page));
        }
      };

    document.title = "DTS | Zilter";
    return ( 
        <>
        {
            viewAccess ?
    <React.Fragment>
            <ToastContainer />   
        {isDynamicModalOpen && <DynamicFilters
            show={isDynamicModalOpen}
            onCloseClick={() => setIsDynamicModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
            filterJson={filterJson}
            setFilterJson={setFilterJson}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
        />}
        {previewModal && <PreviewModal
            show={previewModal}
            onCloseClick={() => setpreview(false)}
            record={record}
            ontoggle={toggle}
            open={open}
            setrecord={setrecord}
            metaData={metaData}
            data={data}
        />}
        {isDeleteOpen && <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />}
		
		{/* <Row>
			{invoiceWidgets.map((invoicewidget, key) => (
				<React.Fragment key={key}>
					<Col xl={3} md={6}>
						<Card className="card-animate">
							<CardBody>
								<div className="d-flex align-items-center">
									<div className="flex-grow-1">
										<p className="text-uppercase fw-medium text-muted mb-0">
											{invoicewidget.label}
										</p>
									</div>
									<div className="flex-shrink-0">
										<h5
											className={
												"fs-14 mb-0 text-" + invoicewidget.percentageClass
											}
										>
											<i className="ri-arrow-right-up-line fs-13 align-middle"></i>{" "}
											{invoicewidget.percentage}
										</h5>
									</div>
								</div>
								<div className="d-flex align-items-end justify-content-between mt-4">
									<div>
										<h4 className="fs-22 fw-semibold ff-secondary mb-4">
											<CountUp
												start={0}
												prefix={invoicewidget.prefix}
												suffix={invoicewidget.suffix}

												end={invoicewidget.counter}
												duration={4}
												className="counter-value"
											/>
										</h4>
										<span className="badge bg-warning me-1">
											{invoicewidget.badge}
										</span>{" "}
										<span className="text-muted">
											{" "}
											{invoicewidget.caption}
										</span>
									</div>
									<div className="avatar-sm flex-shrink-0">
										<span className="avatar-title bg-light rounded fs-3">
											<FeatherIcon
												icon={invoicewidget.feaIcon}
												className="text-success icon-dual-success"
											/>
										</span>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</React.Fragment>
			))}
		</Row>         */}
		<Row>
				<Col lg={12}>
					<Card id="invoiceList">
						<CardHeader className="border-0">
							<div className="d-flex align-items-center">
								<h5 className="card-title mb-0 flex-grow-1">{props.t("invoices.channel_invoices")}</h5>
							</div>
							<Row className='hstack gap-3 my-2'>
				<Col md={3} sm={12} xl={3} xxl={3} lg={3}>
					<div className="search-box">
						<Input
							type="text"
							size={14}
							className="search"
                            onKeyDown={handleKeyDown}
							placeholder={props.t("invoices.search_for")}
							onChange={(e) => handleChange(e.target.value)}
							value={search}
						/>
						{search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

					</div>
				</Col>
				
			</Row>
						</CardHeader>
						<CardBody className="pt-0">
							<div>
								<TableContainer
									columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={invoices || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    access={access}
                                    loading={loading}
								/>
								<ToastContainer closeButton={false} limit={1} />
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
    </React.Fragment>
    : <AccessDenied />
    }
    </>
    );
};
export default withTranslation()(Sources);


