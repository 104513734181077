import { Reducer } from "redux";
import { UniversityActionTypes, UniversityState } from "./types";


export const initialState: UniversityState =
{
    universitiesMetadata: [],
    universities: null,
    university: null,
    pagination: null,
    universitylist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    loading: false,
    dataloading: false
}
const reducer: Reducer<UniversityState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case UniversityActionTypes.GET_UNIVERSITYMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case UniversityActionTypes.GET_UNIVERSITYMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                universitiesMetadata: action.payload.content
            };
        }
        case UniversityActionTypes.GET_UNIVERSITYMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UniversityActionTypes.GET_UNIVERSITY_REQUEST: {
            return { ...state, loading: true };
        }
        case UniversityActionTypes.GET_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                university: action.payload
            };
        }
        case UniversityActionTypes.GET_UNIVERSITY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case UniversityActionTypes.POST_UNIVERSITY_REQUEST: {
            return { ...state, loading: true };
        }
        case UniversityActionTypes.POST_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case UniversityActionTypes.POST_UNIVERSITY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case UniversityActionTypes.GET_UNIVERSITIES_REQUEST: {
            return { ...state, dataloading: true };
        }
        case UniversityActionTypes.GET_UNIVERSITIES_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                universities: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case UniversityActionTypes.GET_UNIVERSITIES_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }
        case UniversityActionTypes.PUT_UNIVERSITY_REQUEST: {
            return { ...state, loading: true };
        }
        case UniversityActionTypes.PUT_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                universitylist: action.payload.content
            };
        }
        case UniversityActionTypes.PUT_UNIVERSITY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UniversityActionTypes.DELETE_UNIVERSITY_REQUEST: {
            return { ...state, loading: true };
        }
        case UniversityActionTypes.DELETE_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case UniversityActionTypes.DELETE_UNIVERSITY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as UniversityReducer };
