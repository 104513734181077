import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { StudentListGetRequest, studentGetRequest, studentUpdateRequest, studentsMetadataGetRequest } from "store/student/action";
import SimpleBar from "simplebar-react";
import ArrangeFields from "Components/Common/FieldsArranger/ArrangeComponent";
import { viewCreateRequest, ViewListGetRequest, viewUpdateRequest } from "store/views/action";

interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const StudentInfo = ({ fields, status }: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metadata = useSelector((state: ApplicationState) => state.student.studentsMetadata);
    const viewlist: any = useSelector((state: ApplicationState) => state.views.views);
    const student: any = useSelector((state: ApplicationState) => state.student.student);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [viewId, setViewId] = useState<any>('');
    const [edit, setEdit] = useState<any>(false);
    const [data, setData] = useState<any>({});

    const onUpdate = (keyValue: any, type?: any) => {
        const handleSuccess = (body: any) => {
            dispatch(studentGetRequest(id))
        }
        let istrue = keyValue && Object.keys(keyValue).length === 0
        if((type && type == 'file') || istrue || !keyValue){
            // const message = () => toast(`Student Updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
        }
        else {
            dispatch(studentUpdateRequest(id, keyValue, handleSuccess, () => { }))
        }
        
    }
    useEffect(() => {
        dispatch(ViewListGetRequest());
    }, [])

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(studentsMetadataGetRequest("students", filters));
    }, [])


    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.students;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metadata && metadata.map((field: any) => {
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
            if (isFieldviewaccess) {
                if (isFieldEditaccess) {
                    let newField = { ...field, isEditAccess: true }
                    return newstatusMetaData.push(newField)
                }
                return newstatusMetaData.push(field)
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metadata])

    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

    useEffect(() => {
        if(student && student.id) {
            let studentDetails = student.valuesJson;
            let neewData: any = {}
            statusMetaData && statusMetaData.map((item: any) => {
                let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
                let rendervalue = studentDetails[field.key];
                if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                    rendervalue = handleselectValue(studentDetails?.[field.key], field.values);
                }
                else if (field.optionLabel) {
                    rendervalue = studentDetails?.[field.key] && studentDetails?.[field.key]?.[field.optionLabel] ? studentDetails?.[field.key]?.[field.optionLabel] : 'NA'
                }
                neewData[field.key]= rendervalue;
            })
            setData({...neewData,id:student.id, studentId:student.id})
        }
    }, [student, statusMetaData])
    return (
        <div>
            <form action="" className="d-flex flex-column justify-content-end h-100">
                <div className="live-preview vstack gap-2">
                    <div className="live-preview vstack gap-2">
                        <SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
                            <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={data} formfor={"students"} fields={fields} isEditAccess={edit} />
                        </SimpleBar>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default StudentInfo;
