import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
} from "reactstrap";

const PreviewModal: React.FC<any> = ({recommendLoading, onRemove,disabled, handleButtonClick, props,link, createRecommend,handleselect,mainList, show, onCloseClick, record, ontoggle, open, setrecord, selectedlist, setSelectedList, ChannelDetail }: any) => {  
    const {id} = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const navigate = useNavigate();
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

  

    return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Review and Send
            </ModalHeader>
            <ModalBody>
                {
                        link ? 
                        <div className="p-5 ms-4 text-center">
                            <h5>
                                Shortlisted Course Sent Successfully...!
                            </h5>                            
                            <Button className="btn-success" onClick={() => handleButtonClick()}>Copy Link</Button>
                        </div>
                    :<div>
                    {!recommendLoading ? 
                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        <th>
                                            Country
                                        </th>
                                        <th>
                                            Course
                                        </th>
                                        <th>
                                            Institute
                                        </th>
                                        <th>
                                            Fee
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="task-list">
                                    {selectedlist && selectedlist.map((item: any, key: any) => {
                                        console.log("item", item)
                                        return <tr key={key}>
                                            <td>
                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(item, false)}
                                                            >
                                                    <i className="ri-delete-bin-fill"></i>
                                                </button>
                                            </td>
                                            <td>
                                            <Label>UK</Label>
                                            </td>
                                            <td>
                                                <label >{item?.course_name}</label>
                                            </td>
                                            <td>{item?.institutes?.name}</td>
                                            <td>
                                                £{item?.course_fee}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            
                        </div>: <DtsTablePlaceholder 
                            rows={selectedlist.length} cols={5}                                     
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                    </div>
                }
            </ModalBody>
            {
                        link ? null :
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createRecommend()}
                        disabled={disabled}
                    >
                        {props.t("courses.generate_shortlist")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("courses.cancel")}
                    </Button>
                </div>
            }
        </Modal>
    </>
    );
};

export default PreviewModal;
