import React from "react";
import { Card, CardBody, Input, Label } from "reactstrap";

const BasicInfo = () => {
  return (
    <div>
      <Card>
        <CardBody>
          <h5>
            0&nbsp;<small className="text-muted">(out of 7)</small>
          </h5>
        </CardBody>
      </Card>
      <div className="mb-2">
        <Label className="hstack py-1 form-label">Description</Label>
        <Input className="form-control=lg" />
      </div>
    </div>
  );
};
export default BasicInfo;
