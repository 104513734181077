import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import OverviewTab from './OverviewTab';
import { useNavigate, useParams } from 'react-router';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bankGetRequest, banksMetadataGetRequest } from 'store/banks/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import UserAvatar from 'Components/Common/UserAvtar/UserAvatar';
import moment from 'moment';


const Section = (props: any) => {
    const { bankId } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const BankDetails: any = useSelector((state: ApplicationState) => state.banks.bank)
    const metaData = useSelector((state: ApplicationState) => state.banks.banksMetadata)
    const [activeTab, setActiveTab] = useState<string>('1');
    const [info, setinfo] = useState<any>({});
	const navigate = useNavigate();
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(bankGetRequest(bankId))
        const filters = {pageSize: 100}
        dispatch(banksMetadataGetRequest(filters));
    }, [])

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    
    useEffect(() => {
        let details = BankDetails && BankDetails?.valuesJson && isJson(BankDetails?.valuesJson)  ? JSON.parse(BankDetails?.valuesJson) : BankDetails?.valuesJson ?  BankDetails?.valuesJson : {}
        setinfo({...details,  createdAt: BankDetails?.createdAt, updatedAt: BankDetails?.updatedAt})
    },[BankDetails]) 

    const goBack = () => {
		navigate(-1);
	  };

    console.log("info", info)
    return (
        <React.Fragment>
             <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info.img} firstName={info?.name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.city}</div>
                                                        <div className="vr"></div>
                                                        {info?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(info?.createdAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {info?.updatedAt ?<div>Updated Date : <span className="fw-medium">{moment(info?.updatedAt).format('lll')}</span></div> : null}
                                                         <div className="vr"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row>
                <Col lg={12}>
                    <Button color="primary" className="w-auto btn-sm" onClick={() => goBack()}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <OverviewTab info={info} metaData={metaData} props={props} />
                    </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(Section);