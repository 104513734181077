import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';
import AdvanceFilter from "./innerComponent/AdvanceFilter";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { ApplicationsListGetRequest, applicationDelete, applicationGetRequest, applicationUpdateRequest, applicationsMetadataGetRequest } from 'store/applications/action';
import { ApplicationsMetadata } from 'store/applications/types';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import AccessDenied from '../AccessDenied/Denied';
import avatar10 from "../../assets/images/users/user-dummy-img.jpg";
import { ToastContainer, toast } from 'react-toastify';
import MoreFilters from './innerComponent/MoreFilters';
import NotesModal from './Modals/NotesModal';
import EmailModal from './Modals/EmailModal';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import { useCCP } from '../../CCPContext/CCPContext';
import MeetingsModal from 'pages/StudentsDetails/Modals/MeetingsModal';
import { callAddRequest, callsListGetRequest } from 'store/calls/action';
import { viewGetRequest, viewUpdateRequest } from 'store/views/action';
import StatusFormModel from '../../Components/Common/StatusFormModal';
import QuickFilter from 'Components/Common/Filter/QuickFilter';
import Upload from './Upload/Upload';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Applications = (props: any) => {
    const { viewId } = props
    const { initializeCCP } = useCCP();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const applicationList = useSelector((state: ApplicationState) => state.applications.applicationlist);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const view: any = useSelector((state: ApplicationState) => state.views.view);
    const paginationDetails = useSelector((state: ApplicationState) => state.applications.pagination); 
    const loading = useSelector((state: ApplicationState) => state.applications.metaLoading); 
    const dataloading = useSelector((state: ApplicationState) => state.applications.dataloading); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])
    const [filterCount, setFilterCount] = useState<any>(0)
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [selectedSearchField, setSelectedSearchField]: any = useState({value: 'all', label: 'All'});
    const [pageSize, setPageSize] = useState<number>(10)
    const [studentID, setStudentID] = useState<any>()
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [fields, setFields] = useState<any>(null);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [metaData, setMetaData] = useState(applicationsMetadata);
    const [applications, setApplication] = useState([]);
    const [applicationsJson, setapplicationsJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] = useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [filter, setFilter]: any = useState(null);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(true);
    const [access, setAccess] = useState<any>([]);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [communicate, setCommunicate] = useState<any>(false);
    const [info, setInfo] = useState<any>({});
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [updateRow, setUpdateRow] = useState<any>(null);
    const [fileInfo, setFileInfo] = useState<any>({});
    const [data, setData] = useState(0)
    const [templateUploading, setTemplateUploading] = useState<boolean>(false);
    const [statusForm, setStatusForm] = useState<any>(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false,
            offset: 0
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };


    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value })
    }

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));

    }, [])

    useEffect(() => {
        setMetaData(applicationsMetadata);
    }, [applicationsMetadata]);

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(applicationGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCommunicate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
           
                   })
               }
            }            
            let communicateaccess = subLevelPermissionsList?.communicate;
            if (communicateaccess && communicateaccess?.length) {
                if (communicateaccess && communicateaccess.length) {
                    communicateaccess.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'communicate') {
                            item.value == "" || item.value == "none" ? setCommunicate(false) : setCommunicate(true)
                        }
           
                    })
                }
            }

        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(ApplicationsListGetRequest(filterObject, newPageNo));
        setapplicationsJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(ApplicationsListGetRequest(filterObject, pagination.page))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(applicationDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    const handleButtonClick = (record: any) => {
        initializeCCP();
        handleLogAWSCall(record);
    };

    const handleLogAWSCall = (record: any) => {
    
        const data = {
            studentId: record.student,
            applicationId: record.id,
            provider: "AWS"
        }
        const handleSuccess = (): void => {
            toast(`Call logged successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(callsListGetRequest(record.id));
        };
        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(callAddRequest(data, handleSuccess, handleError));
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const toggleEnable = (el1: any) => {
        let a: any = document.getElementById(el1.target.id)
        a.disabled = true;
    }

    const handleAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    const handleNoteModal = (record: any) => {
        setrecord(record)
        setNotesModals(true)
    }

    const onChangeStatus = (value: any, key: any, data: any, id: any) => {
        if (value?.fields?.length) {
            const formFields: any = []
            metaData.forEach((fieldJson: any) => {
                const field = fieldJson.valuesJson && isJson(fieldJson.valuesJson) ? JSON.parse(fieldJson.valuesJson) : {}
                const fieldExists = value?.fields.find((vField: any) => vField.value === field.key)
                if (fieldExists) {
                    if (field) {
                        if (!field.validate) {
                            field.validate = {}; // Initialize validate object if it doesn't exist
                        }
                        field.validate.required = fieldExists?.mandatory ?? false; // Set required based on mandatory, defaulting to false if undefined
                        formFields.push(field); // Push the updated field into formFields
                    }
                }
            })
            setStatusForm({ fields: formFields, id, existingData: data, status: { [key]: value.value } })
        } else {
            setUpdateRow({ ...data, [key]: value.value });
            const handleSuccess = (body: any) => {
                setTriggerApi((triggerApi: boolean) => !triggerApi)
            }
            dispatch(applicationUpdateRequest(id, { [key]: value.value }, handleSuccess, () => { }))
        }
    };
    const onUpdate = (formValues: any, form: any) => {
        const { id, status, existingData } = form;
        setUpdateRow({ ...existingData, ...status });
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const data = { ...formValues, ...status }
        dispatch(applicationUpdateRequest(id, data, handleSuccess, () => { }))
        setStatusForm(null)
    }

    const viewsColumns = () => {
        let columns: any[] = [];
        fields && fields.length && fields.map((item: any) => {
            const { extras } = item;
            if (metaData && metaData.length > 0) {
                metaData.map((fieldName) => {
                    if (fieldName.id == item.id) {
                        let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                        let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) : true;
                        if (isFieldviewaccess) {
                            let newColumn = {
                                Header: _.startCase(fields.label),
                                accessor: fields.key,
                                id: fields.key,
                                filterable: true,
                                isSortable: false,
                                disableFilters: true,
                                fieldid: fieldName.id,
                                valueJson: fields,
                                meta: fieldName,
                                show: extras.show,
                                Cell: (cellProps: any) => {
                                    let rendervalue = cellProps.row.original[fields.key];
                                    if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                        rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                    }
                                    else if (fields.key == 'course_link' && cellProps.row.original[fields.model] && cellProps.row.original[fields.model][fields.key] !== "NA") {
                                        return <div className='ms-3'>
                                            <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.model][fields.key])} />
                                        </div>
                                    }
                                    else if (fields.optionLabel && fields.optionLabel) {
                                        rendervalue = cellProps?.row?.original[fields.model] && cellProps?.row?.original[fields.model][fields.optionLabel] ? cellProps?.row?.original[fields.model][fields.optionLabel] : 'NA'
                                    }  
                                    return <div className="d-flex align-items-center justify-content-between">                                      {
                                        fields.key == "course_name" ?   
                                            <Row style={{ width: 250 }} className='justfy-content-start align-items-center'>
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                    <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                    <div className='hstack'>
                                                        <Button
                                                            size='sm'
                                                            onClick={() => { setpreview(true); handleLeadClick(cellProps.row.original) }}
                                                            color="primary"
                                                            title='Preview'
                                                            className="btn-primary justfy-self-end hoverbutton">
                                                            <i className="ri-layout-right-2-fill label-icon align-middle fs-16"></i>
                                                        </Button>
                                                        {
                                                            communicate ?
                                                                <Button
                                                                    onClick={() => {
                                                                        handleButtonClick(cellProps.row.original)
                                                            
                                                                    }}
                                                                    title='Call'
                                                                    className="hoverbutton justfy-self-end btn btn-sm btn-success remove-list">
                                                                    <i className="ri-phone-fill label-icon align-middle fs-16"></i>
                                                                </Button> : null}
                                                        <Button
                                                            onClick={() => {
                                                                handleNoteModal(cellProps.row.original)
                                                            }}
                                                            title='Note'
                                                            className="btn btn-sm hoverbutton justfy-self-end btn-info remove-list">
                                                            <i className="ri-sticky-note-fill label-icon align-middle fs-16"></i>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            :
                                            <div>
                                                {rendervalue}
                                            </div>
                                    }
                                    </div>
                                }
                            }
                            columns.push(newColumn)
                        }
                    }
                });
            }
        });        
        if (metaData && metaData.length > 0 && fields && fields.length) {
            const result = metaData.filter(item => !fields.some((field: any) => (item.id === field.id)))
            if (result && result.length > 0) {
                result.map((fieldName: any) => {     
                    let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                    let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                    let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) : true;
                    if (isFieldviewaccess) {
                        let newColumn = {
                            Header: _.startCase(fields.label),
                            accessor: fields.key,
                            id: fields.key,
                            filterable: true,
                            isSortable: false,
                            disableFilters: true,
                            fieldid: fieldName.id,
                            valueJson: fields,
                            meta: fieldName,
                            show: fields.tablecolumn,
                            Cell: (cellProps: any) => {
                                let rendervalue = cellProps.row.original[fields.key];
                                if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                    rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                }
                                else if (fields.key == 'course_link' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
                                    return <div className='ms-3'>
                                        <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.key])} />
                                    </div>
                                }
                                else if (fields.optionLabel) {
                                    rendervalue = cellProps.row.original[fields.optionLabel]
                                }
                                return <div className="d-flex align-items-center justify-content-between">                                      {
                                    fields.key == "course_name" ? <Row style={{ width: 350 }} className='justfy-content-start align-items-center'>
                                        <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                            <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >
                                                {rendervalue}
                                            </Link>
                                            <div className='hstack'>
                                                <Button
                                                    size='sm'
                                                    onClick={() => { setpreview(true); handleLeadClick(cellProps.row.original) }}
                                                    color="primary"
                                                    title='Preview'
                                                    className="btn-primary justfy-self-end hoverbutton">
                                                    <i className="ri-layout-right-2-fill label-icon align-middle fs-16"></i>
                                                </Button>
                                                {
                                                    communicate ?
                                                        <Button
                                                            onClick={() => {
                                                                handleButtonClick(cellProps.row.original)

                                                            }}
                                                            title='Call'
                                                            className="hoverbutton justfy-self-end btn btn-sm btn-success remove-list">
                                                            <i className="ri-phone-fill label-icon align-middle fs-16"></i>
                                                        </Button> : null}
                                                <Button
                                                    onClick={() => {
                                                        handleButtonClick(cellProps.row.original)
                                                        
                                                    }}
                                                    title='Call'
                                                    className="hoverbutton justfy-self-end btn btn-sm btn-success remove-list">
                                                    <i className="ri-phone-fill label-icon align-middle fs-16"></i>
                                                </Button>
                                                <Button
                                                       onClick={() => {
                                                        handleNoteModal(cellProps.row.original)
                                                    }}
                                                    title='Note'
                                                    className="btn btn-sm hoverbutton justfy-self-end btn-info remove-list">
                                                    <i className="ri-sticky-note-fill label-icon align-middle fs-16"></i>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row> :
                                        <div>
                                            {rendervalue}
                                        </div>
                                }
                                </div>
                            }
                        }
                        columns.push(newColumn)
                    }
                });
            }
        }
        return columns;
    }
    
    const defaultViewColumn = () => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) : true;
                if (isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        fieldid: fieldName.id,
                        valueJson: fields,
                        meta: fieldName,
                        show: fields.tablecolumn,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            else if (fields.key == 'course_link' && cellProps.row.original[fields.model] && cellProps.row.original[fields.model][fields.key] !== "NA") {
                                return <div className='ms-3'>
                                    <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.model][fields.key])} />
                                </div>
                            }
                            else if (fields.optionLabel && fields.optionLabel) {
                                rendervalue = cellProps?.row?.original[fields.model] && cellProps?.row?.original[fields.model][fields.optionLabel] ? cellProps?.row?.original[fields.model][fields.optionLabel] : 'NA'
                            }  
                            return <div className="d-flex align-items-center justify-content-between">                                      {
                                fields.key == "course_name" ? <Row style={{ width: 350 }} className='justfy-content-start align-items-center'>
                                    <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                        <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >
                                            {rendervalue}
                                        </Link>
                                        <div className='hstack'>
                                            <Button
                                                size='sm'
                                                title='Preview'
                                                onClick={() => { setpreview(true); handleLeadClick(cellProps.row.original) }}
                                                color="primary"
                                                className="btn-primary justfy-self-end hoverbutton">
                                                <i className="ri-layout-right-2-fill label-icon align-middle fs-16"></i>
                                            </Button>
                                            {
                                                communicate ?
                                                    <Button
                                                        onClick={() => {
                                                            handleButtonClick(cellProps.row.original)

                                                        }}
                                                        title='Call'
                                                        className="hoverbutton justfy-self-end btn btn-sm btn-success remove-list">
                                                        <i className="ri-phone-fill label-icon align-middle fs-16"></i>
                                                    </Button> : null}
                                            <Button
                                                    onClick={() => {
                                                    handleNoteModal(cellProps.row.original)
                                                }}
                                                title='Note'
                                                className="btn btn-sm hoverbutton justfy-self-end btn-info remove-list">
                                                <i className="ri-sticky-note-fill label-icon align-middle fs-16"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row> :
                                    <div>
                                        {rendervalue}
                                    </div>
                            }
                            </div>
                        }
                    }
                    columns.push(newColumn)
                }
            });
        }
        return columns
    }
    
    useEffect(() => {
        let columns: any[] = [];
        if (viewId) columns = viewsColumns()
        else columns = defaultViewColumn();
        const nameColumnIndex = columns.findIndex(column => column.accessor === "course_name");
        // If "Name" column is found, move it to the beginning
        if (nameColumnIndex !== -1) {
            const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
            columns.unshift(nameColumn); // Add it to the beginning
        }
        if (columns && columns.length > 0) {
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        if (metaData && metaData.length > 0) {
            let datafields: any = [];
            metaData.forEach((fieldName) => {
                let field =  fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {} ;
                if(field.filter && (field.type === 'textfield' || field.type === 'textarea')) {
                    datafields.push({ label : field.label, value:field.key })
                }
            })
            setFieldOptions(datafields)
        }
        
        
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile, fields, communicate]);

    useEffect(() => {
        setMetaData(applicationsMetadata)
    }, [applicationsMetadata != undefined])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {
        setColumns(tempcolumns)
        if (viewId) {
            let fields: any = []
            tempcolumns && tempcolumns.length && 
                tempcolumns.map((field: any) => {
                    let item = field?.meta && isJson(field?.meta) ? JSON.parse(field?.meta) : {}                
                    if (item.field == 'application_name') fields.push({ id: field.fieldid, extras: { key: item.key, show: true } })
                    else fields.push({ id: field.fieldid, extras: { key: item.key, show: field.show } })
                })
            setFields(fields)
        }
        setEditOpen(false)
    }

    const clearFilter = () => {
        setSearch("")
        setPagination({
            size: pagination?.size,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        })
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'course_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a = isEqual(applicationList, applicationsJson)
        return !a
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    useEffect(() => {
        const parsedData: any = applicationList && applicationList.length ? applicationList.map((item: any) => {
            const valuesJson = item?.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, ...item, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let applications = parsedData && parsedData.length && parsedData.reverse()
        setApplication(applications)
        setapplicationsJson(applicationList)
    }, [execute()])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };

        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            dispatch(ApplicationsListGetRequest(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi])

    useEffect(() => {
        if (view) {
            let newFields: any = view && view.fields && isJson(view.fields) ? JSON.parse(view.fields) : {}
            setFields(newFields)
            let filtersObj: any = view && view.filters && isJson(view.filters) ? JSON.parse(view.filters) : null
            if (filtersObj) {
                const { filters, sorts } = filtersObj;
                if (filters?.length) {
                    if (filters[0]?.search) {
                        setSearch(filters[0].search?.term || '')
                    }
                    if (filters[0]?.search) {
                        setSelectedSearchField(filters[0].search?.valuesLabel || '')
                    }
                    const filter = {
                        quick: [],
                        advance: []
                    }
                    if (filters[0]?.quick) {
                        filter.quick = filters[0]?.quick
                    }
                    if (filters[0]?.advance) {
                        filter.advance = filters[0]?.advance
                    }
                    setFilter(filter)
                }
                if (sorts?.length) {
                    setSortBy(sorts[0].field)
                    setSortOrder(sorts[0].order)
                }
            }
        }
    }, [view])

    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }

    const applyFilters = (filters: any) => {
        dispatch(ApplicationsListGetRequest(filters, 0));
        setapplicationsJson([])
    }

    const onChangeIndex = (column: any, index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
            let filters = { ...filterObject, sortBy: columnName, sortOrder: sort }
            setFilterObject(filters)    
            dispatch(ApplicationsListGetRequest(filters, pagination.page));
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
            let filters = { ...filterObject, sortBy: columnName, sortOrder: 'asc' }
            setFilterObject(filters)    
            dispatch(ApplicationsListGetRequest(filters, pagination.page));
        }
    };

    const saveview = () => {
        const filtersData = {
            filters: [{
                ...filter,
                search: null
            }],
            sorts: [
                {
                    "field": sortBy,
                    "order": sortOrder
                }
            ]
        }

        // Add search filter
        if (search) {
            filtersData.filters[0].search = {
                term: search,
                field: selectedSearchField?.value,
                valuesLabel: selectedSearchField
            }
        }
        let data = {
            "fields": JSON.stringify(fields),
            "filters": JSON.stringify(filtersData)
        }         
        const handleSuccess = () => {
            const message = () => toast(`View Saved Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        const handleFailure = () => {

    }
    dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))
    }

    
    useEffect(() => {
        if (viewId) dispatch(viewGetRequest(viewId))
    }, [viewId])
    const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);


    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            <NotesModal
                props={props}
                notesModals={notesModals}
                setNotesModals={setNotesModals}
                record={record}
            />

            {emailModals && <EmailModal
                props={props}
                emailModals={emailModals}
                setEmailModals={setEmailModals}
            />}

            {meetingsModals && <MeetingsModal
                info={info}
                props={props}
                meetingsModals={meetingsModals}
                setMeetingsModals={setMeetingsModals}
            />}
            {isExportCSV && <ExportDataModal
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                // data={data}
                props={props}
            />}
            {isFilterModalOpen && <AdvanceFilter
                show={isFilterModalOpen}
                onCloseClick={() => setisFilterModalOpen(false)}
                dataFields={columns}
                props={props}
                setFilterObject={setFilterObject}
                filterObject={filterObject}
                applyFilters={applyFilters}
            />}
            {isDynamicModalOpen && <DynamicFilters
                show={isDynamicModalOpen}
                onCloseClick={() => setIsDynamicModalOpen(false)}
                dataFields={columns}
                props={props}
                setFilterObject={setFilterObject}
                filterObject={filterObject}
                applyFilters={applyFilters}
                filterJson={filterJson}
                setFilterJson={setFilterJson}
                filterCount={filterCount}
                setFilterCount={setFilterCount}
            />}
            <PreviewModal
                show={previewModal}
                onCloseClick={() => setpreview(false)}
                record={record}
                ontoggle={toggle}
                open={open}
                setrecord={setrecord}
                props={props}
            />
            {editModal && <EditModal
                show={editModal}
                onCloseClick={() => setEditOpen(false)}
                onApplyClick={onApplyClick}
                onchange={onchange}
                columns={columns}
                visibleColumns={visibleColumns}
                toggleColumn={toggleColumn}
                onClickmove={onClickmove}
                deSelectColumnall={deSelectColumnall}
                onChangeIndex={onChangeIndex}
                tempcolumns={tempcolumns}
                setTempColumns={setTempColumns}
            />}
            {iscreateModal && <AddEntryModal
                show={iscreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={metaData}
                props={props}
            />}
            {isImportCSV && <ImportDataModal
                show={isImportCSV}
                onCloseClick={() => setIsImportCSV(false)}
                props={props}
            />}
            {isDeleteOpen && <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />}
            {statusForm && <StatusFormModel form={statusForm} onUpdate={onUpdate} onToggle={() => setStatusForm(null)} />}
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <Row className='hstack gap-3'>
                            <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                <InputGroup className='border rounded'>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-light">
                                                {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => setSelectedSearchField({value: 'all', label: 'All'})}>All</DropdownItem>
                                                <DropdownItem divider />
                                                {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                    <Input className='border-0' placeholder="Search..." style={{padding: '1.2rem 0.9rem'}} onChange={onSearchHandle} value={search}></Input>
                                </InputGroup>
                            </Col>
                                <Col>
                                    <div className='hstack gap-2 justify-content-end'>
                                        <div className='hstack gap-2'>
                                            {viewId ? <Button
                                                onClick={() => saveview()}
                                                color="primary"
                                                // size='sm'
                                                className="btn-label">
                                                <i className="ri-layout-3-line label-icon align-middle fs-16 me-2"></i>
                                                {props.t("student.save_view")}
                                            </Button> : null}
                                           
                                            <Button
                                                onClick={() => setEditOpen(true)}
                                                color="primary"
                                                className="btn-label btn-soft-primary">
                                                <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("applications.edit_columns")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className=''>
                                {metaData && metaData.length ? 
                                    <QuickFilter
                                        dataFields={metaData}
                                        columns={columns}
                                        translation={props}
                                        onFilter={onFilter}
                                        clearFilterParent={clearFilter}
                                        filter={filter}
                                    /> : ""}
                            </Row>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={applications || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    access={access}
                                    loading={loading}
                                    dataloading={dataloading}
                                    onChange={onChangeStatus}
                                    updateRow={updateRow}
                                />
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Applications);