import React, { useEffect, useState } from "react";
import {
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	Card,
	CardBody,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
} from "reactstrap";
import { ApplicationsListGetRequest, applicationCreateRequest } from "store/applications/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Form from "./DtsResponsiveForm";
import { useParams, useSearchParams } from "react-router-dom";
import { studentExternalApplication, studentGetRequest, studentUpdateRequest, studentsMetadataGetRequest } from "store/student/action";
import UpdateForm from "Components/Common/FormBuilder/UpdateForm";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import multikeys from './multiwordsKeys'
import moment from "moment";



const AddEntry = ({ dataFields, data, onCloseClick, show, OcrLoading }: any) => {
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const source = searchParams.get('source');
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const student: any = useSelector((state: ApplicationState) => state.student.student);
	const [statusMetaData, setStatusMetaData] = useState<any>([]);
	const [formValues, setFormValues] = useState<any>({});
	const onUpdate = (formValues: any, type: any) => {
		const handleSuccess = (body: any) => {
			const message = () => toast(`Student updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
			dispatch(studentGetRequest(id))
			if (type !== "single") onCloseClick()
		}
		dispatch(studentUpdateRequest(id, formValues, handleSuccess, () => { }))
	}
	useEffect(() => {
		const filters = { pageSize: 100 }
        dispatch(studentsMetadataGetRequest("students", filters));
	}, [])

	useEffect(() => {
		setStatusMetaData(dataFields)
	}, [dataFields])

	const isJson = (str: any) => {
		try {
			let options = JSON.parse(str);
			return options
		} catch (e) {
			return false;
		}
	}

	const assigngender  = (str: any) => {
		let gender = ""
		switch(str) {
			case 'F':gender = 'female'; break;
			case 'M' :gender = 'male'; break;
			case 'O' :gender = 'other'; break;
		}
		return gender;

	}
	function toCamelCase(str: any){
		return str.split(" ").map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
	  }

	const decideField = (docfield: any, data: any) => {
		let str = docfield.toLowerCase();
		let finalkeyvalue = {}
		multikeys && multikeys.map((item: any) => {
			item && item.keys && item.keys.map((key: any) => {
				if (str.includes(key)) {
					let value: any= "";
					dataFields.map((field: any) => {
						const fieldkey = field?.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson : {};
						let camparekey = fieldkey.key
						if(camparekey == item.key) {
							if(item.key == 'gender') { 
								value = assigngender(data[docfield])	
							}					
							else if(fieldkey.type == 'select' ) {
								value = data[docfield].toLowerCase()
							}
							else if(fieldkey.type == 'datetime' ) {
								value = moment(data[docfield], ['DD/MM/YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'YYYY/MM/DD']).format('YYYY-MM-DD');

							}
						}
					})
					finalkeyvalue ={[item.key]: value ? value : toCamelCase(data[docfield])};
				}
			})
		})
		return finalkeyvalue
	}

	const fuzzyMatch = (input: any, target: any) => {
		const inputLen = input.length;
		const targetLen = target.length;
		const maxDistance = Math.max(inputLen, targetLen);

		const distance = new Array(maxDistance + 1).fill(null).map(() => new Array(maxDistance + 1).fill(0));

		for (let i = 0; i <= inputLen; i++) {
			distance[i][0] = i;
		}

		for (let j = 0; j <= targetLen; j++) {
			distance[0][j] = j;
		}

		for (let i = 1; i <= inputLen; i++) {
			for (let j = 1; j <= targetLen; j++) {
				const cost = input[i - 1] === target[j - 1] ? 0 : 1;
				distance[i][j] = Math.min(
					distance[i - 1][j] + 1,
					distance[i][j - 1] + 1,
					distance[i - 1][j - 1] + cost
				);
			}
		}

		return distance[inputLen][targetLen];
	}

	const fuzzyMatchRatio = (input: any, target: any) => {
		const distance = fuzzyMatch(input, target);
		const inputLen = input.length;
		const targetLen = target.length;
		const maxLen = Math.max(inputLen, targetLen);

		return (maxLen - distance) / maxLen;
	}

	const handlePrefieldData = (data: any) => {
		const keys = Object.keys(data);
		let formValues = {}
		keys && keys.map((key: any) => {
			let returnedkey: any = decideField(key, data);
			formValues = {...formValues, ...returnedkey}	
		})
		setFormValues(formValues)

	};
	const mapfield = async () => {
		let newMetadata: any = []
		await dataFields.map((field: any) => {
				const item = JSON.parse(field.valuesJson);
				if (formValues.hasOwnProperty(item.key)) newMetadata.push(field);
			})
			return newMetadata
	}

	useEffect(() => {
		mapfield().then((metadata) => {
			setStatusMetaData(metadata)
		})
		}, [formValues, dataFields])

	useEffect(() => {
		handlePrefieldData(data)
	}, [data])
	return (
		<Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true} size="lg">
			<ModalHeader toggle={onCloseClick}>
				Review and Accept
			</ModalHeader>
			<ModalBody className='p-5'>
				<Card className="px-3 py-1">
					<SimpleBar className={OcrLoading ? "placeholder-glow" : ""}>
						<CardBody>
							<div className="live-preview vstack">
								{OcrLoading ?
									<span className="d-flex align-items-center">
										<Spinner size="xl" type="grow" className="flex-shrink-0"> Loading... </Spinner>
										<span className="flex-grow-1 ms-2">
											Reading data please wait...
										</span>
									</span>
									:
									<Form isUpdate={true} BtnClassName={"w-auto justify-content-end"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={statusMetaData} btnAction={onUpdate} btn_text={'Accept and Update'} data={formValues} onCloseClick={onCloseClick} />
								}
							</div>
						</CardBody>
					</SimpleBar>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default AddEntry;

