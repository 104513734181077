import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Dropdown, ButtonGroup } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import {
    ProductsGlobalFilter,
    CustomersGlobalFilter,
    OrderGlobalFilter,
    ContactsGlobalFilter,
    CompaniesGlobalFilter,
    LeadsGlobalFilter,
    CryptoOrdersGlobalFilter,
    InvoiceListGlobalSearch,
    TicketsListGlobalFilter,
    NFTRankingGlobalFilter,
    TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import { Link } from "react-router-dom";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import {getAvailableStatuses} from "helpers/workflowStatusHelper";

interface TableContainerProps {
    columns?: any;
    data?: any;
    isGlobalFilter?: any;
    isAddOptions?: any;
    divClassName?: any;
    tableClassName?: any;
    theadClassName?: any,
    tableClass?: any;
    theadClass?: any;
    isBordered?: boolean;
    isAddUserList?: any;
    handleOrderClicks?: any;
    handleUserClick?: any;
    handleCustomerClick?: any;
    isAddCustList?: any;
    customPageSize?: any;
    className?: any;
    customPageSizeOptions?: any;
    iscustomPageSize?: boolean;
    SearchPlaceholder: string;
    isProductsFilter?: boolean;
    isPageSizeChange?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    setIsMultiDeleteButton: (e: any) => void,
    handleEditClick: (e: any) => void,
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    pagesList: any;
    setpreview: (e: any) => void;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    setNotesModals: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    access: any;
    loading: any;
    dataloading: any;
    onChange: (value: any, key: any, data: any, id: any) => void;
    updateRow: any
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    isGlobalFilter,
    isProductsFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isLeadsFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    SearchPlaceholder,
    setIsMultiDeleteButton,
    handleEditClick,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    setpreview,
    setNotesModals,
    handleSort,
    sortOrder,
    sortBy,
    pageSize,
    props,
    access,
    loading,
    dataloading,
    onChange,
    updateRow,
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        // preGlobalFilteredRows,
        setGlobalFilter,
        // state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteaccess, setDeleteAccess] = useState(false);
    const [open, setOpen] = useState("");
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setView(true) 
            setEdit(true)
            setDeleteAccess(true)
        }
        else {
            access && access.length && access.map((item: any, index: any) => {
                if(item.name) {
                    switch(item.name) {
                        case "view":
                        case "View": item.value == "" || item.value == "none" ? setView(false)  : setView(true) 
                        break
                        case "edit":
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true)
                        break
                        case "delete": 
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(false)  : setDeleteAccess(true)
                        break
                    }
                }
    
            })
        }
    })
    
    return (
        <div>
            {loading || dataloading ?
                 <DtsTablePlaceholder 
                    columns={columns} 
                    theadClass={theadClass}     
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                : 
            <div>{!loading && !dataloading && page && page.length ? 
                <Table responsive={true} hover {...getTableProps()} className={tableClass + ' m-0'}>
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} className={thClass + " fw-bold"}
                                        {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                    >
                                        <div onClick={() => handleSort(column.id)}  className={"cursor-pointer" + `${column.accessor == 'course_name' ? '': ' otherth'}`} title={column.Header}>
                                            {column.render("Header")}
                                            {sortBy === column.id && (
                                                <span>
                                                    {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                </span>
                                            )}
                                        </div>   
                                    </th>
                                ))}
                                <th className={thClass + " fw-bold"} >
                                    Actions
                                </th>
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr className="popup" style={{verticalAlign: 'middle'}}>
                                        {row.cells.map((cell: any) => {
                                            let fields =  cell.column.valueJson;     
                                            if(cell.column.id === 'application_status') {
                                                const currentValue = row.original.id === updateRow?.id ? updateRow.application_status : row.original[cell.column.id];
                                                const {statuses, status} = getAvailableStatuses(fields?.process_flows, currentValue, row?.original?.process_flow);
                                                return <td>
                                                    <ButtonGroup>
                                                        <UncontrolledDropdown className="position-static">
                                                            <DropdownToggle
                                                                tag="button"
                                                                className="btn btn-soft-light text-left d-flex align-items-center justify-content-between text-dark"
                                                                style={{ width: '200px' }}
                                                            >
                                                                {status?.label} <i className="mdi mdi-chevron-down"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdownmenu-primary dropup">
                                                                {statuses.map((status: any, index: number) => (
                                                                    <DropdownItem
                                                                        key={index}
                                                                        onClick={(e) => onChange(status, fields.key, row.original, row.original.id)}
                                                                    >
                                                                        {status?.label}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </ButtonGroup>
                                                </td>
                                            }
                                            else return (
                                                <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                        <td>
                                            <ul className="list-inline hstack gap-2 mb-0">
                                                <li className="list-inline-item">
                                                    <Dropdown isOpen={open==row.original.id}  toggle={() => open==row.original.id ? setOpen("") : setOpen(row.original.id)}>
                                                        <DropdownToggle
                                                            href="#"
                                                            className="btn btn-soft-primary btn-sm dropdown"
                                                            tag="button"
                                                        >
                                                            <i className="ri-more-fill"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu  container="body" className="dropdown-menu-center">
                                                        {view ?
                                                            <DropdownItem className="dropdown-item" href={`/applications/${row.original.id}`}
                                                            >
                                                                <button className="btn btn-sm btn-soft-info remove-list fs-12"
                                                                        >
                                                                            <i className="ri-eye-fill"></i>{" "}
                                                                </button>{" "}
                                                                            View                                                           
                                                            </DropdownItem>
                                                            : null}
                                                            {edit ?
                                                            <DropdownItem
                                                                className="dropdown-item edit-item-btn fs-12"
                                                                href={`/applications/${row.original.id}`}
                                                            >
                                                                <button className="btn btn-sm btn-soft-info remove-list"
                                                                        >
                                                                    <i className="ri-pencil-fill"></i>{" "}
                                                                </button>{" "}
                                                                Edit   
                                                            </DropdownItem>: null}
                                                            {deleteaccess ? 
                                                            <DropdownItem
                                                                className="dropdown-item remove-item-btn fs-12"

                                                                onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true);  setpreview(false);  }}
                                                            >
                                                                <button className="btn btn-sm btn-soft-danger remove-list"
                                                                        >
                                                                <i className="ri-delete-bin-fill"></i>
                                                                </button>  {" "}
                                                                Delete 
                                                            </DropdownItem> : null}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>:
                    <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                        <thead className={theadClass}>
                                {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                                    <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column: any) => (
                                            <th key={column.id} className={thClass + " fw-bold"}
                                                {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                                
                                            >
                                                <div onClick={() => handleSort(column.id)}  className={"cursor-pointer" + `${column.accessor == 'course_name' ? '': ' otherth'}`}  title={column.Header}>
                                                    {column.render("Header")}
                                                    {sortBy === column.id && (
                                                        <span>
                                                            {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                        </span>
                                                    )}
                                                </div>     
                                            </th>
                                        ))}
                                        <th className={thClass + " fw-bold"} >
                                            {props.t("student.actions")}
                                        </th>
                                    </tr>
                                )): null}
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={headerGroups && headerGroups.length && headerGroups[0].headers.length + 1} className="p-5 fw-semibold fs-16 text-center">
                            <div className="hstack p-5 justify-content-center fw-semibold fs-16 text-center w-100">
                                No data Found
                            </div>
                            </td>
                        </tr>
                        </tbody>
                </Table>}
                <Row className="align-items-center mt-2 g-3 text-center text-sm-start">                
                    <div className="col-sm">
                        <div className="text-muted">
                            {props.t("applications.showing")}
                            <span className="fw-semibold ms-1">
                            {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                            </span>
                        </div>
                    </div>   
                    <div className="col-sm-auto">
                        <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                            currentPage={pagination?.page}
                            pageChanged={(e:any) => pageChanged(e)}
                            pageSize={pageSize} 
                            isPageSizeChange={isPageSizeChange}
                            onChangeInSelect={(e:any) => onChangeInSelect(e)}
                            pagination={pagination}
                            props={props}
                        />
                    </div>
                </Row>
            </div>}
        </div>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;