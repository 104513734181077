import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Col, Row, Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { allGroupsWithToken, GroupListForStudentForm } from 'store/properties/action';
import { userGetRequest } from 'store/auth/action';
import { studentCreateRequest, studentGetRequest, StudentListGetRequest, studentUpdateRequest } from 'store/student/action';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import UpdateFormCol from 'Components/Common/FormBuilder/UpdateFormCol';
import moment from 'moment';

const UploadDocuments = ({ status }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const me = useSelector((state: ApplicationState) => state.user.user);
    const groups = useSelector((state: ApplicationState) => state.properties.allGroups);
    const student: any = useSelector((state: ApplicationState) => state.student.student);
    const [completedSteps, setCompletedSteps] = useState<any>([]);
    const [activeVerticalTab, setActiveVerticalTab] = useState<any | null>(1);
    const [formData, setFormData] = useState<{ [key: number]: any }>({});
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);
    const [info, setInfo] = useState<any>({});

    const isJson = (str: any) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    };
    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

    useEffect(() => {
        if(student && student.id) {
            let studentDetails = student.valuesJson;
            let neewData: any = {}
                groups && groups.length  && 
                groups.map((item: any, index: any) => {
                    item && item.metadata && item.metadata.map((meta: any) => {
                        let field = meta?.valuesJson && isJson(meta?.valuesJson) ? JSON.parse(meta?.valuesJson) : {}
                        let rendervalue = studentDetails[field.key];
                        if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                            rendervalue = handleselectValue(studentDetails?.[field.key], field.values);
                        }
                        else if (field.optionLabel) {
                            rendervalue = studentDetails?.[field.key] && studentDetails?.[field.key]?.[field.optionLabel] ? studentDetails?.[field.key]?.[field.optionLabel] : 'NA'
                        }
                        neewData[field.key]= rendervalue;
                    });
                })     
                setInfo({...neewData,id:student.id, studentId:student.id})                   
        }
      }, [student])

      
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.students;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])


    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    const steps = groups && groups.length  ? [
        ...groups.map((item: any, index: any) => {
            console.log("item", item)
            const parsedFields = item && item.metadata && item.metadata.map((meta: any) => ({
                ...meta,
                valuesJson: isJson(meta.valuesJson) ? meta.valuesJson : "{}"
            }));
            console.log("parsedFields", parsedFields)
            let newstatusMetaData: any = [];
            parsedFields && parsedFields.map((field: any) => {
                let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
                let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
                if (isFieldviewaccess) {
                    if (isFieldEditaccess) {
                        let newField = { ...field, isEditAccess: true }
                        return newstatusMetaData.push(newField)
                    }
                    return newstatusMetaData.push(field)
                }
            })
            return {
                label: item.groupTitle,
                tab: (index + 1).toString(),
                fields: newstatusMetaData
            };
        }),
        {
            tab: (Array.isArray(groups) ? (groups.length + 1) : 1).toString(),
            fields: statusMetaData
        }
    ]: []


    useEffect(() => {
        let filterObject = {PageSize: 100}
        dispatch(allGroupsWithToken('students', filterObject));
        dispatch(userGetRequest());
    }, [dispatch]);

    useEffect(() => {
        if (me) {
            let filterObject = {PageSize: 100}
            dispatch(allGroupsWithToken('students', filterObject));
        }
    }, [dispatch, me]);

    const toggleVerticalTab = (tab: string) => {
        setActiveVerticalTab((prevTab: string) => (prevTab === tab ? null : tab));
        if (!completedSteps.includes(tab)) {
            setCompletedSteps([...completedSteps, tab]);
        }
    };
    

    const handleAddEntry = (formValues: any, tab: string) => {

        const formValuesJson = JSON.stringify(formValues);

        setFormData((prevData: any) => ({
            ...prevData,
            [tab]: { valuesJson: formValuesJson }
        }));
    };

    const onUpdate = (formValues: any, type?:any) => {
        const updatedFormData = {
            ...formData,
            [activeVerticalTab!]: { valuesJson: JSON.stringify(formValues) }
        };

        const combinedFormData = Object.keys(updatedFormData).reduce((acc: any, key: any) => {
            try {
                const valuesJson = updatedFormData[key].valuesJson;
                if (valuesJson) {
                    const parsedData = JSON.parse(valuesJson);
                    return { ...acc, ...parsedData };
                }
            } catch (error) {
                console.error(`Error parsing JSON for tab ${key}:`, error);
            }
            return acc;
        }, {});

        setFormData(updatedFormData);

        const handleSuccess = (body: any) => {
            dispatch(studentGetRequest(id))
        };
        let istrue = formValues && Object.keys(formValues).length === 0
        if((type && type == 'file') || istrue || !formValues){
            // const message = () => toast(`Student Updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
        }
        else {
            dispatch(studentUpdateRequest(id, combinedFormData, handleSuccess, () => { }));
        }

        
    };
    const initCaps = (str: any) => {
        if (!str) return ''; // Handle edge case if str is null or undefined
        return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a: any) {
          return a.toUpperCase();
        });
      };

      useEffect(() => {
        dispatch(studentGetRequest(id))
      }, [])

 

    return (
        <React.Fragment>
            <Row className="g-0 text-center pb-1">
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-exchange-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{status?.label}</h6>
                            <p className="text-muted mb-0">Status</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-group-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <div className="d-flex flex-wrap gap-1 justify-content-center">
                                {status?.roles?.map((role: any) => {
                                    return (
                                        <Badge className='' color="primary">
                                            {role?.label}
                                        </Badge>
                                    )
                                })}
                            </div>
                            <p className="text-muted mb-0">Role</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-terminal-box-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{student?.updatedAt ? moment(student?.updatedAt).format('MMMM Do YYYY') : "No Activities"}</h6>
                            <p className="text-muted mb-0">Last Activity Date</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3}  className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0 border-end-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-git-repository-commits-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{moment(student?.createdAt).format('MMMM Do YYYY')}</h6>
                            <p className="text-muted mb-0">Created Date</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Accordion className="custom-accordionwithicon accordion-fill-primary" open={activeVerticalTab} toggle={toggleVerticalTab}>
                {steps && steps.length && steps.map(({ tab, fields, label }: any) => {
                    const halfLength = fields && fields.length && Math.ceil(fields.length / 2);
                    const firstHalf = fields && fields.length && fields.slice(0, halfLength);
                    const secondHalf = fields && fields.length && fields.slice(halfLength);

                    if (fields && fields.length > 0) {
                        return (
                            <AccordionItem key={tab}>
                                <AccordionHeader targetId={tab}>
                                    {label}
                                </AccordionHeader>
                                <AccordionBody accordionId={tab}>
                                    <Row>
                                        {firstHalf.map((field: any, index: any) => (
                                            <Col xxl={6} lg={6} md={6} key={index}>
                                                <div>
                                                    <UpdateFormCol
                                                        dataFields={[field]}
                                                        onUpdate={onUpdate}
                                                        showbtn={false}
                                                        data={info}
                                                        formfor={"students"}
                                                        isEditAccess={edit}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                        {secondHalf.map((field: any, index: any) => (
                                            <Col  xxl={6} lg={6} md={6} key={index}>
                                                <div>
                                                    <UpdateFormCol
                                                        dataFields={[field]}
                                                        onUpdate={onUpdate}
                                                        showbtn={false}
                                                        data={info}
                                                        formfor={"students"}
                                                        isEditAccess={edit}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </AccordionBody>
                            </AccordionItem>
                        );
                    } else {
                        return null;
                    }
                })}
            </Accordion>
        </React.Fragment>
    );
};

export default withTranslation()(UploadDocuments);
