import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { applicationsMetadataGetRequest, applicationUpdateRequest, ApplicationsListGetRequest, applicationGetRequest } from "store/applications/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ViewListGetRequest } from "store/views/action";
// Define types or interfaces
interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const ApplicationInfo = ({ onCloseClick, fields }: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const applicationsDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit]= useState<any>(false);
    const [data, setData] = useState<any>({});

    useEffect(() =>{
        const filters = {pageSize: 100}
        dispatch(applicationsMetadataGetRequest("applications",filters));
        dispatch(applicationGetRequest(id))
    }, [])

    const onUpdate = (formValues: any) => {
        let data = { ...applicationsDetails, valuesJson: formValues };
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(applicationGetRequest(id))
        }
        dispatch(applicationUpdateRequest(id, formValues, handleSuccess, () => { }))
    }

    
    const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => ( subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    };
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    useEffect(() => {
        dispatch(ViewListGetRequest());
    },[])
    
    useEffect(() => {
        let newstatusMetaData: any = []
        applicationsMetadata && applicationsMetadata.map((field: any) => { 
            let permissionsJson = field?.permissionsJson  && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {} 
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) :  true;       
            if(isFieldviewaccess) {
                if(isFieldEditaccess) {                    
                    let newField = {...field, isEditAccess: true}
                    return newstatusMetaData.push(newField)
                } 
                return newstatusMetaData.push(field)   
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [applicationsMetadata])
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setEdit(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if(access && access?.length) {
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                       }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

    useEffect(() => {
        if(applicationsDetails && applicationsDetails.id) {
            let applicationsDetailsDetails = applicationsDetails.valuesJson;
            let newData: any = {}
            statusMetaData && statusMetaData.map((item: any) => {
                let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
                let rendervalue = applicationsDetailsDetails[field.key];
                if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                    rendervalue = handleselectValue(applicationsDetailsDetails?.[field.key], field.values);
                }
                else if (field.optionLabel) {
                    rendervalue = applicationsDetailsDetails?.[field.model] && applicationsDetailsDetails?.[field.model]?.[field.optionLabel] ? applicationsDetailsDetails?.[field.model]?.[field.optionLabel] : 'NA'
                }
                newData[field.key]= rendervalue;
            })
            setData({...newData,id:applicationsDetails.id, applicationsDetailsId:applicationsDetails.id})
        }
    }, [applicationsDetails, statusMetaData])

    return (
        <div>            
            <ToastContainer />
            <form action="" className="d-flex flex-column justify-content-end h-100">
                <div className="live-preview vstack gap-2">
                    <div className="live-preview vstack gap-2">
                    <SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
                        <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={data} formfor={"applications"} fields={fields} isEditAccess={edit} />
                    </SimpleBar>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default ApplicationInfo;
