import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Input,
  PopoverBody,
  PopoverHeader,
  Table,
  UncontrolledPopover,
} from "reactstrap";

const SingleCheckboxTable = () => {
  const SortOptions = [
    { value: "Choices 1", label: "Custom" },
    { value: "Choices 2", label: "Alphabetical" },
  ];
  const SingleOptions = [
    { value: "Choices 1", label: "Country" },
    { value: "Choices 2", label: "Timezone" },
    { value: "Choices 3", label: "Gender" },
    { value: "Choices 4", label: "Age" },
  ];

  const [selectedSingle, setSelectedSingle] = useState<any>(null);
  const handleSelectSingle = (selectedSingle: any) => {
    setSelectedSingle(selectedSingle);
  };
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-left gap-2 mt-3">
        <div className="w-50">
          <label>Sort</label>
          <Select
            value={selectedSingle}
            onChange={(selectedSingle: any) => {
              handleSelectSingle(selectedSingle);
            }}
            options={SortOptions}
          />
        </div>
        <div className="w-50">
          <label>Search</label>
          <Input
            className="form-control"
            placeholder="Search"
            id="autoCompleteCars"
            type="text"
            dir="ltr"
            spellCheck={false}
            autoComplete="off"
            autoCapitalize="off"
          />
        </div>
      </div>
      <div className="mt-3">
        <Table className="table-hover align-middle table-nowrap mb-0">
          <thead>
            <tr className="bg-dark-subtle">
              <th scope="col">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    defaultValue="option1"
                  />
                </div>
              </th>
              <th scope="col">Label</th>
              <th scope="col">Internal Value</th>
              <th scope="col">In-forms</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                {/* <div className="form-check"> */}
                <Input
                  disabled
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="option1"
                  defaultChecked
                />
                {/* </div> */}
              </th>
              <td>
                {/* <div> */}
                <Input
                  value="Yes"
                  disabled
                  type="text"
                  className="form-control border-dashed"
                  id="borderInput"
                  placeholder="Enter label"
                />
                {/* </div> */}
              </td>
              <td>
                {/* <div> */}
                <Input
                  value="true"
                  disabled
                  type="text"
                  className="form-control border-dashed"
                  id="borderInput"
                  placeholder="Enter value"
                />
                {/* </div> */}
              </td>
              <td>
                <Link to="#">
                  <div className="form-check form-switch form-switch-secondary mb-3">
                    <Input
                      disabled
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="SwitchCheck2"
                      defaultChecked
                    />
                  </div>
                </Link>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {/* <div className="form-check"> */}
                <Input
                  disabled
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="option1"
                  defaultChecked
                />
                {/* </div> */}
              </th>
              <td>
                {/* <div> */}
                <Input
                  value="No"
                  disabled
                  type="text"
                  className="form-control border-dashed"
                  id="borderInput"
                  placeholder="Enter label"
                />
                {/* </div> */}
              </td>
              <td>
                {/* <div> */}
                <Input
                  value="false"
                  disabled
                  type="text"
                  className="form-control border-dashed"
                  id="borderInput"
                  placeholder="Enter value"
                />
                {/* </div> */}
              </td>
              <td>
                <Link to="#">
                  <div className="form-check form-switch form-switch-secondary mb-3">
                    <Input
                      disabled
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="SwitchCheck2"
                      defaultChecked
                    />
                  </div>
                </Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-flex p-2 gap-2">
        <Button disabled size="sm">
          Add option
        </Button>
        <div className="hstack flex-wrap gap-2">
          <Button disabled id="PopoverTop" size="sm">
            Load options
          </Button>
          <UncontrolledPopover placement="top" target="PopoverTop">
            <PopoverHeader className="dropdown-menu-md">
              {" "}
              Load options{" "}
            </PopoverHeader>
            <PopoverBody>
              <div className="mb-2">
                <Select
                  value={selectedSingle}
                  onChange={(selectedSingle: any) => {
                    handleSelectSingle(selectedSingle);
                  }}
                  options={SingleOptions}
                />
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
        <Button disabled size="sm">
          Clear all
        </Button>
      </div>
    </div>
  );
};
export default SingleCheckboxTable;
