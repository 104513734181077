import React, { useEffect, useState } from "react";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Label, Table } from "reactstrap";
import { InvoiceListGetRequest, invoiceUpdateRequest } from "store/invoices/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


const ApplicationInfo = ({ onCloseClick, record, metaData, data }: any) => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const [datafields, setdatafields] = useState<any>([])
	const [newpaidAmount, setnewpaidAmount] = useState('')
	const [open, setOpen] = useState(false)
	const [filterObject, setFilterObject] =  useState<any>({});
	const model = "channels";

	const handlestatus = (paid: any, amount: any) => {
        let balance = amount - paid;
        console.log("balance", balance, "paid", paid)
        if(balance > 0 && paid == 0) return 'unpaid'
        else if(balance > 0 && paid > 0) return 'partially_paid'
        else if(balance == 0) return 'paid';        
		else if (balance < 0) return 'advanced_paid';
        else return 'unpaid';
    }

	const valuesChanged = (invoiceDetails: any) => {
		let value: any = parseFloat(newpaidAmount);
		let newTotalpaid = data.paidAmount ? parseFloat(data.paidAmount) + parseFloat(value) : parseFloat(value);
		let balanceDue = parseFloat(data.invoice_amount) - newTotalpaid;
		let payment_status = handlestatus(newTotalpaid, data.invoice_amount)
		let newValues = { ...data, balanceDue: balanceDue, paidAmount: newTotalpaid, payment_status: payment_status }
		// setnewpaidAmount(value)
		const handleSuccess = () => {
			setOpen(false)
			onCloseClick()
			const message = () => toast(`Invoice updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
			dispatch(InvoiceListGetRequest(model, filterObject));
		}
		const handleFailure = () => {

		}
		dispatch(invoiceUpdateRequest(data.id, newValues, handleSuccess, handleFailure));
	}

	const isJson = (str: any) => {
		try {
			let options = JSON.parse(str);
			return options
		} catch (e) {
			return false;
		}
	}

	useEffect(() => {
		if (metaData && metaData.length > 0) {
			let datafields = metaData.map((fieldName: any) => {
				let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) && JSON.parse(fieldName.valuesJson)
				return fields
			});
			datafields.push({
				key: 'paidAmount',
				label: 'Paid Amount',
				type: 'textfield',
				placeholder: 'Enter paid Amount'
			})
			setdatafields(datafields)
		}
	}, [metaData]);

	return (
		<Table className="table table-borderless mb-0">
			{datafields && datafields.length && datafields.map((field: any) => {
				return <tbody>
					{field.key == "paidAmount" ? <>
						<tr className="border border-0 border-bottom">
							<td className="fw-semibold">
								{field.label}
							</td>
							<td>
								<div className="w-100">
									{data?.[field.key]}<i className=" ri-add-fill fs-18 cursor-pointer" onClick={() => setOpen(true)} />
								</div>
							</td>
						</tr>
						<div style={{minWidth: 200, position: 'relative'}}>
						{
							open ?
								<div className="search-icon">
									<FormBuilder
										placeholder={field.placeholder}
										onChange={(value: any) => setnewpaidAmount(value)}
										options={field.values && field.values.reverse() || []}
										actionButtons={true}
										onSaveClick={(e: any) => valuesChanged(data)}
										type={field.type}
										value={newpaidAmount}
										className="w-100 h-100 text-start"
								/></div> : null
						}
						</div></>
						:
						<tr className="border border-0 border-bottom">
							<td className="fw-semibold">
								{field.label}
							</td>
							<td style={{overflowWrap:"anywhere"}}>
								{data?.[field.key]}
							</td>
						</tr>}
				</tbody>
			})}
		</Table>
	);
};

export default ApplicationInfo;



