import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner ,Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Nav, TabContent, TabPane, NavLink, NavItem, ButtonGroup} from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import archiveddataFields from './archivedpropertiesfields.json';
import archiveddata from './archivedpropertiesList.json';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import ArchiveModal from './innerComponents/ArchivedModal';
import DeleteModal from './innerComponents/DeleteModal';
import CreatePropertyModal from './innerComponents/CreatePropertyModal';
import { ToastContainer, toast } from 'react-toastify';
import EditPropertyModal from './innerComponents/EditPropertyModal';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { propertyArchivedListGetRequest, propertyListGetRequest, propertyRestoreRequest} from 'store/properties/action';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import { usersListGetRequest } from 'store/user/action';
import RestoreModal from './innerComponents/RestoreModal';
import moment from 'moment';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Properties = (props : any) => {
    const { model } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const archivedPropertyList = useSelector((state: ApplicationState) => state.properties.achivedlist);   
    const paginationDetails = useSelector((state: ApplicationState) => state.properties.achivedpagination); 
    const loading = useSelector((state: ApplicationState) => state.properties.archivedloading); 
    const users = useSelector((state: ApplicationState) => state.user.users);
    const [columns, setColumns] = useState<any>([])
    const [propertiesData, setPropertiesData] = useState<any>([])
    const [Archivedcolumns, setArchivedColumns] = useState<any>([])
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [filterObject, setFilterObject] =  useState<any>({pageSize:10});
    const [createInputs, setCreateInputs] = useState<any>({
        objectType: model,
        label: '',
        description: '',
        fieldtype: '',
        options: [],
        rules: []
    });
    const [searchArchivedData, setArchivedsearchData] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedPropertyFieldType, setSelectedPropertyFieldType] = useState<any>(null);
    const [selectedAPropertytype, setSelectedAPropertytype] = useState<any>(null);
    const [selecteduser, setUser] = useState<any>(null);
    const [Selectedcount, setselectedcount] = useState<number>(0)
    const [isArchivedOpen, setIsArchivedOpen] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isRestore, setisRestoreOpen] = useState<boolean>(false);
    const [isEditOpen, setisEditOpen] = useState<boolean>(false); 
    const [disabled, setEndDisabled] = useState<boolean>(true); 
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")  
    const [pagesList, setPagesList] = useState<any>([]);
    const [minDate, setMinDate] = useState<string>()  
    const [id, setId] = useState();
    const [propertiesArchivedJson, setArchivedPropertiesJson] = useState<any>([])
    const [propertyObject, setpropertyObject] = useState<any>({
        label: "Students",
        value: "students"
    })
    const [EditInputs, setEditInputs] = useState<any>({
        objectType: propertyObject.value,
        label: '',
        description: '',
        fieldtype: '',
        options: [],
        rules: []
    });
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            totalPages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }
    ];

    const renderField = (value: any) => {        
        let fieldlabel =""
          let a = fieldtype.map((field: any) => {
            field && field.options.length && field.options.map((item: any) => {
                if(item.value == value) fieldlabel = item.label;
            })
        })
        return fieldlabel
    }
    const onRestoreClick = (cellProps:any) => {
        setisRestoreOpen(true); 
        setrecord(cellProps.row); 
        onClickrow(cellProps.row); 
        setId(cellProps.row.original.id)
    }
    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort} 
          setFilterObject(filters)         
          dispatch(propertyArchivedListGetRequest(model,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}      
          setFilterObject(filters)        
          dispatch(propertyArchivedListGetRequest(model,filters, pagination.page));
        }
      };

    useEffect(() => {
        if(archivedPropertyList && archivedPropertyList.length) {
            let fields =  JSON.parse(archivedPropertyList[0].valuesJson);  
            const keys = Object.keys(fields)
            keys.push('createdBy') 
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) =>{
                    // if(field == 'id') return columns[0] = {
                    //         Header:"",
                    //         accessor: field,
                    //         filterable: true,
                    //         isSortable:false,
                    //         disableFilters: true,
                    //         show: false,
                    //         Cell: (cellProps: any) => { <></> }

                    // }
                    if(field == 'label')  return columns[1] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            // let fields =  cellProps.row.original && cellProps.row.original.valuesJson ? JSON.parse(cellProps.row.original.valuesJson) : []    
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                   
                                    {cellProps.row.original[field]}
                                </div>
                            </div>
                            {
                                field == "label" ? 
                                <Button 
                                size='sm'
                                color="dark"
                                outline={true}
                                className={"btn_preview"}   
                                onClick={() => {setisRestoreOpen(true); setrecord(cellProps.row); onClickrow(cellProps.row); setId(cellProps.row.original.id)}} >
                                   {props.t("properties.restore")}
                                </Button> 
                                    : null
                                }
                    </div>                        
                            
                        }
                    }
                    else if(field == 'type')  return columns[2] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return <div className="d-flex align-items-center justify-content-between">                                
                                        <div className="hstack flex-grow-1  gap-2">
                                                <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                {renderField(cellProps.row.original[field])}
                                            </div>
                                        </div>
                                </div>                               
                        }
                    }
                    else if(field == 'createdBy' )  return columns[3] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {        
                            return <span className="fw-semibold fs-14">{cellProps.row.original.createdBy}</span>                               
                        }
                    }                   
                })
                columns.push({
                    Header:'Archived Date',
                    accessor: 'deletedAt',
                    filterable: true,
                    disableFilters: true,
                    show: true,
                    Cell: (cellProps: any) => {        
                        return <span className="fw-semibold fs-14">{cellProps.row.original["deletedAt"] ? moment(cellProps.row.original["deletedAt"]).format('lll'):null}</span>                               
                    }
                })
                setArchivedColumns(columns)
            }           
        }
    }, [archivedPropertyList]); 
 
    const onClickrow = (data: any) => {
        setrecord(data.original);
        setInputs(data);
        let object = {...data.original,objectType: propertyObject.value,}
        setEditInputs(object)
    }

    const Archivedexecute = () => {
        let a= isEqual(archivedPropertyList, propertiesArchivedJson);
        return !a
     }

    useEffect(() => {
        const parsedArchivedData: any = archivedPropertyList && archivedPropertyList.length ? archivedPropertyList.map((item: any) => {
            const valuesJson = JSON.parse(item.valuesJson);
            let created = item.createdBy && item.createdBy.name
            let values = { ...valuesJson, id: item.id , createdBy: created, deletedAt:item.deletedAt}
            return values
        }) : []
        let properties = parsedArchivedData && parsedArchivedData.length ? parsedArchivedData.reverse() : [];
        setArchivedsearchData(properties)
        setArchivedPropertiesJson(archivedPropertyList)
    }, [Archivedexecute()])

    
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(propertyArchivedListGetRequest(model,filterObject, newPageNo));
    };
    
    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value);
        let filters= {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(propertyArchivedListGetRequest(model,filters, 0));
    }

    const propetytypes = [
        {
            options: [
                { label: "All fields", value: "all_fields" },
                { label: "Custom fields", value: "custom_fields" },
                { label: "Zilter fields", value: "zilter_fields" },
            ],
        },
    ];
    

    const onRestore = () => {
        const handleSuccess = () => {
            const message = () => toast(`${record.label} is restored successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message()
            dispatch(propertyListGetRequest(model, filterObject))
            dispatch(propertyArchivedListGetRequest(model));
            setisRestoreOpen(false)
        }
        const handleFailure = () => {
            setisRestoreOpen(false)

        }
        dispatch(propertyRestoreRequest(model,record.id, handleSuccess, handleFailure))  
    }

    const clearFilter = () => {
        const filters = {}
        setFilterObject(filters)
        setUser({})
        setsearchvalue("")
        setFilterObject({pageSize: pageSize})
        setPageSize(pageSize)
        setSelectedPropertyFieldType({})
        dispatch(propertyArchivedListGetRequest(model,filters));     
    }
    
    const handleChange = (value: any) => {
        setsearchvalue(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters);            
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(propertyArchivedListGetRequest(model,filters));  
        }, 2000);

        setTypingTimeout(newTimeout);
    };

    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    useEffect(() => {        
        dispatch(propertyArchivedListGetRequest(model)); 
    },[model])

    const handleselectedArchived = (option: any,name:any) => {
        let filters: any = {}
        switch(name) {
            case "type": setSelectedAPropertytype(option)
                        filters = {...filterObject, type: option.value}
                        setFilterObject(filters)
                        dispatch(propertyArchivedListGetRequest(model, filters)); 
                break;
        }
    }

    const handledateSelect = (date: any, name: string) => {
        if(name == 'startDate') {  
            setEndDisabled(false)
            const minDateForEndDate = date[0] ? moment(date[0]).add(1, 'day').toDate() : null;
            setMinDate(minDateForEndDate);
            let filters = {...filterObject, [name]: date[0]}
            setFilterObject(filters)
        }
        else {
            let filters = {...filterObject, [name]: date[0]}
            setFilterObject(filters)
            dispatch(propertyArchivedListGetRequest(model, filters)); 
        }              
    }
    document.title="Properties | Zilter";
    return (
        <React.Fragment>
            <RestoreModal 
                show={isRestore}
                onCloseClick={() => setisRestoreOpen(false)}
                onRestore={onRestore}
                record={record} 
                props={props}
            />
            <Card>
                <CardBody>
                    {/* <Row>
                        <Col className='hstack gap-3 fw-medium mb-2 fs-14'>
                            <div>{props.t("properties.archive_disclaimer")}</div>
                        </Col>
                    </Row> */}
                    <Row className='hstack'>                                
                        <Col>
                            <div className="search-box">                                
                                <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder="Search for..."
                                onChange={(e) => handleChange(e.target.value)}
                                value={searchvalue}
                            />
                            { searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
                    
                            </div>  
                        </Col>
                        <Col className="p-0">
                            <div>
                                {/* <Select
                                    // isMulti
                                    placeholder={props.t("properties.select")}
                                    value={selectedAPropertytype}
                                    onChange={(option : any) => handleselectedArchived(option, 'type')}
                                    options={propetytypes}
                                    name="choices-single-default"
                                    id="idStatus"
                                ></Select> */}
                            </div>                          
                        </Col>                        
                        <Col md={7} sm={7} xl={7} xxl={7} lg={7} className="hstack gap-1 justify-content-end" >
                            {/* <div className='w-25'>{props.t("properties.archive_date")}:</div>
                            <Flatpickr
                                className="form-control w-75"
                                id="datepicker-publish-input"
                                placeholder={`${props.t("properties.select_a_date")}`}
                                onChange={(date: any) =>{handledateSelect(date, 'startDate');}}
                                options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "d.m.y",
                                }}
                            />&nbsp;{props.t("properties.to")}&nbsp; 
                            <Flatpickr
                                // disabled={disabled}
                                className="form-control w-75"
                                id="datepicker-publish-input"
                                placeholder={`${props.t("properties.select_a_date")}`}
                                onChange={(date: any) =>{handledateSelect(date, 'endDate')}}
                                options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "d.m.y",
                                    minDate:minDate
                                }}
                            />     */}
                        </Col>
                    </Row>
                    <Row className={"students-table mt-1"}>
                                    <TableContainer
                                        columns={Archivedcolumns}
                                        data={searchArchivedData || []}
                                        customPageSize={pageSize}
                                        divClass="table-responsive"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        thClass="border-bottom-1 table-soft-primary"
                                        SearchPlaceholder='Search...'
                                        isPageSizeChange={true}
                                        isBordered={true}
                                        setIsMultiDeleteButton={setIsMultiDeleteButton}
                                        setselectedcount={setselectedcount}                                             
                                        pageChanged={pageChanged}
                                        pagination={paginationDetails}
                                        setPagination={setPagination}
                                        pagesList={pagesList}
                                        onChangePageSize={onChangePageSize}
                                        pageSize={pageSize}                                    
                                        onClickrow={onClickrow}
                                        setIsArchivedOpen={setIsArchivedOpen}
                                        setisEditOpen={setisEditOpen}  
                                        setisDeleteOpen={setisDeleteOpen}
                                        archive={true}  
                                        onRestoreClick={onRestoreClick}      
                                        handleSort={handleSort}
                                        sortBy={sortBy}
                                        sortOrder={sortOrder}     
                                        props={props} 
                                        loading={loading}                           
                                    />
                    </Row>
                </CardBody>
            </Card>       
        </React.Fragment>
    );
};
export default withTranslation()(Properties);


