import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, TabContent, TabPane, Modal, ModalHeader, ModalBody, Input, Label, Row, Pagination } from "reactstrap";
import BasicInfo from "../editstep/BasicInfo";
import FieldType from "../editstep/FieldType";
import Rules from "../editstep/Rules";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { GroupGetByModelRequest, GroupListGetRequest, propertyCreateRequest, propertyListGetRequest, propertyUpdateRequest } from '../../../store/properties/action';
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import Roles from "../roles/Roles";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";

const ManageAccess = ({props,id, record,model, show, onCloseClick, setpermissionInputs,setEditInputs,pagination,  EditInputs, permissionInputs, filterObject}: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionList = useSelector((state: ApplicationState) => state.properties.groups);  
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [disabled, setdisabled] = useState<boolean>(false);
    const [groupoptions, setgroupOptions] = useState([]);
    const [fieldJson, setFieldJson] = useState<any>(null);
    const [PermissionId, setPermissionId]= useState<any>("view_edit")

    const toggleArrowTab = (tab: any) => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }
    const nextClicked = () => {
        toggleArrowTab(activeArrowTab + 1)
     }
     const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1)
     }

     
    const onApply = (permissions: any) => {
        const RolePermissions = {
            "permissions": PermissionId,
            "item": permissions
        }
        const data = {
            "permissionsJson": JSON.stringify(RolePermissions),
            "valuesJson": JSON.stringify(EditInputs),
            "model": EditInputs.objectType,            
            "groupId": EditInputs.groupId,
        }
          const handleSuccess = () => {
            dispatch(propertyListGetRequest(EditInputs.objectType, filterObject,pagination.page))
            const message = () => toast(`Property updated successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            toggleArrowTab(1)
            onCloseClick()
            setpermissionInputs({})
            setEditInputs({})
          }
          const handleFailure = () => {
            
          }
          dispatch(propertyUpdateRequest(id,data, handleSuccess, handleFailure ))
    }

    useEffect(() => {        
            dispatch(GroupGetByModelRequest(model))
    }, [])

     useEffect(() => {
            switch(activeArrowTab) {
                case 1: setdisabled(!EditInputs.label || !EditInputs.objectType)
                        break;
                case 2: setdisabled(!EditInputs.type)
                        break;
            }         
      }, [EditInputs]);   
      useEffect(() => {
            switch(activeArrowTab) {
                case 1: setdisabled(!EditInputs.label || !EditInputs.objectType)
                        break;
                case 2: setdisabled(!EditInputs.type)
                        break;
            }   
      }, [activeArrowTab]);   
      const onChange = (value: any) => {
        setPermissionId(value)
      }
      const options = [
        { label: 'Private to super admins only', value: 'Owner' },
        { label: 'Allow everyone to access and edit', value: 'view_edit' },
        { label: 'Allow everyone view', value: 'view' },
        { label: 'Assign to roles', value: 'role_based' }
      ];

      useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            return { label: item.groupTitle, value: item.id }
        })
        setgroupOptions(options)
    }, [optionList])

      useEffect(() => {
        if(EditInputs && EditInputs.groupId) { 
            groupoptions && groupoptions.length && groupoptions.map((field: any) => {          
                if(field.label === EditInputs.groupId)  {
                    setEditInputs({...EditInputs, groupId:field.value})
                }})
        }
      }, [EditInputs])

      useEffect(() => {
        if(permissionInputs && permissionInputs.permissions) {
            setPermissionId(permissionInputs.permissions)
        }
      }, [permissionInputs])
      console.log("record", record)
        return (
            <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="bg-info bg-gradient p-3 offcanvas-header-dark" toggle={() => { onCloseClick(); }}>
                    <span className="text-light">{props.t("properties.manage_access")} - {record?.original?.label}</span>
            </ModalHeader>
            <ModalBody className='text-center p-3'>
                {/* <SimpleBar> */}
                    <div className="mt-1 text-left">     
                        <p>{props.t("properties.manage_access_helper_text")}</p>
                        <h5>{props.t("properties.property_access")}</h5>
                        <div className="vstack gap-1 ms-3">
                        {options && options.length ? options.map((item: any) => {
                            return <div>
                                <Input
                                    className={"form-check-input"}
                                    type={"radio"}
                                    id={item.value}                                
                                    checked={PermissionId ? item.value == PermissionId : item.value == "view_edit"}
                                    onChange={(e) => onChange(item.value)}
                                />
                                <Label className="form-check-label" >
                                    {item.label}
                                </Label>
                            </div>
                        }): null}
                        {
                            PermissionId == "role_based"  ?
                            <Card>
                                <Roles onCloseClick={onCloseClick} onApply={onApply} props={props} permissionInputs={permissionInputs} record={record} />
                            </Card>
                            : null
                        }
                        {
                            PermissionId !== "role_based"  ?
                                <Row>
                                    <div className="hstack gap-2 justify-content-center">
                                        <Button className="btn btn-primary" onClick={() => onApply([])} disabled={!PermissionId}>
                                            {props.t("properties.save")}
                                        </Button>
                                        <Button color="danger" onClick={() => onCloseClick()} outline>
                                            {props.t("properties.cancel")}
                                        </Button>
                                    </div>
                                </Row>
                                : null
                        }
                    </div>
                    </div>
                {/* </SimpleBar> */}
            </ModalBody>
        </Modal>
    );
};

export default withTranslation()(ManageAccess);
