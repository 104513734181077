import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import logoLight from "../../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import FormRender from "./FormRender";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const Preview = ({ props, show, onCloseClick,title, formField, cols, formValues, onCreate, loading, btndisabled }: any) => {
   return (
            <Modal size="xl" backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
                <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => {onCloseClick();}}>
                    Preview
                </ModalHeader>
                <ModalBody className='p-5'>
                    <div className="mt-1">     
                    <React.Fragment>
                        <Container>  
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoLight} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="30" />
                                    </span>
                                </Link>
                                <Link to="" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLight} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="30" />
                                    </span>
                                </Link>
                            </div>  
                            <Row>
                                <div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
                                    <Label>{title}</Label>
                                </div>
                            </Row>                       
                            <FormRender dataFields={formField || []} md={cols} lg={cols} xl={cols} BtnClassName={"w-auto justify-content-end"}  btn_text={'Submit'} data={formValues} />
                        </Container>
                    </React.Fragment>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button onClick={onCreate} className="btn btn-primary w-md mb-3" disabled={btndisabled}>
                            Create Form
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
    ) as unknown as JSX.Element;
};

export default Preview;