import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-perfect-scrollbar/dist/css/styles.css";
import AsyncCreatableSelect from "react-select/async-creatable";
import FeatherIcon from "feather-icons-react";

export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface FProps {
  props: any;
  emailModals: boolean;
  setEmailModals: (event: boolean) => void;
  reply?: boolean;
}

const SingleOptions = [
  { value: "Anna Adame", label: "Anna Adame(annaadane@gmail.com)" },
  { value: "James Will", label: "James Will(james@gmail.com" },
];

const EmailModal: React.FC<FProps> = ({
  props,
  emailModals,
  setEmailModals,
  reply,
}) => {
  const [showCcField, setShowCcField] = useState<boolean>(false);
  const [showBccField, setShowBccField] = useState<boolean>(false);

  const filterSuggestions = (inputValue: string) => {
    return SingleOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const toggle = () => {
    if (emailModals) {
      setEmailModals(false);
    } else {
      setEmailModals(true);
    }
};
  const promiseOptions = (inputValue: string) =>
    new Promise<SuggestionOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSuggestions(inputValue));
      }, 1000);
    });
  return (
    <React.Fragment>
      <div>
      <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={emailModals} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={toggle}>
                    New Message
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Input
                                type="text"
                                className="form-control email-compose-input"
                                defaultValue="support@themesbrand.com"
                            />
                            <div className="position-absolute top-0 end-0">
                                <div className="d-flex">
                                    <button
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                    >
                                        Cc
                                    </button>
                                    <button
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                    >
                                        Bcc
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="collapse" id="CcRecipientsCollapse">
                            <div className="mb-3">
                                <label>Cc:</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Cc recipients"
                                />
                            </div>
                        </div>
                        <div className="collapse" id="BccRecipientsCollapse">
                            <div className="mb-3">
                                <label>Bcc:</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bcc recipients"
                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <Input type="text" className="form-control" placeholder="Subject" />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event, editor) => {
                                    // editor.getData();
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                          setEmailModals(false);
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                              setEmailModals(false);
                            }}
                        >
                            Send
                        </button>
                        {/* <DropdownToggle
                            tag="button"
                            type="button"
                            className="btn btn-success"
                            split
                        >
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <li>
                                <DropdownItem href="#">
                                    <i className="ri-timer-line text-muted me-1 align-bottom"></i>{" "}
                                    Schedule Send
                                </DropdownItem>
                            </li>
                        </DropdownMenu> */}
                    </UncontrolledDropdown>
                </div>
            </Modal>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(EmailModal);
