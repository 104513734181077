import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';

import company2 from "../../../assets/images/companies/img-2.png";
import AdvanceFilter from "./innerComponent/AdvanceFilter";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { financeChannelListGetRequest, financeDelete, financeGetRequest } from "store/finance/action";
import { ToastContainer, toast } from 'react-toastify';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import Upload from './Upload/Upload';
import UpdateModal from './innerComponent/UpdateModal';
import FieldsJson from "./Fields.json"
import { coursesMetadataGetRequest } from 'store/course/action';
import staticfields from './Fields.json'
import UserAvatar from 'pages/Students/innerComponent/UserAvatar';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Finance = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const financeMetadata = useSelector((state: ApplicationState) => state.properties.list);
    const [createModal, setCreateModal] = useState<boolean>(false);
    const financeList = useSelector((state: ApplicationState) => state.finance.channelfinances);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.finance.pagination); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const coursesMetadata = useSelector((state: ApplicationState) => state.courses.coursesMetadata); 
    const courses = useSelector((state: ApplicationState) => state.courses); 
    console.log("courses", courses)
    const coursesMetadataLoading = useSelector((state: ApplicationState) => state.auth.loading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [channeldatafields, setDatafields] = useState<any>([]);
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])  
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [fileInfo, setFileInfo] = useState<any>({});
    const [finance, setFinance] = useState([]);
    const [financeJson, setfinanceJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [isTable, setisTable] = useState<boolean>(true);
    const [isUpdateModalOpen, setUpdateModal] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [levels, setLevels ] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
   useEffect(() => {
    dispatch(coursesMetadataGetRequest("courses",{pageSize: 500}, 0));
   }, [])

   useEffect(() => {
    console.log("coursesMetadata", coursesMetadata)
    coursesMetadata && coursesMetadata.length && coursesMetadata.map((item: any) =>{        
        let field = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
        if(field && field.key == "level") {
            console.log("field", field)
            let options = field.values;
            console.log("options", options)
            let levelsfields:any = []
            options && options.map((item: any) => {
                let level = {
                    "label": item.label,
                    "key": item?.label?.toLowerCase(),
                    "placeholder": `Enter ${item.label} Commission`,
                    "quickCreate": true,
                    "type": "groupinput",
                    "values": [
                        {
                            "label": "Fixed",
                            "value": "fixed",
                            "in_forms": true
                        },
                        {
                            "label": "Percent",
                            "value": "percent",
                            "in_forms": true
                        }
                    ]
                }
                levelsfields.push(level)
            })
            console.log("levelsfields", levelsfields)
            setLevels(levelsfields)
        }
    })
   }, [coursesMetadata])

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(financeChannelListGetRequest(id,filters, 0));
        setfinanceJson([])
    }
    

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(financeGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.finances;
            setAccess(access)
            setViewAccess(true)
        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(financeChannelListGetRequest(id,filterObject, newPageNo));
        setfinanceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Entry Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(financeChannelListGetRequest(id))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(financeDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])


    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const handleAccess = (permissionJson : any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        if (!permissionJson || !permissionJson.permissions) return false;
        if(permissionJson.permissions === "Owner") {
            if(userRole === "Owner") return true 
            else return false
        }
        if(permissionJson.permissions === "view") {
            return true
        }
        if(permissionJson.permissions === "view_edit") {
            return true
        }
        else if (permissions === 'role_based') 
        {            
            const items = permissionJson.item || [];
            let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
            let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
            return isview && isview.length
        }
        else return false
    }

    const handleType = (type: any, currency: any) => {
        if(type == 'percent') return '%';
        else if(currency) return currency
    }

    useEffect(() => {        
        const columns: any = []
        if(staticfields && staticfields.length) {
            staticfields.map((field,index) =>{  
                if(field.key == 'institutes') {
                    let newcolumn = {
                        Header: field.label,
                        accessor: field.key,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            console.log("cellProps.row.original?", cellProps.row.original)
                            return  <div className="d-flex align-items-center">                                
                                        <div className="hstack flex-grow-1 gap-2">
                                            <div className="flex-shrink-0 text-decoration-none hstack gap-2 fw-bold" >
                                               <UserAvatar firstName={cellProps.row.original['instituteId']} />
                                                {cellProps.row.original['instituteId']}
                                            </div>
                                        </div>
                                    </div>                        
                            
                        }
                    }
                    columns.push(newcolumn)
                }
            })
        } 
        if(levels && levels.length) {
            levels.map((field: any) =>{  
                let newcolumn = {
                    Header: field.label,
                    accessor: field.label.toLowerCase(),
                    filterable: true,
                    disableFilters: true,
                    show: true,
                    Cell: (cellProps: any) =>{  
                                let values = cellProps.row.original?.valuesJson?.[field.label.toLowerCase()] ? JSON.parse(cellProps.row.original?.valuesJson?.[field.label.toLowerCase()]) : {}
                              return  <div className="d-flex align-items-center justify-content-between">                                
                                <div className="hstack flex-grow-1  gap-2">
                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" >   
                                            <h5 className="fs-14 my-1 fw-medium text-center">{values?.["value"]}
                                                {handleType(values?.["type"], values?.["currency"])}
                                            </h5>
                                            <span className="text-muted">{field.label}</span>
                                    </div>
                                </div>
                        </div>                        
                                
                            }
                }
                columns.push(newcolumn)
            })
        } 
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)   
        
    }, [levels, coursesMetadata]); 

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

   
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {       
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(financeChannelListGetRequest(id,filters));
            setfinanceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const ClearFilter = () => {
        setsearch("")
        setstatusvalue("")
        setApplyFilter(false);
        setFilterJson([])
        setFilterCount(0)
        setPageSize(pageSize)
        let filter= {pageSize: pageSize}
        setFilterObject(filter)
        dispatch(financeChannelListGetRequest(id, filter));
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'finance_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a= isEqual(financeList, financeJson)
        return !a
     }

    useEffect(() => {
        const parsedData: any = financeList && financeList.length ? financeList.map((item: any) => {
            console.log("?????????????/item", item)
            const valuesJson = item?.valuesJson;
            let values = { ...valuesJson, id: item.id , createdAt: item.createdAt, instituteId: item.instituteId}
            return values
        }) : []
        let finance = parsedData && parsedData.length && parsedData.reverse() 
        setFinance(finance)
        setfinanceJson(financeList)
    }, [execute()])

    
    useEffect(() => {
        dispatch(financeChannelListGetRequest(id))
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(financeChannelListGetRequest(id,filters,0));
        setfinanceJson([])
    }
    
    const onChangeIndex = (column: any,index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort} 
          setFilterObject(filters)         
          dispatch(financeChannelListGetRequest(id,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}         
          setFilterObject(filters)     
          dispatch(financeChannelListGetRequest(id,filters, pagination.page));
        }
      };
      const handleFileClicks = () => {
        setModalFile(!modalFile);
        fileToggle();
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.commissions;            
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])
    console.log("levels", levels)
    useEffect(() => {
        let dataFields: any = []
        let count = 0
         staticfields.map((item: any) =>{
            let field =JSON.stringify(item);
            dataFields[count]= {valuesJson : field};
            count++;
         })
         levels && levels.length && levels.map((item: any) =>{
            let field =JSON.stringify(item);
            dataFields[count]= {valuesJson : field};
            count++;
         })
         setDatafields(dataFields)
    }, [levels])
    const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);

    document.title = "DTS | Zilter";
    return ( 
        <>
         { !authLoading ?
            viewAccess ? 
    <React.Fragment>
            {isExportCSV && <ExportDataModal
            show={isExportCSV}
            onCloseClick={() => setIsExportCSV(false)}
            props={props}
        />}
        {isFilterModalOpen && <AdvanceFilter
            show={isFilterModalOpen}
            onCloseClick={() => setisFilterModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
        />}
        {isDynamicModalOpen && <DynamicFilters
            show={isDynamicModalOpen}
            onCloseClick={() => setIsDynamicModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
            filterJson={filterJson}
            setFilterJson={setFilterJson}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
        />}
        {editModal && <EditModal
            show={editModal}
            onCloseClick={() => setEditOpen(false)}
            onApplyClick={onApplyClick}
            onchange={onchange}
            columns={columns}
            visibleColumns={visibleColumns}
            toggleColumn={toggleColumn}
            onClickmove={onClickmove}
            deSelectColumnall={deSelectColumnall}
            onChangeIndex={onChangeIndex}
            tempcolumns={tempcolumns}
            setTempColumns={setTempColumns}
        />}
        {createModal && <AddEntryModal
            show={createModal}
            onCloseClick={() => setCreateModal(false)}
            dataFields={channeldatafields}
            props={props}
        />}
        {isUpdateModalOpen && <UpdateModal
            show={isUpdateModalOpen}
            onCloseClick={() => setUpdateModal(false)}
            dataFields={channeldatafields}
            record={record}
            props={props}
        />}
        {isImportCSV && <ImportDataModal
            show={isImportCSV}
            onCloseClick={() => setIsImportCSV(false)}
            props={props}
        />}
        {isDeleteOpen && <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />}
            <Row  className='h-100'>
                <Col xl={12} lg={12} md={12} className='h-100'>
                    <Card className='p-4'>
                        <div>
                            <Row className='hstack gap-3'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <Input
                                            type="text"
                                            size={14}
                                            className="search"
                                            placeholder={props.t("finance.search_for")}
                                            onChange={(e) => handleChange(e.target.value)}
                                            value={search}
                                        />
                                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                    </div>
                                </Col>
                                <Col>
                                    <div className='hstack gap-2 justify-content-end'>
                                            {
                                                create ? 
                                                <Button
                                                    onClick={() => setCreateModal(true)}
                                                    color="primary"
                                                    className="btn-label">
                                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("applications.create_entry")}
                                                </Button>
                                            :         
                                                <Button
                                                    color="primary"
                                                    disabled
                                                    className="btn-label">
                                                        <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("applications.create_entry")}
                                                </Button>
                                            }
                                            {
                                                create ? 
                                            <Button
                                                color="success"
                                                onClick={() => handleFileClicks()}
                                                className="btn-label">
                                                    <i className="ri-upload-2-line label-icon align-middle fs-16 me-2"></i>
                                                    Upload file
                                            </Button>: null}
                                    </div>
                                </Col>
                            </Row>     
                                <Row className="row-cols-xxl-12 row-cols-lg-12 row-cols-1 mt-1">
                                    <TableContainer
                                        columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                        data={financeList || []}
                                        customPageSize={pageSize}
                                        divClass="table-responsive table-card mb-1"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        SearchPlaceholder='Search...'
                                        isPageSizeChange={true}
                                        isBordered={true}
                                        handleEditClick={handleEditClick}
                                        handleLeadClick={handleLeadClick}
                                        setisDeleteOpen={setisDeleteOpen}
                                        setIsMultiDeleteButton={setIsMultiDeleteButton}
                                        setselectedcount={setselectedcount}
                                        pageChanged={pageChanged}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        pagesList={pagesList}
                                        onChangePageSize={onChangePageSize}
                                        pageSize={pageSize}
                                        setpreview={setpreview}
                                        handleSort={handleSort}
                                        sortBy={sortBy}
                                        sortOrder={sortOrder}
                                        props={props}
                                        access={access}
                                        setUpdateModal={setUpdateModal}
                                    />
                                </Row>                                
                        </div>
                        
                    </Card>
                </Col>
            </Row>
          {/* File Modal */}
          <Modal backdrop="static" id="createFileModal" isOpen={modalFile} toggle={fileToggle} modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]) }} className="p-3 bg-success-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>
    </React.Fragment>
            : <AccessDenied />
            : <ListingLoader 
                columns={columns} 
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
                thClass="border-bottom-1 table-soft-primary"
            /> }
    </>
    );
};
export default withTranslation()(Finance);


