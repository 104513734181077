import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Table,
} from "reactstrap";
import { ApplicationState } from "store";
import { dealCreateRequest, dealsGetRequest, dealsMetadataGetRequest } from "store/deals/action";
import { productCreateRequest, productGetRequest, productsMetadataGetRequest } from "store/products/action";
import ProductInfoEdit from "./ProductInfoEdit";
import { isJson } from "utils";

const DealCreate: React.FC<any> = ({products, show, onCloseClick, props, options, productid, setproductId,  product, setOptions}: any) => {  
   const { id } = useParams();
   const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
   const metadata = useSelector((state: ApplicationState) => state.products.productsMetadata);
   const [fields, setFields] = useState([])

  
   useEffect(() => {
        dispatch(productsMetadataGetRequest({PageSize: 100}))
    }, [])

    useEffect(() => {
        let newoptions: any= []
        products?.length && products.map((item: any) =>{
            newoptions= [...newoptions, {label:item.valuesJson.name, value: item.id}]
        })
        setOptions(newoptions)
    }, [products != undefined])
    
    const handleProductSelect = (option: any) => {
        setproductId(option)
        dispatch(productGetRequest(option.value))
    }
    useEffect(() => {
        let fields: any = []
        metadata && metadata.length && metadata.map((field: any) => {  
            let fieldvaluesJson = field && field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson: {}
            if(fieldvaluesJson.quickCreate) {
                fields.push(field)
            }
        })
        setFields(fields)
    }, [metadata])


    const OnAddEntry = (formValues: any) => {
        const handleSuccess = (body: any) => {
            onCloseClick()
            dispatch(dealsGetRequest("students",id));
        }
        const data ={
            students: id,
            products: {...formValues, id: product.id},
        }
        dispatch(dealCreateRequest(data, handleSuccess , () => {}))
    }
    return (<>
     <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody>
                     <div className="my-2">
                           <Select
                                className="fs-16 fw-medium"
                                defaultOptions
                                placeholder={props.t("deal.select_product")}
                                value={productid}
                                onChange={(option : any) => handleProductSelect(option)}
                                options={options}
                                name="choices-single-default"
                            ></Select>
                     </div>
                     <div className="my-2">
                        <ProductInfoEdit OnAddEntry={OnAddEntry} fields={fields} product={product} />
                    </div>
            </OffcanvasBody>     
        </Offcanvas>
    </>
    );
};

export default DealCreate;
