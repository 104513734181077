import React, { useEffect, useState } from "react";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Label, Table } from "reactstrap";
import DtsDataSelectInput from "./DtsDataSelectInput";


const StudentInfo = ({ onCloseClick, record }: any) => {
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
	const [metaData, setMetaData] = useState<{ [key: string]: any }>({});
	const [datafields, setdatafields] = useState<any>([])
	const studentDetails = useSelector((state: ApplicationState) => state.student.student);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
	const studentMetadata = useSelector((state: ApplicationState) => state.student.studentsMetadata);
	const onChange = (option: any, key: any) => {
		if (typeof option == 'object') setFormValues({ ...formValues, [key]: option.value })
		else setFormValues({ ...formValues, [key]: option })
	};

	const handleInputChange = (e: React.ChangeEvent<any>) => {
		const { name, value, type } = e.target;
		let fieldValue: any = value;
		if (type === "checkbox") {
			const target = e.target as HTMLInputElement;
			fieldValue = target.checked;
		}

		setFormValues({
			...formValues,
			[name]: fieldValue,
		});
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		onCloseClick();
	};
	useEffect(() => {
		setMetaData(studentMetadata)
	}, [studentMetadata != undefined])
	const isJson = (str: any) => {
		try {
			let options = JSON.parse(str);
			return options
		} catch (e) {
			return false;
		}
	}


	const handleAccess = (permissionJson: any, fields: any) => {
		let userRole = userProfile?.role?.title;
		let roleid = userProfile?.role?.id;
		const permissions = permissionJson?.permissions;
		if (userRole === "Owner") return true
		else if (permissions) {
			switch (permissions) {
				case "Owner":
				case "owner": if (userRole === "Owner") return true;
				else return false;
				case "view": return true;
				case "view_edit": return true;
				case "role_based": const items = permissionJson.item || [];
					let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
					let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
					return isview && isview.length;
				default: return false;
			}

		}
	}
	useEffect(() => {
		if (metaData && metaData.length > 0) {
			let datafields: any = []
			metaData.map((fieldName: any) => {
				let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
				let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
				let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
				if (fields && isFieldviewaccess) datafields.push(fields)
			});
			setdatafields(datafields)
		}
	}, [metaData]);
	const handleselectValue = (value: any, options: any) => {
		if (options?.length && value) {
			const foundItem = options.find((item: any) => item.value === value);
			if (foundItem) {
				return foundItem.label;
			}
		}
	}

	return (
		<Table className="table table-borderless mb-0">
			{datafields && datafields.length && datafields.map((field: any) => {
				let rendervalue = record?.[field.key];
				if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
					rendervalue = handleselectValue(record?.[field.key], field.values);
				}
				else if (field.optionLabel) {
					rendervalue = record?.[field.key] && record[field.key]?.[field.optionLabel] ? record[field.key][field.optionLabel] : 'NA'
				}
				return <tbody>
					<tr className="border border-0 border-bottom">
						<td className="fw-semibold">
							{field.label}
						</td>
						<td className="flex-wrap" style={{ maxWidth: 300 }}>{rendervalue}</td>
					</tr>
				</tbody>
			})}
		</Table>
	);
};

export default StudentInfo;


