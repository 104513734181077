import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {   Accordion, Input, Label } from 'reactstrap';
import Select from "react-select";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    UncontrolledAccordion,
} from 'reactstrap';
import { components } from 'react-select';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
interface Permission {
    name: string;
    options?: Options[];
    value: string; 
}

interface Options {
    label: string;
    value: string;
}
const MenuTab = ({ openpermission, setOpenpermission,mainItem,level_1, level_2,setAccessData, allexpanded, accessData, setAccessPermissions, accessPermissions, PreAccessPermissions}: any) => {
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);
    const [featuresopentab, setFeaturesOpentab] = useState<string[]>(['0', '1', '2', '3', '4'])
    const [stayOpen, setStayOpen] = useState<boolean>(false)
    useEffect(() => {
        mainItem && mainItem.permissions && mainItem.permissions.length && mainItem.permissions.map((subitem: any, subitemindex: any) => {
            setFeaturesOpentab(prevfeaturesopentab => [...prevfeaturesopentab, subitem.id]);
        })
        // setAccessPermissions(PreAccessPermissions)
    }, []);

    useEffect(() => {
        setStayOpen(allexpanded)
    }, [allexpanded]);

    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className={`hstack justify-content-between`}>
                <span>{props.children}</span>
                <span>{props.isSelected && <i className="ri-check-line fs-20" />}</span>
            </div>
          </components.Option>
        );
      };

    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    width: 200, 
                    // color: '#495057',
                    fontWeight: 900,
                    // border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 3,
                    boxShadow: 'none',
                    '&:hover': {
                    // border: '0.5px solid lightgrey',
                    },
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          borderRadius: 4,
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }

    const handleParentToggle = (options: any, name: any) => {
        if(name?.toLowerCase() === 'courses' || name?.toLowerCase() === 'institutes' || options.length == 1) return options[0]?.value
        else return options[1]?.value
    }

    const updateObjectInHierarchy = (array: any, targetId: any, updatedObject: any) => {        
        return array.map((item: any) => {
            if (item.id === targetId) { 
                let value = typeof updatedObject === 'object' ? updatedObject.value : updatedObject           
                if(value == false && item?.permissions && item?.permissions.length) {
                    let permissions = item.permissions.map((subitem: any) => {
                        if(subitem.type === "boolean") return {...subitem, value: false}
                        else  return {...subitem, value: "", unassigned: false}
                    })
                    let finalobject = {...item, value: value, permissions:permissions }
                    return finalobject
                } 
                if(value == true && item?.permissions && item?.permissions.length) {
                    let permissions = item.permissions.map((subitem: any) => {                       
                        let option = isJson(item.options) ? handleParentToggle(isJson(subitem.options), item.name) : null
                        if(subitem.name.toLowerCase() === "view") return {...subitem, value:option, unassigned: false}
                        else  return {...subitem, value: "", unassigned: false}
                    })
                    let finalobject = {...item, value: value, permissions:permissions }
                    return finalobject
                } 
                else {
                    let newObject = {...item, value: value}
                    return { ...item, ...newObject }; 
                }                
            } else if (item.children && item.children.length > 0) {
                return { ...item, children: updateObjectInHierarchy(item.children, targetId, updatedObject) };
            } else if (item.permissions && item.permissions.length > 0) {
                return { ...item, permissions: updateObjectInHierarchy(item.permissions, targetId, updatedObject) };
            } else {
                return item;
            }
        });
    };

    
    const updateunassignedObjectInHierarchy = (array: any, targetId: any, updatedObject: any) => {
        return array.map((item: any) => {
            if (item.id === targetId) {
                let value = typeof updatedObject === 'object' ? updatedObject.unassingned : updatedObject
                let newObject = { ...item, unassingned: value }
                return { ...item, ...newObject };
            } else if (item.children && item.children.length > 0) {
                return { ...item, children: updateunassignedObjectInHierarchy(item.children, targetId, updatedObject) };
            } else if (item.permissions && item.permissions.length > 0) {
                return { ...item, permissions: updateunassignedObjectInHierarchy(item.permissions, targetId, updatedObject) };
            } else {
                return item;
            }
        });
    };


    const updateObject = (targetId: any, updatedObject: any) => {
        const updatedArray = updateObjectInHierarchy(accessData, targetId, updatedObject);
        setAccessData(updatedArray);
    };

    const unassignedupdateObject = (targetId: any, updatedObject: any) => {
        const updatedArray = updateunassignedObjectInHierarchy(accessData, targetId, updatedObject);
        setAccessData(updatedArray);
    };

    const updateUnssignedPermissionValue = (
        subitem: any,
        option: any
    ) => {
        let value = option.target.checked
        let permissionItem: any = {
            permissionId: subitem.id,
            value: subitem.value,
            unassigned: value
        }
        const newRolesPermissions = accessPermissions.filter((item: any) => item.permissionId !== permissionItem.permissionId);
        newRolesPermissions.push(permissionItem);
        setAccessPermissions(newRolesPermissions)
        unassignedupdateObject(subitem.id, value);
    };

    const updatePermissionValue = (
        subitem: any,
        option: any,
        isparent: boolean
    ) => {
        // e.preventDefault()
        let value = typeof option === 'object' ? option.value : option;
        if (value == null || value == "OFF" || value == "false" || value == false) {
            if(isparent) {
                let tempnewAccessPermission = accessPermissions;
                let newAccessPermission = tempnewAccessPermission.filter((item: any) => item.permissionId !== subitem.id)
                const newSubitemPermissions = subitem.permissions.map((permission: any) => ({
                    ...permission,
                    value: null
                }));
                
                const newItem = { ...subitem, value: false, permissions: newSubitemPermissions };
                subitem.permissions.map((permission: any, subindex : any) => {
                    newAccessPermission.map((item: any,index : any) => {
                        if(permission.id === item.permissionId) newAccessPermission.splice(index, 1);
                    })
                })                
                setAccessPermissions(newAccessPermission);                
                const updatedArray = updateObjectInHierarchy(accessData, subitem.id, newItem);
                setAccessData(updatedArray);
            }
            else {
                // If condition is met, remove the object from accessPermissions
                let newAccessPermission = accessPermissions.filter((item: any) => item.permissionId !== subitem.id);
                setAccessPermissions(newAccessPermission);
                
            }
        }
        else {
            let permissionItem: any = {}
            if(isparent) {
                permissionItem= {
                    permissionId: subitem.id,
                    value: value
                }
                let childPermissions: any={}
                subitem.permissions && subitem.permissions.length && subitem.permissions.map((item: any) => {                    
                    if(item.name.toLowerCase() == 'view') {                        
                        let option =  isJson(item.options) ? handleParentToggle(isJson(item.options), subitem.name) : ""
                        return childPermissions ={
                            permissionId :item.id,
                            value: option,
                            unassigned:false
                        }
                    }
                })                
                const newRolesPermissions = accessPermissions.filter((item: any) => item.permissionId !== permissionItem.permissionId);
                newRolesPermissions.push(permissionItem);
                newRolesPermissions.push(childPermissions);
                setAccessPermissions(newRolesPermissions)
            }
            else {
                permissionItem = {
                    permissionId: subitem.id,
                    value: value,
                    unassigned: subitem.unassigned
                }                
                const newRolesPermissions = accessPermissions.filter((item: any) => item.permissionId !== permissionItem.permissionId);
                newRolesPermissions.push(permissionItem);
                setAccessPermissions(newRolesPermissions)
            }
        }
        updateObject(subitem.id, option);
    };    

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const renderAccordion = (subitem: any, subitemindex: any) => {

        if (subitem.permissions && subitem.permissions.length) {
            return <React.Fragment key={subitem.id}>
                <AccordionItem className='border border-1'>
                    <AccordionHeader targetId={`${subitem.id}`}  className='hstack justify-content-around' onClick={() => setOpenpermission([subitem.id])}>
                        <div className='fw-bold vstack gap-1'>
                            <span className='fs-15'>{subitem.name}</span>
                            <p className='fw-normal m-0 fs-14' style={{ maxWidth: 400 }}>{subitem.description}</p>
                        </div>
                        {
                            subitem.type == "boolean" ? <div className='d-flex justify-content-end'>
                                <div className="form-check-primary border-primary form-switch mb-3">
                                    <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                        disabled={subitem.editable}
                                        checked={subitem.value === "true" || subitem.value === true} defaultChecked={subitem.value === "true" || subitem.value === true} onChange={(e: any) => { updatePermissionValue(subitem, e.target.checked, true) }} />
                                </div>
                            </div> :
                                <div className='d-flex flex-column justify-content-end dts_select'>
                                    <Select
                                        defaultOptions
                                        className='form-select-sm'
                                        components={{ Option: CustomOption }}
                                        styles={customStyles}
                                        onChange={(option: any) => updatePermissionValue(subitem, option, false)}
                                        defaultValue={
                                            isJson(subitem.options) && isJson(subitem.options).filter((option: any) =>
                                                option.value === subitem.value)
                                        }
                                        value={
                                            isJson(subitem.options) && isJson(subitem.options).filter((option: any) =>
                                                option.value === subitem.value)
                                        }
                                        options={isJson(subitem.options)}
                                    ></Select>
                                    {                                        
                                        subitem.name.toLowerCase() === 'students' || subitem.name.toLowerCase() === 'applications'  ? subitem.value == '' ? null :
                                        <div className="form-check-primary hstack align-content-center gap-1 mb-1  mx-4">
                                            <Input className="form-check-input form-check-primary" type="checkbox" defaultChecked={subitem.unassigned == true || subitem.unassigned == "true"}  onChange={(option: any) => updateUnssignedPermissionValue(subitem, option)} />
                                            <Label className="fs-13 mb-1 mt-1 fw-medium">Unassigned</Label>
                                        </div>
                                        : null
                                    }
                                </div>
                        }
                    </AccordionHeader>
                    <AccordionBody accordionId={`${subitem.id}`} className='p-0 vstack gap-2'>
                    {
                            subitem.permissions && subitem.permissions.length ? subitem.permissions.map((item: any, permissionindex: any) => {
                               let parentValue = subitem.value === "true" || subitem.value === true
                               let childDisabled = !parentValue
                                return <div>{
                                    item && item.type == "dropdown" ? <div> 
                                    {permissionindex == 0 ? null: <div><hr className="m-1 w-100 border dropdown-divider" /></div>}
                                    <div className='hstack mt-1 justify-content-around' style={{ height: 'auto' }}>
                                        <div className='fw-bold  ms-2 fs-14 vstack align-self-center'>
                                            <h6>{item.name}</h6>
                                        </div>
                                        <div className='d-flex flex-column justify-content-end dts_select'>                                                
                                                <Select
                                                    styles={customStyles}
                                                    defaultOptions
                                                    components={{ Option: CustomOption }}
                                                    className='form-select-sm'
                                                    defaultValue={childDisabled ?  null :
                                                        isJson(item.options) && isJson(item.options).filter((option: any) =>
                                                            option.value === item.value)
                                                    }
                                                    value= {childDisabled ?  null :
                                                        isJson(item.options) && isJson(item.options).filter((option: any) =>
                                                            option.value === item.value)
                                                    }
                                                    isDisabled={childDisabled}
                                                    options={isJson(item.options)}
                                                    onChange={(option: any) => updatePermissionValue(item, option, false)}
                                                ></Select>
                                            {                                                
                                                subitem.name.toLowerCase() === 'students' || subitem.name.toLowerCase() === 'applications'  ? item.value == '' ? null :
                                                    <div className="form-check-primary hstack align-content-center gap-1 mb-1 mx-4">
                                                        <Input className="form-check-input form-check-primary" type="checkbox" defaultChecked={item.unassigned == true || item.unassigned == "true"} disabled={childDisabled}  onChange={(option: any) => updateUnssignedPermissionValue(item, option)}/>
                                                        <Label className="fs-13 mb-1 mt-1 fw-medium">Unassigned</Label>
                                                    </div>
                                                    : null }
                                        </div>
                                    </div>  
                                    </div> :
                                    <div>
                                        {/* <div><hr className="m-1 w-100 border dropdown-divider" /></div> */}
                                            <div className='hstack my-2 justify-content-around mt-3'>
                                                <div className='fw-bold ms-2 fs-14 vstack align-self-center'>
                                                    <h6>{item.name}</h6>
                                                </div>
                                                <div className="form-check-primary border-primary form-switch mb-4">
                                                <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                                            disabled={item.editable}
                                                            defaultChecked={item.value === "true" || item.value === true} checked={item.value === "true" || item.value === true} onChange={(e: any) => { updatePermissionValue(item, e.target.checked, false) }} />
                                            </div>
                                        </div>
                                    </div>
                                }</div>

                            }) : null
                        }
                    </AccordionBody>
                </AccordionItem>
            </React.Fragment>
        }
        else return <React.Fragment>
            <AccordionItem className='border border-1'>
              <div className='hstack justify-content-around px-3 py-3' onClick={() => setOpenpermission([subitem.id])}>
                        <div className='fw-bold vstack'>
                            <span className='fs-15'>{subitem.name}</span>
                            <p className='fw-normal m-0 fs-14' style={{ maxWidth: 400 }}>{subitem.description}</p>
                        </div>
                        {
                            subitem.type == "boolean" ? <div className='d-flex justify-content-end'>
                                <div className="form-check-primary border-primary form-switch mb-3">
                                    <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                        disabled={subitem.editable}
                                        checked={subitem.value === "true" || subitem.value === true} defaultChecked={subitem.value === "true" || subitem.value === true} onChange={(e: any) => { updatePermissionValue(subitem, e.target.checked, false) }} />
                                </div>
                            </div> :
                                <div className='d-flex flex-column justify-content-end dts_select'>
                                    <Select
                                        styles={customStyles}
                                        defaultOptions
                                        className='form-select-sm'
                                        components={{ Option: CustomOption }}
                                        onChange={(option: any) => updatePermissionValue(subitem, option, false)}
                                        defaultValue={
                                            isJson(subitem.options) && isJson(subitem.options).filter((option: any) =>
                                                option.value === subitem.value)
                                        }
                                        value={
                                            isJson(subitem.options) && isJson(subitem.options).filter((option: any) =>
                                                option.value === subitem.value)
                                        }
                                        options={isJson(subitem.options)}
                                    ></Select>
                                    {                                        
                                        subitem.name.toLowerCase() === 'students' || subitem.name.toLowerCase() === 'applications'  ? subitem.value == '' ? null :
                                            <div className="form-check-primary hstack align-content-center gap-1 mb-1  mx-4">
                                                <Input className="form-check-input form-check-primary" type="checkbox" defaultChecked={subitem.unassigned == true || subitem.unassigned == "true"} onChange={(option: any) => updateUnssignedPermissionValue(subitem, option)} />
                                                <Label className="fs-13 mb-1 mt-1 fw-medium">Unassigned</Label>
                                            </div>
                                            : null}
                                </div>
                        }
                    </div></AccordionItem>
        </React.Fragment>
    }

    const handlePermissions = (subitem: any,subitemindex: any) => { 
        if(subitem && subitem.permissions && subitem.permissions.length) {            
                return <React.Fragment key={subitem.id}>
                    <>
                    {renderAccordion(subitem, subitemindex)}
                    </>
                </React.Fragment> 
        }   
        else return <React.Fragment key={subitem.id}>
            <>{renderAccordion(subitem, subitemindex)}</>
        </React.Fragment>      
    }
    return (
        <Accordion className="custom-accordion-border accordion-dark" id="accordionBordered" open={allexpanded ? featuresopentab : openpermission} toggle={()=>{}}>
            {mainItem && mainItem.permissions.length ? (
                mainItem.permissions.map((permission: any, index: any) => {

                    return <React.Fragment key={permission.id}>
                        <>{handlePermissions(permission, index)}</>
                    </React.Fragment>
                })) : null}
        </Accordion>
    );
};
export default withTranslation()(MenuTab);


