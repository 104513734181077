export interface FinancesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Finance {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum FinanceActionTypes {
    GET_FINANCEMETADATA_REQUEST = "@@finance/GET_FINANCEMETADATA_REQUEST",
    GET_FINANCEMETADATA_SUCCESS = "@@finance/GET_FINANCEMETADATA_SUCCESS",
    GET_FINANCEMETADATA_ERROR = "@@finance/GET_FINANCEMETADATA_ERROR",
    GET_FINANCE_REQUEST = "@@finance/GET_FINANCE_REQUEST",
    GET_FINANCE_SUCCESS = "@@finance/GET_FINANCE_SUCCESS",
    GET_FINANCE_ERROR = "@@finance/GET_FINANCE_ERROR",
    GET_CHANNEL_FINANCES_REQUEST = "@@finance/GET_CHANNEL_FINANCES_REQUEST",
    GET_CHANNEL_FINANCES_SUCCESS = "@@finance/GET_CHANNEL_FINANCES_SUCCESS",
    GET_CHANNEL_FINANCES_ERROR = "@@finance/GET_CHANNEL_FINANCES_ERROR",
    GET_UNIVERSITY_FINANCES_REQUEST = "@@finance/GET_UNIVERSITY_FINANCES_REQUEST",
    GET_UNIVERSITY_FINANCES_SUCCESS = "@@finance/GET_UNIVERSITY_FINANCES_SUCCESS",
    GET_UNIVERSITY_FINANCES_ERROR = "@@finance/GET_UNIVERSITY_FINANCES_ERROR",
    PUT_FINANCE_REQUEST = "@@finance/PUT_FINANCE_REQUEST",
    PUT_FINANCE_SUCCESS = "@@finance/PUT_FINANCE_SUCCESS",
    PUT_FINANCE_ERROR = "@@finance/PUT_FINANCE_ERROR",
    DELETE_FINANCE_REQUEST = "@@finance/DELETE_FINANCE_REQUEST",
    DELETE_FINANCE_SUCCESS = "@@finance/DELETE_FINANCE_SUCCESS",
    DELETE_FINANCE_ERROR = "@@finance/DELETE_FINANCE_ERROR",
    POST_FINANCE_REQUEST = "@@finance/POST_FINANCE_REQUEST",
    POST_FINANCE_SUCCESS = "@@finance/POST_FINANCE_SUCCESS",
    POST_FINANCE_ERROR = "@@finance/POST_FINANCE_ERROR",
}

export interface FinanceState {
    readonly finance: Finance | null;
    readonly pagination: Pagination | null;
    readonly financeMetadata: FinancesMetadata[];
    readonly financelist: any;
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly channelfinances: any,
    readonly universityfinances: any,
    readonly loading: boolean;
}
