import React, { useState } from "react";
import Select from "react-select";
import { Input } from "reactstrap";

export default function MultiCheckboxEdit() {
  const SortOptions = [
    { value: "Choices 1", label: "Custom" },
    { value: "Choices 2", label: "Alphabetical" },
  ];
  const SingleOptions = [
    { value: "Choices 1", label: "Country" },
    { value: "Choices 2", label: "Timezone" },
    { value: "Choices 3", label: "Gender" },
    { value: "Choices 4", label: "Age" },
  ];

  const [selectedSingle, setSelectedSingle] = useState<any>(null);
  const handleSelectSingle = (selectedSingle: any) => {
    setSelectedSingle(selectedSingle);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-left gap-2 mt-3">
        <div className="w-50">
          <label>Sort</label>
          <Select
            value={selectedSingle}
            onChange={(selectedSingle: any) => {
              handleSelectSingle(selectedSingle);
            }}
            options={SortOptions}
          />
        </div>
        <div className="w-50">
          <label>Search</label>
          <Input
            className="form-control"
            placeholder="Search"
            id="autoCompleteCars"
            type="text"
            dir="ltr"
            spellCheck={false}
            autoComplete="off"
            autoCapitalize="off"
          />
        </div>
      </div>
    </>
  );
}
