export interface Currency {
  "result":"success",
  "documentation":"https://www.exchangerate-api.com/docs",
  "terms_of_use":"https://www.exchangerate-api.com/terms",
  "time_last_update_unix":1722816001,
  "time_last_update_utc":"Mon, 05 Aug 2024 00:00:01 +0000",
  "time_next_update_unix":1722902401,
  "time_next_update_utc":"Tue, 06 Aug 2024 00:00:01 +0000",
  "base_code":"USD",
  "conversion_rates":{
   "USD":1,
   "AED":3.6725,
   "AFN":70.9175,
   "ALL":92.0483,
   "AMD":387.9660,
   "ANG":1.7900,
   "AOA":891.9860,
   "ARS":935.0800,
   "AUD":1.5383,
   "AWG":1.7900,
   "AZN":1.6987,
   "BAM":1.7936,
   "BBD":2.0000,
   "BDT":117.4755,
   "BGN":1.7937,
   "BHD":0.3760,
   "BIF":2880.4391,
   "BMD":1.0000,
   "BND":1.3275,
   "BOB":6.9190,
   "BRL":5.7303,
   "BSD":1.0000,
   "BTN":83.8298,
   "BWP":13.5042,
   "BYN":3.2652,
   "BZD":2.0000,
   "CAD":1.3874,
   "CDF":2847.0945,
   "CHF":0.8581,
   "CLP":949.7399,
   "CNY":7.1706,
   "COP":4104.7278,
   "CRC":523.2983,
   "CUP":24.0000,
   "CVE":101.1161,
   "CZK":23.2257,
   "DJF":177.7210,
   "DKK":6.8413,
   "DOP":58.9075,
   "DZD":134.3646,
   "EGP":48.7238,
   "ERN":15.0000,
   "ETB":79.9984,
   "EUR":0.9171,
   "FJD":2.2550,
   "FKP":0.7822,
   "FOK":6.8410,
   "GBP":0.7824,
   "GEL":2.7086,
   "GGP":0.7822,
   "GHS":15.5854,
   "GIP":0.7822,
   "GMD":69.5675,
   "GNF":8699.1783,
   "GTQ":7.7447,
   "GYD":209.1302,
   "HKD":7.8062,
   "HNL":24.7413,
   "HRK":6.9093,
   "HTG":131.7786,
   "HUF":364.1923,
   "IDR":16200.2426,
   "ILS":3.8010,
   "IMP":0.7822,
   "INR":83.8320,
   "IQD":1310.3495,
   "IRR":42017.9073,
   "ISK":138.3083,
   "JEP":0.7822,
   "JMD":156.6059,
   "JOD":0.7090,
   "JPY":146.4334,
   "KES":129.7452,
   "KGS":84.0821,
   "KHR":4124.4334,
   "KID":1.5370,
   "KMF":451.1480,
   "KRW":1358.7066,
   "KWD":0.3052,
   "KYD":0.8333,
   "KZT":475.2478,
   "LAK":22025.0025,
   "LBP":89500.0000,
   "LKR":301.6424,
   "LRD":198.1039,
   "LSL":18.2588,
   "LYD":4.8294,
   "MAD":9.8638,
   "MDL":17.6877,
   "MGA":4556.5707,
   "MKD":56.9612,
   "MMK":2100.6240,
   "MNT":3397.8278,
   "MOP":8.0414,
   "MRU":39.7837,
   "MUR":46.6747,
   "MVR":15.4293,
   "MWK":1737.0476,
   "MXN":19.3358,
   "MYR":4.4975,
   "MZN":63.6772,
   "NAD":18.2588,
   "NGN":1616.9394,
   "NIO":36.8801,
   "NOK":10.9482,
   "NPR":134.1277,
   "NZD":1.6804,
   "OMR":0.3845,
   "PAB":1.0000,
   "PEN":3.7347,
   "PGK":3.9004,
   "PHP":57.9468,
   "PKR":278.5275,
   "PLN":3.9318,
   "PYG":7599.5210,
   "QAR":3.6400,
   "RON":4.5812,
   "RSD":107.5714,
   "RUB":85.5605,
   "RWF":1317.5550,
   "SAR":3.7500,
   "SBD":8.5239,
   "SCR":14.4918,
   "SDG":458.5389,
   "SEK":10.5897,
   "SGD":1.3273,
   "SHP":0.7822,
   "SLE":22.5184,
   "SLL":22518.3987,
   "SOS":571.7417,
   "SRD":29.0206,
   "SSP":1957.1456,
   "STN":22.4672,
   "SYP":12853.4410,
   "SZL":18.2588,
   "THB":35.3992,
   "TJS":10.6119,
   "TMT":3.4992,
   "TND":3.1049,
   "TOP":2.3440,
   "TRY":33.2794,
   "TTD":6.7656,
   "TVD":1.5370,
   "TWD":32.5374,
   "TZS":2685.3877,
   "UAH":41.2418,
   "UGX":3723.2703,
   "UYU":40.2965,
   "UZS":12539.6710,
   "VES":36.6555,
   "VND":25253.8318,
   "VUV":120.3385,
   "WST":2.7687,
   "XAF":601.5307,
   "XCD":2.7000,
   "XDR":0.7531,
   "XOF":601.5307,
   "XPF":109.4307,
   "YER":250.1417,
   "ZAR":18.2667,
   "ZMW":25.9025,
   "ZWL":13.7739
  }
 }

export enum CurrencyActionTypes {
    POST_CURRENCY_REQUEST = "@@currency/POST_CURRENCY_REQUEST",
    POST_CURRENCY_SUCCESS = "@@currency/POST_CURRENCY_SUCCESS",
    POST_CURRENCY_ERROR = "@@currency/POST_CURRENCY_ERROR",
    GET_CURRENCY_REQUEST = "@@currency/GET_CURRENCY_REQUEST",
    GET_CURRENCY_SUCCESS = "@@currency/GET_CURRENCY_SUCCESS",
    GET_CURRENCY_ERROR = "@@currency/GET_CURRENCY_ERROR",
    GET_CURRENCIES_REQUEST = "@@currency/GET_CURRENCIES_REQUEST",
    GET_CURRENCIES_SUCCESS = "@@currency/GET_CURRENCIES_SUCCESS",
    GET_CURRENCIES_ERROR = "@@currency/GET_CURRENCIES_ERROR",
}

export interface CurrencyState {
    readonly currencies: Currency[] | null;
    readonly currency: Currency  | null;
    readonly loading: boolean;
}
