import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    CardBody,
    Row,
    Col,
    Label,
    Table,
    Card,
} from "reactstrap";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import axios from "axios";
import { ApplicationState } from "store";


const PreviewModal: React.FC<any> = ({ show, onCloseClick, formValues, record, ontoggle, StudentsList, targetRef }: any) => {  
    return (<>
        <div ref={targetRef} id="content-id" >
            <CardBody className="border-bottom border-bottom-dashed p-0">
                <Row className="hstack justify-content-end">
                    <Col lg={12} md={12} xl={12}className="hstack justify-content-end">  
                        <div className="justify-content-end">
                            <h1>INVOICE</h1>
                            <span>#{formValues.invoiceNo}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xxl={12} xl={12} md={12} lg={12}>
                        <Card>
                            <CardBody className="border-bottom border-bottom-dashed py-2 px-2">
                                <Row className="hstack justify-content-between">
                                    <Col lg={4} md={4} xl={4}>
                                        <div className="profile-user mx-auto  mb-1 mt-2">
                                            <Label for="profile-img-file-input" className="d-block">
                                                <span
                                                    className="border border-dashed d-flex align-items-center justify-content-center rounded"
                                                    style={{ height: "100px", width: "100px" }}
                                                >{formValues?.logo ?
                                                    <img
                                                        src={formValues?.logo}
                                                        className="card-logo card-logo-dark user-profile-image img-fluid"
                                                        alt="logo dark"
                                                        width={100}
                                                        height={100}
                                                    />
                                                    : null}
                                                </span>
                                                <span
                                                    className="border border-dashed d-flex align-items-center justify-content-center rounded"
                                                    
                                                >{formValues?.name ?
                                                formValues?.name
                                                    : null}
                                                </span>
                                            </Label>
                                        </div>
                                        <div>
                                            <div className="vstack">
                                                <Label for="companyAddress">Address</Label>
                                                <Label for="companyAddress">{formValues?.address}</Label>
                                            </div>
                                            <div  className="vstack">
                                                <Label for="companyAddress">Postal Code</Label>
                                                <Label for="companyAddress">{formValues?.postalCode}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={7} md={7} xl={7} className="align-self-start mt-4">
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Registration Number:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.registrationNo}</Label>
                                        </div>
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Email:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.email}</Label>
                                        </div>
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Website:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.website}</Label>
                                        </div>
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Contact Number:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.contactNo}</Label>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>                               
                            <CardBody className="p-4 border-top border-top-dashed px-2">
                                <Row className="w-100 hstack justify-content-between">
                                    <Col lg={5} sm={12} md={5} xl={5}>
                                        <div>
                                            <Label
                                                for="billingName"
                                                className="text-muted text-uppercase fw-semibold mb-1"
                                            >
                                                Billing Address
                                            </Label>
                                        </div>
                                        <div className="mb-1">
                                            <Label for="totalamountInput">{formValues?.channel_name}</Label>
                                        </div>
                                        <div className="mb-1">
                                            <Label for="totalamountInput">{formValues?.channel_address}</Label>
                                        </div>
                                        <div className="mb-1">
                                            <Label for="totalamountInput">{formValues?.channel_phone}</Label>
                                        </div>
                                        <div className="mb-1">
                                            <Label for="totalamountInput">{formValues?.channel_taxNumber}</Label>
                                        </div>
                                    </Col>
                                    <Col lg={5} sm={12} md={5} xl={5} className="align-self-start">                                           
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice Date:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{moment(formValues?.invoiceDate).format('DD-MM-YYYY')}</Label>
                                        </div>
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice No:</Label>
                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.invoiceNo}</Label>
                                        </div>
                                        <div className="mb-1 hstack justify-content-between">
                                            <Label  className="fw-bold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                            <Label className="fw-bold mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.balanceDue}</Label>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4 px-2">
                                <div className="table-responsive">
                                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                                        <thead className="align-middle">
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>
                                                    #
                                                </th>
                                                <th scope="col">Student Details</th>
                                                <th scope="col" style={{ width: "120px" }}>
                                                    <div className="d-flex currency-select input-light align-items-center text-center ">
                                                        Rate
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                    style={{ width: "200px" }}
                                                >
                                                    Tax
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                    style={{ width: "150px" }}
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="newlink">
                                            {StudentsList && StudentsList.length ? StudentsList.map((item: any, index: any) => {
                                                return <tr id="1" className="product">
                                                    <th scope="row" className="product-id">
                                                        {index + 1}
                                                    </th>
                                                    <td className="text-start">
                                                        <div className="mb-2">
                                                            {item.studentName}
                                                        </div>
                                                        {item.universityName}<br />
                                                        {item.courseName}
                                                    </td>
                                                    <td className="justify-content-center">
                                                        <Label for="totalamountInput">{item.rate}</Label>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="text-center position-relative w-100">
                                                            <Label for="totalamountInput">{formValues?.currency?.currency}{item?.itemAmount}</Label> 
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="text-center position-relative w-100">
                                                            <Label for="totalamountInput">{formValues?.currency?.currency}{item?.amount}</Label> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : null}

                                        </tbody>
                                    </Table>
                                </div>
                                <CardBody>
                                    <Row className="mt-1 justify-content-end">
                                        <Col lg={5} className="mt-1">
                                            <div className="mb-1 hstack justify-content-between">
                                                <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Subtotal:</Label>
                                                <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.totalAmount}</Label>
                                            </div>
                                            <div className="mb-1 hstack justify-content-between">
                                                <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Tax:</Label>
                                                <Label className="text-muted mb-1 hstack justify-content-end w-50">
                                                    {
                                                        formValues?.taxType == 'fixed' ?                                                     
                                                        <Label for="totalamountInput">{formValues?.currency?.currency}{formValues?.tax}</Label>
                                                        : 
                                                        <Label for="totalamountInput">{formValues?.tax}%</Label>
                                                    }              
                                                </Label>
                                            </div>
                                            <div className="mb-1 hstack justify-content-between">
                                                <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Total:</Label>
                                                <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.total}</Label>
                                            </div>
                                            <div className="mb-1 hstack justify-content-between">
                                                <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Amount Paid:</Label>
                                                <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.paidAmount ? formValues?.paidAmount : 0}</Label>
                                            </div>
                                            <div className="mb-1 hstack justify-content-between">
                                                <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                                <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.balanceDue}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mt-2 vstack">
                                                <Label
                                                    for="exampleFormControlTextarea1"
                                                    className="form-label text-muted text-uppercase fw-semibold"
                                                >
                                                    NOTES
                                                </Label>
                                                <Label for="totalamountInput">{formValues?.notes}</Label>
                                            </div>
                                            <div className="mt-2 vstack">
                                                <Label
                                                    for="exampleFormControlTextarea1"
                                                    className="form-label text-muted text-uppercase fw-semibold"
                                                >
                                                    TERMS
                                                </Label>
                                                <Label for="totalamountInput">{formValues?.terms}</Label>                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </div>
    </>
    );
};

export default PreviewModal;
