import React, { useEffect, useState } from "react";
import { Col, Row, Button } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import SentimentsLineChart from "./SentimentsLineChart";
import SentimentsChart from "./SentimentsChart";
import TalktimeChart from "./TalktimeChart";
import Transcript from "./Transcript";


const Sentiments = ({ sentiments }: any) => {
    const transcript = sentiments.Transcript;
    const sentimentsList = sentiments.ConversationCharacteristics.Sentiment.SentimentByPeriod.QUARTER;
    const talktime = sentiments.ConversationCharacteristics.TalkTime.DetailsByParticipant;
    const nontalktime = sentiments.ConversationCharacteristics.NonTalkTime.TotalTimeMillis;
    return (
        <React.Fragment>
        <Row>
            <Col xs={12}>
               <SentimentsChart sentiments={transcript} />
            </Col>
            <Col xs={12}>
               <SentimentsLineChart sentiments={sentimentsList} />
            </Col>
            <Col xs={12}>
               <TalktimeChart talktime={talktime} nontalktime={nontalktime} />
            </Col>
            <Col xs={12}>
               <Transcript transcript={transcript} />
            </Col>
        </Row>
        </React.Fragment>
    )
};

export default Sentiments;