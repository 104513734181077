import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51GXJngKBdQtb1E4J4SY5WrnzQ3TPRMcNWmFzMxUTtTmEIwBUuBeQyNGy5N4dPbp7mbBYnwVXDLuv6Tc68ogYQXjp00OQZOppzL');
const PRICING = [
  {
      id: 1,
      type: "Essential",
      purpose: "For Startup",
      planIcon: "ri-book-mark-line",
      rate: 37,
      projects: 3,
      Customers: 299,
      FTP: 5,
      supportClass: "danger",
      supportClassSymbol: "close",
      storageClass: "danger",
      storageClassSymbol: "close",
      domainClass: "danger",
      domainClassSymbol: "close",
      ribbon: false,
      planButtonClassname: "soft-success",
      icon: "ri-book-mark-line",
      btntxt: "Subscribe",
      rateYear: "29",
      delrateYear: "37",
      priceIdYear: "price_1PzsKxKBdQtb1E4JIOFoKjBH",
      priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
  },
  {
      id: 2,
      type: "Professional",
      purpose: "For Startup",
      planIcon: "ri-book-mark-line",
      rate: 43,
      projects: 3,
      Customers: 299,
      FTP: 5,
      supportClass: "danger",
      supportClassSymbol: "close",
      storageClass: "danger",
      storageClassSymbol: "close",
      domainClass: "danger",
      domainClassSymbol: "close",
      ribbon: true,
      planButtonClassname: "soft-success",
      icon: "ri-book-mark-line",
      btntxt: "Subscribe",
      rateYear: "34",
      delrateYear: "43",
      priceIdYear: "price_1PzsKxKBdQtb1E4JIOFoKjBH",
      priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
  },
  {
      id: 3,
      type: "Enterprise",
      purpose: "For Startup",
      planIcon: "ri-book-mark-line",
      rate: 74,
      projects: 3,
      Customers: 299,
      FTP: 5,
      supportClass: "danger",
      supportClassSymbol: "close",
      storageClass: "danger",
      storageClassSymbol: "close",
      domainClass: "danger",
      domainClassSymbol: "close",
      ribbon: false,
      planButtonClassname: "soft-success",
      icon: "ri-book-mark-line",
      btntxt: "Subscribe",
      rateYear: "59",
      delrateYear: "74",
      priceIdYear: "price_1PzsKxKBdQtb1E4JIOFoKjBH",
      priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
  },
];

const Pricing = ({userid}: any) => {
    document.title = "Pricing | Velzon - React Admin & Dashboard Template";

    //Tab 
    const [activeTab, setActiveTab] = useState<string>('2');
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleCheckout = async (product: any) => {
      const stripe = await stripePromise;
      const price = activeTab === "1" ? product.priceIdMonth : product.priceIdYear
      const { error } = await stripe!.redirectToCheckout({
        lineItems: [{ price: price, quantity: 1 }], // Replace with your price ID from Stripe
        mode: 'subscription', // or 'subscription' for recurring
        successUrl: 'http://localhost:3001/signup/success',
        cancelUrl: 'http://localhost:3001/signup/cancel',
        clientReferenceId: userid
      });
      if (error) {
        console.error('Stripe Checkout error:', error);
      }
    };
    return (
        <React.Fragment>
            <div className="page-content pt-4">
                <Container fluid>
                    <Row className="justify-content-center mt-4">
                        <Col lg={5}>
                            <div className="text-center mb-4">
                                <h4 className="fw-bold fs-22">Plans & Pricing</h4>
                                <p className="text-muted mb-4 fs-15">Simple pricing. No hidden fees. Advanced features for you business.</p>

                                <div className="d-inline-flex">
                                    <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '1' }, 'fw-semibold')}
                                                onClick={() => { toggleTab('1'); }}
                                            >Quaterly
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '2' }, 'fw-semibold')}
                                                onClick={() => { toggleTab('2'); }}
                                            >Annually <span className="badge bg-success">25% Off</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={9}>
                            <Row>
                                {(PRICING || []).map((price, key) => (
                                    <Col lg={4} key={key}>
                                        <Card className="pricing-box ribbon-box right">
                                            {price.ribbon === true ? <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div> : ""}
                                            <CardBody className="p-4 m-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-1 fw-bold">{price.type}</h5>
                                                        <p className="text-muted mb-0">{price.purpose}</p>
                                                    </div>
                                                    <div className="avatar-sm">
                                                        <div className="avatar-title bg-light rounded-circle text-primary">
                                                            <i className={"fs-20 " + price.icon}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-4">
                                                  <div className="ms-auto">
                                                      <TabContent activeTab={activeTab}>
                                                          <TabPane tabId="1">
                                                              <h2 className="month mb-0">&#163;{price.rate} <small className="fs-13 text-muted">/User/Month</small></h2>
                                                          </TabPane>
                                                          <TabPane tabId="2">
                                                              <h2 className="annual mb-0"><small className="fs-16"><del>&#163;{price.delrateYear}</del></small> &#163;{price.rateYear} <small className="fs-13 text-muted">/User/Month</small></h2>
                                                          </TabPane>
                                                      </TabContent>
                                                  </div>
                                                </div>
                                                <hr className="my-4 text-muted" />
                                                <div>
                                                    <ul className="list-unstyled text-muted vstack gap-3">
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    Upto <b>{price.projects}</b> Projects
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    Upto <b>{price.Customers}</b> Customers
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    Scalable Bandwidth
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <b>{price.FTP}</b> FTP Login
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className={`flex-shrink-0 text-${price.supportClass} me-1`}>
                                                                    <i className={`ri-${price.supportClassSymbol}-circle-fill fs-15 align-middle`}></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <b>24/7</b> Support
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className={`flex-shrink-0 text-${price.storageClass} me-1`}>
                                                                    <i className={`ri-${price.storageClassSymbol}-circle-fill fs-15 align-middle`}></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <b>Unlimited</b> Storage
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className={`flex-shrink-0 text-${price.domainClass} me-1`}>
                                                                    <i className={`ri-${price.domainClassSymbol}-circle-fill fs-15 align-middle`}></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    Domain
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div className="mt-4">
                                                      <Button onClick={() => handleCheckout(price)} className={`btn btn-${price.planButtonClassname} w-100 waves-effect waves-light`}>{price.btntxt}</Button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Pricing;