import React, { useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";

const NumbersEdit = () => {
  const [selectedSingle, setSelectedSingle] = useState<any>(null);
  const handleSelectSingle = (selectedSingle: any) => {
    setSelectedSingle(selectedSingle);
  };
  const NumberOptions = [
    { value: "Choices 1", label: "Formatted Number" },
    { value: "Choices 2", label: "Unformatted Number" },
    { value: "Choices 2", label: "Currency" },
    { value: "Choices 2", label: "Percentage" },
  ];
  return (
    <div>
      <Label className="hstack py-1 form-label">Object type *</Label>
      <Select
        value={selectedSingle}
        onChange={(selectedSingle: any) => {
          handleSelectSingle(selectedSingle);
        }}
        options={NumberOptions}
      />
    </div>
  );
};
export default NumbersEdit;
