import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ApplicationListing from './ApplicationsTabs';

const Applications = () => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <Row>
                        <ApplicationListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Applications;