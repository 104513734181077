import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Container, Dropdown, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledCollapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Dragula from 'react-dragula';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import taskImg from "../../assets/images/task.png";
import DeleteModal from '../../Components/Common/DeleteModal';
import { todoCollapse, todoTaskList } from 'common/data';
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
//redux
import { useSelector, useDispatch } from 'react-redux';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { taskAddRequest, tasksListGetRequest } from 'store/applications/action';
import { usersListGetRequest } from 'store/user/action';
import { components } from 'react-select';
import { getStudentsActivity } from 'store/student/action';

const EditTasks = ({ studentId, setTaskDetails, setIsEditOpen, isEditOpen, taskDetails, setIsEditTask }: any) => {
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const tasksList = useSelector((state: ApplicationState) => state.applications.tasks);
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const [filterObject, setFilterObject] = useState<any>({
        applicationId: id
    });
    const [template, setTemplate] = useState<any>(null);
    const [users, setUsers] = useState<any[]>([])
    const [taskData, setTaskData] = useState<any>(taskDetails);
    const [dueDate, setDueDate] = useState(taskData.dueDate);
    const [status, setStatus] = useState(taskDetails.status)
    const [priority, setPriority] = useState(taskDetails.priority)
    const toggleEdit = (() => setIsEditOpen(!isEditOpen) );

    const assignedToList = taskDetails && taskDetails.taskAssignments?.map((assignment: { assignedTo: { name: any; id: any; }; }) => {
        const { name, id } = assignment?.assignedTo;
        return { label: name, value: id };
      });

      const handleSelectChange = (selectedOptions: any) => {
        if (!Array.isArray(selectedOptions)) {
          selectedOptions = [selectedOptions];
        }
    
        const updatedTaskAssignments = selectedOptions.map((option: { label: any; value: any; }) => {
          return {
            assignedTo: {
              name: option.label,
              id: option.value
            }
          };
        });
    
        setTaskData({
          ...taskDetails,
          taskAssignments: updatedTaskAssignments
        });
      };
      
      // const handleSelectChange = (selectedOptions: any) => {
      //   const updatedTaskAssignments = selectedOptions.map((option: { label: any; value: any; }) => {
      //     return {
      //       assignedTo: {
      //         name: option.label,
      //         id: option.value
      //       }
      //     };
      //   });
    
      //   setTaskData({
      //     ...taskDetails,
      //     taskAssignments: updatedTaskAssignments
      //   });
      // };
    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);

    useEffect(() => {
        dispatch(usersListGetRequest())
    }, [])
    
    const handleEditTask = () => {
        const userIdString = users && users.map((userString: { value: any; }) => userString.value).join(',');
        const usersIds = JSON.stringify(userIdString);
        const data = {
            id: taskData.id,
            title: taskData.title,
            status: taskData.status,
            priority: taskData.priority,
            dueDate: taskData.dueDate,
            studentId: studentId,
            applicationId: id,
            assignedTo: usersIds?.slice(1, -1)
        }
        const handleSuccess = (): void => {
            toast(`Task edited successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            let studentsId = applicationDetails && applicationDetails?.valuesJson?.students && applicationDetails?.valuesJson?.students.id ? applicationDetails?.valuesJson?.students.id : "";
            dispatch(getStudentsActivity(studentsId,filterObject,0))
       
        setIsEditOpen(false);
            dispatch(tasksListGetRequest(studentId,filterObject));
        };

        const handleError = (): void => { };

        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }

    const sortbystatus = [
        {
            options: [
                { label: "New", value: "NEW" },
                { label: "Inprogress", value: "INPROGRESS" },
                { label: "Completed", value: "COMPLETED" },
            ],
        },
    ];

    const templateOptions = [
      {
          options: [
              { label: "Template 1", value: "TEMPLATE1" },
              { label: "Template 2", value: "TEMPLATE2" },
              { label: "Template 3", value: "TEMPLATE2" },
          ],
      },
  ];

    const sortbypriority = [
        {
            options: [
                { label: "Low", value: "LOW" },
                { label: "Medium", value: "MEDIUM" },
                { label: "High", value: "HIGH" },
            ],
        },
    ];

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };
    return (
        <React.Fragment>
            {
                taskDetails && taskDetails.id ?

                    <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
                        <ModalHeader toggle={()=>toggleEdit()} className="p-3 bg-primary-subtle"> {"Edit Task"} </ModalHeader>

                        <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
      <ModalHeader toggle={toggleEdit} className="p-3 bg-primary-subtle">
        {"Edit Task"}
      </ModalHeader>

      <ModalBody>
        <div id="task-error-msg" className="alert alert-danger py-2"></div>
        <Form
          id="creattask-form"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <input type="hidden" id="taskid-input" className="form-control" />
          <div className="mb-3">
            <label htmlFor="task-title-input" className="form-label">Task Title</label>
            <Input
              type="text"
              id="task-title-input"
              className="form-control"
              placeholder="Enter task title"
              name="task"
              onChange={(e) => setTaskData({ ...taskDetails, title: e.target.value })}
              value={taskDetails.title}
            />
          </div>
          <div className="mb-2 gap-2 d-flex position-relative">
            <div>
            <label htmlFor="task-assign-input" className="form-label">Assigned To:</label>
            </div>
            <div>
            <Label>{assignedToList[0]?.label}</Label>
            </div>
            
          
            {/* <div className="select-element">
              <Select
                value={assignedToList}
                isMulti={false}
                onChange={handleSelectChange}
                options={users}
                classNamePrefix="js-example-basic-multiple mb-0"
                components={{ Option: CustomOption }}
              />
            </div> */}
          </div>
          <Row className="g-4 mb-3">
            <Col lg={6}>
              <label htmlFor="task-status" className="form-label">Status</label>
              <Input
                name="status"
                type="select"
                className="form-select"
                id="status-field"
                onChange={(e) => setTaskData({ ...taskDetails, status: e.target.value })}
                value={status}
              >
                {sortbystatus.map((item, key) => (
                  <React.Fragment key={key}>
                    {item.options.map((optionItem, optionKey) => (
                      <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                    ))}
                  </React.Fragment>
                ))}
              </Input>
            </Col>
            <Col lg={6}>
              <label htmlFor="priority-field" className="form-label">Priority</label>
              <Input
                name="priority"
                type="select"
                className="form-select"
                id="priority-field"
                onChange={(e) => setTaskData({ ...taskDetails, priority: e.target.value })}
                value={priority}
              >
                {sortbypriority.map((sortItem, sortKey) => (
                  <React.Fragment key={sortKey}>
                    {sortItem.options.map((optionItem, optionKey) => (
                      <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                    ))}
                  </React.Fragment>
                ))}
              </Input>
            </Col>
          </Row>
          <div className="mb-4">
            <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
            <Flatpickr
              name="dueDate"
              id="date-field"
              className="form-control"
              placeholder="Due date"
              options={{
                enableTime: true,
                dateFormat: "Y-m-d H:i",
              }}
              onChange={dates => {
                const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                setDueDate(formattedDate); // Set the formatted date to state
                setTaskData({ ...taskDetails, dueDate: formattedDate }); // Update task details
              }}
              value={taskDetails.dueDate}
            />
          </div>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-ghost-primary" color='primary' onClick={() => {
              toggleEdit();
              setTaskData(taskDetails);
            }}>
              <i className="ri-close-fill align-bottom"></i> Close
            </Button>
            <Button type="submit" color='primary' className="btn btn-primary" onClick={handleEditTask} id="editTodo">
              {"Update Task"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
                    </Modal >
                    : null}

        </React.Fragment >
    );
};

export default EditTasks;