import { toast } from "react-toastify";


export const levelhandle = (levelvalue: any) => {
	let level = ""
	switch (levelvalue) {
		case 1: level = 'Undergraduate';
			break
		case 2: level = 'Postgraduate';
			break
		case 3: level = 'Foundation';
			break
		case 4: level = 'Research';
			break
		default: level = 'NA';
			break
	}
	return level;
}

const errorGlobal: any = {
	500: {
		"message": "Server error, please try again later",
		"success": false
	},
	502: {
		"message": "Server error, please try again later",
		"success": false
	},
	400: {
		"message": "Invalid request, please check your input",
		"success": false
	},
	401: {
		"message": "You need to log in to access this page",
		"success": false
	},
	403: {
		"message": "Access denied, you don't have permission",
		"success": false
	},
	408: {
		"message": "Request timed out, please try again",
		"success": false
	},
	503: {
		"message": "Server is currently unavailable",
		"success": false
	},
	504: {
		"message": "Server timed out, please try again",
		"success": false
	},
	201: {
		"message": "Created Successfully",
		"success": true
	},	
	200: {
		"message": "Request Successful",
		"success": true
	},		
	204: {
		"message": "Deleted Successfully",
		"success": true
	},		
	208: {
		"message": "You are trying to add duplicate Entry",
		"success": false
	}
}

export const responseHandler = async (response: any, dispatch: any, typeSuccess: any, typeError: any, error?: any, handleSuccess?: any, handleError?: any, method?: any) => {
	console.log("response", response)	
	if (response?.status) {
		const httpResponse: any=  error && error[response?.status]
		  ? error[response?.status]
		  : errorGlobal[response?.status]; 
		if (httpResponse?.success) {
			try {
				const body = await response.json();
				dispatch({
					type: typeSuccess,
					payload: body
				});
				handleSuccess && handleSuccess(body)
			} catch {
				dispatch({
					type: typeSuccess,
					payload: null
				});
				handleSuccess && handleSuccess()
			}
		} else {
			dispatch({
				type: typeError,
				payload: httpResponse?.message || 'An unexpected error occurred, please try again later'
			});
			handleError && handleError()
		}	
		let bgColor = 	httpResponse?.success ? "bg-success" : "bg-danger";
		if (!method || method !== "GET") {
			const message = () => toast(`${httpResponse?.message || 'An unexpected error occurred, please try again later'}`, { position: "top-center", hideProgressBar: true, className: `${bgColor} text-white` });
			message();
		}
	} else {
		dispatch({
			type: typeError,
			payload: 'An unexpected error occurred, please try again later'
		});
		handleError && handleError()
		if (!method || method !== "GET") {
			const message = () => toast(`An unexpected error occurred, please try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		}
	}		
}

export const isJson = (str: any) => {
	try {
		let options = JSON.parse(str);
		return options
	} catch (e) {
		//Error
		//JSON is not okay
		return false;
	}
}

export const evaluateConditionalLogic = (conditional: any, formValues: any) => {
	console.log("conditional", conditional)
	let isPresent=null;
	if (conditional?.show && conditional?.when && conditional?.condition && conditional?.values) {   
		const values = conditional?.values.map((item: any) => item.value);
		switch(conditional?.condition)   {
			case 'EQ': 
			case 'contains_exactly': 
			case 'IN': 
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when].includes(item));
				 if(isPresent) return conditional.show
				break
			case 'NEQ': 
			case 'is_none_of':
				isPresent = formValues?.[conditional?.when] ? values .length && values?.some((item: any) => formValues?.[conditional?.when]?.includes(item)): true;
				 if(!isPresent) return conditional.show
				 break
			case 'LIKE': 
			case 'contains':
					isPresent = formValues?.[conditional?.when] &&  values .length && values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when]?.some((formValue: any) => formValue?.includes(item)));
					if(isPresent) return conditional?.show
					break                  
			case 'does_not_contain':
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when].some((formValue: any) => formValue?.includes(item)));
				if(!isPresent) return conditional?.show
				break  
			case 'is_empty': 
				isPresent = !formValues?.[conditional?.when];
				if(isPresent) return conditional?.show
				break
			case 'is_not_empty': 
				isPresent = formValues?.[conditional?.when];
				if(isPresent) return conditional?.show
				break
		}  
	}
  };
