import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import BankInfoEdit from './BankInfoEdit';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { isJson } from 'utils';

const OverviewTab = ({ info, metaData, props }: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [filterFields, setFilterFields] = useState<any>([]);
  const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
  const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
  const [access, setAccess] = useState<any>([]);
  const [isEditAccess, setEditAccess] = useState<boolean>(false);

  useEffect(() => {
    let userRole = userProfile?.role?.title;
    if(userRole === "Owner") {
        setEditAccess(true)
    }
    else if(subLevelPermissionsList) {
        let companiesaccess = subLevelPermissionsList?.companies;            
        if(companiesaccess && companiesaccess?.length) {
            setAccess(companiesaccess)
            if(companiesaccess && companiesaccess.length) {            
                companiesaccess.map((item: any, index: any) => {
                    if(item.name.toLowerCase() == 'edit') {
                        item.value == "" || item.value == "none" ? setEditAccess(false)  : setEditAccess(true) 
                    }            
               })
           }
        }
    }
}, [subLevelPermissionsList, userProfile])

const handleViewAccess = (permissionJson : any) => {        
    let userRole = userProfile?.role?.title;
    let roleid = userProfile?.role?.id;        
    const permissions = permissionJson?.permissions;
    if(userRole === "Owner") return true 
    else if(permissions) {
        switch(permissions) {
            case "Owner": 
            case "owner": if(userRole === "Owner") return true;
                            else return false;
            case "view": return true;
            case "view_edit": return true;
            case "role_based":  const items = permissionJson.item || [];
                                let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                return isview && isview.length;
            default: return false;
        }

    }
}
useEffect(() =>{
    let filterFields: any = []
    metaData && metaData.length && metaData.map((field: any) => {
        let item = field.valuesJson   && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
        let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {}            
        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
        if (field.valuesJson && isFieldviewaccess) return filterFields.push(item)
    })
    setFilterFields(filterFields)
}, [metaData])
    
    const DetailsList = ({ label, value, item, index }: any) => {
        return (
            <React.Fragment>
                <Col md={3} lg={3} sm={6} Key={index}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-muted mb-1 fs-14">{label} :</p>
                                <h5 className="mb-0 fs-14">{value}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardHeader className='hstack align-items-center'>  
                        <Col  className='hstack justify-content-start p-0 ps-3 align-items-end'>
                            <h4>Bank Account Details</h4>
                        </Col> 
                        <Col  className='hstack justify-content-end'>
                            {
                                    isEdit ? <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="danger"
                                    onClick={() => setIsEdit(false)}
                                    outline
                                >
                                    <i className="ri-close-fill"></i>{" "}
                                    Close
                                </Button> :  
                                isEditAccess ?                       
                                <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="primary"
                                    onClick={() => setIsEdit(true)}
                                >
                                    <i className="ri-pencil-fill"></i>{" "}
                                    {props.t("company.update")}
                                </Button>:
                                 <Button
                                 color="primary"
                                 disabled
                                 className="btn-label">
                                     <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                     Update
                             </Button>
                            }
                        </Col>                        
                        </CardHeader>
                        <CardBody>
                            <div className="text-muted">
                                <div className="border-top border-top-dashed p-3">
                                  {
                                    isEdit ? <BankInfoEdit /> :
                                    <Row className="gy-3">
                                        {filterFields && filterFields.length ? filterFields.map((item: any, key: any) => {
                                            return <DetailsList
                                                label={item.label}
                                                value={info[item.key]}
                                                item={item}
                                                key={key}
                                            />
                                        }): null}
                                    </Row>
                                  }
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;