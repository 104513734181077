import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import DeleteModal from './DeleteModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { recommendApplicationList } from 'store/recommendation/action';
import { applicationDelete, applicationGetRequest, applicationsMetadataGetRequest } from 'store/applications/action';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';
import { buildUrl } from 'build-url-ts';
import { applicationCreateRequest } from 'store/applications/action';
import AddApplication from './innerComponent/AddApplication';
import axios from 'axios';
import config from "../../../../config"



const { DTS_API_STUDENT } = config.api;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Applications = (props: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    // const applicationList = useSelector((state: ApplicationState) => state.student.applicationlist);
    const applicationList = useSelector((state: ApplicationState) => state.recommend.list);
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const paginationDetails = useSelector((state: ApplicationState) => state.recommend.pagination); 
    const loading = useSelector((state: ApplicationState) => state.recommend.loading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [appliedCourses, setAppliedCourses] = useState([]);
    const [create, setCreate] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(true);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [open, setOpen] = useState('1');
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [access, setAccess] = useState<any>([]);
    const [metaData, setMetaData] = useState(applicationsMetadata);
    const [studentId, setSudentId] = useState<any>("");
    const [applications, setApplication] = useState([]);
    const [applicationsJson, setapplicationsJson] = useState<any>([]);
    const [pagesList, setPagesList] = useState<any>([]);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [activeTab, setActiveTab] = useState<any>("1");
    const [search, setsearch] = useState<string>("");
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(recommendApplicationList(id, filters, 0));
        setapplicationsJson([])
    }

    
    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    }; 

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
        dispatch(recommendApplicationList(id, filterObject, 0));
        setMetaData(applicationsMetadata)
        setSudentId(id)
    }, [])

    useEffect(() => {
        setMetaData(applicationsMetadata);
    }, [applicationsMetadata]);


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(applicationGetRequest(lead.id))
    };
  


    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(recommendApplicationList(id, filterObject, newPageNo));
        setapplicationsJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(recommendApplicationList(id, filterObject))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(applicationDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }
    

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
        if (permissionJson.permissions === "view") {
            return true
        }
        if (permissionJson.permissions === "view_edit") {
            return true
        }
        else if (permissions === 'role_based') {
            const items = permissionJson.item || [];
            let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
            let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
            return isview && isview.length
        }
        else return false
    }
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

    
    const Apply = (courseId: any) => {
        let data = {
            course_name: courseId,
            student: id
        };
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Created Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            fetchAppliedCourses()
            setCreateOpen(false)
        }
        const handleError = () => {

        }
        dispatch(applicationCreateRequest(id, data, handleSuccess, handleError))
    }
    
    const fetchAppliedCourses = () => {
        let filter= {
            pageSize: 100
        }
        const config = {           
            headers: {
                'Content-Type':'application/json',
                "token": access_token
            },
        }
        axios.get(`${DTS_API_STUDENT}/applications/student/${id}?`, config)
        .then((res: any) => {
            setAppliedCourses(res?.content)
        })
    }
    
    useEffect(() => {
        fetchAppliedCourses()
    }, [])

    const isCourseApplied = (courseId: any, appliedCourses: any) => {
        if(appliedCourses && appliedCourses.length) {
           return appliedCourses.some((course: any) => {
               let valuesJson = course?.valuesJson ? course?.valuesJson : {}
               return valuesJson.course_name === courseId;
           });
       }
   };

   const capitalizeFirstLetterOfEachWord = (input: any) => {
    const exceptions = ['and', 'or', 'the', 'a', 'an', 'but', 'for', 'nor', 'on', 'at', 'to', 'by', 'with']; // Add any other exceptions you need
    return input.split(' ').map((word: any, index: any) => {
        if (exceptions.includes(word.toLowerCase()) && index !== 0) {
            return word.toLowerCase();
        } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
    }).join(' ');
}

useEffect(() => {
    let userRole = userProfile?.role?.title;
    if(userRole === "Owner") {
        setViewAccess(true)
        setCreate(true)
    }
    else if(subLevelPermissionsList) {
        let access = subLevelPermissionsList?.applications;            
        if(access && access?.length) {
            setAccess(access)
            setViewAccess(true)
            if(access && access.length) {            
                access.map((item: any, index: any) => {
                    
                   if(item.name.toLowerCase() == 'create') {
                    item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                   }
       
               })
           }
        }
        else {
            setViewAccess(false)
        }
    }
}, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                let permissionsJson = fieldName.permissionsJson && isJson(fieldName.permissionsJson) ? JSON.parse(fieldName.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true; 
                if (fields.tablecolumn && isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: true,
                        Cell: (cellProps: any) => {
                            let result = isCourseApplied(cellProps.row.original.id, appliedCourses);
                            let rendervalue = cellProps.row.original[fields.key]; 
                            if(fields.key == 'application_status'  && cellProps.row.original[fields.key]) {
                                rendervalue = capitalizeFirstLetterOfEachWord(cellProps.row.original[fields.key])
                            }
                            else if(fields.type =='select' || fields.type=='radio' || fields.type=='checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], cellProps.row.original[fields.values]);
                            }   
                            else if (fields.key == 'course_link' && cellProps.row.original[fields.model] && cellProps.row.original[fields.model][fields.key] !== "NA") {
                                return <div className='ms-3'>
                                    <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.model][fields.key])} />
                                </div>
                            }
                            else if (fields.optionLabel && fields.optionLabel) {
                                 rendervalue = cellProps?.row?.original[fields.model] && cellProps?.row?.original[fields.model][fields.optionLabel] ? cellProps?.row?.original[fields.model][fields.optionLabel] : 'NA'
                            }  
                            return  <div className="d-flex align-items-center justify-content-between">  
                                    {
                                        fields.key == "application_name" || fields.key == "course_name" ? <Row style={{width: 300}} className='justfy-content-start align-items-center'>                                           
                                            <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >                                            
                                                    {cellProps.row.original[fields.key]}
                                                </Link>  
                                                {
                                                    create ?                                             
                                                    <Button
                                                        size='sm'
                                                        color="success"
                                                        className='justfy-self-end hoverbutton'
                                                        disabled={result ? true :  false}
                                                        onClick={() => {handleLeadClick(cellProps.row.original); setCreateOpen(true) }}>
                                                        
                                                        { result ? props.t("student.created") : props.t("student.create")}
                                                    </Button> : null}
                                            </Col>                                           
                                        </Row> : 
                                        <div>
                                            {rendervalue}
                                        </div>
                                    }
                                </div>
                            }
                    }
                    columns.push(newColumn)
                }   
            })
            const nameColumnIndex = columns.findIndex(column => (column.accessor === "course_name" || column.accessor === "application_name"));
           if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between" style={{width:200}}>
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        // setTempColumns(columns)
    }, [metaData]);

    useEffect(() => {
        setMetaData(applicationsMetadata)
    }, [applicationsMetadata != undefined])

    const execute = () => {
        let a = isEqual(applicationList, applicationsJson)
        return !a
    }

    useEffect(() => {
        const parsedData: any = applicationList && applicationList.length ? applicationList.map((item: any) => {
            const valuesJson = item?.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let applications = parsedData && parsedData.length && parsedData.reverse() 
        setApplication(applications)
        setapplicationsJson(applicationList)
    }, [applicationList])
    
    useEffect(() => {
        let filters={...filterObject, pageSize: pageSize }
        dispatch(recommendApplicationList(id, filters))
    }, [])
    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(recommendApplicationList(id, filters));
            setapplicationsJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          dispatch(recommendApplicationList(id, filters));
          setapplicationsJson([])
        }
    };
    
    document.title = "DTS | Zilter";
    return ( 
        <React.Fragment>
            {/* <ToastContainer />   */}
            <AddApplication
                show={iscreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={applicationsMetadata}
                props={props}
                record={record}
                studentId={id}
                fetchAppliedCourses={fetchAppliedCourses}
            /> 
            <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />
            <Row>
                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                    <div className="search-box">
                        <Input
                            type="text"
                            size={14}
                            className="search"
                            placeholder={props.t("applications.search_for")}
                            onChange={(e) => handleChange(e.target.value)}                            
                            onKeyDown={handleKeyDown}
                            value={search}
                        />
                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
            
                    </div>
                </Col>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={applications || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    props={props}
                                    loading={loading}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    
    );
};
export default withTranslation()(Applications);