import React, { useEffect, useState } from "react";
import { Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { WithTranslation } from 'react-i18next';
import { resendOtp } from "store/user/action";
import config from "../../../config";
import { useGoogleLogin } from "@react-oauth/google";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { getGoogleCalendarData, postGoogleCalendarSync } from "store/calendarSync/action";
import MicrosoftLogin from "react-microsoft-login";
import * as msal from '@azure/msal-browser';
const { api } = config;
const DTS_API_DOMAIN = api.DTS_API_DOMAIN
const { link: { PRIVACY_POLICY } } = config;
interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}
interface Step1Props {
    toggleArrowTab: (e: any) => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    resendOTP: () => void;
    t: (key: string) => string;
    loading: boolean;
    isEditEmail: boolean;
    signUpGoogle: () => void;
    signUpMicrosoft: () => void;
}
const Step1: React.FC<Step1Props> = ({ t, toggleArrowTab, inputs, setInputs, onCreate, loading, resendOTP, isEditEmail, signUpGoogle, signUpMicrosoft }: Step1Props) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(true)
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, email: e.target.value });
    };
    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/1b9e5340-f9f7-4130-8fe3-a87963878cf0",
            redirectUri: api.url
        }
    };
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    useEffect(() => {
        setDisabled(inputs.email !== "" && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email))
    }, [inputs.email])
    
    return (
        <div>

            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex flex-column justify-content-center">
                    {/* <div className="hstack fs-32 fw-bold justify-content-center">
                        <h1 className="fw-bold">{t("signup.create_your_free_account")}</h1>
                    </div> */}
                    {/* <div className="hstack fs-17 fw-medium mb-2 justify-content-center">
                        {t("signup.no_credit_card_needed")}
                    </div> */}
                </Col>
                <Col lg={3}>

                    <button
                        onClick={signUpGoogle}
                        type="button"
                        className="w-100 fw-bold btn btn-primary bg-gradient mb-2 btn-label right fw-semibold"
                    >
                        <i className="ri-arrow-right-line ri-google-fill fs-16 label-icon align-middle ms-2"></i>
                        {t("signup.continue_with_google")}
                    </button>
                    <div className="hstack fs-17 fw-normal justify-content-center">
                        {t("signup.or")}
                    </div>
                    <button
                        onClick={() => signUpMicrosoft()}
                        type="button"
                        className="w-100 fw-bold btn btn-primary bg-gradient mb-2 btn-label right fw-semibold"
                    >
                        <i className="ri-arrow-right-line ri-windows-line fs-16 label-icon align-middle ms-2"></i>
                        {t("signup.signup_with_microsoft")}
                    </button>
                    {/* <MicrosoftLogin
        clientId={'9dce3457-ea35-4f69-9c96-aecbda169c12'}
        authCallback={signUpMicrosoft}
        redirectUri={'http://localhost:3000'}
        debug={true}
        graphScopes={['User.Read', 'Calendars.ReadWrite', 'Mail.ReadWrite', 'email' ]}
        children={undefined}
      /> */}
                </Col>
                <Col lg={3}>
                    <div className="hstack justify-content-center">
                        <Label className="form-label w-100" htmlFor="steparrow-gen-info-email-input">
                            {t("signup.email")}
                        </Label>
                    </div>
                    <div className="vstack gap-1 justify-content-center">
                        <Input
                            type="text"
                            className="form-control w-100"
                            id="steparrow-gen-info-email-input"
                            placeholder={t("signup.enter_email")}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => { setInputs({ ...inputs, email: e.target.value }); }}
                        />
                        {inputs.email !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email) ? (
                            <div className="text-danger fs-14 fw-medium">
                                {t("signup.please_enter_valid_email_address")}
                            </div>
                        ) : null}
                    </div>
                </Col>
                <Col lg={3}>
                    <button
                        type="button"
                        className="w-100 btn btn-success btn-icon waves-effect waves-light"
                        onClick={() => { isEditEmail ? resendOTP() : onCreate() }}
                        // disabled={!inputs.email}
                        disabled={!disabled}
                    >
                        {loading ? <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span> : t("signup.verify_email")}
                    </button>{" "}
                </Col>
                <Col lg={3}>
                    {t("signup.disclaimer")}
                    <Link to={`${PRIVACY_POLICY}`} className="link-success fw-bold" target="_blank" rel="noopener noreferrer">
                        &nbsp;{t("signup.privacy_policy")}&nbsp;
                        <FeatherIcon icon="external-link" className="icon-xs icon-dual-success" />
                    </Link>
                </Col>
            </Row>
        </div >
    );
};
export default Step1;