import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { ApplicationsListGetRequest } from 'store/student/action';
import { applicationDelete, applicationGetRequest, applicationsMetadataGetRequest } from 'store/applications/action';
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from './DeleteModal';
import NotesModal from './NotesModal';
import AddEntryModal from './AddEntryModal';
import Courses from './Courses/Courses';
import classNames from 'classnames';
import { buildUrl } from 'build-url-ts';
import AccessDenied from 'pages/AccessDenied/Denied';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Applications = (props: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const applicationList = useSelector((state: ApplicationState) => state.student.applicationlist);
    const paginationDetails = useSelector((state: ApplicationState) => state.student.Applicationpagination); 
    const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const loading = useSelector((state: ApplicationState) => state.applications.metaLoading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(true);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [open, setOpen] = useState('1');
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [metaData, setMetaData] = useState(applicationsMetadata);
    const [studentId, setSudentId] = useState<any>("");
    const [applications, setApplication] = useState([]);
    const [applicationsJson, setapplicationsJson] = useState<any>([]);
    const [pagesList, setPagesList] = useState<any>([]);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [activeTab, setActiveTab] = useState<any>("1");
    const [viewAccess, setViewAccess] = useState<boolean>(true);
    const [access, setAccess] = useState<any>([]);
    const [search, setsearch] = useState<string>("");
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(ApplicationsListGetRequest(id, filters, 0));
        setapplicationsJson([])
    }

    
    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    }; 

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
        dispatch(ApplicationsListGetRequest(id, filterObject, 0));
        setMetaData(applicationsMetadata)
        setSudentId(id)
    }, [])

    useEffect(() => {
        setMetaData(applicationsMetadata);
    }, [applicationsMetadata]);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.applications;
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(applicationGetRequest(lead.id))
    };
  


    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(ApplicationsListGetRequest(id, filterObject, newPageNo));
        setapplicationsJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(ApplicationsListGetRequest(id))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(applicationDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
        if (permissionJson.permissions === "view") {
            return true
        }
        if (permissionJson.permissions === "view_edit") {
            return true
        }
        else if (permissions === 'role_based') {
            const items = permissionJson.item || [];
            let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
            let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
            return isview && isview.length
        }
        else return false
    }
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }
    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                let permissionsJson = fieldName.permissionsJson && isJson(fieldName.permissionsJson) ? JSON.parse(fieldName.permissionsJson) : {}
                // let isFieldviewaccess = handleAccess(permissionsJson);     
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;      
                if (fields.tablecolumn && isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: true,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            else if (fields.key == 'course_link' && cellProps.row.original[fields.model] && cellProps.row.original[fields.model][fields.key] !== "NA") {
                                return <div className='ms-3'>
                                    <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.model][fields.key])} />
                                </div>
                            }
                            else if (fields.optionLabel) {
                                 rendervalue = cellProps?.row?.original[fields.model] && cellProps?.row?.original[fields.model][fields.optionLabel] ? cellProps?.row?.original[fields.model][fields.optionLabel] : 'NA'
                            }  
                            return  <div className="d-flex align-items-center justify-content-between">  
                                            {
                                                fields.key == "course_name" ? <Row style={{width: 250}} className='justfy-content-start align-items-center'>                                           
                                                    <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                        <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >                                            
                                                            {cellProps.row.original[fields.key]}
                                                        </Link>
                                                        {/* <Button
                                                            size='sm'
                                                            color="dark"
                                                            className='justfy-self-end hoverbutton'
                                                            outline={true}
                                                            onClick={() => { setpreview(true); handleLeadClick(cellProps.row.original) }}>
                                                            {props.t("student.preview")}
                                                        </Button> */}
                                                    </Col>                                           
                                                </Row> : 
                                                <div>
                                                    {rendervalue}
                                                </div>
                                            }
                                    </div>
                            }
                    }
                    columns.push(newColumn)
                }   
            });
            const nameColumnIndex = columns.findIndex(column => column.accessor === "course_name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
    }, [metaData]);

    useEffect(() => {
        setMetaData(applicationsMetadata)
    }, [applicationsMetadata != undefined])

    const execute = () => {
        let a = isEqual(applicationList, applicationsJson)
        return !a
    }

    useEffect(() => {
        const parsedData: any = applicationList && applicationList.length ? applicationList.map((item: any) => {
            const valuesJson = item?.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let applications = parsedData && parsedData.length && parsedData.reverse() 
        setApplication(applications)
        setapplicationsJson(applicationList)
    }, [execute()])
    
    useEffect(() => {
        dispatch(ApplicationsListGetRequest(id))
    }, [])

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(ApplicationsListGetRequest(id, filters));
            setapplicationsJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          dispatch(ApplicationsListGetRequest(id, filters));
            setapplicationsJson([])
        }
      };

    const handleButtonClick = (url: string) => {
        const tenant_id = userProfile?.tenant?.id;
        navigator.clipboard.writeText(url).then(() => {
            toast('URL copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
            window.open(url + tenant_id, '_blank');
        }).catch(err => {
           
        });
    };

    document.title = "DTS | Zilter";
    return ( 
        <>
        {
            viewAccess ?
        <React.Fragment>
            <ToastContainer />    
            <NotesModal
                props={props}
                notesModals={notesModals}
                setNotesModals={setNotesModals}
                onAddNote={setOnAddNote}
                record={record}
            />         
            <AddEntryModal
                show={iscreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={metaData}
                props={props}
                studentId={studentId}
            />
            
            <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />
            <Row>
                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                    <div className="search-box m-2">
                        <Input
                            type="text"
                            size={14}
                            className="search"
                            placeholder={props.t("applications.search_for")}
                            onChange={(e) => handleChange(e.target.value)}
                            onKeyDown={handleKeyDown}
                            value={search}
                        />
                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
            
                    </div>
                </Col>
                <Col md={9} sm={9} xl={9} xxl={9} lg={9} className='gap-3 hstack justify-content-end'>
                    {/* <Button  size='sm' type="button" className="py-2" color="primary" onClick={() => handleButtonClick(`http://localhost:3000/students/forms/`)}>
                    <FeatherIcon icon="copy" className="icon-xs" />{" "}
                        Copy Link
                    </Button> */}
                    {/* <Button
                        type="button"
                        size='sm'
                        className="py-2"
                        color="primary"
                        onClick={() => setCreateOpen(true)}
                    >
                        <FeatherIcon icon="plus-circle" className="icon-xs" />{" "}

                        {props.t("applications.create_entry")}
                    </Button> */}
                </Col>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={applications || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    props={props}
                                    loading={loading}
                                    access={access}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment> : <AccessDenied />}
        </>
    
    );
};
export default withTranslation()(Applications);