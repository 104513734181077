export interface Role {
    list: any;
    name: string;
    last_name: string;
    image_url: string;
    phone_number: string;
    email: string;
    website: string;
    company_name:string;
    no_of_employees: string;
}
export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    first: boolean,
    last: boolean
  }

export enum RoleActionTypes {
    GET_ROLES_REQUEST = "@@role/GET_ROLES_REQUEST",
    GET_ROLES_SUCCESS = "@@role/GET_ROLES_SUCCESS",
    GET_ROLES_ERROR = "@@role/GET_ROLES_ERROR",
    GET_ROLE_REQUEST = "@@role/GET_ROLE_REQUEST",
    GET_ROLE_SUCCESS = "@@role/GET_ROLE_SUCCESS",
    GET_ROLE_ERROR = "@@role/GET_ROLE_ERROR",
    PUT_ROLE_REQUEST = "@@role/PUT_ROLE_REQUEST",
    PUT_ROLE_SUCCESS = "@@role/PUT_ROLE_SUCCESS",
    PUT_ROLE_ERROR = "@@role/PUT_ROLE_ERROR",
    POST_ROLE_REQUEST = "@@role/POST_ROLE_REQUEST",
    POST_ROLE_SUCCESS = "@@role/POST_ROLE_SUCCESS",
    POST_ROLE_ERROR = "@@role/POST_ROLE_ERROR",
    DELETE_ROLE_REQUEST = "@@role/DELETE_ROLE_REQUEST",
    DELETE_ROLE_SUCCESS = "@@role/DELETE_ROLE_SUCCESS",
    DELETE_ROLE_ERROR = "@@role/DELETE_ROLE_ERROR",
    GET_PERMISSIONS_REQUEST = "@@role/GET_PERMISSIONS_REQUEST",
    GET_PERMISSIONS_SUCCESS = "@@role/GET_PERMISSIONS_SUCCESS",
    GET_PERMISSIONS_ERROR = "@@role/GET_PERMISSIONS_ERROR",
    POST_PERMISSION_REQUEST = "@@role/POST_PERMISSION_REQUEST",
    POST_PERMISSION_SUCCESS = "@@role/POST_PERMISSION_SUCCESS",
    POST_PERMISSION_ERROR = "@@role/POST_PERMISSION_ERROR",
}

export interface RoleState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly response?: any;
    readonly token?: string;
    readonly permissionsdata?: any;
    readonly list?: any;
    readonly roleDetails?: any;
    readonly pagination: Pagination | null;
}
