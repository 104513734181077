import {CalendarActionTypes, Calendar} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import * as msal from '@azure/msal-browser';
import { jwtDecode } from "jwt-decode";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const postGoogleCalendarSync: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const signUpMicrosoftRequest = () => {
    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/1b9e5340-f9f7-4130-8fe3-a87963878cf0",
            redirectUri: "http://localhost:3000"
        }
    };
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        dispatch({
            type: CalendarActionTypes.SIGNUP_MICROSOFT_REQUEST
        });
        try {
            const loginRequest = {
                scopes: [
                    "User.Read",
                    "Calendars.ReadWrite",
                    "Mail.ReadWrite"
                ]
            };
            
            const response = await msalInstance.loginPopup(loginRequest);
            
            if (response.accessToken) {
                const accessToken = jwtDecode(response.accessToken);
                
                const backendResponse = await fetch(`${DTS_API_DOMAIN}/accounts`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        code: accessToken,
                        scope: "email profile openid https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Read",
                        provider: 'microsoft',
                        isSignup: 'true'
                    })
                });

                const body = await backendResponse.json();
                const {response: response_1, body: body_1} = {response, body};
                if (!backendResponse.ok) {
                    dispatch({
                        type: CalendarActionTypes.SIGNUP_MICROSOFT_ERROR,
                        payload: body_1
                    });
                } else {
                    const userData: any = jwtDecode(body.token);
                    dispatch({
                        type: CalendarActionTypes.SIGNUP_MICROSOFT_SUCCESS,
                        payload: userData
                    });
                    console.log("User data:", userData);
                }
                // Example of setting user data in local state or context
                // setUserData(userData); // Assuming setUserData is available in this scope
                // setUserId(userData.user_id); // Assuming setUserId is available in this scope

             

                // Example function call for state update
                // toggleArrowTab(5); // Assuming toggleArrowTab is available in this scope
            }
        } catch (error) {
            dispatch({
                type: CalendarActionTypes.SIGNUP_MICROSOFT_ERROR,
                payload: error
            });
            console.error("Login Error: ", error);
        }
    };
};

// export const microsoftSignup: AppThunk = (data, handleSuccess, handleError) => {
//     return async (dispatch: Dispatch, getState: any): Promise<void> => {
//         const { auth: { token } } = getState();
//         dispatch({
//             type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_REQUEST
//         });
//         const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 'token': token
//             },
//             body: JSON.stringify(data)
//         });
//         const body = await response.json();
//         const {response: response_1, body: body_1} = {response, body};
//         if (!response_1.ok) {
//             dispatch({
//                 type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_ERROR,
//                 payload: body_1
//             });
//             handleError(body_1);
//         } else {
//             dispatch({
//                 type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS,
//                 payload: body_1
//             });
//             handleSuccess(body_1);
//         }
//     };
// };

export const createGoogleCalendarEvent: AppThunk = (email, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/calendar/events/${email}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const getGoogleCalendarData: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            // handleSuccess(body_1);
        }
    };
};
export const getGoogleCalendarEvents: AppThunk = (emailId, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/calendar/events/${emailId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            // body: JSON.stringify(emailId)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_SUCCESS,
                payload: body_1
            });
            // handleSuccess(body_1);
        }
    };
};

export const deleteGoogleEvent: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (email, eventId, handleSuccess) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/calendar/events/${email}/${eventId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            // dispatch({
            //     type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_ERROR
            // });
            // handleError();
        } else {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};

export const disconnectGoogleAccount: ActionCreator<
ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: data
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

