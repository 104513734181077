import {RoleActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const roleGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: RoleActionTypes.GET_ROLE_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/roles/${id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.GET_ROLE_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: RoleActionTypes.GET_ROLE_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const permissionsGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: RoleActionTypes.GET_PERMISSIONS_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/categories/permissions`);
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.GET_PERMISSIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: RoleActionTypes.GET_PERMISSIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/roles?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const roleListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: RoleActionTypes.GET_ROLES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = createQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.GET_ROLES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: RoleActionTypes.GET_ROLES_SUCCESS,
                payload: body_1
            });
        }
    };
};
export const roleNameListRequest: AppThunk = (queryParams, page, pageSize, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: RoleActionTypes.GET_ROLES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/roles?${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.GET_ROLES_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: RoleActionTypes.GET_ROLES_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Users fetched successfully");
        }
    };
};
export const roleUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: RoleActionTypes.PUT_ROLE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/roles/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.PUT_ROLE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: RoleActionTypes.PUT_ROLE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const roleCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: RoleActionTypes.POST_ROLE_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/roles`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.POST_ROLE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: RoleActionTypes.POST_ROLE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const permissionCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data,id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: RoleActionTypes.POST_PERMISSION_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/roles/${id}/permissions`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.POST_PERMISSION_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: RoleActionTypes.POST_PERMISSION_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const roleDeleteRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: RoleActionTypes.DELETE_ROLE_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/roles/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RoleActionTypes.DELETE_ROLE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: RoleActionTypes.DELETE_ROLE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};


