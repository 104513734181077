import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "reactstrap";
import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import moment from "moment";
// const socket = io("http://18.134.240.58:5001");
// const socket = io("http://localhost:5001");
const socket = io("https://api.dts.whatsapp.zilter.io");

const MessageAck: any = {
    "-1": {
        icon: "ri-close-fill",
        color: "text-danger"
    },
    "0": {
        icon: "ri-time-line",
        color: "text-warning"
    },
    "1": {
        icon: "ri-check-line",
        color: "text-default"
    },
    "2": {
        icon: "ri-check-double-line",
        color: "text-default"
    },
    "3": {
        icon: "ri-check-double-line",
        color: "text-info"
    },
    "4": {
        icon: "ri-play-line",
        color: "text-info"
    }
}

interface ChatbotProps {
    onClose: (event: boolean) => void
}
const Chatbot = ({onClose}: ChatbotProps) => {
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const studentDetails = useSelector((state: ApplicationState) => state.student.student);
    const messagesEndRef: any = useRef(null)
    const [qr, setQr] = useState('');
    const [student, setStudent]: any = useState(null);
    const [message, setMessage]: any = useState('');
    const [messages, setMessages]: any = useState([]);
    const [contactId, setContactId]: any = useState(null);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (user?.id && student?.mobile) {
            socket.on('qr', (data) => {
                const { qr } = data;
                if (qr) {
                    setQr(qr)
                }
            });
            socket.on('ready', (data) => {
                const { client } = data;
                const number = user?.phoneNumber?.replace(/\D/g,"");
                const studentNumber = student.mobile?.replace(/\D/g,"");
                if(number === client?.me?.user) {
                    setQr('')
                    setReady(true)
                    socket.emit("get_chats", {
                        id: user.id,
                        number: studentNumber
                    })
                } else {
                    setQr('')
                    setError(true)
                    socket.emit("destroy_client", {
                        id: user.id
                    })
                }
            });
            socket.on('message_create', (data) => {
                const { message } = data;
                updateMessages(message)
            });
            socket.on('message_ack', (data) => {
                const { message } = data;
                messageACK(message)
            });
            socket.emit("create_session", {
                id: user.id
            })
            socket.on('all_chats', (data) => {
                const { messages, contactId } = data;
                setMessages(messages)
                setContactId(contactId)
            });
            return () => {
              socket.off('qr');
              socket.off('ready');
              socket.off('message_create');
              socket.off('create_session');
              socket.off('all_chats');
            };
        }
    }, [user?.id, student?.mobile]);
    useEffect(() => {
        if (studentDetails && studentDetails.valuesJson) {
            setStudent(studentDetails.valuesJson)
        }
    }, [studentDetails])
    useEffect(() => {
      scrollToBottom()
    }, [messages]);
    const scrollToBottom = () => {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
    }
    const updateMessages = (message: any) => {
        const number = student.mobile?.replace(/\D/g,"");
        const contactId = number.includes('@c.us') ? number : `${number}@c.us`;
        if((('from' in message && message.from === contactId) || ('to' in message && message.to === contactId) ) && !message.isStatus) {
            setMessages((messages: any) => [...messages, message]);
        }
    }
    const messageACK = (message: any) => {
        const number = student.mobile?.replace(/\D/g,"");
        const contactId = number.includes('@c.us') ? number : `${number}@c.us`;
        if((('from' in message && message.from === contactId) || ('to' in message && message.to === contactId) ) && !message.isStatus) {
            const number = student.mobile?.replace(/\D/g,"");
            socket.emit("get_chats", {
                id: user.id,
                number: number
            })
        }
    }
    const onChange = (message: string) => {
        setMessage(message)
    }
    const onKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onSend()
        }
    }
    const onSend = () => {
        socket.emit("send_message", {
            id: user.id,
            message,
            contactId
        })
        setMessage('')
    }
    const onRegenerate = () => {
        setError(false)
        socket.emit("create_session", {
            id: user.id
        })
    }
    return (
        <div className="dts-chatbot">
            <div className="row container d-flex justify-content-center">
                <div className="col-md-12">
                    <div className="box border-top-1 border-primary direct-chat direct-chat-warning position-relative border-radius-1 shadow-lg">
                        <div className="box-header with-border d-flex align-items-center justify-content-between">
                            <h3 className="box-title">Whatsapp Messages</h3>
                            <div className="">
                                <Button color="light" className="btn-icon btn-ghost-dark" onClick={() =>onClose(false)}> <i className="ri-close-fill" /> </Button>
                            </div>
                        </div>
                        <div className="box-body">
                            {error ? <div className="chatboat-error">
                                <i className="ri-error-warning-fill fs-36 text-danger"></i>
                                <h3>Number mismatch</h3>
                                <p>Scanned number is not linked with your account. Only number added to your account are allowed to connect.</p>                                   
                                <Button
                                    onClick={() => onRegenerate()}
                                    color="primary"
                                    // size='sm'
                                    className="btn-label">
                                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                                        Regenerate
                                </Button>
                            </div> : ready ? <div className="direct-chat-messages">
                                {
                                    messages.map((message: any, messageIndex: number) => {
                                        const ack = MessageAck[message.ack];
                                        const ackIcon = <i className={`${ack.icon} ${ack.color} fs-16`} />
                                        if (message.fromMe) {
                                            if(message.type === 'chat') {
                                                return (
                                                    <div key={messageIndex} className="direct-chat-msg right">
                                                        <div className="direct-chat-info clearfix">
                                                            <span className="direct-chat-name pull-right">{user.name}</span>
                                                            <span className="direct-chat-timestamp pull-left">{moment.unix(message.timestamp).format("MMM Do, h:mm a")} {ackIcon}</span>
                                                        </div>
                                                        <img className="direct-chat-img" src="https://img.icons8.com/office/36/000000/person-female.png" alt="message user" />
                                                        <div className="direct-chat-text">{message.body}</div>
                                                    </div>
                                                )
                                            }
                                        } else {
                                            if(message.type === 'chat') {
                                                return (
                                                        <div key={messageIndex} className="direct-chat-msg">
                                                            <div className="direct-chat-info clearfix">
                                                                <span className="direct-chat-name pull-left">{student.name}</span>
                                                                <span className="direct-chat-timestamp pull-right">{moment.unix(message.timestamp).format("MMM Do, h:mm a")}</span>
                                                            </div>
                                                            <img className="direct-chat-img" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="message user" />
                                                            <div className="direct-chat-text">{message.body}</div>
                                                        </div>
                                                )
                                            }
                                        }
                                        return null;
                                    })
                                }
                                <div ref={messagesEndRef} ></div>
                            </div> : qr ? <div className="d-flex flex-column align-items-center p-3 justify-content-center">
                                <h3 className="text-center">Use your phone whatsapp to scan the QR code</h3>
                                <QRCode fgColor='#3b4a54' value={qr} />
                            </div> : <div className="chatboat-loader"><i className="ri-whatsapp-line display-1 shimmer"></i></div>
                            }
                        </div>
                        <div className="box-footer">
                            <div className="input-group align-items-center border rounded-3">
                                <Input disabled={error || !!qr} value={message} type="text" className="form-control border-0" ame="message" placeholder="Type Message ..." onChange={(event) => onChange(event.target.value)} onKeyDown={onKeyDown}/>
                                <Button disabled={error || !!qr} color="primary" className="btn-icon btn-primary" onClick={onSend}> <i className="ri-send-plane-fill" /> </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Chatbot;