import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Profile from "./Profile";
import Review from "./Review";
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { userGetRequest } from 'store/user/action';
import { permissionsGetRequest, roleGetRequest, roleNameListRequest } from 'store/role/action';

interface Options {
  label: string;
  value: string;
}

interface Permission {
  name: string;
  options?: Options[];
  value: string;
}

interface Feature {
  description: string;
  editable: boolean;
  name: string;
  value: boolean;
  type: string;
  permissions: Permission[];
  options?: Options[];
}

interface SubItem {
  name: string;
  features: Feature[];
}

interface MenuItem {
  name: string;
  items: SubItem[];
}

interface CreateInputs {
  role: string;
  access: MenuItem[];
}

const UserProfile = () => {
  const { id } = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const userDetails = useSelector((state: ApplicationState) => state.user?.user);
  const roleDetails = useSelector((state: ApplicationState) => state.role.roleDetails);
  const [accessData, setAccessData] = useState<any>(null);
  const [cardHeaderTab, setCardHeaderTab] = useState<string>("1");
  const [createInputs, setCreateInputs] = useState<CreateInputs>({ role: "", access: [] });
  const [verticalTab, setVerticalTab] = useState<string>("1");

  useEffect(() => {
    if (id) {
      dispatch(userGetRequest(id));
      dispatch(roleNameListRequest());
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (roleDetails?.categoriesWithPermission) {
      setAccessData(roleDetails.categoriesWithPermission);
    }
  }, [roleDetails]);

  useEffect(() => {
    if (userDetails?.role) {
      dispatch(roleGetRequest(userDetails.role.id));
      dispatch(permissionsGetRequest());
    }
  }, [userDetails?.id]);

  const toggleVertical = (tab: string) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };

  const cardHeaderToggle = (tab: string) => {
    if (cardHeaderTab !== tab) {
      setCardHeaderTab(tab);
    }
  };

  

  return (
    <>
        <Row className="mx-3">
            <Col xxl={12} className="" style={{ padding: "10px" }}>
                {/* <Card>
                    <CardBody> */}
                        <Row>
                            <Col md={3} className="" style={{ backgroundColor: "#f8f9fa", padding: "10px" }}>
                                <Nav pills className="flex-column" id="v-pills-tab">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                "mb-2": true,
                                                active: verticalTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleVertical("1");
                                            }}
                                            id="v-pills-home-tab"
                                        >
                                            Profile
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                "mb-2": true,
                                                active: verticalTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleVertical("2");
                                            }}
                                            id="v-pills-profile-tab"
                                        >
                                            Access
                                        </NavLink>
                                    </NavItem>
                                
                                   
                                </Nav>
                            </Col>
                            <div className="border-rounnd w-75">
                                <TabContent
                                    activeTab={verticalTab}
                                    className="d-felx justify-content-center align-items-center text-muted ms-5 mt-0"
                                    id="v-pills-tabContent"
                                >
                                    <TabPane tabId="1" id="v-pills-home">
                                        <SimpleBar>
                                        <Profile user={id}/>
                                        </SimpleBar>
                                       
                                    </TabPane>
                                    <TabPane tabId="2" id="v-pills-profile">
                                        {userDetails && userDetails.role.title == 'Owner' || userDetails?.role.title == 'owner' ? (
                                            <>
                                            <h4 className="">Access</h4>
                                            <p>Owner has all access and permissions.</p>
                                            </>
                                        ) : 
                                        <>
                                        <Review createInputs={createInputs}
                                            accessData={accessData}/>
                                        </>
                                        }
                                   
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Row>
                    {/* </CardBody>
                </Card> */}
            </Col>
        </Row>
    </>
)
};

export default UserProfile;
