
import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Button, Col, Form, Input, Modal, ModalBody, ModalHeader, Row, } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import DeleteModal from '../../Components/Common/DeleteModal';
import { todoTaskList } from 'common/data';
import Select from "react-select";
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from 'react-redux';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { taskAddRequest, taskDelete, tasksListGetRequest } from 'store/task/action';
import { usersListGetRequest } from 'store/user/action';
import UsersList from 'pages/UsersList/UsersList';
import { components } from 'react-select';
import { studentCreateRequest } from 'store/student/action';
import EditTasks from './EditTask';
import SimpleBar from 'simplebar-react';
import { taskGetRequest } from 'store/applications/action';
import { userGetRequest } from 'store/auth/action';
// import EditTasks from ""

const Status = ({ status }: any) => {
    switch (status) {
        case "NEW":
            return <span className="badge bg-info-subtle  text-info text-uppercase">{status}</span>;
        case "PENDING":
            return <span className="badge bg-warning-subtle  text-warning text-uppercase">{status}</span>;
        case "INPROGRESS":
            return <span className="badge bg-secondary-subtle text-secondary  text-uppercase">{status}</span>;
        case "COMPLETED":
            return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
        default:
            return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
    }
};
const Priority = ({ priority }: any) => {
    switch (priority) {
        case "HIGH":
            return <span className="badge bg-danger text-uppercase">{priority}</span>;
        case "MEDIUM":
            return <span className="badge bg-warning text-uppercase">{priority}</span>;
        case "LOW":
            return <span className="badge bg-success text-uppercase">{priority}</span>;
        default:
            return <span className="badge bg-success text-uppercase">{priority}</span>;
    }
};
interface ImgData {
    id: number,
    name: string;
    img: string;
}
interface SortOrder {
    taskName: 'ASC' | 'DESC';
    assignedTo: 'ASC' | 'DESC';
    assignedBy: 'ASC' | 'DESC';
    dueDate: 'ASC' | 'DESC';
    status: 'ASC' | 'DESC';
    priority: 'ASC' | 'DESC';
}
const Tasks = ({t, setRenderTasks, renderTasks}: any) => {
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const tasksList = useSelector((state: ApplicationState) => state.task.tasks);
    const me = useSelector((state: ApplicationState) => state.auth.userProfile)
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const paginationDetails = useSelector((state: ApplicationState) => state.task.pagination);
    const [taskTitle, setTaskTitle] = useState('')
    const [sortBy, setsortBy] = useState<any>(null);
    const [users, setUsers] = useState<any[]>([])
    document.title = "To Do Lists | Zilter";
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [taskList, setTaskList] = useState<any>([]);
    const [status, setStatus] = useState('')
    const [priority, setPriority] = useState('')
    const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [taskId, setTaskId] = useState<string>('')
    const [taskDetails, setTaskDetails] = useState({})
    const [currentPage, setCurrentPage] = useState(0);
    const [template, setTemplate] = useState<any>(null);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [editTaskId, setEditTaskId] = useState(null);
    const [sortByColumn, setSortByColumn] = useState<string>("dueDate");
    const [sortByOrder, setSortByOrder] = useState<string>("desc");
    const [isEditTask, setIsEditTask] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [deleteTodoId, setDeleteTodoId] = useState(null);
    const [navBadgeTab, setnavBadgeTab] = useState<any>("1");
    const [searchTaskName, setSearchTaskName] = useState<string>('')
    const [modal_positionTop, setmodal_positionTop] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [tick, setTick] = useState<boolean>()
    const [user, setUser] = useState<any>(me.id);
    const [selectedUser, setSelectedUser] = useState([{value: me.id, label: me.name}]);
    const navBadgeToggle = (tab: any) => {
        if (navBadgeTab !== tab) {
            setnavBadgeTab(tab);
        }
    };
    const [sortOrder, setSortOrder] = useState<SortOrder>({
        taskName: 'DESC',
        assignedTo: 'DESC',
        assignedBy: 'DESC',
        dueDate: 'DESC',
        status: 'DESC',
        priority: 'DESC'
    });
    const filteredUsers = users.filter(user => user.label && user.value);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const handleSort = (column: keyof SortOrder) => {
        const newSortOrder = {
            ...sortOrder,
            [column]: sortOrder[column] === 'ASC' ? 'DESC' : 'ASC',
        };
        setSortOrder(newSortOrder);
    };

    useEffect(() => {
        dispatch(userGetRequest())
        // let queryParams: any = new URLSearchParams({});
        // let filter ={ pageSize: "100", status: "CONFIRMED" }
        let filter = `status=CONFIRMED&pageSize=100`
        dispatch(usersListGetRequest(filter))
        dispatch(tasksListGetRequest(id));
        setUser(me.id)
       
    }, [])

    // useEffect(() => {
    //     dispatch(tasksListGetRequest(id));
    // },[taskList.length])

    useEffect(() => {
        if(renderTasks && renderTasks === true) dispatch(tasksListGetRequest(id));
        setRenderTasks(false)
    }, [renderTasks])

    const renderSortIcon = (column: keyof SortOrder) => {
        if (sortOrder[column] === 'ASC') {
            return <i className="ri-arrow-up-s-fill"></i>;
        } else if (sortOrder[column] === 'DESC') {
            return <i className="ri-arrow-down-s-fill"></i>;
        } else {
            return null;
        }
    };
    // Projects
    const [modalProject, setModalProject] = useState<boolean>(false);
    // To do Task List
    // To dos
    const [todo, setTodo] = useState<any>(null);
    const [modalTodo, setModalTodo] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const handleTaskTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskTitle(e.target.value);
    }
    const toggle = (() => {
        setsortBy(null)
        setModalTodo(false)
    });
    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);
    useEffect(() => {
        const task: any = [];
        tasksList && tasksList.map((item: any) => {
            task.push({ value: item.id, label: item.name });
        });
        setTaskList(task);
    }, [tasksList]);


    const clearTaskData = () => {
        setTaskTitle('')
        setDueDate(undefined)
        setStatus('')
        setPriority('')
    }
    const handleCreateTask = () => {
        const taskStatus = status ? status : "NEW";
        const taskPriority = priority ? priority : "LOW"
        // const userIdString = sortBy;
        // const usersIds = JSON.stringify(userIdString);
        const data = {
            id: "",
            title: taskTitle,
            status: taskStatus,
            priority: taskPriority,
            dueDate: dueDate,
            studentId: id,
            assignedTo: user
        }
        const handleSuccess = (): void => {
            setRenderTasks(true)
            toast(`Task added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tasksListGetRequest(id));
            clearTaskData()
        };
        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }

    const handleCompleteTask = (item: any) => {
        let assignedUser = item.taskAssignments[0]?.assignedTo?.id;
        let queryParams: any = new URLSearchParams({});
        if (tick) {
            queryParams.set('status', "COMPLETED");
        } else {
            queryParams.set('status', "NEW");
        }
        const newStatus = item.status === "NEW" || item.status === "INPROGRESS" ? "COMPLETED" : "NEW";
        const capitalizedStatus = newStatus.charAt(0).toUpperCase() + newStatus.slice(1).toLowerCase();
        // Clone the task object and update the status
        const updatedTask = { ...item, status: newStatus };
        const userIdString = sortBy && sortBy.map((userString: { value: any; }) => userString.value).join(',');
        const usersIds = JSON.stringify(userIdString);
        const data = {
            id: updatedTask.id,
            title: updatedTask.title,
            status: newStatus,
            priority: updatedTask.priority,
            dueDate: updatedTask.dueDate,
            studentId: id,
            assignedTo: assignedUser
        }
        const handleSuccess = (): void => {
            setmodal_positionTop(false);
            toast(`Task marked ${capitalizedStatus}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tasksListGetRequest(!tick ? `${id}?${queryParams.toString()}` : `${id}?${''}`));
        };
        const handleError = (): void => {
            // Handle error if needed
        };
        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }

    const handleDeleteTask = (taskId: any) => {
        const handleSuccess = (): void => {
            toast(`Task deleted successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });

            dispatch(tasksListGetRequest(id));
        };
        const handleError = (): void => {
            const message = () => toast(`Error occurred`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        };
        dispatch(taskDelete(taskId, handleSuccess, handleError));
    }
    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    // Add To do
    const handleTodoClicks = () => {
        setsortBy(null)
        setTaskTitle('')
        setModalTodo(!modalTodo);
        setIsEdit(false);
        toggle();
    };
    // Delete To do
    const onClickTodoDelete = (todoId: any) => {
        // Set the delete modal state to true to show the modal
        setDeleteModal(true);
        // Set the todo ID to be deleted
        setDeleteTodoId(todoId);
    };
    const handleDeleteTodo = () => {
        // Perform deletion logic here using the deleteTodoId state
        handleDeleteTask(deleteTodoId);
        // After deletion, close the delete modal
        setDeleteModal(false);
    };
    const sortbystatus = [
        {
            options: [
                { label: "New", value: "NEW" },
                { label: "Inprogress", value: "INPROGRESS" },
                { label: "Completed", value: "COMPLETED" },
            ],
        },
    ];

    const templateOptions = [
        {
            options: [
                { label: "Template 1", value: "TEMPLATE1" },
                { label: "Template 2", value: "TEMPLATE2" },
                { label: "Template 3", value: "TEMPLATE2" },
            ],
        },
    ];

    const sortbypriority = [
        {
            options: [
                { label: "Low", value: "LOW" },
                { label: "Medium", value: "MEDIUM" },
                { label: "High", value: "HIGH" },
            ],
        },
    ];
    useEffect(() => {
        if (tasksList && tasksList.length > 0) {
            setTaskList(tasksList)
        }
    }, [tasksList])

    // const dragulaDecorator = (componentBackingInstance: any) => {
    //     if (componentBackingInstance) {
    //         let options = {};
    //         Dragula([componentBackingInstance], options);
    //     }
    // };
    let pageNumbers: number[] = [];
    let totalPages = 0;
    if (pagination) {
        // recordsSize = Array.from({ length: Math.ceil(pagination.elements / 10) }, (_, index) => (index + 1) * 10);
        totalPages = pagination.total
        // recordsSize = Array.from({ length: totalPages }, (_, index) => (index + 1) * 10);
        pageNumbers = Array.from({ length: totalPages }, (_, index) => index);
    }
    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };
    const handleselected = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        let sortByPriority;
        let sortByStatus;
        let sortBySearch;

        switch (name) {
            case 'priority':
                // sortByPriority = `priority=${encodeURIComponent(option)}`;
                // sortByStatus = status ? `status=${encodeURIComponent(status)}` : '';
                status && queryParams.set('status', status)
                queryParams.set('priority', option);
                break;
            case 'status':
                priority && queryParams.set('priority', priority)
                queryParams.set('status', option);
                break;

            case 'searchTask':
                // sortBySearch = `search=${encodeURIComponent(option)}`
                queryParams.set('search', (option));
                break;
            case 'column':
                setSortByColumn(option);
                if (sortByColumn === option) {
                    setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                } else {
                    setSortByOrder("asc");
                }
                status && queryParams.set('status', status)
                priority && queryParams.set('priority', priority)
                queryParams.set('sortBy', option);
                queryParams.set('sortOrder', sortByOrder === "asc" ? "desc" : "asc");
                break;
            case 'prevPage':
                queryParams.set('page', (currentPage - 1).toString());
                break;
            case 'nextPage':
                queryParams.set('page', (currentPage + 1).toString());
                break;
            case 'pageNumber':
                queryParams.set('page', option.toString());
                break;
            case 'pageSize':
                queryParams.set('pageSize', option.toString());
                break;
            default:
        }

        dispatch(tasksListGetRequest(id ? `${id}?${queryParams.toString()}` : queryParams.toString()));
    }

    const tog_positionTop = () => {
        setmodal_positionTop(!modal_positionTop);
    }
    const handleConfirmComplete = (item: any, checked: boolean) => {
        setSelectedTask(item);
        // If checked, open the top modal for confirmation
        if (checked) {
            setTick(true)
            // handleCompleteTask(item)

            setmodal_positionTop(true);
        } else {
            // handleCompleteTask(item)
            setmodal_positionTop(true);
            // If unchecked, directly mark the task as incomplete
            // You can add your logic here to update the task status without confirmation
            // For example: updateTaskStatus(item.id, "INCOMPLETE");
        }
    };

    const handleUserSearch = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        queryParams.set('status', "CONFIRMED")
        switch (name) {
          case 'searchUser':
            if (option) {
              queryParams.set('search', option.toString());
        
            }
            break;
        }
        dispatch(usersListGetRequest(queryParams.toString()));
      }

    const handleInputChange = (inputValue: any) => {
        handleUserSearch(inputValue, "searchUser");
      };

      const handleUserSelect = (sortBy: any) => {
        setUser(sortBy.value);
        setSelectedUser(sortBy);
        setMenuIsOpen(false);
      };

     
    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <Modal id="topmodal" isOpen={modal_positionTop} toggle={() => { tog_positionTop() }}>...</Modal>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Modal isOpen={modal_positionTop} toggle={() => setmodal_positionTop(!modal_positionTop)} className="modal-dialog-top">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirmation</h5>
                        <button type="button" className="btn-close" onClick={() => setmodal_positionTop(!modal_positionTop)}></button>
                    </div>
                    { }
                    <div className="modal-body">
                        {tick ? (
                            <p>Are you sure you want to complete the task "{selectedTask?.title}"?</p>
                        ) :
                            <p>Are you sure you want to mark "{selectedTask?.title}" as New?</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <Button color="success"
                            onClick={() => handleCompleteTask(selectedTask)}
                        >Yes</Button>
                        <Button color="danger"
                            onClick={() => setmodal_positionTop(false)}
                        >No</Button>
                    </div>
                </div>
            </Modal>
            <EditTasks setTaskDetails={setTaskDetails} setIsEditTask={setIsEditTask} taskDetails={taskDetails} isEditOpen={isEditOpen} setIsEditOpen={setIsEditOpen} isEditTask={isEditTask} />
            <div className="chat-wrapper d-lg-flex gap-1">
                <div className="file-manager-content w-100 pb-0">
                    <div className="p-3 bg-light rounded mb-4">
                        <Row className="g-2">
                            <Col className="col-lg-auto">
                                <select
                                    onChange={(e) => {
                                        handleselected(e.target.value.toUpperCase(), 'priority');
                                        setPriority(e.target.value.toUpperCase());
                                    }}
                                    className="form-control"
                                    name="choices-select-sortlist"
                                    id="choices-select-sortlist"
                                >
                                    <option value="">Priority</option>
                                    {sortbypriority.map((sortOption) =>
                                        sortOption.options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </Col>
                            <Col className="col-lg-auto">
                                <select className="form-control" onChange={(e) => {
                                    handleselected(e.target.value.toUpperCase(), 'status')
                                    setStatus(e.target.value.toUpperCase())
                                }
                                }
                                    name="choices-select-status" id="choices-select-sortlist">
                                    <option value="">All Tasks</option>
                                    {sortbystatus.map((sortOption) =>
                                        sortOption.options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </Col>
                            <Col className="col-lg">
                                <div className="search-box">
                                    <Input type="text" id="searchTaskList" onChange={(e) => {
                                        e.preventDefault()
                                        setSearchTaskName(e.target.value)
                                        handleselected(e.target.value, 'searchTask')
                                    }} className="form-control search" placeholder="Search task name" />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </Col>
                            <Col className="col-lg-auto">
                                <Button
                                    onClick={() => setModalTodo(true)}
                                    color="primary"
                                    className="btn btn-primary btn-label">
                                    <i className="ri-task-fill label-icon align-middle fs-16 me-2"></i>
                                    {t("student.add_tasks")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="todo-content position-relative px-4 mx-n4" id="todo-content">
                        {!todoTaskList && <div id="elmLoader">
                            <div className="spinner-border text-primary avatar-sm" role="status">
                                <span className="visually-hidden">{t("student.loading")}</span>
                            </div>
                        </div>}

                        <div className="todo-task" id="todo-task">

                            <div className="table-responsive">
                                <SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
                                    <table className="table align-middle position-relative table-nowrap">
                                        <thead className="cursor-pointer table-active position-sticky">
                                            <tr>
                                                <th scope="col" onClick={() => {
                                                    handleSort('taskName');
                                                    handleselected('title', 'column')
                                                }}>
                                                    Task Name {renderSortIcon('taskName')}
                                                </th>
                                                <th scope="col" onClick={() => {
                                                    handleSort('assignedTo');
                                                    handleselected('assignedTo', 'column')
                                                }}>
                                                    Assigned To {renderSortIcon('assignedTo')}
                                                </th>
                                                <th scope="col" onClick={() => {
                                                    handleSort('assignedBy');
                                                }}>
                                                    Assigned By {renderSortIcon('assignedBy')}
                                                </th>
                                                <th scope="col" onClick={() => {
                                                    handleSort('dueDate');
                                                    handleselected('dueDate', 'column')
                                                }}>
                                                    Due Date {renderSortIcon('dueDate')}
                                                </th>
                                                <th scope="col" onClick={() => {
                                                    handleSort('status');
                                                    handleselected('status', 'column')
                                                }}>
                                                    Status {renderSortIcon('status')}
                                                </th>
                                                <th scope="col" onClick={() => {
                                                    handleSort('priority');
                                                    handleselected('priority', 'column')
                                                }}>
                                                    Priority {renderSortIcon('priority')}
                                                </th>
                                                {status && status !== "COMPLETED" || status === "" ? (
                                                    <th scope="col">
                                                        {t("student.actions")}
                                                    </th>
                                                ) : null}

                                            </tr>
                                        </thead>

                                        <tbody id="task-list">
                                        {tasksList && tasksList.map((item: any, key: any) => (
                                                <tr key={key}>
                                                    <td className='align-middle d-flex gap-4 justify-content-start'>
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 me-0">
                                                                {/* <div className="task-handle px-1 bg-light rounded">: :
                                                                </div> */}
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input me-1 mt-1"
                                                                        type="checkbox"
                                                                        value={item.id}
                                                                        id={"todo" + item.id}
                                                                        checked={item.status === "COMPLETED"} // Dynamically set checked attribute
                                                                        onChange={(e) => {
                                                                            setStatus(e.target.value.toUpperCase())
                                                                            handleConfirmComplete(item, e.target.checked)
                                                                            setTick(e.target.checked)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "180px" }} className='text-nowrap text-truncate text-nowrap-ellipsis'>
                                                            <label>{item.title}
                                                                {moment(item.dueDate).isBefore(moment()) && item.status !== "COMPLETED" ? (
                                                                    <span>&nbsp;<Badge color="warning" pill>Overdue </Badge></span>
                                                                ) : null}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "150px" }} className="text-nowrap overflow-hidden text-ellipsis d-flex avatar-group align-middle">
                                                            <div>
                                                                <img src={avatar1} alt="" className="rounded-circle avatar-xxs" />
                                                                <span className='text-nowrap text-overlow-hidden text-truncate text-nowrap-ellipsis ms-1'>
                                                                <label>{item.taskAssignments[0]?.assignedTo?.name}</label>
                                                            </span>
                                                            </div>
                                                           
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <label >{item.taskAssignments[0]?.assignedBy?.name} </label>
                                                    </td>
                                                    <td>{moment(item.dueDate).format('MMMM DD, YYYY h:mm A')}</td>
                                                    <td>
                                                        <Status status={item.status.toUpperCase()} />
                                                    </td>
                                                    <td>
                                                        <Priority priority={item.priority.toUpperCase()} />
                                                    </td>
                                                    {status == "COMPLETED" ? null : (
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button type="button" className="btn btn-sm btn-soft-danger remove-list" onClick={(e) => {
                                                                    onClickTodoDelete(item?.id)
                                                                    e.preventDefault();
                                                                }}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                                <button type="button" className="btn btn-sm btn-soft-info edit-list" onClick={(e) => {
                                                                    setIsEditOpen(true)
                                                                    setTaskId(item?.id)
                                                                    setTaskDetails(item)
                                                                    // setEditTask(true)
                                                                    e.preventDefault();
                                                                }}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    )}

                                                </tr>))}
                                        </tbody>

                                    </table>
                                </SimpleBar>
                            </div>

                        </div>
                        <div className="py-4 mt-4 text-center" id="noresult" style={{ display: "none" }}>
                            <i className="ri-search-line display-5 text-success"></i>
                            <h5 className="mt-4">{t("student.data_error")}</h5>
                        </div>
                    </div>
                    <div>
                        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                            <div className="col-sm">
                                <div className="text-muted">{t("student.showing")}{" "}<span className="fw-semibold ms-1">{tasksList?.length}</span> {t("student.of")}{" "} <span className="fw-semibold">{pagination?.elements}</span> {t("student.results")}
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                    <li className={currentPage <= 0 ? "page-item disabled" : "page-item"}>
                                        <Link to="#" className="page-link" onClick={() => {
                                            setCurrentPage(currentPage - 1);
                                            handleselected(currentPage, 'prevPage');
                                        }}>{t("student.previous")}</Link>
                                    </li>
                                    {/* <li className={pagination && pagination.first ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={() => {
                                 setCurrentPage(currentPage - 1)
                                 handleselected(currentPage, 'prevPage')
                            }}>Previous</Link>
                        </li> */}
                                    {pagesList.map((item: any, key: any) => (
                                        <React.Fragment key={key}>
                                            <li className="page-item" key={key}>
                                                <Link to="#" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => {
                                                    setCurrentPage(item);
                                                    handleselected(item, 'pageNumber');
                                                }}>{item + 1}</Link>
                                            </li>
                                        </React.Fragment>
                                    ))}
                                    <li className={currentPage == totalPages - 1 ? "page-item disabled" : "page-item"}>
                                        <Link to="#" className="page-link" onClick={() => {
                                            setCurrentPage(currentPage + 1);
                                            handleselected(currentPage, 'nextPage');
                                        }}>{t("student.next")}</Link>
                                    </li>
                                    {/* <li className={pagination && pagination.last ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={() => {
                                     setCurrentPage(currentPage + 1);
                             handleselected(currentPage, 'nextPage')}
                            }>Next</Link>
                        </li> */}
                                    <select
                                        className="mx-1 w-50 border border-2 border-light form-select-sm"
                                        value={pageSize}
                                        onChange={(e) => {
                                            const selectedValue = parseInt(e.target.value);
                                            setCurrentPage(0)
                                            setPageSize(selectedValue)
                                            handleselected(e.target.value, 'pageSize');
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map((size) => (
                                            <option key={size} value={size}>
                                                {t("student.show")} {size}
                                            </option>
                                        ))}
                                    </select>
                                </ul>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>

            <Modal id="createTask" isOpen={modalTodo} modalClassName="zoomIn" centered tabIndex={-1}>
                <ModalHeader toggle={toggle} className="p-3 bg-primary-subtle"> {"Create Task"} </ModalHeader>
                <ModalBody>
                    <div id="task-error-msg" className="alert alert-danger py-2"></div>
                    <Form id="creattask-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    >

                        <input type="hidden" id="taskid-input" className="form-control" />
                        <div className="mb-3">
                            <>
                                <label htmlFor="task-title-input" className="form-label">Task Title</label>
                                <Input type="text" id="task-title-input" className="form-control" placeholder="Enter task title"
                                    name="task"
                                    onChange={(e) => setTaskTitle(e.target.value)}
                                    defaultValue={''}
                                    value={taskTitle}
                                />
                            </>
                        </div>
                        <div className="mb-3 position-relative">
                            <label htmlFor="task-assign-input" className="form-label">Assign To&nbsp;<span>{sortBy?.label}</span></label>
                            <div className="avatar-group d-flex justify-content-center" id="assignee-member">
                                {sortBy ? (
                                    <div className='p-1'>
                                        <Link to="#">
                                            <img
                                                src={avatar1} // Use your image source here
                                                width={36}
                                                height={36}
                                                alt=""
                                                className="rounded-circle avatar-xs"
                                            />
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                            <div className="select-element">
                            <Select
                        placeholder="Assign to . . ."
                        value={selectedUser}
                        isMulti={false}
                        onInputChange={handleInputChange}
                        onChange={handleUserSelect}
                        options={users}
                        classNamePrefix="js-example-basic-multiple mb-0"
                        components={{ Option: CustomOption }}
                        isLoading={false}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                      />
                        {/* <Select
                        placeholder="Select Role"
                        value={role.label}
                        onInputChange={handleInputChange}
                        onChange={handleRoleSelect}
                        options={roles}
                        classNamePrefix="js-example-data-array"
                        isLoading={false}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                      /> */}
                                {/* <Select
                                    placeholder="Assign to . . ."
                                    value={sortBy}
                                    isMulti={false}
                                    onChange={(sortBy: any) => {
                                        setsortBy(sortBy);
                                    }}
                                    options={filteredUsers}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                    components={{ Option: CustomOption }}
                                /> */}
                            </div>
                        </div>
                        <Row className="g-4 mb-3">
                            <Col lg={6}>
                                <label htmlFor="task-status" className="form-label">Status</label>
                                <Input
                                    name="status"
                                    type="select"
                                    className="form-select"
                                    id="status-field"
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    defaultValue={'Pending'}
                                    value={status}
                                >
                                    {sortbystatus.map((item, key) => (
                                        <React.Fragment key={key}>
                                            {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                        </React.Fragment>
                                    ))}
                                </Input>
                            </Col>
                            <Col lg={6}>
                                <label htmlFor="priority-field" className="form-label">Priority</label>
                                <Input
                                    name="priority"
                                    type="select"
                                    className="form-select"
                                    id="priority-field"
                                    onChange={(e) => {
                                        setPriority(e.target.value)
                                    }}
                                    defaultValue={'Low'}
                                    value={priority}
                                >
                                    {sortbypriority.map((sortItem, sortKey) => (
                                        <React.Fragment key={sortKey}>
                                            {sortItem.options.map((optionItem, optionKey) => (
                                                <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <div className="mb-4">
                            <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
                            {/* <DatePicker
                            name="dueDate"
                            id="date-field"
                            className="form-control"
                            placeholder="Due date"
                            onChange={dates => {
                                const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                                setDueDate(formattedDate); // Set the formatted date to state
                            }} /> */}
                            <Flatpickr
                                name="dueDate"
                                id="date-field"
                                className="form-control"
                                placeholder="Due date"
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    defaultDate: dueDate, // Set the default date and time to the current state value
                                }}
                                onChange={(dates) => {
                                    const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                    setDueDate(selectedDate); // Set the selected Date object directly to state
                                }}
                                value={dueDate} // Bind the Flatpickr value to the state
                            />
                        </div>
                        {/* <div className="mb-4">
                        <label htmlFor="task-duedate-input" className="form-label">Template</label>
                        <Select
                                    placeholder="Choose template..."
                                    value={template}
                                    isMulti={false}
                                    onChange={(template: any) => {
                                        setTemplate(template);
                                    }}
                                    options={templateOptions}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                />
                        </div> */}
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-ghost-success" onClick={() => {
                                handleTodoClicks();
                                setModalTodo(false)
                            }
                            }><i className="ri-close-fill align-bottom"></i> Close</button>
                            <button type="submit"
                                disabled={!dueDate || !selectedUser || !taskTitle}
                                className="btn btn-primary" onClick={() => {
                                    handleCreateTask();
                                    handleTodoClicks()
                                }} id="addNewTodo">{"Add Task"}</button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >
        </React.Fragment >
    );
};
export default withTranslation()(Tasks);
