import { Reducer } from "redux";
import { ViewActionTypes, ViewState } from "./types";


export const initialState: ViewState =
{
    viewMetadata: [],
    view: null,
    views: null,
    pagination: null,
    viewlist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    applicationsView: [],
    invoicesstudents: [],
    loading: false,
    viewusers: []
}
const reducer: Reducer<ViewState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ViewActionTypes.GET_VIEWMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_VIEWMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                viewMetadata: action.payload.content
            };
        }
        case ViewActionTypes.GET_VIEWMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewActionTypes.GET_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                view: action.payload,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable?.offset
                }
            };
        }
        case ViewActionTypes.GET_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  

        
        case ViewActionTypes.GET_VIEWS_USERS_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_VIEWS_USERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                viewusers: action.payload.content
            };
        }
        case ViewActionTypes.GET_VIEWS_USERS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        
        case ViewActionTypes.PUT_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.PUT_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ViewActionTypes.PUT_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        
        
        case ViewActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                applicationsView: action.payload.content
            };
        }
        case ViewActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 

        case ViewActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoicesstudents: action.payload.content
            };
        }
        case ViewActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 

        case ViewActionTypes.POST_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.POST_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ViewActionTypes.POST_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ViewActionTypes.GET_VIEWS_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_VIEWS_SUCCESS: {
            return {
                ...state,
                loading: false,
                views: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ViewActionTypes.GET_VIEWS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewActionTypes.PUT_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.PUT_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                viewlist: action.payload.content
            };
        }
        case ViewActionTypes.PUT_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewActionTypes.DELETE_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.DELETE_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ViewActionTypes.DELETE_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as ViewReducer };
