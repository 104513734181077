import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Container, Nav, NavLink, Row, TabContent, TabPane, Button, Accordion, AccordionItem, AccordionHeader, AccordionBody, Badge } from 'reactstrap';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { GroupListForStudentForm, allGroupsWithToken } from 'store/properties/action';
import { userGetRequest } from 'store/auth/action';
import Form from 'Components/Common/FormBuilder/Form';
import ReviewForm from 'Components/Common/FormBuilder/UpdateForm';
import { studentCreateRequest, studentExternalApplication, studentGetRequest, StudentListGetRequest } from 'store/student/action';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import UpdateFormCol from 'Components/Common/FormBuilder/UpdateFormCol';
import { applicationGetRequest, applicationUpdateRequest } from 'store/applications/action';

const UploadDocuments = ({ status }: any) => {
    const {id} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const me = useSelector((state: ApplicationState) => state.user.user);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const groups = useSelector((state: ApplicationState) => state.properties.allGroups);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const applicationDetails:any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const [completedSteps, setCompletedSteps] = useState<any>([]);
    const [activeVerticalTab, setActiveVerticalTab] = useState<any | null>(1);
    const [formData, setFormData] = useState<{ [key: number]: any }>({});
    const [entryAdded, setEntryAdded] = useState(false);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [applicationData, setApplicationData] = useState<any>({});
    const [edit, setEdit]= useState<any>(false);

    const isJson = (str: any) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    };

    // useEffect(() => {
    //     if(applicationDetails && applicationDetails.valuesJson) {
    //       let values =  applicationDetails.valuesJson ? applicationDetails.valuesJson : {}
    //       setApplicationData(values)
    //     }
    //   }, [applicationDetails])

      const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => ( subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        applicationsMetadata && applicationsMetadata.map((field: any) => { 
            let permissionsJson = field?.permissionsJson  && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {} 
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) :  true;       
            if(isFieldviewaccess) {
                if(isFieldEditaccess) {                    
                    let newField = {...field, isEditAccess: true}
                    return newstatusMetaData.push(newField)
                } 
                return newstatusMetaData.push(field)   
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [applicationsMetadata])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setEdit(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if(access && access?.length) {
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                       }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])


    const steps = groups && groups.length ? [
        ...groups.map((item: any, index: any) => {
            const parsedFields = item && item.metadata.map((meta: any) => ({
                ...meta,
                valuesJson: isJson(meta.valuesJson) ? meta.valuesJson : "{}"
            }));
            return {
                label: item.groupTitle,
                tab: (index + 1).toString(),
                fields: parsedFields
            };
        }),
        {
            tab: (Array.isArray(groups) ? (groups.length + 1) : 1).toString(),
            // fields: statusMetaData
        }
    ]: [];

    

    useEffect(() => {
        dispatch(allGroupsWithToken('applications'));
        dispatch(userGetRequest());
    }, [dispatch]);

    useEffect(() => {
        if (me) {
            dispatch(allGroupsWithToken('applications'));
        }
    }, [dispatch, me]);

    const toggleVerticalTab = (tab: string) => {
        setActiveVerticalTab((prevTab: string) => (prevTab === tab ? null : tab));
        if (!completedSteps.includes(tab)) {
            setCompletedSteps([...completedSteps, tab]);
        }
    };

    const handleAddEntry = (formValues: any, tab: any) => {

        // Convert form values to a JSON string
        const formValuesJson = JSON.stringify(formValues);

        setFormData((prevData: any) => ({
            ...prevData,
            [tab]: { valuesJson: formValuesJson }
        }));

        setEntryAdded(true);
    };

    const handleSubmit = () => {
        // Merge the form data from each tab
        const combinedFormData = Object.keys(formData).reduce((acc: any, key: any) => {
            try {
                const valuesJson = formData[key].valuesJson;
                if (valuesJson) {
                    const parsedData = JSON.parse(valuesJson);
                    return { ...acc, ...parsedData };
                }
            } catch (error) {
            }
            return acc;
        }, {});

        const handleSuccess = (body: any) => {
            toast(`Form Submitted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(StudentListGetRequest());
        };

        dispatch(studentCreateRequest(combinedFormData, handleSuccess, () => { }));
    };


    const onUpdate = (formValues: any) => {
        // Update formData with the new formValues for the current active tab
        const updatedFormData = {
            ...formData,
            [activeVerticalTab!]: { valuesJson: JSON.stringify(formValues) }
        };

        // Merge all valid form data into a single object
        const combinedFormData = Object.keys(updatedFormData).reduce((acc: any, key: any) => {
            try {
                const valuesJson = updatedFormData[key].valuesJson;
                if (valuesJson) {
                    const parsedData = JSON.parse(valuesJson);
                    return { ...acc, ...parsedData };
                }
            } catch (error) {
                console.error(`Error parsing JSON for tab ${key}:`, error);
            }
            return acc;
        }, {});

        setFormData(updatedFormData);

        const handleSuccess = (body: any) => {
            toast(`Student updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // dispatch(StudentListGetRequest());
            dispatch(applicationGetRequest(id))
        };

        // Call dispatch with the updated form data (commented out for now)
        dispatch(applicationUpdateRequest(id, combinedFormData, handleSuccess, () => { }))
    };
    const initCaps = (str: any) => {
        if (!str) return ''; // Handle edge case if str is null or undefined
        return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a: any) {
          return a.toUpperCase();
        });
      };

      const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

      useEffect(() => {
        if(applicationDetails && applicationDetails.id) {
            let applicationData = applicationDetails.valuesJson;
            let newData: any = {}
                groups && groups.length  && 
                groups.map((item: any, index: any) => {
                    item && item.metadata && item.metadata.map((meta: any) => {
                        let field = meta?.valuesJson && isJson(meta?.valuesJson) ? JSON.parse(meta?.valuesJson) : {}
                        let rendervalue = applicationData[field.key];
                        if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                            rendervalue = handleselectValue(applicationData?.[field.key], field.values);
                        }
                        else if (field.optionLabel) {
                            rendervalue = applicationData?.[field.model] && applicationData?.[field.model]?.[field.optionLabel] ? applicationData?.[field.model]?.[field.optionLabel] : 'NA'
                        }
                        newData[field.key]= rendervalue;
                    });
                })     
                setApplicationData({...newData,id:applicationDetails.id, studentId:applicationDetails?.id})                   
        }
      }, [applicationDetails, groups])
    return (
        <React.Fragment>
         <Row className="g-0 text-center pb-1">
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-exchange-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{status?.label}</h6>
                            <p className="text-muted mb-0">Status</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-group-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <div className="d-flex flex-wrap gap-1 justify-content-center">
                                {status?.roles?.map((role: any) => {
                                    return (
                                        <Badge className='' color="primary">
                                            {role?.label}
                                        </Badge>
                                    )
                                })}
                            </div>
                            <p className="text-muted mb-0">Role</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-terminal-box-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{applicationDetails?.updatedAt ? moment(applicationDetails?.updatedAt).format('MMMM Do YYYY') : "No Activities"}</h6>
                            <p className="text-muted mb-0">Last Activity Date</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3}  className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0 border-end-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-git-repository-commits-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{moment(applicationDetails?.createdAt).format('MMMM Do YYYY')}</h6>
                            <p className="text-muted mb-0">Created Date</p>
                        </div>
                    </div>
                </Col>
            </Row>
        <Accordion className="custom-accordionwithicon accordion-fill-primary" open={activeVerticalTab} toggle={toggleVerticalTab}>
            {steps && steps.length && steps.map(({ tab, fields, label }: any) => {
                    const halfLength = fields && fields.length && Math.ceil(fields.length / 2);
                    const firstHalf = fields && fields.length && fields.slice(0, halfLength);
                    const secondHalf = fields && fields.length && fields.slice(halfLength);

                if (fields && fields.length > 0) {
                    return (
                        <AccordionItem key={tab}>
                            <AccordionHeader targetId={tab}>
                                {label}
                            </AccordionHeader>
                            <AccordionBody accordionId={tab}>
                                <Row>
                                    {firstHalf.map((field: any, index: any) => (
                                        <Col xxl={6} lg={6} md={6} key={index}>
                                            <div>
                                                <UpdateFormCol
                                                    dataFields={[field]}
                                                    onUpdate={onUpdate}
                                                    showbtn={false}
                                                    data={applicationData}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                    {secondHalf.map((field: any, index: any) => (
                                        <Col  xxl={6} lg={6} md={6} key={index}>
                                            <div>
                                                <UpdateFormCol
                                                    dataFields={[field]}
                                                    onUpdate={onUpdate}
                                                    showbtn={false}
                                                    data={applicationData}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </AccordionBody>
                        </AccordionItem>
                    );
                } else {
                    return null;
                }
            })}
        </Accordion>
    </React.Fragment>
    
    );
};

export default withTranslation()(UploadDocuments);
