import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import Courses from './Courses/Courses';
import ApplicationComponent from './ApplicationComponent';
import Recommend from './Recommend/Recommend';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Applications = (props: any) => {
    const [activeTab, setActiveTab] = useState<any>("1");
    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    document.title = "DTS | Zilter";
    return ( 
        <Card>
            <CardHeader>
                <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 gap-2"
                    role="tablist">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                                tabChange("1");
                            } }
                            type="button" to={''}                        >
                            <i className="ri-contacts-book-line"></i>{" "}
                            <strong>{props.t("student.applications")}</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                                tabChange("2");
                            } }
                            type="button" to={''}                        >
                            <i className="ri-file-3-line"></i>{" "}
                            <strong>{props.t("student.shortlist")}</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "3" })}
                            onClick={() => {
                                tabChange("3");
                            }}
                            type="button"
                        >
                            <i className="ri-book-open-line"></i>{" "}
                            <strong>{props.t("student.courses")}</strong>
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardHeader>
            <CardBody className="p-2">
                <TabContent activeTab={activeTab}>
                    {activeTab == "1" && <TabPane tabId="1">
                        <ApplicationComponent />
                    </TabPane>}
                    {activeTab == "2" && <TabPane tabId="2">
                        <Recommend />
                    </TabPane> } 
                    {activeTab == "3" && <TabPane tabId="3">                        
                        <Courses />
                    </TabPane> } 
                </TabContent>
            </CardBody>
    </Card>
    
    );
};
export default withTranslation()(Applications);


