import { Reducer } from "redux";
import { CourseActionTypes, CourseState } from "./types";


export const initialState: CourseState =
{
    coursesMetadata: [],
    courses: null,
    course: null,
    pagination: null,
    courselist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    loading: null,
    dataloading: false
}
const reducer: Reducer<CourseState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CourseActionTypes.GET_COURSEMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.GET_COURSEMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                coursesMetadata: action.payload.content
            };
        }
        case CourseActionTypes.GET_COURSEMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CourseActionTypes.GET_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.GET_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                course: action.payload
            };
        }
        case CourseActionTypes.GET_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case CourseActionTypes.POST_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.POST_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CourseActionTypes.POST_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case CourseActionTypes.GET_COURSES_REQUEST: {
            return { ...state, dataloading: true };
        }
        case CourseActionTypes.GET_COURSES_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                courses: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CourseActionTypes.GET_COURSES_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }
        case CourseActionTypes.PUT_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.PUT_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                courselist: action.payload.content
            };
        }
        case CourseActionTypes.PUT_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CourseActionTypes.DELETE_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.DELETE_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CourseActionTypes.DELETE_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        
        case CourseActionTypes.GET_FILTER_COURSES_REQUEST: {
            return { ...state, dataloading: true };
        }
        case CourseActionTypes.GET_FILTER_COURSES_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                courses: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CourseActionTypes.GET_FILTER_COURSES_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }      
        default: {
            return state;
        }
    }
};

export { reducer as CourseReducer };
