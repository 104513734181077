import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";


const SentimentsChart = ({ sentiments }: any) => {
    let cneutral = 0;
    let cpositive = 0;
    let cnegative = 0;
    let aneutral = 0;
    let apositive = 0;
    let anegative = 0;
    sentiments.map((sentiment: any) => {
        switch(sentiment.Sentiment) {
            case "POSITIVE":
                if(sentiment.ParticipantId == "CUSTOMER") {
                    cpositive = cpositive + 1;
                } else {
                    apositive = apositive + 1;
                }
                break;
            case "NEGATIVE":
                if(sentiment.ParticipantId == "CUSTOMER") {
                    cnegative = cnegative + 1;
                } else {
                    anegative = anegative + 1;
                }
                break;
            default:
                if(sentiment.ParticipantId == "CUSTOMER") {
                    cneutral = cneutral + 1;
                } else {
                    aneutral = aneutral + 1;
                }
                break;
        }
    })
    const dataColors = '["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning", "--vz-info"]'

    //Setiment Percentage
    var chartStackedBar100Colors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Neutral",
            data: [cneutral, aneutral],
        },
        {
            name: "Positive",
            data: [cpositive, apositive],
        },
        {
            name: "Negative",
            data: [cnegative, anegative],
        }
    ];

    const options = {
        chart: {
            stacked: !0,
            stackType: "100%",
            toolbar: {
                show: !1,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        title: {
            text: "",
            style: {
                fontWeight: 600,
            },
        },
        xaxis: {
            categories: ['Customer', 'Agent'],
        },
        tooltip: {
            y: {
                formatter: function (val:any) {
                    return val + "K";
                },
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
        colors: chartStackedBar100Colors,
    };
    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Customer and Agent Sentiments</h4>
                </CardHeader>
                <CardBody className="p-0">
                    <ReactApexChart dir="ltr"
                        className="apex-charts"
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default SentimentsChart;