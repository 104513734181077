import React, { useEffect, useState } from "react";
import { Form, Link, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { notesListGetRequest, taskAddRequest, tasksListGetRequest } from "store/applications/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { usersListGetRequest } from "store/user/action";
interface FProps {
    props: any;
    tasksModals: boolean;
    setTasksModals: (event: boolean) => void;
  }

  const Delete = ({ props, show, onCloseClick, record , onDelete}: any) => {
    return ( record ?
        <Modal  backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <i className="ri-delete-bin-5-line display-5 text-danger"></i>                                  
                        <h4 className="mb-2 mt-2">{record.name}</h4>
                        <p className="text-muted fs-15 mb-4">{props.t("properties.delete_disclaimer1")} {record.name}. {props.t("properties.delete_disclaimer2")}</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button className="btn btn-danger" onClick={() => onDelete()}>
                                {props.t("properties.delete")}
                            </Button>
                            <Button color="danger" onClick={() => onCloseClick()} outline>
                                {props.t("properties.cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default Delete;