import { Reducer } from "redux";
import { TaskActionTypes, TaskState } from "./types";


export const initialState: TaskState =
{

    tasks: [],
    task: null,
    pagination: null,
    loading: false,
}
const reducer: Reducer<TaskState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case TaskActionTypes.GET_TASK_REQUEST: {
            return { ...state, loading: true };
        }
        case TaskActionTypes.GET_TASK_SUCCESS: {
            return {
                ...state,
                loading: false,
                task: action.payload
            };
        }
        case TaskActionTypes.GET_TASK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TaskActionTypes.GET_TASKS_REQUEST: {
            return { ...state, loading: true };
        }
        case TaskActionTypes.GET_TASKS_SUCCESS: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                loading: false,
                tasks: action.payload.content,
                pagination: {
                    page,
                    total,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    offset: action.payload.pageable.offset

                }
            };
        }
        case TaskActionTypes.GET_TASKS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TaskActionTypes.DELETE_TASK_REQUEST: {
            return { ...state, loading: true };
        }
        case TaskActionTypes.DELETE_TASK_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TaskActionTypes.DELETE_TASK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as taskReducer };
