import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

// Import Images
// import error from "../../../assets/images/error.svg";
import error from "../../assets/images/error.svg"

const AccessDenied = () => {
    // document.title="Access Denied | Zilter";

    return (
        <React.Fragment>
                <ParticlesAuth>
                    <div className="text-center">
                        <div className="">
                            <img src={error} alt="" className="error-basic-img move-animation" />
                        </div>
                        <div className="">
                            <h5 className="display-5 fw-semibold">Access Denied:</h5>
                            <h6 className="text-uppercase"> Sorry, you do not have permission to view this page.</h6>
                            <p className="text-muted mb-4"> Please contact your system administrator for assistance.</p>
                            <Link to="/students" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                        </div>
                    </div>
                </ParticlesAuth>
        </React.Fragment>
    );
};

export default AccessDenied;