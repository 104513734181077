import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";

const Number = ({property, setConditionjson, conditionjson, setFilterObject, filterObject}: any) => {  
    const [condition, setCondition]= useState<any>("");
    const handleChange = (value: any, name: any, label: any) => {
        if(value == 'on') {
            setCondition({value:name, label: label })
            let newconditionjson = {propertylabel: property.label, propertyvalue: property.value, conditionLabel: label, conditionvalue: value}
            setConditionjson(newconditionjson)
        }
    }

    const handleTextChange = (value: any, name: any) => {
        let newconditionjson ={...conditionjson, value: value}
        setConditionjson(newconditionjson)
    }
    useEffect(() => {
    }, [conditionjson])   
    
    return (
        <div className="vstack gap-2"> 
            <div className="vstack gap-1">
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="equal" checked={condition.value == "equal"} onChange={(e) => handleChange(e.target.value,"equal","is equal to")} />
                <Label className="form-check-label" htmlFor="equal">
                    is equal to
                </Label>
                {condition.value == "equal" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"equal")}
                    // value={search}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="notequal" checked={condition.value =="notequal"} onChange={(e) => handleChange(e.target.value,"notequal", "is not equal to")}/>
                <Label className="form-check-label" htmlFor="notequal">
                    is not equal to
                </Label>
                {condition.value =="notequal" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"notequal")}
                    // value={search}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="greaterthan" id="greaterthan" checked={condition.value == "greaterthan"} onChange={(e) => handleChange(e.target.value,"greaterthan","is greater than")} />
                <Label className="form-check-label" htmlFor="greaterthan">
                    is greater than
                </Label>
                {condition.value == "greaterthan" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"greaterthan")}
                    // value={search}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="greaterthanequal" id="greaterthanequal" checked={condition.value == "greaterthanequal"} onChange={(e) => handleChange(e.target.value,"greaterthanequal", "is greater than or equal to")} />
                <Label className="form-check-label" htmlFor="greaterthanequal">
                    is greater than or equal to
                </Label>
                {condition.value == "greaterthanequal" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"greaterthanequal")}
                    // onChange={(e) => handleChange(e.target.value)}
                    // value={search}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="lessthan" id="lessthan" checked={condition.value == "lessthan"} onChange={(e) => handleChange(e.target.value,"lessthan", "is less than")} />
                <Label className="form-check-label" htmlFor="lessthan">
                    is less than
                </Label>
                {condition.value == "lessthan" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"lessthan")}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="lessthanequal" id="lessthanequal" checked={condition.value == "lessthanequal"} onChange={(e) => handleChange(e.target.value,"lessthanequal","is less than or equal to")} />
                <Label className="form-check-label" htmlFor="lessthanequal">
                    is less than or equal to
                </Label>
                {condition.value == "lessthanequal" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"lessthanequal")}
                    // onChange={(e) => handleChange(e.target.value)}
                    // value={search}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="between" id="between" checked={condition.value == "between"} onChange={(e) => handleChange(e.target.value,"between", "is between")} />
                <Label className="form-check-label" htmlFor="between">
                    is between
                </Label>
                {condition.value == "between"  ? <div className="hstack gap-1">
                    <Input
                        type="text"
                        size={14}
                        className="search"
                        onChange={(e) => handleTextChange(e.target.value,"from")}
                        // onChange={(e) => handleChange(e.target.value)}
                        // value={search}
                    />
                    and
                    <Input
                        type="text"
                        size={14}
                        className="search"
                        onChange={(e) => handleTextChange(e.target.value,"to")}
                        // onChange={(e) => handleChange(e.target.value)}
                        // value={search}
                    />
                </div>: null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="known" id="known" checked={condition.value == "known"} onChange={(e) => handleChange(e.target.value,"known","is known")}/>
                <Label className="form-check-label" htmlFor="known">
                    is known
                </Label>
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="unknown" id="unknown" checked={condition.value == "unknown"} onChange={(e) => handleChange(e.target.value,"unknown", "is unknown")}/>
                <Label className="form-check-label" htmlFor="unknown">
                    is unknown
                </Label>
            </div> 
        </div>
    );
};

export default Number;
