import React, { useEffect, useState } from "react";
import { Form, Link, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { taskAddRequest, tasksListGetRequest } from "store/applications/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { usersListGetRequest } from "store/user/action";
import moment from "moment";
import { userGetRequest } from "store/auth/action";
interface FProps {
    props: any;
    tasksModals: boolean;
    setTasksModals: (event: boolean) => void;
    setRenderTasks: (event: boolean) => void;
    renderTasks: boolean;
    userProfile: any;
  }
const AddTask: React.FC<FProps> = ({
    props,
    tasksModals,
    setTasksModals,
    setRenderTasks,
    renderTasks,
    userProfile
  }) => {
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const [modalTodo, setModalTodo] = useState<boolean>(false);
    const [sortBy, setsortBy] = useState<any>(null);
    const [template, setTemplate] = useState<any>(null);
    const [taskTitle, setTaskTitle] = useState('')
    const [users, setUsers] = useState<any[]>([])
    const [status, setStatus] = useState('')
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [priority, setPriority] = useState('')
    const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
    const [user, setUser] = useState<any>(userProfile.id);
    const [selectedUser, setSelectedUser] = useState([{value: userProfile.id, label: userProfile.name}]);

    const sortbystatus = [
        {
            options: [
                { label: "New", value: "NEW" },
                { label: "Inprogress", value: "INPROGRESS" },
                { label: "Completed", value: "COMPLETED" },
            ],
        },
    ];
    const templateOptions = [
        {
            options: [
                { label: "Template 1", value: "TEMPLATE1" },
                { label: "Template 2", value: "TEMPLATE2" },
                { label: "Template 3", value: "TEMPLATE2" },
            ],
        },
    ];
    const sortbypriority = [
        {
            options: [
                { label: "Low", value: "LOW" },
                { label: "Medium", value: "MEDIUM" },
                { label: "High", value: "HIGH" },
            ],
        },
    ];

    useEffect(() => {
        if(userList) {
            dispatch(userGetRequest())
            let filter = `status=CONFIRMED&pageSize=100`
            dispatch(usersListGetRequest(filter))
        }
    }, [])

    useEffect(() => {
        if (userProfile && userProfile.id) {
          setUser(userProfile.id);
          setSelectedUser([{value: userProfile.id, label: userProfile.name}]);
        }
        
      }, [userProfile]);

    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);

    const toggle = (() => {
        setsortBy(null)
        setModalTodo(false)
    });
    const handleTodoClicks = () => {
        setsortBy(null)
        setTaskTitle('')
        setTasksModals(false);
        toggle();
    };
    const handleCreateTask = () => {
        const selectedStatus = status ? status : "NEW"
        // const userIdString = sortBy;
        // const usersIds = JSON.stringify(userIdString);
        const data = {
            id: "",
            title: taskTitle,
            status: status ? status : "NEW",
            priority: priority ? priority : "LOW",
            dueDate: dueDate,
            studentId: id,
            assignedTo: user
        }
        const handleSuccess = (): void => {
            clearTaskData()
            toast(`Task added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tasksListGetRequest(id));
        };
        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }
    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };

    const handleUserSearch = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        queryParams.set('status', "CONFIRMED")
        switch (name) {
          case 'searchUser':
            if (option) {
              queryParams.set('search', option.toString());
        
            }
            break;
        }
        dispatch(usersListGetRequest(queryParams.toString()));
      }

    const handleInputChange = (inputValue: any) => {
        handleUserSearch(inputValue, "searchUser");
      };

      const handleUserSelect = (sortBy: any) => {
        setUser(sortBy.value);
        setSelectedUser(sortBy);
        setMenuIsOpen(false);
      };

      const clearTaskData = () => {
        setTaskTitle('')
        setDueDate(undefined)
        setStatus('')
        setPriority('')
    }
    return(
        <>
          <Modal id="createTask" isOpen={tasksModals} modalClassName="zoomIn" centered tabIndex={-1}>
                <ModalHeader toggle={() => {
            setTasksModals(false);
          }} className="p-3 bg-primary-subtle"> {"Create Task"} </ModalHeader>
                <ModalBody>
                    <div id="task-error-msg" className="alert alert-danger py-2"></div>
                    {/* <Form id="creattask-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    > */}

                        <input type="hidden" id="taskid-input" className="form-control" />
                        <div className="mb-3">
                            <>
                                <label htmlFor="task-title-input" className="form-label">Task Title</label>
                                <Input type="text" id="task-title-input" className="form-control" placeholder="Enter task title"
                                    name="task"
                                    onChange={(e) => setTaskTitle(e.target.value)}
                                    defaultValue={''}
                                    value={taskTitle}
                                />
                            </>
                        </div>
                        <div className="mb-3 position-relative">
                            <label htmlFor="task-assign-input" className="form-label">Assign To&nbsp;<span>{sortBy?.label}</span></label>
                            <div className="avatar-group d-flex justify-content-center" id="assignee-member">
                                {sortBy ? (
                                    <div className='p-1'>
                                        <Link to="#">
                                            <img
                                                src={avatar1} // Use your image source here
                                                width={36}
                                                height={36}
                                                alt=""
                                                className="rounded-circle avatar-xs"
                                            />
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                            <div className="select-element">
                            <Select
                        placeholder="Assign to . . ."
                        value={selectedUser}
                        isMulti={false}
                        onInputChange={handleInputChange}
                        onChange={handleUserSelect}
                        options={users}
                        classNamePrefix="js-example-basic-multiple mb-0"
                        components={{ Option: CustomOption }}
                        isLoading={false}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                      />
                            </div>
                        </div>
                        <Row className="g-4 mb-3">
                            <Col lg={6}>
                                <label htmlFor="task-status" className="form-label">Status</label>
                                {/* <Input
                                    name="status"
                                    type="select"
                                    className="form-select"
                                    id="status-field"
                                    onChange={(e) => {
                                        setStatus((e.target.value).toUpperCase())
                                    }}
                                    value={status}

                                ><option value="NEW">New</option>
                                    {sortbystatus.map((item, key) => (
                                        <React.Fragment key={key}>
                                            {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                        </React.Fragment>
                                    ))}
                                </Input> */}
                                 <select
                                    onChange={(e) => {
                                        setStatus(e.target.value.toUpperCase());
                                    }}
                                    className="form-control"
                                    name="choices-select-sortlist"
                                    id="choices-select-sortlist"
                                >
                                    {sortbystatus.map((sortOption) =>
                                        sortOption.options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </Col>
                            <Col lg={6}>
                                <label htmlFor="priority-field" className="form-label">Priority</label>
                                <Input
                                    name="priority"
                                    type="select"
                                    className="form-select"
                                    id="priority-field"
                                    onChange={(e) => {
                                        setPriority(e.target.value)
                                    }}
                                    value={priority}
                                >
                                    {sortbypriority.map((sortItem, sortKey) => (
                                        <React.Fragment key={sortKey}>
                                            {sortItem.options.map((optionItem, optionKey) => (
                                                <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <div className="mb-4">
                            <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
                            {/* <DatePicker
                            name="dueDate"
                            id="date-field"
                            className="form-control"
                            placeholder="Due date"
                            onChange={dates => {
                                const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                                setDueDate(formattedDate); // Set the formatted date to state
                            }} /> */}
                            <Flatpickr
                                name="dueDate"
                                id="date-field"
                                className="form-control"
                                placeholder="Due date"
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                }}
                                onChange={dates => {
                                    const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                    const parsedDate = moment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                                    const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                                    setDueDate(parsedDate); // Set the formatted date to state
                                }}
                                value={undefined}
                            />
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-ghost-success" onClick={() => {
                                handleTodoClicks();
                                    setTasksModals(false);
                            }
                            }><i className="ri-close-fill align-bottom"></i> Close</button>
                            <button type="submit"
                            disabled={!dueDate || !selectedUser || !taskTitle}
                            className="btn btn-primary" onClick={() => {
                                setRenderTasks(true)
                                handleCreateTask();
                                handleTodoClicks()
                            }} id="addNewTodo">{"Add Task"}</button>
                        </div>
                    {/* </Form> */}
                </ModalBody>
            </Modal >
            </>
    )
}
export default AddTask;