import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { UniversityListGetRequest, universityCreateRequest } from "store/university/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { financeChannelListGetRequest, financeCreateRequest } from "store/finance/action";
import { useParams } from "react-router";
const AddEntry = ({ props, show, onCloseClick, dataFields }: any) => {   
    const { id } = useParams(); 
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const OnAddEntry = (formValues: any) => {
        console.log("formValues", formValues)
        let data = {
            "valuesJson": formValues,
            "channelId": id,
          }
       
        const handleSuccess = (body: any) => {
            const message = () => toast(`Commission Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            onCloseClick()
            dispatch(financeChannelListGetRequest(id));
        }
        const handleError = () => {

        }
        dispatch(financeCreateRequest(data, handleSuccess ,handleError))
    }
    return (
        <Offcanvas
            direction="end"            
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Entry'} />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

