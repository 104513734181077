import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	useTable,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	useFilters,
	useExpanded,
	usePagination,
	useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, CardBody, Input, Label, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { DefaultColumnFilter } from "../../../Components/Common/filters";
import {
	ProductsGlobalFilter,
	CustomersGlobalFilter,
	OrderGlobalFilter,
	ContactsGlobalFilter,
	CompaniesGlobalFilter,
	LeadsGlobalFilter,
	CryptoOrdersGlobalFilter,
	InvoiceListGlobalSearch,
	TicketsListGlobalFilter,
	NFTRankingGlobalFilter,
	TaskListGlobalFilter,
} from "../../../Components/Common/GlobalSearchFilter";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { usersListGetRequest } from "store/user/action";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
interface GlobalFilterProps {
	preGlobalFilteredRows?: any;
	globalFilter?: any;
	setGlobalFilter?: any;
	SearchPlaceholder?: string;
	isProductsFilter?: boolean;
	isCustomerFilter?: any;
	isOrderFilter?: any;
	isContactsFilter?: any;
	isCompaniesFilter?: any;
	isCryptoOrdersFilter?: any;
	isInvoiceListFilter?: any;
	isTicketsListFilter?: any;
	isNFTRankingFilter?: any;
	isTaskListFilter?: any;
	isLeadsFilter?: any;
}


interface TableContainerProps {
	columns?: any;
	data?: any;
	isGlobalFilter?: any;
	isAddOptions?: any;
	divClassName?: any;
	tableClassName?: any;
	theadClassName?: any;
	tableClass?: any;
	theadClass?: any;
	isBordered?: boolean;
	isAddUserList?: any;
	handleOrderClicks?: any;
	handleUserClick?: any;
	handleCustomerClick?: any;
	isAddCustList?: any;
	customPageSize?: any;
	className?: any;
	customPageSizeOptions?: any;
	iscustomPageSize?: boolean;
	SearchPlaceholder: string;
	isProductsFilter?: boolean;
	isPageSizeChange?: any;
	isCustomerFilter?: any;
	isOrderFilter?: any;
	isContactsFilter?: any;
	isCompaniesFilter?: any;
	isLeadsFilter?: any;
	isPagination?: any;
	isCryptoOrdersFilter?: any;
	isInvoiceListFilter?: any;
	isTicketsListFilter?: any;
	isNFTRankingFilter?: any;
	isTaskListFilter?: any;
	trClass?: any;
	thClass?: any;
	divClass?: any;
	access?: any;
	props: any;
	setPagination: (e: any) => void;
	onChangePageSize: (e: any) => void;
	pageChanged: (e: any) => void,
	pagination: any;
	pageSize: any;
	pagesList: any;
	setisDeleteOpen: (e: any) => void;
	setRecord: (e: any) => void;
	usersLoading: any;
	//   handleSelectChange?: (e: any) => void;
}
const TableContainer = ({
	columns,
	data,
	isPageSizeChange,
	isGlobalFilter,
	isProductsFilter,
	isCustomerFilter,
	isOrderFilter,
	isContactsFilter,
	isCompaniesFilter,
	isLeadsFilter,
	isCryptoOrdersFilter,
	isInvoiceListFilter,
	isTicketsListFilter,
	isNFTRankingFilter,
	isTaskListFilter,
	isAddOptions,
	isAddUserList,
	handleOrderClicks,
	handleUserClick,
	handleCustomerClick,
	isAddCustList,
	customPageSize,
	tableClass,
	theadClass,
	trClass,
	thClass,
	divClass,
	SearchPlaceholder,
	access,
	props,
	setRecord,
	setisDeleteOpen,
	setPagination,
	onChangePageSize,
	pageChanged,
	pagination,
	pagesList,
	usersLoading
}: TableContainerProps) => {
	const {

		onPageSizeChange,
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		// preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			defaultColumn: { Filter: DefaultColumnFilter },
			initialState: {
				pageIndex: 0,
				pageSize: 1000,
				selectedRowIds: 0,
				sortBy: [
					{
						desc: true,
					},
				],
			},
		},
		useGlobalFilter,
		useFilters,
		// useSortBy,
		useExpanded,
		usePagination,
		useRowSelect
	);
    const navigate = useNavigate();
	const handleSelectChange = (e: any) => {
		const pageSize = parseInt(e.target.value);
		onPageSizeChange(pageSize); // Call the provided function with the new page size
	};
	const generateSortingIndicator = (column: any) => {
		return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
	};

	const onChangeInSelect = (event: any) => {
		setPageSize(Number(event.target.value));
		onChangePageSize(event.target.value)
	};

	const checkedAll = () => {
		const checkall: any = document.getElementById("checkBoxAll");
		const ele = document.querySelectorAll(".leadsCheckBox");

		if (checkall.checked) {
			ele.forEach((ele: any) => {
				ele.checked = true;
			});
		} else {
			ele.forEach((ele: any) => {
				ele.checked = false;
			});
		}
		// deleteCheckbox();
	};

	const [view, setView] = useState(false);
	const [edit, setEdit] = useState(false);
	const [deleteaccess, setDeleteAccess] = useState(false);
	const [create, setCreate] = useState(false);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

	useEffect(() => {
		let userRole = userProfile?.role?.title;
		if (userRole === "Owner") {
			setView(true)
			setEdit(true)
			setCreate(true)
			setDeleteAccess(true)
		}
		else {
			access && access.length && access.map((item: any, index: any) => {
				if (item.name) {
					switch (item.name) {
						case "View": item.value == "" || item.value == "none" ? setView(false) : setView(true)
							break
						case "Edit": item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
							break
						case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(false) : setDeleteAccess(true)
							break
						case "Create": item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
							break
					}
				}

			})
		}
	})

	
    const handleEditClick = (id: string) => {
		navigate(`/users/${id}`);
	};

	return (
		<Fragment>
			{
			!usersLoading ?
			page && page.length > 0?
			<div className={divClass}>
				<Table hover {...getTableProps()} className={tableClass}>
					<thead className={theadClass}>
						{headerGroups.map((headerGroup: any) => (
							<tr
								className={trClass}
								key={headerGroup.id}
								{...headerGroup.getHeaderGroupProps()}
							>
								{headerGroup.headers.map((column: any) => (
									<th
										key={column.id}
										className={thClass}
									>
										{column.render("Header")}
										{generateSortingIndicator(column)}
									</th>
								))}
								<th
										className={thClass}
									>Action</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row: any) => {
							prepareRow(row);
							return (
								<Fragment key={row.getRowProps().key}>
									<tr>
										{row.cells.map((cell: any) => {
											let classList = cell.column.className;
											return (
												<td
													key={cell.id}
													className={`${classList}`}
													{...cell.getCellProps()}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
										<td>
											<li className="list-inline-item">
												<UncontrolledDropdown>
													<DropdownToggle
														href="#"
														className="btn btn-soft-primary btn-sm dropdown"
														tag="button"
													>
														<i className="ri-more-fill"></i>
													</DropdownToggle>
													<DropdownMenu className="dropdown-menu-end">
														{view ?
															<DropdownItem className="dropdown-item" onClick={() => handleEditClick(row.original.id)}
															>
																<button className="btn btn-sm btn-soft-info remove-list fs-12"
																>
																	<i className="ri-eye-fill"></i>{" "}
																</button>{" "}
																{props.t("student.view")}
															</DropdownItem>
															: null}
														{edit ?
															<DropdownItem
																className="dropdown-item edit-item-btn fs-12"
																onClick={() => handleEditClick(row.original.id)}
															>
																<button className="btn btn-sm btn-soft-secondary remove-list"
																>
																	<i className="ri-pencil-fill"></i>{" "}
																</button>{" "}
																{props.t("student.edit")}
															</DropdownItem> : null}
														{deleteaccess ? (
															<DropdownItem
																disabled={
																	row.original.id === userProfile?.id ||
																	row.original.role?.title === "Owner"
																}
																onClick={() => {
																	setRecord(row.original);
																	setisDeleteOpen(true);
																}}
															>
																<button className="btn btn-sm btn-soft-danger remove-list"
																>
																	<i className="ri-delete-bin-fill"></i>{" "}
																</button>{" "}
																{props.t("student.delete")}
															</DropdownItem>
														) : null}
													</DropdownMenu>
												</UncontrolledDropdown>
											</li>
										</td>
									</tr>
								</Fragment>
							);
						})}
					</tbody>
				</Table>
				<Row className="align-items-center mt-2 g-3 text-center text-sm-start mb-2">
					<div className="col-sm">
						<div className="text-muted">
							{props.t("applications.showing")}
							<span className="fw-semibold ms-1">
							{data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
							</span>
						</div>
					</div> 
					<div className="col-sm-auto">
						<Paginator
							total={pagination && pagination.elements ? pagination.elements : 0}
							currentPage={pagination?.page}
							pageChanged={(e: any) => pageChanged(e)}
							pageSize={pageSize}
							isPageSizeChange={isPageSizeChange}
							onChangeInSelect={(e: any) => onChangeInSelect(e)}
							pagination={pagination}
							props={props}
							pages={pagesList}
						/>
					</div>
				</Row>
			</div>
			: 
			<Row className="text-center">
				<Col>
					<span className="fs-18 fw-semibold">No users to show</span>
				</Col>
			</Row>:
			<DtsTablePlaceholder 
					columns={columns} 
					theadClass={theadClass}     
					tableClass="align-middle table-nowrap"
					thClass="border-bottom-1 table-soft-primary"
				/>
			}
		</Fragment>
	);
};

TableContainer.propTypes = {
	preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
