import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
} from "reactstrap";
import { ApplicationState } from "store";
import { SetInvoceData } from "store/auth/action";

const PreviewModal: React.FC<any> = ({loading, onRemove,disabled, handleButtonClick, props,link, createRecommend,handleselect,mainList, show, onCloseClick, record, ontoggle, open, setrecord, selectedlist, setSelectedList, ChannelDetail }: any) => {  
    const {id} = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const studentDetails = useSelector((state: ApplicationState) => state.student.student);
    const navigate = useNavigate();
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    const createInvoice = () => {
        let details: any= studentDetails && studentDetails.valuesJson ? studentDetails.valuesJson : {};
        let student = {...details, student_id: id, phone_number: details.mobile}  
        let productlist =   selectedlist && selectedlist.length && selectedlist.map((item: any) => {
            return { ...item.products, id: item.id, commission: item.products.amount}
        })  
        dispatch(SetInvoceData(productlist, student))
        navigate("/students/invoices/create-invoice")
    }

    return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Review and Create
            </ModalHeader>
            <ModalBody>
              <div>
                    {!loading ? 
                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="task-list">
                                    {selectedlist && selectedlist.map((item: any, key: any) => {
                                      
                                        return <tr key={key}>
                                            <td>
                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(item, false)}
                                                            >
                                                    <i className="ri-delete-bin-fill"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <label >{item?.["products"]?.["name"]}</label>
                                            </td>
                                            <td>{item["products"]?.["description"]}</td>
                                            <td>
                                                £{item["products"]?.["amount"]}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            
                        </div>: <DtsTablePlaceholder 
                            rows={selectedlist.length} cols={5}                                     
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                    </div>
            </ModalBody>
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createInvoice()}
                        disabled={disabled}
                    >
                        {props.t("courses.create_invoice")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("courses.cancel")}
                    </Button>
                </div>
        </Modal>
    </>
    );
};

export default PreviewModal;
