import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { ApplicationsListGetRequest, applicationCreateRequest } from "store/applications/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";


const AddEntry = ({ props, show, onCloseClick, dataFields, studentId }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [statusMetaData, setStatusMetaData] = useState<any>([]);    

    const OnAddEntry = (formValues: any) => {
        let data = formValues;
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            onCloseClick()
            dispatch(ApplicationsListGetRequest());
        }
        const handleError = () => {

        }
        dispatch(applicationCreateRequest(studentId, data, handleSuccess, handleError))
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    
    useEffect(() => {
        let newstatusMetaData: any = []
        dataFields && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(item?.quickCreate) return newstatusMetaData.push(field)
        })
        let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student');
        setStatusMetaData(addEntryFields)
    }, [dataFields])

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >

            <OffcanvasHeader className="bg-light" toggle={() => { onCloseClick(!show) }}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card>
                    <CardBody>
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={statusMetaData} btnAction={OnAddEntry} btn_text={'Add Entry'} />
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

