import React, { useEffect, useRef, useState } from 'react';
import { FormBuilder } from '@formio/react';
import { Button, Container } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { createForm } from 'store/form/action';
import { Link, useNavigate } from 'react-router-dom';
import { ApplicationState } from 'store';
import styles from './FormBuilder.module.scss';

const FormCreationSection = () => {
    const navigate = useNavigate()
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const formData = useSelector((state: ApplicationState) => state.form.formData);
  const [form, setForm] = useState({});
  const onChange = (schema: any) => {
    setForm(schema);
  };


  const onCreate = () => {
    dispatch(createForm(form))
  };

  const onPreview = () => {
    navigate('/form')
  };

  useEffect(() => {
    setForm(formData)
  },[])

return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid>    
                <div 
                    className={styles.formbuilderstyle}
                >                    
                    <FormBuilder form={form} onChange={onChange}  />   
                </div>                                   
                <div className='hstack justify-content-end gap-2'>
                    <div>
                        <Button onClick={onCreate} className="btn btn-primary w-md mb-3">Create Form</Button>
                    </div>
                    <div>
                        <Button onClick={onPreview} className="btn btn-success w-md mb-3" disabled={Object.keys(formData).length == 0}>Preview</Button>
                    </div>
                </div>
            </Container>
        </div>
    </React.Fragment>
);
}

export default FormCreationSection;