import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import {productCreateRequest } from "store/products/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";

const AddEntry = ({ props, show, onCloseClick, dataFields, setTriggerApi }: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  
    
    const OnAddEntry = (formValues: any) => {
        let data: any = formValues;
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            onCloseClick()
        }
        dispatch(productCreateRequest(data, handleSuccess , () => {}))
    }


    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}            
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("product.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody>
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry}  btn_text={'Add Entry'} formfor="products" />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

