import React, { useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Label,
  Input,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import classnames from "classnames";
import BasicInfo from "./components/BasicInfo";
import FieldType from "./components/FieldType";
import RulesEdit from "./components/RulesEdit";
const EditProperty = () => {
  document.title = "Wizard | Zilter";
  const [justifyTab, setjustifyTab] = useState<any>("1");
  const justifyToggle = (tab: any) => {
    if (justifyTab !== tab) {
      setjustifyTab(tab);
    }
  };
  const GroupOptions = [
    {
      label: "Text Input",
      options: [
        { label: "Single-line text", value: "single-line-text" },
        { label: "Multi-line text", value: "multi-line-text" },
        { label: "Phone number", value: "phone-number" },
      ],
    },
    {
      label: "Choosing options",
      options: [
        { label: "Single checkbox", value: "single-checkbox" },
        { label: "Multiple checkbox", value: "multiple-checkbox" },
        { label: "Dropdown select", value: "dropdown-select" },
        { label: "Radio select", value: "radio-select" },
        { label: "Datepicker", value: "datepicker" },
      ],
    },
  ];

  const [isRight, setIsRight] = useState<boolean>(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  return (
    <>
      <div className="hstack flex-wrap justify-content-end gap-2">
        <Button
          className="btn-sm"
          color="secondary"
          onClick={toggleRightCanvas}
        >
          Edit property
        </Button>
      </div>
      <Offcanvas
        className=""
        direction="end"
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
      >
        <OffcanvasHeader
          toggle={toggleRightCanvas}
          id="offcanvasRightLabel"
          className="bg-info bg-gradient p-3 offcanvas-header-dark"
        >
          <span className="text-white">Edit property</span>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="mb-2">
            <Label className="hstack py-1 form-label">Label *</Label>
            <div className="d-flex align-items-center gap-2">
              <Input className="form-control=lg" value="Alternate Number" />
              <Button id="PopoverBottom" className="btn-sm" color="light">
                <i id="tooltipLeft" className="ri-code-s-slash-line"></i>
              </Button>
              <UncontrolledTooltip placement="left" target="tooltipLeft">
                {" "}
                If you are using this property for an integration, you can
                access its internal name here.{" "}
              </UncontrolledTooltip>
              <UncontrolledPopover placement="bottom" target="PopoverBottom">
                <PopoverBody>
                  <p>
                    This unique identifier will be used when syncing with
                    integration partners. Once created it can't be edited.
                  </p>
                  <div>
                    <Label>Internal name</Label>
                    <Input className="form-control=lg" />
                  </div>
                  <div className="d-flex p-2 gap-2">
                    <Button size="sm">Save</Button>
                    <Button size="sm">Cancel</Button>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          </div>
          {/* <Col xxl={6}>
            <h5 className="mb-3">Justify Tabs</h5> */}
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs nav-justified mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyTab === "1" })}
                    onClick={() => {
                      justifyToggle("1");
                    }}
                  >
                    Basic Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyTab === "2" })}
                    onClick={() => {
                      justifyToggle("2");
                    }}
                  >
                    Field Type
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyTab === "3" })}
                    onClick={() => {
                      justifyToggle("3");
                    }}
                  >
                    Rules
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={justifyTab} className="text-muted">
                <TabPane tabId="1" id="base-justified-home">
                  <BasicInfo />
                </TabPane>

                <TabPane tabId="2" id="product">
                  <FieldType />
                </TabPane>

                <TabPane tabId="3" id="base-justified-messages">
                  <RulesEdit />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          {/* </Col> */}
        </OffcanvasBody>
        <div className="bg-info bg-opacity-10 offcanvas-foorter fs-16 align-items-center border p-3 d-flex justify-content-between">
          <Button className="fs-16" color="info" type="submit">
            Save
          </Button>

          <div className="d-flex gap-3 align-items-center">
            <Button
              onClick={toggleRightCanvas}
              className="text-danger fw-bold w-auto"
              color="danger"
              outline
            >
              Cancel
            </Button>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default EditProperty;
