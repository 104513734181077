import { useEffect, useState } from 'react';
import { Card, Col, Input, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import { registerPlugin } from 'react-filepond';
import AddEntryModal from './innerComponent/AddEntryModal';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import CoursesPreview from './innerComponent/CoursesPreview';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { CourseListGetRequest, CoursesGetRequest, courseDelete, courseGetRequest, coursesMetadataGetRequest } from 'store/course/action';
import AccessDenied from '../../../AccessDenied/Denied';
import { CreateRecommendations, recommendApplicationList } from 'store/recommendation/action';
import { toast } from 'react-toastify';
import QuickFilter from 'Components/Common/Filter/QuickFilter';


const Courses = (props: any) => {
    const {id} = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);    
    const coursesMetadata = useSelector((state: ApplicationState) => state.courses.coursesMetadata);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const coursesList = useSelector((state: ApplicationState) => state.courses.courses);
    const paginationDetails = useSelector((state: ApplicationState) => state.courses.pagination);
    const loading = useSelector((state: ApplicationState) => state.courses.loading);
    const recommendLoading = useSelector((state: ApplicationState) => state.recommend.loading);
    const dataloading = useSelector((state: ApplicationState) => state.courses.dataloading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);    
    const [link, setLink] = useState<any>(null);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])
    const [filterCount, setFilterCount] = useState<any>(0)
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [linktrue, setlinktrue] = useState<boolean>(false)
    const [disabled, setdisabled] = useState<boolean>(false)
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [shortListed, setShortListed] = useState<any>([])
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [metaData, setMetaData] = useState(coursesMetadata);
    const [courses, setCourses] = useState([]);
    const [coursesJson, setcoursesJson] = useState<any>([]);
    const [selectedlist, setSelectedList] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] = useState<any>({});
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [checkedRows, setCheckedRows] = useState<any>({}); 
    const [filter, setFilter]: any = useState(null);
    const [selectedSearchField, setSelectedSearchField]: any = useState({value: 'all', label: 'All'});
   
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value })
    }

    const handleButtonClick = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast('URL copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
            setpreview(false);
            setLink(false)
        }).catch(err => {
           
        });
    };

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(coursesMetadataGetRequest("courses" ,filters));
    }, [])

    useEffect(() => {
        setMetaData(coursesMetadata);
    }, [coursesMetadata]);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.courses;
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(courseGetRequest(lead.id, ))
    };
    useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
	
		// Clear timer and cancel request on unmount
		const cleanup = () => {
			controller.abort("New request");
			clearTimeout(timer);
		};

        // Main logic wrapped in a timer
		const timer = setTimeout(() => {
            const filtersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

			// Add search filter
			if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
			}
            dispatch(CoursesGetRequest(filtersData, pagination.page, pagination.size, signal));
        }, 600);
		// Cleanup on unmount or effect rerun
		return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder])


    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Course Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(CourseListGetRequest())
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(courseDelete(record.id, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }
    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };  
    const capitalizeFirstLetterOfEachWord = (input: any) => {
        const exceptions = ['and', 'or', 'the', 'a', 'an', 'but', 'for', 'nor', 'on', 'at', 'to', 'by', 'with']; // Add any other exceptions you need
        return input.split(' ').map((word: any, index: any) => {
            if (exceptions.includes(word.toLowerCase()) && index !== 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        }).join(' ');
    }  
    const handlelevel = (levelvalue: any) => {
        let level = ""
        switch(levelvalue) {
            case 1:
            case "1":
            case "1.0": level ='Undergraduate';            
                    break
            case 2:
            case "2":
            case "2.0": level ='Postgraduate';            
                    break
            case 3:
            case "3":
            case "3.0": level ='Foundation';            
                    break
            case 4:
            case "4":
            case "4.0": level ='Research';            
                    break  
            default: level ='NA';            
                    break  
        }
        return level;
    }

    const handleselect = (obj: any, value: boolean) => {
        if(value) {
            let newList = selectedlist;
            newList.push(obj)
            setlinktrue(true)            
            setSelectedList(newList)
            let newshortListed = shortListed;
            newshortListed.push(obj.id)
            setShortListed(newshortListed)
            handleCheckboxChange(obj.id, true)
            setdisabled(!newshortListed.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);    
            if(!newList.length)  {                
                setlinktrue(false) 
            }      
            setSelectedList(newList)
            let newshortListed = shortListed.filter((item: any) => item !== obj.id);    
            setShortListed(newshortListed)            
            handleCheckboxChange(obj.id, false)
            setdisabled(!newList.length)
        }
    }
    const handleCheckboxChange = (id: any, value: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: value }));
    };

    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName) => {
                let fields =  fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {}    
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;     
                if(fields.tablecolumn) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: true,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if(fields.key =='course_type' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
                                return <div className='ms-3'>
                                        {handlelevel(cellProps.row.original[fields.key])}
                                    </div>
                            }
                           else if(fields.key =='course_link' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
                                    return <div className='ms-3'>
                                            <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.key])}/>
                                        </div>
                            }
                            else if(fields.key =='department_name' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
                                return <div className='ms-3'>
                                        {capitalizeFirstLetterOfEachWord(cellProps.row.original[fields.key])}
                                    </div>
                            }                            
                            else if(fields.key =='intake' && cellProps.row.original?.[fields.key] && cellProps.row.original?.[fields.key] !== "NA") {
                                let value = cellProps.row.original?.[fields.key]
                                let intakearray = value.split(",")
                                return <div>
                                    {intakearray?.length && intakearray.map((item: any) => {                                          
                                        return <div className='ms-0'>
                                            <span className="badge bg-success">{handleselectValue(item, fields.values)}</span>
                                        </div>
                                    })}
                                </div>
                            }
                            else if(fields.key =='institute_group' && cellProps.row.original?.[fields.key] && cellProps.row.original?.[fields.key] !== "NA") {
                                let value = cellProps.row.original?.[fields.key]
                                let institute_group = value.split(",")
                                return <div>
                                    {institute_group?.length && institute_group.map((item: any) => {                                          
                                        return <div className='ms-0'>
                                            {item}
                                        </div>
                                    })}
                                </div>
                            }                               
                            else if (fields.optionLabel) {
                                rendervalue = cellProps?.row?.original?.[fields.model]?.[fields.optionLabel] ? cellProps?.row?.original?.[fields.model]?.[fields.optionLabel] : 'NA'
                            } 
                            else  if(fields.type=='select' || fields.type=='radio' || fields.type=='checboxes'){
                                    rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                }
                                return  <div className="d-flex align-items-center justify-content-between">                                      {
                                                fields.key == "course_name" ? <Row style={{width: 300}} className='justfy-content-start align-items-center'>                                           
                                                    <Col md={12} lg={12} className={'gap-3 hstack justify-content-start'}>                                                    
                                                         
                                                        <Link to={`/courses/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >                                            
                                                            {cellProps.row.original[fields.key]}
                                                        </Link>
                                                    </Col>                                           
                                                </Row> : 
                                                <div>
                                                    {rendervalue && rendervalue !== 'NA' ? rendervalue : ""}
                                                </div>
                                            }
                                        </div>
                            
                        }
                    }
                    columns.push(newColumn)
                }   
            });
            const nameColumnIndex = columns.findIndex(column => column.accessor === "course_name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            columns.push({
                Header:"Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                    ''}
                        </div>
                }
            })
        }
        if (metaData && metaData.length > 0) {
            let datafields: any = [];
            metaData.forEach((fieldName) => {
                let field =  fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {} ;
                if(field.filter && field.type === 'textfield') {
                    datafields.push({ label : field.label, value:field.key })
                }
            })
            setFieldOptions(datafields)
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile]);

    useEffect(() => {
        setMetaData(coursesMetadata)
    }, [coursesMetadata != undefined])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setSearch(value);
        const filters = { ...filterObject, search: value }
        if(value == "") {
            delete filters['searchin']
        }
        setFilterObject(filters)


        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(CourseListGetRequest(filters));
            setcoursesJson([])
        }, 2000);

        setTypingTimeout(newTimeout);

    };

    const clearFilter = () => {
        setSearch("")
        setPagination({
            size: pagination.size,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        })
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'course_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    const execute = () => {
        let a = isEqual(coursesList, coursesJson)
        return !a
    }

    useEffect(() => {
        const parsedData: any = coursesList && coursesList.length ? coursesList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            const newitem = item?.institutes && isJson(item?.institutes) ? JSON.parse(item?.institutes): {};
            let values = { ...valuesJson, institutes: newitem, id: item.id, createdAt: item.createdAt, }
            return values
        }) : []
        let courses = parsedData && parsedData.length && parsedData.reverse()
        setCourses(courses)
        setcoursesJson(coursesList)
    }, [execute()])


    const onChangeIndex = (column: any, index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }
    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
            let filters = { ...filterObject, sortBy: columnName, sortOrder: sort }
            setFilterObject(filters)    
            dispatch(CourseListGetRequest(filters, pagination.page));
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
            let filters = { ...filterObject, sortBy: columnName, sortOrder: 'asc' }
            setFilterObject(filters)    
            dispatch(CourseListGetRequest(filters, pagination.page));
        }
    };

    document.title = "DTS | Zilter";

    useEffect(() => {
        if(selectedlist && selectedlist.length > 0) {

            setlinktrue(true)
        }
        else {
            setlinktrue(false)
        }
    }, [selectedlist])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;            
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {                        
                       if(item.name.toLowerCase() == 'create') {
                    item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const createRecommend = () => {
        setdisabled(true)
        const course_name = shortListed.join(",")
        const data = {
            student: id,
            course_name: course_name
        }
        const handleSuccess = (body: any) => {
            // const message = () => toast(`Shortlisted courses sent Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setCheckedRows({})  
            setShortListed([])
            setSelectedList([])
            setlinktrue(false)
            setLink(body.url)               
            dispatch(recommendApplicationList(id));     
        }
        const handleError = () => {
    
        }
        dispatch(CreateRecommendations( data, handleSuccess ,handleError))
      }

    return (
        <>
        {
            viewAccess ?
    <div>
        

        <CoursesPreview
            show={previewModal}
            onCloseClick={() => {setpreview(false); setLink(false);}}
            selectedlist={selectedlist}
            setSelectedList={setSelectedList}
            handleselect={handleselect}
            props={props}
            createRecommend={createRecommend}
            link={link}
            handleButtonClick={handleButtonClick}
            onRemove={handleselect}
            disabled={disabled}
            recommendLoading={recommendLoading}
        />
        <EditModal
            show={editModal}
            onCloseClick={() => setEditOpen(false)}
            onApplyClick={onApplyClick}
            onchange={onchange}
            columns={columns}
            visibleColumns={visibleColumns}
            toggleColumn={toggleColumn}
            onClickmove={onClickmove}
            deSelectColumnall={deSelectColumnall}
            onChangeIndex={onChangeIndex}
            tempcolumns={tempcolumns}
            setTempColumns={setTempColumns}
        />
        <AddEntryModal
            show={iscreateModal}
            onCloseClick={() => setCreateOpen(false)}
            dataFields={metaData}
            props={props}
        />
        <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />

        <Row>
            <Col xl={12} lg={12} md={12}>
                <div>
                    <Card className='p-3'>
                        <Row className='hstack gap-3'>
                        <Col md={4} sm={12} xl={4} xxl={4} lg={4}>
                            <InputGroup className='border rounded'>
                                <ButtonGroup>
                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-light">
                                            {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => setSelectedSearchField({value: 'all', label: 'All'})}>All</DropdownItem>
                                            <DropdownItem divider />
                                            {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ButtonGroup>
                                <Input className='border-0' placeholder="Search..." style={{padding: '1.2rem 0.9rem'}} onChange={onSearchHandle} value={search}></Input>
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className=''>
                            {metaData && metaData.length ? 
                                <QuickFilter
                                    dataFields={metaData}
                                    columns={columns}
                                    translation={props}
                                    onFilter={onFilter}
                                    clearFilterParent={clearFilter}
                                /> : ""}
                        </Row>
                        {
                            linktrue ?                        
                            <Row>
                                <Col md={4} lg={4} xl={4}>
                                    <Button className="btn-sm" onClick={() => setpreview(true)}>
                                        {props.t("student.shortlisted")}
                                    </Button>
                                </Col>
                            </Row>
                            : null
                        }
                        <Row className='students-table mt-2'>
                            <TableContainer
                                columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                data={courses || []}
                                customPageSize={pagination.size}
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                isPageSizeChange={true}
                                handleLeadClick={handleLeadClick}
                                setisDeleteOpen={setisDeleteOpen}
                                setIsMultiDeleteButton={setIsMultiDeleteButton}
                                setselectedcount={setselectedcount}
                                pageChanged={pageChanged}
                                pagination={pagination}
                                pagesList={pagesList}
                                onChangePageSize={onChangePageSize}
                                pageSize={pagination.size}
                                setpreview={setpreview}
                                handleSort={handleSort}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                props={props}
                                access={access}
                                handleselect={handleselect}
                                selectedlist={selectedlist}
                                checkedRows={checkedRows}
                                loading={loading}
                                dataloading={dataloading}
                                create={create}

                            />
                        </Row>
                    </Card>
                </div>
            </Col>
        </Row>
    </div>
    : <AccessDenied />
    }
    </>
    );
};
export default withTranslation()(Courses);


