import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledDropdown,
} from "reactstrap";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import classnames from "classnames";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import dataFields from "./RoleFields.json";
import TableContainer from "./TableComponent";
import _ from "lodash";
import BreadCrumb from "Components/Common/BreadCrumb";
import { WithTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { roleDeleteRequest, roleListGetRequest, permissionsGetRequest, roleGetRequest } from "store/role/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ApplicationState } from "store";
import { ToastContainer, toast } from "react-toastify";
import SimpleBar from "simplebar-react";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface permissionData {
    permissionId: string,
    value: string;
    item: any;
}


const Roles: React.FC<any> = ({onCloseClick, onApply, props, permissionInputs, record}: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const roleList = useSelector((state: ApplicationState) => state.role.list);
    const roleDetails = useSelector((state: ApplicationState) => state.role.roleDetails);
    const paginationDetails = useSelector((state: ApplicationState) => state.role.pagination);
    const permissionsdata = useSelector((state: ApplicationState) => state.role.permissionsdata);
    const [customActiveTab, setcustomActiveTab] = useState<any>("1");
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [columns, setColumns] = useState<any>([]);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [updateId, setUpdateId] = useState<any>("");
    const [sortOrder, setSortOrder] = useState<string>("")
    const [isIconUp, setIsIconUp] = useState(false);
    const [predefinedPermissions, setPredefinedPermissions] = useState([]);
    const [rolesPermissions, setRolesPermissions] = useState<any>([]);
    const [PreAccessPermissions, setPreAccessPermissions]= useState<permissionData[]>([]);
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [filterObject, setFilterObject] = useState({
        pageSize: 10
    })
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const handleChange = (e: any) => {
        let value = e.target.value
        setsearchvalue(value);
        const filters = {...filterObject, search: e.target.value}
        setFilterObject(filters)                  
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(roleListGetRequest(filters));
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)  
        dispatch(roleListGetRequest(filters, 0));
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(roleListGetRequest(filterObject, newPageNo));
    };
          
    const toggleIcon = () => {
        setIsIconUp((prevIsIconUp) => !prevIsIconUp);
    };

    useEffect( () => {     
        const fetchData = async () => {
            await Promise.all([
                // dispatch(permissionsGetRequest()),
                dispatch(roleListGetRequest())
            ]);
        };   
        fetchData();
        let columns: any = [];
        const fieldsCols: any =
            dataFields && dataFields.fields
                ? dataFields.fields.map((fields, index) => {
                    if (fields.name == "id")
                        return {
                            Header: _.startCase(fields.name),
                            accessor: fields.name,
                            filterable: true,
                            isSortable: false,
                            disableFilters: true,
                            show: false,
                        };
                    else if (fields.name == "title")
                        return {
                            Header: _.startCase(fields.name),
                            accessor: fields.name,
                            isSortable: true,
                            isSorted: true,
                            filterable: true,
                            disableFilters: true,
                            show: true,
                            Cell: (cellProps: any) => {
                                return (
                                    <div className="hstack multi-item">
                                        <span>{cellProps.row.original.title}</span>
                                    </div>
                                );
                            },
                        };
                    
                })
                : [];
        columns.push.apply(columns, fieldsCols);
        setColumns(columns);
    }, []);

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}   
          setFilterObject(filters)   
          dispatch(roleListGetRequest(filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(roleListGetRequest(filters, pagination.page));
        }
      };


    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    
    const updateObjectInHierarchy = (array: any, updatedObject: any) => {      
        if (array && array.length) {
            return array.map((item: any) => {
                if (item.children && item.children.length > 0) {
                    const updatedChildren = updateObjectInHierarchy(item.children, updatedObject);
                    return { ...item, children: updatedChildren };
                } 
                else if (item.permissions && item.permissions.length > 0) {
                    
                    const updatedPermissions = item.permissions.map((permission: any) => {
                        if (permission.id === updatedObject.id) {
                            let newObject = {...permission, value: updatedObject.value}
                            return { ...permission, ...newObject};
                        }
                        let newupdated = { ...permission, value: null };
                        return newupdated
                    });
                    
                    // Check if any child permission was updated to true
                    const isChildUpdatedToTrue = updatedPermissions.some((permission: any) => permission.value === 'true');
                    // If any child permission was updated to true, update the parent permission
                    if (isChildUpdatedToTrue) {
                        return { ...item, permissions: updatedPermissions, value: 'true' };
                    }
                } 
                else if (item.permissions && !item.permissions.length) {
                    
                        if (item.permissions.id === updatedObject.id) {
                            let newObject = {...item.permissions, value: updatedObject.value}
                            return { ...item.permissions, ...newObject};
                        }
                        else {
                            let newObject = {...item.permissions, value: null}
                            return { ...item.permissions, ...newObject};
                        }
                }
                else if (item.id === updatedObject.id) {
                            let newObject = {...item, value: updatedObject.value}
                            return { ...item, ...newObject};
                }
                return { ...item, value: null };
            });
        }
        return array;
    };
    const updateObjectInHierarchyToNull = (array: any) => {      
        if (array && array.length) {
            return array.map((item: any) => {
                if (item.children && item.children.length > 0) {
                    const updatedChildren = updateObjectInHierarchyToNull(item.children);
                    return { ...item, children: updatedChildren };
                } 
                else if (item.permissions && item.permissions.length > 0) {                    
                    const updatedPermissions = item.permissions.map((permission: any) => {                        
                        if (permission.type) {
                            let newObject = {...permission, value: null}
                            return { ...permission, ...newObject};
                        }  
                        if(permission && permission.permission && permission.permission.length) {
                            let newObject = {...permission, value: null}
                            return { ...permission, ...newObject};
                        }                      
                    });
                    return { ...item, permissions: updatedPermissions};
                } 
                else if (item.permissions && !item.permissions.length) {
                    let newObject = {...item.permissions, value:null}
                    return { ...item.permissions, ...newObject};
                }
                else if (item.type) {
                    let newObject = {...item, value: null}
                    return { ...item, ...newObject};
                }
                return { ...item, value: null };
            });
        }
        return array;
    };
    

    useEffect(() => {        
        if (roleDetails && roleDetails.categoriesWithPermission && roleDetails.categoriesWithPermission.length) {  
            setPredefinedPermissions(roleDetails.categoriesWithPermission);
        }
    }, [roleDetails]);

    useEffect(() => {
        let newpermissions = record?.original?.permissionsJson;
        let newrolespermissions: any = []
        newpermissions && newpermissions.item && newpermissions.item.length && newpermissions.item.map((subitem: any) => {     
            newrolespermissions.push(subitem)   
        });    
        setRolesPermissions(newrolespermissions)
    }, [record])

    const onChange = (option: any, row: any) => {
        const rolepermissions: any = {
            "role_id": row.original.id,
            "permission": option.value
        };
        
        handleCheckboxChange( row.original.id, option)
        
        // Remove existing entry with the same role_id
        const newRolesPermissions = rolesPermissions.filter((item: any) => item.role_id !== row.original.id);
    
        // Add the new permission entry
        newRolesPermissions.push(rolepermissions);        
        setRolesPermissions(newRolesPermissions);
    };

    const handleCheckboxChange = (id: any, option: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: option }));
    };
    return (
        <>
            <div className="d-flex justify-content-end mb-3">
                <div className="d-flex gap-3 mb-3">
                    <div className="search-box">
                        <Input
                            type="text"
                            size={30}
                            className="search sm"
                            placeholder={props.t("roles.search_for_role")}
                            onChange={(e) => handleChange(e)}
                            value={searchvalue}
                        />
                        <i className="ri-search-line search-icon"></i>{" "}
                    </div>
                </div>
            </div>
            {/* <SimpleBar style={{maxHeight: 300}}> */}
                <div className="students-table mb-5">
                    <TableContainer
                        columns={columns}
                        data={roleList || []}
                        customPageSize={pageSize}
                        // setrecord={setrecord}
                        setisDeleteOpen={setisDeleteOpen}                        
                        divClass="table-responsive"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                        thClass="border-bottom-1 table-soft-primary"
                        SearchPlaceholder='Search...'
                        isPageSizeChange={true}
                        isBordered={true}
                        pageChanged={pageChanged}
                        pagination={pagination}
                        setPagination={setPagination}
                        pagesList={pagesList}
                        onChangePageSize={onChangePageSize}
                        pageSize={pageSize}
                        isIconUp={isIconUp}
                        handleSort={handleSort}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        props={props}
                        setRolesPermissions={setRolesPermissions}
                        rolesPermissions={rolesPermissions}
                        onCloseClick={onCloseClick}
                        onApply={onApply}
                        permissionInputs={permissionInputs}
                        onChange={onChange}
                        checkedRows={checkedRows}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </div>
            {/* </SimpleBar> */}
        </>
    );
};
export default withTranslation()(Roles);
