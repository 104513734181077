import { Reducer } from "redux";
import { ApplicationsActionTypes, ApplicationsState } from "./types";


export const initialState: ApplicationsState =
{
    applicationMetadata: [],
    applications: null,
    applicationDetails: null,
    pagination: null,
    applicationlist: [],
    emails:[],
    notes: [],
    tasks: [],
    loading: false,
    dataloading: false,
    metaLoading: false,
}
const reducer: Reducer<ApplicationsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ApplicationsActionTypes.GET_APPLICATIONSMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case ApplicationsActionTypes.GET_APPLICATIONSMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                applicationMetadata: action.payload.content
            };
        }
        case ApplicationsActionTypes.GET_APPLICATIONSMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }

        case ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                emails: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    pageable: action.payload.pageable,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ApplicationsActionTypes.GET_APPLICATION_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_APPLICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                applicationDetails: action.payload
            };
        }
        case ApplicationsActionTypes.GET_APPLICATION_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case ApplicationsActionTypes.POST_APPLICATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.POST_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ApplicationsActionTypes.POST_APPLICATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

              
        case ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ApplicationsActionTypes.GET_APPLICATIONS_REQUEST: {
            return { ...state, dataloading: true };
        }
        case ApplicationsActionTypes.GET_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                applicationlist: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ApplicationsActionTypes.GET_APPLICATIONS_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.PUT_APPLICATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.PUT_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                application: action.payload.content
            };
        }
        case ApplicationsActionTypes.PUT_APPLICATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.DELETE_APPLICATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.DELETE_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ApplicationsActionTypes.DELETE_APPLICATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        

        case ApplicationsActionTypes.GET_NOTE_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_NOTE_SUCCESS: {
            return {
                ...state,
                loading: false,
                note: action.payload
            };
        }
        case ApplicationsActionTypes.GET_NOTE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.GET_NOTES_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_NOTES_SUCCESS: {
            return {
                ...state,
                loading: false,
                notes: action.payload.content
            };
        }
        case ApplicationsActionTypes.GET_NOTES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.DELETE_NOTE_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.DELETE_NOTE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ApplicationsActionTypes.DELETE_NOTE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.GET_TASK_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_TASK_SUCCESS: {
            return {
                ...state,
                loading: false,
                task: action.payload
            };
        }
        case ApplicationsActionTypes.GET_TASK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.GET_TASKS_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.GET_TASKS_SUCCESS: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                loading: false,
                tasks: action.payload.content,
                pagination: {
                    page,
                    total,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    offset: action.payload.pageable.offset

                }
            };
        }
        case ApplicationsActionTypes.GET_TASKS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ApplicationsActionTypes.DELETE_TASK_REQUEST: {
            return { ...state, loading: true };
        }
        case ApplicationsActionTypes.DELETE_TASK_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ApplicationsActionTypes.DELETE_TASK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as ApplicationsReducer };
