import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import SimpleBar from "simplebar-react";
import currencyOptions from "./currency.json"

const NumberPreview = ({ setEditInputs,  EditInputs, fieldType }: any) => {
    const [value, setvalue] = useState<string>('12365.67')
    const [formatednumber, setFormated] = useState<string>('12365')
    const [hover, setHover] = useState<any>(false)
    const [selectedFormats, setFormats] = useState<any>(
        {
            label: "Formatted number",
            subtext: "Format your property as number",
            value: "formatted"
        }
    )
    const [selectedCurrency, setCurrency] = useState<any>({
        "name": "India",
        "code": "IN",
        "currency": "Indian Rupee",
        "currency_code": "INR",
        "currency_symbol": "₹"
    })
    const [currencyvalue, setcurrencyvalue] = useState<any>()
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [searchedcurrency, setsearchedcurrency] = useState<any>()
    const formatNumber = (numbervalue: any) => {   
        let number = parseFloat(numbervalue);
        if (!isNaN(number)) {
            let formattedNumber = number.toLocaleString();
            setFormated(formattedNumber);
            return formattedNumber;
        }
        return numbervalue
    }

    const onChangevalue = (value: any) => {  
        setvalue(value)
    }

    const handleselected = (option: any, name: string, ) => {
        switch(name) {
            case 'number-format': setFormats(option);
                                setEditInputs({...EditInputs, numberformat: option.value})
                                break;

            case 'currency': setCurrency(option);
                            setEditInputs({...EditInputs, currency: option.value})
                            break;
        }
    }

    const numberFormats = [
        {
            label: "Formatted number",
            subtext: "Format your property as number",
            value: "formatted"
        },
        {
            label: "Unformatted number",
            subtext: "Remove formatting from property",
            value: "unformatted"
        },
        {
            label: "Currency",
            subtext: "Format your property as currency",
            value: "currency"
        },
        {
            label: "Percentage",
            subtext: "Format your property as percentage",
            value: "percentage"
        }
    ]

    const handleChange = (searchvalue: any) => {
        let value = searchvalue.replace(/\+/g, '');
        setsearchvalue(value)
        const updatedData = currencyOptions.filter((item: any) => {
            return (
                item.name.toLowerCase().search(value.toLowerCase()) !== -1
                || item.code.toLowerCase().search(value.toLowerCase()) !== -1
                || item.currency_symbol.search(value) !== -1
            );
        });
        setsearchedcurrency(updatedData);
    }

    const toggle = () => setDropdownOpen((prevState) => !prevState);
   
    useEffect(() => {
        let formattedValue = value;
        if (selectedFormats.value === 'formatted') {
            formattedValue = formatNumber(value);
        } else if (selectedFormats.value === 'currency') {
            let appendcurrency = selectedCurrency.currency_symbol ? selectedCurrency.currency_symbol : '';
            formattedValue = `${appendcurrency} ${formatNumber(value)}`;
        } else if (selectedFormats.value === 'percentage') {
            formattedValue = `${formatNumber(value)}%`;
        }
        setFormated(formattedValue);
   }, [value, selectedFormats, selectedCurrency]);



    console.log("return formatednumber", formatednumber)
    return (
        <div>
            <Row>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-semibold">Number Format</Label>                            
                        <Select
                            defaultOptions
                            placeholder={'Select field type'}
                            className="fs-16 fw-medium"
                            value={selectedFormats}
                            onChange={(option : any) => handleselected(option, 'number-format')}
                            options={numberFormats}
                            name="choices-single-default"
                        ></Select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="d-flex flex-column justify-content-center">    
                {
                    selectedFormats && selectedFormats.value == 'currency' ? <>
                        <Label className="fs-16 fw-semibold mt-2">Currency</Label>  
                        <div className="hstack justify-content-between">
                            <Dropdown className="w-100" isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle as="button" className="hstack btn text-left bg-light border arrow-none w-100 bg-opacity-0">
                                {selectedCurrency && selectedCurrency.name ? <>
                                    <span className="countrylist-codeno text-dark">{selectedCurrency.name}</span>&nbsp;
                                <span className="countrylist-codeno text-dark">{selectedCurrency.code}</span>&nbsp;
                                <span className="countrylist-codeno text-dark">{selectedCurrency.currency_symbol}</span>
                                </> : null}
                            </DropdownToggle>
                            <DropdownMenu as='ul' className="list-unstyled w-100 dropdown-menu-list mb-0">
                                <SimpleBar style={{ maxHeight: "220px" }} className="px-3">
                                    <div className="search-box">
                                        <Input
                                            type="text"
                                            size={14}
                                            className="search"
                                            placeholder="Search for..."
                                            onChange={(e) => handleChange(e.target.value)}
                                            value={searchvalue}
                                        />
                                        <i className="ri-search-line search-icon"></i>{" "}
                                    </div>
                                    {(searchedcurrency || []).map((item: any, key: any) => (
                                        <DropdownItem as='li' onClick={() => setCurrency(item)} key={key} className="dropdown-item d-flex">                                                    
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="country-name me-1">{item.name}</div>&nbsp;
                                                    <span className="countrylist-codeno text-muted">{item.code}</span>&nbsp;
                                                    <span className="countrylist-codeno text-muted">{item.currency_symbol}</span>
                                                </div>
                                            </div>
                                        </DropdownItem>
                                    ))}
                                </SimpleBar>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    </>
                : null
        }
                </Col>
            </Row>
            <div id="myDivparent">
                <div  className="fs-16 mt-2 fw-semibold">Preview</div>
                <Card className="mb-3 mt-3 p-3" id="myDivChild">
                    
                    <Label className="fs-16 fw-semibold">{EditInputs.label}</Label>    
                    { hover ? <div className="hstack justify-content-between input-step step-info">
                        <Input
                            type={"number"}
                            name={"label"}
                            value={value}
                            className="fs-16 fw-semibold w-100 h-100 text-start"
                            onChange={(e) => onChangevalue(e.target.value)}                                                         
                        /> 
                        <div className="hstack gap-2">
                        <Button
                            type="button"
                            color="danger"
                            className="minus"
                        >
                            <FeatherIcon  onClick={() => {setHover(true); onChangevalue("")}} icon="x" className="icon-xs" /> 
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            className="plus"
                            onClick={() => setHover(false)}
                        >
                            <FeatherIcon  onClick={() => setHover(true)} icon="check" className="icon-xs cursor-pointer" /> 
                        </Button>
                    </div>
                        </div>
                        :
                        <div className="hstack text-dark justify-content-between">
                            <div>
                                {formatednumber}
                            </div>                        
                            <FeatherIcon  onClick={() => setHover(true)} icon="edit-2" className="icon-xs text-info cursor-pointer" />                                                     
                        </div>
                    }
                </Card>
            </div>
        </div>
    );
};

export default NumberPreview;
