import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import TemplateInfoEdit from './TemplateInfoEdit';
import { propertyListGetRequest } from 'store/properties/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

const OverviewTab = ({ info, metaData }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [editaccess, seteditaccess] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filterFields, setfilterFields] = useState([]);
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }

    const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    useEffect(() =>{
        let filterFields: any = []
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson   && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson : {}
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {}            
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            if (field.valuesJson && isFieldviewaccess) return filterFields.push(item)
        })
        setfilterFields(filterFields)
    }, [metaData])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            seteditaccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.templates;
            if(access && access?.length) {
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? seteditaccess(false)  : seteditaccess(true) 
                       }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])   

    const DetailsList = ({ label, value, item, index, type }: any) => {
        return (
            <React.Fragment>
                <Col md={12} lg={12} sm={6} Key={index}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className=" mb-1 fs-14 fw-bold">{label} :</p>
                                { type == "texteditor" ? <div dangerouslySetInnerHTML={{ __html: value }} />
                                : <h5 className="mb-0 fs-14">{value}</h5>}
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardHeader className='hstack align-items-center'>  
                        <Col  className='hstack justify-content-start p-0 ps-3 align-items-end'>
                            <h4>Template Information</h4>
                        </Col> 
                        <Col  className='hstack justify-content-end'>
                            {
                                    isEdit ? <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="danger"
                                    onClick={() => setIsEdit(false)}
                                    outline
                                >
                                    <i className="ri-close-fill"></i>{" "}
                                    Close
                                    {/* {props.t("channel.update")} */}
                                </Button> :   
                                editaccess ?                      
                                <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="primary"
                                    onClick={() => setIsEdit(true)}
                                >
                                    <i className="ri-pencil-fill"></i>{" "}
                                    Update
                                    {/* {props.t("channel.update")} */}
                                </Button> : null
                            }
                        </Col>                        
                        </CardHeader>
                        <CardBody>
                            <div className="text-muted">
                                <div className="border-top border-top-dashed p-3">
                                  {
                                    isEdit ? <TemplateInfoEdit /> :
                                    <Row className="gy-3">
                                        {filterFields && filterFields.length ? filterFields.map((item: any, key: any) => {
                                           return <DetailsList
                                                label={item.label}
                                                value={info[item.key]}
                                                item={item}
                                                key={key}
                                                type={item.type}
                                            />
                                        }): null}
                                    </Row>
                                  }
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;