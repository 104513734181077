export interface companiesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Company {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum CompanyActionTypes {
    GET_COMPANYMETADATA_REQUEST = "@@company/GET_COMPANYMETADATA_REQUEST",
    GET_COMPANYMETADATA_SUCCESS = "@@company/GET_COMPANYMETADATA_SUCCESS",
    GET_COMPANYMETADATA_ERROR = "@@company/GET_COMPANYMETADATA_ERROR",
    GET_COMPANY_REQUEST = "@@company/GET_COMPANY_REQUEST",
    GET_COMPANY_SUCCESS = "@@company/GET_COMPANY_SUCCESS",
    GET_COMPANY_ERROR = "@@company/GET_COMPANY_ERROR",
    GET_COMPANY_BANKS_REQUEST = "@@company/GET_COMPANY_BANKS_REQUEST",
    GET_COMPANY_BANKS_SUCCESS = "@@company/GET_COMPANY_BANKS_SUCCESS",
    GET_COMPANY_BANKS_ERROR = "@@company/GET_COMPANY_BANKS_ERROR",
    GET_COMPANIES_REQUEST = "@@company/GET_COMPANIES_REQUEST",
    GET_COMPANIES_SUCCESS = "@@company/GET_COMPANIES_SUCCESS",
    GET_COMPANIES_ERROR = "@@company/GET_COMPANIES_ERROR",
    PUT_COMPANY_REQUEST = "@@company/PUT_COMPANY_REQUEST",
    PUT_COMPANY_SUCCESS = "@@company/PUT_COMPANY_SUCCESS",
    PUT_COMPANY_ERROR = "@@company/PUT_COMPANY_ERROR",
    DELETE_COMPANY_REQUEST = "@@company/DELETE_COMPANY_REQUEST",
    DELETE_COMPANY_SUCCESS = "@@company/DELETE_COMPANY_SUCCESS",
    DELETE_COMPANY_ERROR = "@@company/DELETE_COMPANY_ERROR",
    POST_COMPANY_REQUEST = "@@company/POST_COMPANY_REQUEST",
    POST_COMPANY_SUCCESS = "@@company/POST_COMPANY_SUCCESS",
    POST_COMPANY_ERROR = "@@company/POST_COMPANY_ERROR",
    GET_COMPANIES_HIERARCHY_REQUEST = "@@company/GET_COMPANIES_HIERARCHY_REQUEST",
    GET_COMPANIES_HIERARCHY_SUCCESS = "@@company/GET_COMPANIES_HIERARCHY_SUCCESS",
    GET_COMPANIES_HIERARCHY_ERROR = "@@company/GET_COMPANIES_HIERARCHY_ERROR",
}

export interface CompanyState {
    readonly companies: Company[] | null;
    readonly company: Company | null;
    readonly pagination: Pagination | null;
    readonly companiesMetadata: companiesMetadata[];
    readonly companylist: any;
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly hierarchycompanies: any;
    readonly banks: any;
    readonly loading: boolean;
    readonly dataloading: boolean;
}
