import {WhatsappActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const whatsappsGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();      
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: WhatsappActionTypes.GET_WHATSAPPS_REQUEST
        });
        const response = await fetch(`${DTS_API_STUDENT}/whatsapp?studentId=${id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: WhatsappActionTypes.GET_WHATSAPPS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: WhatsappActionTypes.GET_WHATSAPPS_SUCCESS,
                payload: body_1
            });
        }
    };
};