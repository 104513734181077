import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const Setting = ({ props, show, onCloseClick, record, setRecord, onUpdateSetting }: any) => {
    const onChanged = (target: any, name: any) => {        
        if(name == "hidden") {
           let field = {...record, hidden: target.checked }
           setRecord(field)
        }
    }
    return (
            <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
                <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                    Field Settings
                </ModalHeader>
                <ModalBody className='text-center p-5'>
                    <div className="vstack gap-3">
                        <div>
                            <div className="fs-16 mb-2 fw-bold text-start">
                                Field visibility
                            </div>
                            <div className="hstack align-content-center gap-1 mb-1">
                                <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" checked={record.hidden} onClick={(e) => onChanged(e.target,'hidden')}/>
                                <Label className="fs-16 mb-0 fw-medium">Hide in Forms.</Label>
                            </div>
                        </div>
                        <div className="hstack gap-2 mt-1 justify-content-center">                      
                                <Button className="btn btn-secondary" onClick={() => onUpdateSetting()}>
                                    {/* {props.t("properties.delete")} */}
                                    Save
                                </Button>
                                <Button color="danger" onClick={() => onCloseClick()} outline>
                                    {props.t("properties.cancel")}
                                </Button>
                            </div>
                        </div>
                </ModalBody>
            </Modal>
    ) as unknown as JSX.Element;
};

export default Setting;