import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";
import Select from "react-select";

import FeatherIcon from 'feather-icons-react';

const Text = ({property, setConditionjson, conditionjson,setFilterObject, filterObject}: any) => {  
    const [condition, setCondition]= useState<any>({});
    const handleChange = (value: any, name: any, label: any) => {
        setConditionjson({})
        if(value == 'on') {
            setCondition({value:name, label: label })
            let newconditionjson = {propertylabel: property.label, propertyvalue: property.value, conditionLabel: label}
            setConditionjson(newconditionjson)
        }
    }

    const handleTextChange = (value: any, name: any) => {
        let newconditionjson = {...conditionjson, value: value}
        setConditionjson(newconditionjson)
    }
    useEffect(() => {
    }, [conditionjson])   
    
    return (
        <div className="vstack gap-2"> 
            <div className="vstack gap-1">
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="equal" checked={condition.value == 'equal'} onChange={(e) => handleChange(e.target.value,"equal", "contains exactly")} />
                <Label className="form-check-label" htmlFor="equal">
                    contains exactly
                </Label>
                {condition.value == 'equal' ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"equal")}
                    value={condition.value == "equal" ? conditionjson.value : ''}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="notequal" checked={condition.value == "notequal"} onChange={(e) => handleChange(e.target.value,"notequal", "doesn't contains exactly")}/>
                <Label className="form-check-label" htmlFor="notequal">
                    doesn't contains exactly
                </Label>
                {condition.value == "notequal" ? <Input
                    type="text"
                    size={14}
                    className="search"
                    onChange={(e) => handleTextChange(e.target.value,"notequal")}
                    value={condition.value == "notequal" ? conditionjson.value : ''}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="known" id="known" checked={condition.value == "known"} onChange={(e) => handleChange(e.target.value,"known", "is known")}/>
                <Label className="form-check-label" htmlFor="known">
                    is known
                </Label>
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="unknown" id="unknown" checked={condition.value == "unknown"} onChange={(e) => handleChange(e.target.value,"unknown", "is unknown")}/>
                <Label className="form-check-label" htmlFor="unknown">
                    is unknown
                </Label>
            </div> 
        </div>
    );
};

export default Text;
