import React, { useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
// import { VerticalForm } from "pages/Forms/FormLayouts/FormlayoutsCode";

const AdvancedFilter = ({ props, show, onCloseClick, dataFields, setFilterObject, filterObject, applyFilters }: any) => {
    const filterFields: any = [];
    dataFields && dataFields.length && dataFields.map((item: any) => {        
            let isFieldType = item && item.valueJson && item.valueJson.type != "textarea" && item.valueJson.type != "phone" && item.valueJson.type != "textfield" && item.valueJson.type != "number" && item.valueJson.type != 'Password' ? true : false
            if(item.valueJson && isFieldType) return filterFields.push(item.valueJson)
       
    })
    const onChange = (option: any,key: any) => {
        let filters = { ...filterObject, [key]:option.value}
        setFilterObject(filters)
    }
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
                {props.t("applications.advanced_filter")}
            </OffcanvasHeader>
            <form action="" className="d-flex flex-column justify-content-end h-100">
                <OffcanvasBody>
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                    {filterFields && filterFields.length && filterFields.map((field: any) => {
                                        return <FormBuilder                             
                                            placeholder={field.placeholder}
                                            onChange={(option : any) => onChange(option, field.key)}
                                            options={field && field.values ? field.values.reverse() : []}
                                            label={field.label}
                                            type={"select"}
                                            value={""}
                                            className="w-100 h-100 text-start"
                                        />
                                    })}
                            </div>
                        </CardBody>
                    </Card>
                </OffcanvasBody>
                <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                    <Button
                        className="btn btn-light w-100"
                        onClick={() => onCloseClick()}
                    >
                        Clear Filter
                    </Button>
                    <Button
                        className="btn btn-success w-100"
                        onClick={() => applyFilters()}
                    >
                        Apply Filters
                    </Button>
                </div>
            </form>
        </Offcanvas>
    );
};

export default AdvancedFilter;
