import { Reducer } from "redux";
import { TemplateActionTypes, TemplatesState } from "./types";


export const initialState: TemplatesState =
{
    templatesMetadata: [],
    templates: null,
    template: null,
    pagination: null,
    templatelist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    hierarchytemplates: [],
    loading: false,
    dataloading: false,
    metaloading: false
}
const reducer: Reducer<TemplatesState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TemplateActionTypes.GET_TEMPLATEMETADATA_REQUEST: {
            return { ...state, metaloading: true };
        }
        case TemplateActionTypes.GET_TEMPLATEMETADATA_SUCCESS: {
            return {
                ...state,
                metaloading: false,
                templatesMetadata: action.payload.content
            };
        }
        case TemplateActionTypes.GET_TEMPLATEMETADATA_ERROR: {
            return { ...state, metaloading: false, errors: action.payload };
        }
        case TemplateActionTypes.GET_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.GET_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                template: action.payload
            };
        }
        case TemplateActionTypes.GET_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case TemplateActionTypes.POST_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.POST_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case TemplateActionTypes.POST_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case TemplateActionTypes.GET_TEMPLATES_REQUEST: {
            return { ...state, dataloading: true };
        }
        case TemplateActionTypes.GET_TEMPLATES_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                templates: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case TemplateActionTypes.GET_TEMPLATES_ERROR: {
            return { ...state, dataloading: false, errors: action.payload };
        }

        
        case TemplateActionTypes.GET_TEMPLATES_HIERARCHY_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.GET_TEMPLATES_HIERARCHY_SUCCESS: {
            return {
                ...state,
                loading: false,
                hierarchytemplates: action.payload,
            };
        }
        case TemplateActionTypes.GET_TEMPLATES_HIERARCHY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case TemplateActionTypes.PUT_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.PUT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                templatelist: action.payload.content
            };
        }
        case TemplateActionTypes.PUT_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as TemplatesReducer };
