import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

interface EditModalProps {
    show?: boolean;
    onApplyClick: () => void;
    onCloseClick: () => void;
    onchange: (column: { accessor: string; }) => void
    toggleColumn: (column: { accessor: string; }) => void
    onClickmove: (column: { accessor: string; }) => void
    columns: [any];
    visibleColumns: [any];
    deSelectColumnall: () => void
    onChangeIndex: (column: any, index: any) => void
    tempcolumns: [any];
    setTempColumns: (columns: any) => any;
}

const EditModal: React.FC<EditModalProps> = ({ tempcolumns,setTempColumns, onChangeIndex, show, toggleColumn, onApplyClick, onCloseClick, columns, onchange, onClickmove, visibleColumns, deSelectColumnall }) => {

    const [filterData, setfilterData] = useState<any>([]);
    const [column, setColumn] = useState<any>({});
    const [index, setIndex] = useState<any>(-1);

    // const filterList = (e: any) => {
    //     const updatedList: any = [];
    //     tempcolumns && tempcolumns.length && tempcolumns.filter(item => {
    //         if (item.show == false) {
    //             updatedList.push(item.Header.toLowerCase().search(e.target.value.toLowerCase()) !== -1)
    //         };
    //     });
    //     setColumns(updatedList);
    // };

    const handleChange = () => {
        setfilterData(filterData);
    };
    useEffect(() => {
        setfilterData(visibleColumns)
    }, [visibleColumns]);

    const onDrop = (index: any) => {
        setIndex(index)
        onChangeIndex(column, index)
    };
    const onDrag = (column: any) => {
        setColumn(column)
    };
    return (
        <Modal backdrop="static" className="edit-column h-75" scrollable={true} size="xl" fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="bg-info-subtle p-3" toggle={() =>{onCloseClick();setTempColumns(columns)}}>
                <h4>Choose which columns you see</h4>
            </ModalHeader>
            <ModalBody className="p-5">
                <Row>
                    <Col lg={6} md={6}
                        onDragOver={(e) => e.preventDefault()}>
                            <h6 className="fw-bold mt-2 fs-14 mb-3">SELECT COLUMN TO ADD</h6>
                            <SimpleBar autoHide={false} className="simplebar-track-dark" style={{ maxHeight: "300px" }}>
                                    <div className="vstack gap-2">                                    
                                        {
                                            tempcolumns.map((column: { accessor: any; show?: any; Header?: any; }, index: React.Key | null | undefined) => {
                                                if (column.accessor !== 'course_name' && column.accessor !== 'action' && column.accessor !== 'id' && column.accessor !== 'checkbox') {
                                                    return (
                                                        <div className="form-check form-check-dark fs-16" key={index}>
                                                            <Input type="checkbox" className="form-check-input" id={`checkbox-${column.accessor}`} checked={column.show}  onClick={() => toggleColumn(column.accessor)}/>
                                                            <Label className="form-check-label" htmlFor={`checkbox-${column.accessor}`}>
                                                                {column.Header}
                                                            </Label>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                            </SimpleBar>
                    </Col>
                    <Col  lg={6} md={6}>
                        <h6 className="fw-bold ms-3 mb-3">SELECTED COLUMNS ({tempcolumns.length}) </h6>
                        <SimpleBar autoHide={false} className="simplebar-track-dark" style={{ maxHeight: "300px" }}
                            onDragOver={(e: any) => e.preventDefault()}>   
                                <div className="vstack">
                                    {
                                        tempcolumns.map((column, index) => {
                                            if (column.accessor == 'course_name') return <div className="mt-3">
                                                <Col md={12} key={index}>
                                                    <Button size="md" disabled={true} color={"dark"} className="hstack form-control btn-soft-muted" outline={true}>
                                                        <span className="hstack align-center justify-content-start fs-16">{column.Header}</span>
                                                    </Button>
                                                </Col>
                                                
                                                <Col md={12} key={index} className="hstack gap-1 text-secondary mt-2 mb-2 justify-content-center">
                                                    <hr className="m-1 w-25 border border-1 dropdown-divider border-dark" /><span className="text-dark fs-16">Columns above are freezed</span><hr className="m-1 w-25 dropdown-divider border border-1 border-dark" />
                                                </Col>
                                            </div>
                                            if (column.show) return <div className="pt-2" onDrop={() => { onDrop(index); }} onDrag={() => { onDrag(column); }}>
                                            <Col md={12} key={index} className="hstack gap-1 text-dark">                                            
                                                    <Button size="md" color={"btn-ghost-light"} className="form-control justify-content-between hstack border border-opacity-25 cursor-grab" outline={true} draggable>
                                                        <span className="hstack align-items-center gap-2"><i className="bx bx-grid-vertical align-center justify-content-end fs-18 text-light-emphasis" />{column.Header}</span>
                                                        <i className="hstack bx bx-x align-center justify-content-end fs-18" onClick={() => toggleColumn(column.accessor)}></i>
                                                    </Button>
                                                </Col>
                                            </div>
                                        })
                                    }
                                </div>
                        </SimpleBar>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className='hstack justify-content-start'>
                <div className='hstack justify-content-start gap-2'>
                    <Button color="dark" className="w-10" onClick={() => onApplyClick()}>
                        Apply
                    </Button>
                    <Button color="light" className="w-10" onClick={() => {onCloseClick();setTempColumns(columns)}}>
                        Cancel
                    </Button>
                    <Button color="btn-ghost-light" onClick={() => deSelectColumnall()} className="w-10">
                        Remove all columns
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default EditModal;