import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import ChannelInfoEdit from './ChannelInfoEdit';
import students from "./students.json"
import StudentList from './StudentsList'
import { ResetInvoceData } from 'store/auth/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';

const OverviewTab = ({ info, metaData, ChannelDetail }: any) => {
const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
const students = useSelector((state: ApplicationState) => state.channels.invoicesstudents)
const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
const [access, setAccess] = useState<any>([]);
const [isEdit, setIsEdit] = useState(false);
const [isStudentListopen, setIsStudentListopen] = useState(false);
const [mainList, setMainList] = useState<any>([]);
const [selectedlist, setSelectedList] = useState<any>([]);
const [mainListJson, setmainListJson] =  useState<any>([]);
const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
const [create, setCreate] = useState<boolean>(false);
const [disabled, setdisabled] = useState<any>(true)
const [viewAccess, setViewAccess] = useState<boolean>(false);
const [isEditAccess, setEditAccess] = useState<boolean>(false);
const [filterFields, setFilterFields] =  useState<any>([]);
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    useEffect(() =>{
        let filterFields: any = []
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field?.valuesJson   && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson : {}
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {}            
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            if (field.valuesJson && isFieldviewaccess) return filterFields.push(item)
        })
        setFilterFields(filterFields)
    }, [metaData])

    
    // const execute = () => {
    //     let a = isEqual(mainList, mainListJson)
    //     return !a
    // }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
            setEditAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;            
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                        }                        
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            let channelaccess = subLevelPermissionsList?.channels;            
            if(channelaccess && channelaccess?.length) {
                setAccess(channelaccess)
                if(channelaccess && channelaccess.length) {            
                    channelaccess.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEditAccess(false)  : setEditAccess(true) 
                        }            
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        const parsedData: any = students && students.length ? students.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, ...item, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let courses = parsedData && parsedData.length && parsedData.reverse()
        setMainList(courses)
        setmainListJson(students)
    }, [students])


 

    const createStudents = () => {
        dispatch(ResetInvoceData())
    }

    const DetailsList = ({ label, value, item, index }: any) => {
        return (
            <React.Fragment>
                <Col md={3} lg={3} sm={6} Key={index}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-muted mb-1 fs-14">{label} :</p>
                                <h5 className="mb-0 fs-14">{value}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    
    const handleselect = (obj: any, value: boolean) => {
        if(value === true) {
            let newList = selectedlist
            newList.push(obj)
            setSelectedList(newList)
            setdisabled(!newList.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);            
            setSelectedList(newList)
            setdisabled(!newList.length)
        }
        
    }
    
    return (
        <React.Fragment>            
            <StudentList
                show={isStudentListopen}
                onCloseClick={() => setIsStudentListopen(false)}
                mainList={mainList}
                selectedlist={selectedlist}
                setSelectedList={setSelectedList}
                handleselect={handleselect}
                ChannelDetail={ChannelDetail}
                disabled={disabled}
            />
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardHeader className='hstack align-items-center'>  
                        <Col  className='hstack justify-content-start p-0 ps-3 align-items-end'>
                            <h4>Channel Information</h4>
                        </Col> 
                        <Col  className='hstack justify-content-end gap-2'>
                                {/* <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="success"
                                    onClick={() => {setIsStudentListopen(true); createStudents()}}
                                >
                                    <i className="ri-article-line"></i>{" "}
                                    Create Invoice
                                </Button> */}
                                {
                                                create ? 
                                                <Button
                                                onClick={() => {setIsStudentListopen(true); createStudents()}}
                                                    color="primary"
                                                    className="btn-label">
                                                        <i className="ri-article-line label-icon align-middle fs-16 me-2"></i>
                                                        Create Invoice
                                                </Button>
                                            :         
                                                <Button
                                                    color="primary"
                                                    disabled
                                                    className="btn-label">
                                                        <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                        Create Invoice
                                                </Button>
                                    }    
                            {
                                    isEdit ? <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="danger"
                                    onClick={() => setIsEdit(false)}
                                    outline
                                >
                                    <i className="ri-close-fill"></i>{" "}
                                    Close
                                    {/* {props.t("channel.update")} */}
                                </Button> :  
                                isEditAccess ?                      
                                <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="primary"
                                    onClick={() => setIsEdit(true)}
                                >
                                    <i className="ri-pencil-fill"></i>{" "}
                                    Update
                                </Button> : 
                                 <Button
                                 color="primary"
                                 disabled
                                 className="btn-label">
                                     <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                     Update
                             </Button>
                            }
                        </Col>                        
                        </CardHeader>
                        <CardBody>
                            <div className="text-muted">
                                <div className="border-top border-top-dashed p-3">
                                  {
                                    isEdit ? <ChannelInfoEdit /> :
                                    <Row className="gy-3">
                                        {filterFields && filterFields.length ? filterFields.map((item: any, key: any) => {
                                            return <DetailsList
                                                label={item.label}
                                                value={info[item.key]}
                                                item={item}
                                                key={key}
                                            />
                                        }): null}
                                    </Row>
                                  }
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;