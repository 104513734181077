import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";

const PreviewComponent = ({ createInputs,  fieldType, fieldJson }: any) => {
    const [value, setvalue] = useState<any>('Sample text')
    return (
        <div id="myDivparent">
            <div  className="fs-16 fw-semibold">Preview</div>
            <Card className="m-3" id="myDivChild">
                <FormBuilder 
                    label={createInputs.label}
                    type={fieldType}
                    value={value}
                    placeholder={createInputs.placeholder}
                    className="w-100 h-100 text-start"
                    onChange={(value: any) => {
                        console.log(value)
                        setvalue(value)
                    } }
                    actionButtons={true}
                    defaultValue={'Sample text'}
                    Editable={true}
                    rest={fieldJson}
                />                
            </Card>
        </div>
    );
};

export default PreviewComponent;
