import React, { useEffect, useState } from "react";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Label, Table } from "reactstrap";
import {levelhandle} from '../../../utils';


const ApplicationInfo = ({ onCloseClick, record ,metaData}: any) => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [datafields, setdatafields] = useState<any>([])
  const onChange = (option: any, key: any) => {
    if(typeof option == 'object') setFormValues({...formValues, [key]: option.value})
    else setFormValues({...formValues, [key]: option})
  };

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value, type } = e.target;
    let fieldValue: any = value;
    if (type === "checkbox") {
      const target = e.target as HTMLInputElement;
      fieldValue = target.checked;
    }

    setFormValues({
      ...formValues,
      [name]: fieldValue,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    onCloseClick();
  };

useEffect(() => {
  if (metaData && metaData.length > 0) {
    let datafields = metaData.map((fieldName: any) => {
        let fields = JSON.parse(fieldName.valuesJson)
       return fields
    });
    setdatafields(datafields)}
}, [metaData]);
  return (
    <Table className="table table-borderless mb-0">
       {datafields && datafields.length && datafields.map((field: any) => {
            // return <FormBuilder                            
            //     placeholder={field.placeholder}
            //     onChange={(e : any) => onChange(e, field.key)}
            //     options={field.values && field.values.reverse() || []}
            //     label={field.label}
            //     type={field.type}
            //     value={record[field.key]}
            //     className="w-100 h-100 text-start"
            // />
            return <tbody>
              <tr className="border border-0 border-bottom">
                <td className="fw-semibold">
                  {field.label}
                </td>
                <td>{field.key == "course_type" ? levelhandle(record[field.key]) : record[field.key]}</td>
              </tr>
              </tbody>
        })}
        </Table>
  );
};

export default ApplicationInfo;





{/* {datafields && datafields.length && datafields.map((field: any) => {
    return <FormBuilder                            
        placeholder={field.placeholder}
        onChange={(e : any) => onChange(e, field.key)}
        options={field.values && field.values.reverse() || []}
        label={field.label}
        type={field.type}
        value={record[field.key]}
        className="w-100 h-100 text-start"
    />
})} */}

