import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledDropdown,
} from "reactstrap";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import classnames from "classnames";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import dataFields from "./RoleFields.json";
import data from "./RolesList.json";
import TableContainer from "./TableComponent";
import _ from "lodash";
import moment from "moment";
import EditModal from "./EditColumnModal";
import BreadCrumb from "Components/Common/BreadCrumb";
import RoleCreateModal from './InnerComponent/RoleCreate';
import RoleEditModal from './EditRole/RoleEdit';
import { WithTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { roleDeleteRequest, roleListGetRequest, permissionsGetRequest, roleGetRequest } from "store/role/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ApplicationState } from "store";
import DeleteModal from "./DeleteModal";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import traverse from './createArray';
import { boolean } from "yup";
import AccessDenied from "pages/AccessDenied/Denied";
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';

const { link : {ROLES_LEARN_MORE}} = config;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface permissionData {
    permissionId: string,
    value: string;
    item: any;
}

interface RolesProps extends WithTranslation { }

const Roles: React.FC<RolesProps> = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const roleList = useSelector((state: ApplicationState) => state.role.list);
    const roleDetails = useSelector((state: ApplicationState) => state.role.roleDetails);
    const [create, setCreate] = useState<boolean>(false);
    const paginationDetails = useSelector((state: ApplicationState) => state.role.pagination);
    const permissionsdata = useSelector((state: ApplicationState) => state.role.permissionsdata);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const loading = useSelector((state: ApplicationState) => state.role.loading);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [customActiveTab, setcustomActiveTab] = useState<any>("1");
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [columns, setColumns] = useState<any>([]);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [record, setrecord] = useState<any>();
    const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [updateId, setUpdateId] = useState<any>("");
    const [sortOrder, setSortOrder] = useState<string>("")
    const [edit, setEdit] = useState(false);
    const [isIconUp, setIsIconUp] = useState(false);
    const [predefinedPermissions, setPredefinedPermissions] = useState([]);
    const [PreAccessPermissions, setPreAccessPermissions]= useState<permissionData[]>([]);
    const [filterObject, setFilterObject] = useState({})
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true) 
            setEdit(true)
        }
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.roles;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                        }           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const handleChange = (value: any) => {
        setsearchvalue(value);
        const filters = {...filterObject, search:value}
        setFilterObject(filters)                  
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(roleListGetRequest(filters));
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          setFilterObject(filters)
          dispatch(roleListGetRequest(filters));
        }
      };

    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)  
        dispatch(roleListGetRequest(filters, 0));
    }

    const onClickrow = async (data: any, index: any) => {
        setUpdateId(data.id)
        await dispatch(roleGetRequest(data.id));
        setrecord(data);        
    };

    const onDelete = (roleId: any) => {
        const handleSuccess = (body: any): void => {
            dispatch(roleListGetRequest());
            setisDeleteOpen(false)
            const message = () => toast(`Role deleted successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        const handleError = (body: any): void => {
        }
        dispatch(roleDeleteRequest(record.id, handleSuccess, handleError));     
    };

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(roleListGetRequest(filterObject, newPageNo));
    };
          
    const toggleIcon = () => {
        setIsIconUp((prevIsIconUp) => !prevIsIconUp);
    };
    
    useEffect( () => {     
        const fetchData = async () => {
            await Promise.all([
                dispatch(permissionsGetRequest()),
                dispatch(roleListGetRequest())
            ]);
        };   
        fetchData();
        let columns: any = [];
        const fieldsCols: any =
            dataFields && dataFields.fields
                ? dataFields.fields.map((fields, index) => {
                    if (fields.name == "id")
                        return {
                            Header: _.startCase(fields.name),
                            accessor: fields.name,
                            filterable: true,
                            isSortable: false,
                            disableFilters: true,
                            show: false,
                        };
                    else if (fields.name == "title")
                        return {
                            Header: _.startCase(fields.name),
                            accessor: fields.name,
                            isSortable: true,
                            isSorted: true,
                            filterable: true,
                            disableFilters: true,
                            show: true,
                            Cell: (cellProps: any) => {
                                return (
                                    <div className="hstack multi-item">
                                <span>{cellProps.row.original.title}</span>
                                {edit ?
                                    cellProps.row.original.title == 'Owner' ? null : <Button  
                                        size='sm'
                                        color="primary"
                                        outline={true}
                                        disabled={cellProps.row.original.title == 'Owner' ? true : false}
                                        onClick={() => {setIsOpenEdit(true); onClickrow(cellProps.row.original,index); setrecord(cellProps.row.original)}}
                                        className={"btn_preview"}>
                                            Edit Role
                                    </Button>: null}
                                </div>
                                );
                            },
                        };
                    else return {
                            Header: _.startCase(fields.name),
                            accessor: fields.name,
                            filterable: true,
                            isSortable: true,
                            disableFilters: true,
                            className: "hoverable",
                            show: true,
                            Cell: (cellProps: any) => {
                                return (
                                    <div className="d-flex align-items-center" key={index} onClick={() => setIsOpenEdit(true)}>
                                        <Label
                                            className="hstack py-1 form-label"
                                            htmlFor={cellProps.row.original[fields.name]}
                                        >
                                            {cellProps.row.original[fields.name]}
                                        </Label>
                                    </div>
                                );

                            },
                        };
                })
                : [];
        columns.push.apply(columns, fieldsCols);
        setColumns(columns);
    }, [edit]);

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}   
          setFilterObject(filters)   
          dispatch(roleListGetRequest(filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(roleListGetRequest(filters, pagination.page));
        }
      };
      console.log("subLevelPermissionsList", subLevelPermissionsList)


    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    
    const updateObjectInHierarchy = (array: any, updatedObject: any) => {      
        if (array && array.length) {
            return array.map((item: any) => {
                if (item.children && item.children.length > 0) {
                    const updatedChildren = updateObjectInHierarchy(item.children, updatedObject);
                    return { ...item, children: updatedChildren };
                } 
                else if (item.permissions && item.permissions.length > 0) {
                    
                    const updatedPermissions = item.permissions.map((permission: any) => {
                        if (permission.id === updatedObject.id) {
                            let newObject = {...permission, value: updatedObject.value}
                            return { ...permission, ...newObject};
                        }
                        let newupdated = { ...permission, value: null };
                        return newupdated
                    });
                    
                    // Check if any child permission was updated to true
                    const isChildUpdatedToTrue = updatedPermissions.some((permission: any) => permission.value === 'true');
                    // If any child permission was updated to true, update the parent permission
                    if (isChildUpdatedToTrue) {
                        return { ...item, permissions: updatedPermissions, value: 'true' };
                    }
                } 
                else if (item.permissions && !item.permissions.length) {
                    
                        if (item.permissions.id === updatedObject.id) {
                            let newObject = {...item.permissions, value: updatedObject.value}
                            return { ...item.permissions, ...newObject};
                        }
                        else {
                            let newObject = {...item.permissions, value: null}
                            return { ...item.permissions, ...newObject};
                        }
                }
                else if (item.id === updatedObject.id) {
                            let newObject = {...item, value: updatedObject.value}
                            return { ...item, ...newObject};
                }
                return { ...item, value: null };
            });
        }
        return array;
    };
    const updateObjectInHierarchyToNull = (array: any) => {      
        if (array && array.length) {
            return array.map((item: any) => {
                if (item.children && item.children.length > 0) {
                    const updatedChildren = updateObjectInHierarchyToNull(item.children);
                    return { ...item, children: updatedChildren };
                } 
                else if (item.permissions && item.permissions.length > 0) {                    
                    const updatedPermissions = item.permissions.map((permission: any) => {                        
                        if (permission.type) {
                            let newObject = {...permission, value: null}
                            return { ...permission, ...newObject};
                        }  
                        if(permission && permission.permission && permission.permission.length) {
                            let newObject = {...permission, value: null}
                            return { ...permission, ...newObject};
                        }                      
                    });
                    return { ...item, permissions: updatedPermissions};
                } 
                else if (item.permissions && !item.permissions.length) {
                    let newObject = {...item.permissions, value:null}
                    return { ...item.permissions, ...newObject};
                }
                else if (item.type) {
                    let newObject = {...item, value: null}
                    return { ...item, ...newObject};
                }
                return { ...item, value: null };
            });
        }
        return array;
    };
    

    useEffect(() => {        
        if (roleDetails && roleDetails.categoriesWithPermission && roleDetails.categoriesWithPermission.length) {   
            // const updatedArray = roleDetails.permissions.reduce((acc: any, permission: any) => {
            //     return updateObjectInHierarchy(acc, permission);
            // }, [...predefinedPermissions]); // Spread to avoid mutating state directly
            setPredefinedPermissions(roleDetails.categoriesWithPermission);
        }
    }, [roleDetails]);

    useEffect(() => {
        let updatedAccess : any= []
        setPreAccessPermissions([])
        updatedAccess = traverse(permissionsdata);
        setPreAccessPermissions(updatedAccess)
        // const makenull = updateObjectInHierarchyToNull(permissionsdata)
        // setPredefinedPermissions(makenull);  
    }, [permissionsdata != undefined]);
    return (
        <React.Fragment>
            <ToastContainer />                     
                <div>
                    { !authLoading ?
                        viewAccess ?
                        <div>                     
                            {isOpenCreate && <RoleCreateModal show={isOpenCreate} onCloseClick={() => setIsOpenCreate(false)} PreAccessPermissions={PreAccessPermissions} />}
                            {isOpenEdit && <RoleEditModal show={isOpenEdit} onCloseClick={() => setIsOpenEdit(false)} record={record} updateId={updateId} predefinedPermissions={predefinedPermissions} />}                    
                            {isDeleteOpen && <DeleteModal  
                                show={isDeleteOpen}
                                onCloseClick={() => setisDeleteOpen(false)}
                                record={record}
                                onDelete={onDelete}
                                t={props.t}
                            />} 
                                <div>
                                    {/* <div className="fs-15 fw-medium mb-3">
                                        {props.t("roles.roles_top_1")}{" "}
                                        <strong>
                                            <Link to={`${ROLES_LEARN_MORE}`} target="_blank" className="text-info fw-medium">
                                                {" "}
                                                
                                                {props.t("roles.roles_top_2")}{" "}
                                            </Link>
                                        </strong>
                                        &nbsp;
                                        <span>
                                            <FeatherIcon icon="arrow-up-right" className="icon-sm" />{" "}
                                        </span>
                                    </div> */}
                                    <hr></hr>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="d-flex gap-3">
                                                    <div className="search-box">
                                                        <Input
                                                            type="text"
                                                            size={30}
                                                            className="search"
                                                            onKeyDown={handleKeyDown}
                                                            placeholder={props.t("roles.search_for_role")}
                                                            onChange={(e) => handleChange(e.target.value)}
                                                            value={searchvalue}
                                                        />
                                                    { searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}                            
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end align-items-baseline gap-3">
                                                    <div>
                                                    </div>
                                                    <div>
                                                    {
                                                        create ? 
                                                        <Button
                                                            onClick={() => setIsOpenCreate(true)}
                                                            color="primary"
                                                            className="btn-label">
                                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t('roles.create_role')}
                                                        </Button>
                                                    :         
                                                        <Button
                                                            color="primary"
                                                            size='sm'
                                                            disabled
                                                            className="btn-label">
                                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t('roles.create_role')}
                                                        </Button>}                                               
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="students-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={roleList || []}
                                                    customPageSize={pageSize}
                                                    setrecord={setrecord}
                                                    setisDeleteOpen={setisDeleteOpen}
                                                    divClass="table-responsive"
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="table-light text-muted"
                                                    thClass="border-bottom-1 table-soft-primary"
                                                    SearchPlaceholder='Search...'
                                                    isPageSizeChange={true}
                                                    isBordered={true}
                                                    pageChanged={pageChanged}
                                                    pagination={pagination}
                                                    setPagination={setPagination}
                                                    pagesList={pagesList}
                                                    onChangePageSize={onChangePageSize}
                                                    pageSize={pageSize}
                                                    isIconUp={isIconUp}
                                                    handleSort={handleSort}
                                                    sortBy={sortBy}
                                                    sortOrder={sortOrder}
                                                    props={props}
                                                    loading={loading}
                                                    access={access}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>                 
                        </div>: <AccessDenied />
                        : <ListingLoader                 
                                columns={columns} 
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                thClass="border-bottom-1 table-soft-primary"
                            /> 
                    }
                </div> 
        </React.Fragment>
    );
};
export default withTranslation()(Roles);
