
import React, { useState, useEffect, useCallback } from 'react';
import prettyMilliseconds from 'pretty-ms';
import { Badge, Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import DeleteModal from '../../Components/Common/DeleteModal';
import { todoTaskList } from 'common/data';
import Select from "react-select";
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from 'react-redux';
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import Sentiments from 'pages/StudentsDetails/Sentiments/Sentiment';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { taskAddRequest, taskDelete, tasksListGetRequest } from 'store/task/action';
import { usersListGetRequest } from 'store/user/action';
import { components } from 'react-select';
// import data from './data.json'; // Import the JSON data
import { callAddRequest, callsListGetRequest, callsSentimentJSON } from 'store/calls/action';
// import Recording from './Recording/Recording';

interface ImgData {
    id: number,
    name: string;
    img: string;
}

interface Props {
    info: any;
    t: any;
}

const Calls: React.FC<Props> = ({ info, t }: Props) => {
    let { id }: any = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const callsList = useSelector((state: ApplicationState) => state.calls.calls);
    const pageDetails = useSelector((state: ApplicationState) => state.calls.pagination);
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const sentiment = useSelector((state: ApplicationState) => state.calls.sentiment);
    const [taskTitle, setTaskTitle] = useState('')
    const [contacted, setContacted] = useState<any>(null);
    const [outcomeBrief, setOutcomeBrief] = useState<any>(null);   //setOutcomeBrief setDirection setCallDescription
    const [direction, setDirection] = useState<any>(null);
    const [users, setUsers] = useState<any[]>([])
    document.title = "Calls | Zilter";
    const [show, setShow] = useState(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [taskList, setTaskList] = useState<any>([]);
    const [status, setStatus] = useState('')
    const [priority, setPriority] = useState('')
    const [taskDetails, setTaskDetails] = useState({})
    const [currentPage, setCurrentPage] = useState(0);
    const [sortByColumn, setSortByColumn] = useState<string>("dueDate");
    const [sortByOrder, setSortByOrder] = useState<string>("desc");
    const [pagesList, setPagesList] = useState<any>([]);
    const [deleteTodoId, setDeleteTodoId] = useState(null);
    const [navBadgeTab, setnavBadgeTab] = useState<any>("1");
    const [searchTaskName, setSearchTaskName] = useState<string>('')
    const [modal_positionTop, setmodal_positionTop] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [tick, setTick] = useState<boolean>()
    const [duration, setDuration] = useState('');
    const [durationInMilliseconds, setDurationInMilliseconds] = useState<number | null>(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dateTime, setDateTime] = useState<Date | undefined>(undefined);
    const [startTime, setStartTime] = useState<any>(new Date())
    const [endTime, setEndTime] = useState<any>(new Date(new Date().getTime() + 10 * 60000))
    const [callDescription, setCallDescription] = useState("")
    const [callData, setCallData] = useState<any>()
    const [sentiments, setSentiments] = useState<any>(null)
    const [studentId, setStudentId] = useState(null);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [callLog, setCallLog] = useState<{}>({
        applicationId: id,
        title: "",
        priority: "",
        callStartTime: new Date(),
        callEndTime: new Date(new Date().getTime() + 10 * 60000),
        status: "",
        studentId: studentId,
        provider: "LOG"
    })

    useEffect(() => {
        if (applicationDetails && applicationDetails?.valuesJson?.students && applicationDetails?.valuesJson?.students.id) {
            setStudentId(applicationDetails?.valuesJson?.students.id);
            setCallLog((prevObject) => ({
                ...prevObject,
                studentId: applicationDetails?.valuesJson?.students.id
            }))
        }
    }, [applicationDetails]);

    useEffect(() => {
        let queryParams = new URLSearchParams({})
        queryParams.set("applicationId",id)
        if (studentId) {
            dispatch(callsListGetRequest(studentId, queryParams.toString()))
            setCallData(callsList)
        }

    }, [studentId])
    useEffect(() => {
        if (sentiment) {
            const parsedObject = JSON.parse(sentiment.sentiment);
            setSentiments(parsedObject)
        }
    }, [sentiment])

    useEffect(() => {
        if (pageDetails) {
            setPagination(pageDetails);
            setPagesList(Array.from({ length: pageDetails.pages }, (_, index) => index))
        }
    }, [pageDetails])

    console.log("Calls list", callsList)
    const navBadgeToggle = (tab: any) => {
        if (navBadgeTab !== tab) {
            setnavBadgeTab(tab);
        }
    };
    const filteredUsers = users.filter(user => user.label && user.value);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const [modalTodo, setModalTodo] = useState<boolean>(false);
    const toggle = (() => {
        setContacted(null)
        setModalTodo(false)
    });
    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);


    const checkObjectEmpty = (obj: any) => {
        return Object.values(obj).some(value => value === null || value === undefined || value === '');
    };

    const handleAddCallLog = () => {
        setIsAddNoteButtonDisabled(true);
        let queryParams = new URLSearchParams({})
        queryParams.set("applicationId", id)
        const data = callLog;
        const handleSuccess = (): void => {
            handleClearData()
            setModalTodo(false)
            toast(`Call logged successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(callsListGetRequest(studentId, queryParams.toString()))
        };
        const handleError = (): void => {
            handleClearData()
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        console.log("Call log data", callLog)
        dispatch(callAddRequest(data, handleSuccess, handleError));
    }

    const handleTodoClick = useCallback((arg: any) => {
        // setIsEdit(true);
        toggle();
    }, [toggle]);

    const sortbyDirection = [
        {
            options: [
                { label: "Inbound", value: "INBOUND" },
                { label: "Outbound", value: "OUTBOUND" }
            ],
        },
    ];
    const outcome = [
        {
            options: [
                { label: "Answered", value: "ANSWERED" },
                { label: "No Answer", value: "NO_ANSWER" },
                { label: "Voicemail Left", value: "VOICEMAIL_LEFT" },
                { label: "Busy", value: "BUSY" },
                { label: "Wrong Number", value: "WRONG_NUMBER" },
            ],
        },
    ];

    // const dragulaDecorator = (componentBackingInstance: any) => {
    //     if (componentBackingInstance) {
    //         let options = {};
    //         Dragula([componentBackingInstance], options);
    //     }
    // };
    let pageNumbers: number[] = [];
    let totalPages = 0;
    if (pagination) {
        // recordsSize = Array.from({ length: Math.ceil(pagination.elements / 10) }, (_, index) => (index + 1) * 10);
        totalPages = pagination.total
        // recordsSize = Array.from({ length: totalPages }, (_, index) => (index + 1) * 10);
        pageNumbers = Array.from({ length: totalPages }, (_, index) => index);
    }
    const [imgStore, setImgStore] = useState<any>();
    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };

    const handleselected = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        let sortByPriority;
        let sortByStatus;
        let sortBySearch;
        const appID = queryParams.set("applicationId", id)
        switch (name) {
            // case 'priority':
            //     // sortByPriority = `priority=${encodeURIComponent(option)}`;
            //     // sortByStatus = status ? `status=${encodeURIComponent(status)}` : '';
            //     status && queryParams.set('status', status)
            //     queryParams.set('priority', option);
            //     break;
            // case 'status':
            //     priority && queryParams.set('priority', priority)
            //     queryParams.set('status', option);
            //     break;

            // case 'searchTask':
            //     // sortBySearch = `search=${encodeURIComponent(option)}`
            //     queryParams.set('search', (option));
            //     break;
            // case 'column':
            //     setSortByColumn(option);
            //     if (sortByColumn === option) {
            //         setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
            //     } else {
            //         setSortByOrder("asc");
            //     }
            //     queryParams.set('sortBy', option);
            //     queryParams.set('sortOrder', sortByOrder === "asc" ? "desc" : "asc");
            //     break;
            case 'prevPage':
                queryParams.set('page', (currentPage - 1).toString());
                break;
            case 'nextPage':
                queryParams.set('page', (currentPage + 1).toString());
                break;
            case 'pageNumber':
                queryParams.set('page', option.toString());
                break;
            case 'pageSize':
                queryParams.set('pageSize', option.toString());
                break;
            default:
        }
        // dispatch(callsListGetRequest(studentId, id))
        // dispatch(callsListGetRequest(studentId ? `${studentId}?${queryParams.toString()}` : queryParams.toString()));
        dispatch(callsListGetRequest(studentId, queryParams.toString()));
        console.log(queryParams.toString());
    }

    console.log(taskDetails)
    const tog_positionTop = () => {
        setmodal_positionTop(!modal_positionTop);
    }

    const onClickSentiment = (callsId: any) => {
        let queryParams: any = new URLSearchParams({});
        queryParams.set('id', callsId)
        queryParams.set('isRecording', false)
        dispatch(callsSentimentJSON(queryParams.toString()))
    }

    const toTitleCase = (str: any) => {
        return str?.replace(/\w\S*/g, (txt: any) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setDuration(value);

        // Convert the duration to milliseconds
        setDurationInMilliseconds(durationToMilliseconds(value));
    };
    const durationToMilliseconds = (duration: any) => {
        const parts = duration.split(':');

        if (parts.length !== 3) {
            return null; // Invalid format, handle accordingly
        }

        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseInt(parts[2], 10);

        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // Invalid numbers, handle accordingly
        }

        const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
        setCallLog((prevObject) => ({
            ...prevObject,
            callDuration: totalMilliseconds
        }))
        return totalMilliseconds;
    };

    const handleClearData = () => {
        setIsAddNoteButtonDisabled(false);
        setCallLog({
            applicationId: id,
            title: "", // Keep title empty
            priority: "",
            callStartTime: new Date(),
            callEndTime: new Date(new Date().getTime() + 10 * 60000),
            status: "",
            studentId: studentId,
            provider: "LOG"
        });
        checkObjectEmpty(callLog)
        setOutcomeBrief('');
        setDirection('');
        setCallDescription('');
    }
    console.log("Call data", callLog)
    return (
        <React.Fragment>
            <>
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    toggle={handleClose}
                    backdrop={false}
                >
                    <OffcanvasHeader className="bg-light" toggle={handleClose}>
                        Conversational analytics
                    </OffcanvasHeader>
                    {sentiments ? (
                        <OffcanvasBody>
                            <Sentiments sentiments={sentiments} />
                        </OffcanvasBody>
                    ) : "Loading sentiment data..."}

                </Offcanvas>
            </>
            <ToastContainer closeButton={false} />
            <Modal id="topmodal" isOpen={modal_positionTop} toggle={() => { tog_positionTop() }}>...</Modal>

            <div className="p-3 bg-light rounded mb-4">
                <Row className="g-2">
                    <Col className="col-lg-auto">
                        <select
                            onChange={(e) => {
                                handleselected(e.target.value.toUpperCase(), 'priority');
                                setPriority(e.target.value.toUpperCase());
                            }}
                            className="form-control"
                            name="choices-select-sortlist"
                            id="choices-select-sortlist"
                        >
                            <option value="">Call Outcome</option>
                            {outcome.map((sortOption) =>
                                sortOption.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))
                            )}
                        </select>
                    </Col>
                    <Col className="col-lg-auto">
                        <select className="form-control" onChange={(e) => {
                            handleselected(e.target.value.toUpperCase(), 'status')
                            setStatus(e.target.value.toUpperCase())
                            console.log(e.target.value)
                        }
                        }
                            name="choices-select-status" id="choices-select-sortlist">
                            <option value="">Status</option>
                            {sortbyDirection.map((sortOption) =>
                                sortOption.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))
                            )}
                        </select>
                    </Col>
                    <Col className="col-lg">
                        <div className="search-box">
                            <input type="text" id="searchTaskList" onChange={(e) => {
                                setSearchTaskName(e.target.value)
                                handleselected(e.target.value, 'searchTask')
                            }} className="form-control search" placeholder="Search calls" />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </Col>
                    <Col className="col-lg-auto">
                        <Button
                            onClick={() => setModalTodo(true)}
                            color="primary"
                            className="btn-label">
                            <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                            {t("student.log_call")}
                        </Button>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col className="col-12">
                    <Row>
                        {callsList && callsList.map((calls: any) => {
                            const startTime = calls.callStartTime;
                            const endTime = calls.callEndTime;
                            const duration = moment(calls?.callEndTime)?.diff(moment(calls?.callStartTime));
                            const formattedDuration = moment.utc(duration).format('HH:mm:ss');
                            return (
                                <Col xxl={12} key={calls.id}> {/* Ensure each mapped element has a unique key */}
                                    <Card>
                                        <CardBody>
                                            <div className='d-flex justify-content-between mb-0'>
                                                <div className='d-flex gap-2 align-items-baseline'>
                                                    <Label className='fs-15'>Logged call</Label>
                                                    <div className='fs-14 text-muted'> by {calls.createdBy?.name ? calls.createdBy?.name : info.name}</div>
                                                    <Badge className='' color="primary" pill>
                                                        {toTitleCase("OUTBOUND")} {/* Render the result of toTitleCase */}
                                                    </Badge>
                                                </div>
                                                <div>
                                                    {/* <small className='text-muted'>{moment(calls.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</small> */}
                                                    <small className='text-muted'>{moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                                </div>
                                            </div>
                                            <div className='mt-0 d-flex gap-2 justify-content-between align-items-baseline'>
                                                <div>
                                                    <div className='d-flex gap-2 align-items-baseline'>
                                                        <div className='fs-14 text-muted'> with {info.name}</div> {/* Assuming info is defined */}
                                                        <div className="badge rounded-pill bg-info-subtle text-info">{calls?.status}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Label>Call Duration: <span><small className='text-muted'>{formattedDuration ? formattedDuration : "Not Provided"}</small></span></Label>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <Label className='mt-2 fs-15'>{calls.title ? calls.title : "Untitled"}</Label>
                                                </div>
                                                <div className='d-flex gap-3'>
                                                    {calls.provider && calls.provider === "aws" ? (
                                                        <div className='d-flex gap-2'>
                                                            <Button
                                                                onClick={() => {
                                                                    handleShow();
                                                                    onClickSentiment(calls.id)
                                                                }}
                                                                color="success"
                                                                className="btn btn-ghost-success waves-effect waves-light">
                                                                Sentiments
                                                            </Button>
                                                            {/* <Recording id={calls.id} /> */}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )}


                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}


                    </Row>
                </Col>
            </Row>
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                            <div className="col-sm">
                                <div className="text-muted">{t("student.showing")}{" "}<span className="fw-semibold ms-1">{callsList?.length}</span> {t("student.of")}{" "} <span className="fw-semibold">{pagination?.elements}</span> {t("student.results")}
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                    <li className={currentPage <= 0 ? "page-item disabled" : "page-item"}>
                                        <Link to="#" className="page-link" onClick={() => {
                                            setCurrentPage(currentPage - 1);
                                            handleselected(currentPage, 'prevPage');
                                        }}>{t("student.previous")}</Link>
                                    </li>
                                    {/* <li className={pagination && pagination.first ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={() => {
                                 setCurrentPage(currentPage - 1)
                                 handleselected(currentPage, 'prevPage')
                            }}>Previous</Link>
                        </li> */}
                                    {pagesList.map((item: any, key: any) => (
                                        <React.Fragment key={key}>
                                            <li className="page-item" key={key}>
                                                <Link to="#" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => {
                                                    setCurrentPage(item);
                                                    handleselected(item, 'pageNumber');
                                                }}>{item + 1}</Link>
                                            </li>
                                        </React.Fragment>
                                    ))}
                                    <li className={currentPage == totalPages - 1 ? "page-item disabled" : "page-item"}>
                                        <Link to="#" className="page-link" onClick={() => {
                                            setCurrentPage(currentPage + 1);
                                            handleselected(currentPage, 'nextPage');
                                        }}>{t("student.next")}</Link>
                                    </li>
                                    {/* <li className={pagination && pagination.last ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={() => {
                                     setCurrentPage(currentPage + 1);
                             handleselected(currentPage, 'nextPage')}
                            }>Next</Link>
                        </li> */}
                                    <select
                                        className="mx-1 w-50 border border-2 border-light form-select-sm"
                                        value={pageSize}
                                        onChange={(e) => {
                                            const selectedValue = parseInt(e.target.value);
                                            setCurrentPage(0)
                                            setPageSize(selectedValue)
                                            handleselected(e.target.value, 'pageSize');
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map((size) => (
                                            <option key={size} value={size}>
                                                {t("student.show")} {size}
                                            </option>
                                        ))}
                                    </select>
                                </ul>
                            </div>
                        </Row>
            <Modal className='modal-lg' id="createTask" isOpen={modalTodo} modalClassName="zoomIn" centered tabIndex={-1}>
                <ModalHeader toggle={toggle} className="p-3 bg-primary-subtle"> {"Log Call"} </ModalHeader>
                <ModalBody>
                    <div id="task-error-msg" className="alert alert-danger py-2"></div>
                    <Form id="creattask-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    >

                        <input type="hidden" id="taskid-input" className="form-control" />
                        <div className='d-flex justify-content-between'>
                            <div>
                                <Label className='text-muted'>
                                    {t("calls.contacted")}
                                </Label>
                                <strong><p className='fs-15 mb-0'>{info.name}</p></strong>
                                {/* <Select
                                    placeholder="Contacted to ..."
                                    value={contacted}
                                    isMulti={false}
                                    onChange={(selectedOption: any) => {
                                        console.log(selectedOption.value)
                                        setContacted(selectedOption);
                                    }}
                                    options={filteredUsers}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                /> */}
                                <div>
                                    <Label className="mt-4 form-label text-muted">{t("calls.call_start_time")}</Label>
                                    <Flatpickr
                                        className="form-control"
                                        options={{
                                            defaultDate: startTime,
                                            enableTime: true,
                                            dateFormat: "Y-m-d h:i K",
                                        }}
                                        onChange={(dates) => {
                                            const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                            setStartTime(selectedDate);
                                            setCallLog((prevObject) => ({
                                                ...prevObject,
                                                callStartTime: selectedDate
                                            })) // Set the selected Date object directly to state
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label className='text-muted'>{t("calls.call_outcome")}</Label>
                                <Select
                                    placeholder="Select outcome"
                                    value={outcomeBrief}
                                    isMulti={false}
                                    onChange={(selectedOption: any) => {
                                        setOutcomeBrief(selectedOption)
                                        setCallLog((prevObject) => ({
                                            ...prevObject,
                                            status: selectedOption.value
                                        }))
                                    }
                                    }
                                    options={outcome}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                />
                                <Label className="mt-2 form-label text-muted">{t("calls.call_end_time")}</Label>
                                <Flatpickr
                                    className="form-control"
                                    options={{
                                        defaultDate: endTime,
                                        enableTime: true,
                                        dateFormat: "Y-m-d h:i K",
                                        minDate: startTime,
                                    }}
                                    onChange={(dates) => {
                                        const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                        setEndTime(selectedDate);
                                        setCallLog((prevObject) => ({
                                            ...prevObject,
                                            callEndTime: selectedDate
                                        })) // Set the selected Date object directly to state
                                    }}
                                />
                            </div>
                            <div>
                                <Label className='text-muted'>{t("calls.direction")}</Label>
                                <Select
                                    placeholder="Select direction"
                                    value={direction}
                                    isMulti={false}
                                    onChange={(selectedOption: any) => {
                                        setDirection(selectedOption)
                                        setCallLog((prevObject) => ({
                                            ...prevObject,
                                            priority: selectedOption.value
                                        }))
                                    }
                                    }
                                    options={sortbyDirection}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div>
                            <textarea className="bg-light-subtle border-0 form-control"
                                onChange={(e: any) => {
                                    setCallDescription(e.target.value)
                                    setCallLog((prevObject) => ({
                                        ...prevObject,
                                        title: e.target.value
                                    }))
                                }}
                                id="exampleFormControlTextarea5" placeholder='Describe your call ...' rows={2}></textarea>
                        </div>
                        <div className='mt-2'>
                            <button className="btn btn-sm btn-primary createTask" type="button"
                                disabled={isAddNoteButtonDisabled || checkObjectEmpty(callLog)}
                                onClick={() => {
                                    handleAddCallLog();
                                    setModalTodo(true)
                                }
                                }
                            >{t("student.log_call")}
                            </button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >
        </React.Fragment >
    );
};
export default withTranslation()(Calls);
