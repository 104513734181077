import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Container, Dropdown, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledCollapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Dragula from 'react-dragula';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import taskImg from "../../assets/images/task.png";
import DeleteModal from '../../Components/Common/DeleteModal';
import { todoCollapse, todoTaskList } from 'common/data';
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
//redux
import { useSelector, useDispatch } from 'react-redux';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar9 from "../../assets/images/users/avatar-9.jpg";
import avatar10 from "../../assets/images/users/avatar-10.jpg";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { taskAddRequest, taskDelete, tasksListGetRequest } from 'store/task/action';
import { usersListGetRequest } from 'store/user/action';
import UsersList from 'pages/UsersList/UsersList';
import { components } from 'react-select';
import { getStudentsActivity, studentCreateRequest } from 'store/student/action';


const Status = ({ status }: any) => {
  switch (status) {
    case "NEW":
      return <span className="badge bg-info-subtle  text-info text-uppercase">{status}</span>;
    case "PENDING":
      return <span className="badge bg-warning-subtle  text-warning text-uppercase">{status}</span>;
    case "INPROGRESS":
      return <span className="badge bg-secondary-subtle text-secondary  text-uppercase">{status}</span>;
    case "COMPLETED":
      return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
    default:
      return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
  }
};

const Priority = ({ priority }: any) => {
  switch (priority) {
    case "HIGH":
      return <span className="badge bg-danger text-uppercase">{priority}</span>;
    case "MEDIUM":
      return <span className="badge bg-warning text-uppercase">{priority}</span>;
    case "LOW":
      return <span className="badge bg-success text-uppercase">{priority}</span>;
    default:
      return <span className="badge bg-success text-uppercase">{priority}</span>;
  }
};

const EditTasks = ({ setTaskDetails, setIsEditOpen, isEditOpen, taskDetails }: any) => {
  let { id } = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const tasksList = useSelector((state: ApplicationState) => state.task.tasks);
  const userList = useSelector((state: ApplicationState) => state.user.users);
  const [taskTitle, setTaskTitle] = useState('')
  const [template, setTemplate] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([])
  document.title = "To Do Lists | Zilter";
  const [taskList, setTaskList] = useState<any>([]);
  const [taskData, setTaskData] = useState<any>(taskDetails);
  const [dueDate, setDueDate] = useState(taskData.dueDate);
  const [status, setStatus] = useState(taskDetails.status)
  const [priority, setPriority] = useState(taskDetails.priority)
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const assignedToList = taskDetails && taskDetails.taskAssignments?.map((assignment: { assignedTo: { name: any; id: any; }; }) => {
    const { name, id } = assignment?.assignedTo;
    return { label: name, value: id };
  });

  const toggleEdit = (() => setIsEditOpen(!isEditOpen));

  useEffect(() => {
    setTaskData(taskDetails)
  },[taskDetails])
  
  useEffect(() => {
    const roleValues: any = [];
    userList && userList.map((item: any) => {
      roleValues.push({ value: item.id, label: item.name });
    });
    setUsers(roleValues);
  }, [userList]);

  useEffect(() => {
    const task: any = [];
    tasksList && tasksList.map((item: any) => {
      task.push({ value: item.id, label: item.name });
    });
    setTaskList(task);
  }, [tasksList]);

  useEffect(() => {
    dispatch(usersListGetRequest())
    dispatch(tasksListGetRequest(id));
  }, [])

  const [sortBy, setsortBy] = useState<any>(assignedToList);

  const handleSelectChange = (selectedOptions: any) => {
    if (!Array.isArray(selectedOptions)) {
      selectedOptions = [selectedOptions];
    }

    const updatedTaskAssignments = selectedOptions.map((option: { label: any; value: any; }) => {
      return {
        assignedTo: {
          name: option.label,
          id: option.value
        }
      };
    });

    setTaskData({
      ...taskDetails,
      taskAssignments: updatedTaskAssignments
    });
  };

  const handleEditTask = () => {
    const userIdString = users && users.map((userString: { value: any; }) => userString.value).join(',');
    const assined = taskData && taskData.taskAssignments?.map((assignment: { assignedTo: { name: any; id: any; }; }) => {
      const { name, id } = assignment.assignedTo;
      return { label: name, value: id };
    });
    const assignedToValues = assined.map((option: { value: any; }) => option.value);

    const data = {
      id: taskData.id,
      title: taskData.title,
      status: taskData.status,
      priority: taskData.priority,
      dueDate: taskData.dueDate,
      studentId: id,
      assignedTo: assignedToValues[0]
    }

    const handleSuccess = (): void => {
      toast(`Task edited successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });

      setIsEditOpen(false);
      dispatch(tasksListGetRequest(id));
      dispatch(getStudentsActivity(id, {}, 0))
    };

    const handleError = (): void => {
      // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      // message();
    };
    dispatch(taskAddRequest(data, handleSuccess, handleError));
  }

  // Converting User ID's to comma seperated string


  // Update To do
  const handleTodoClick = useCallback((arg: any) => {
    setIsEdit(true);
    toggleEdit();
  }, [toggleEdit]);


  const sortbystatus = [
    {
      options: [
        { label: "New", value: "NEW" },
        { label: "Inprogress", value: "INPROGRESS" },
        { label: "Completed", value: "COMPLETED" },
      ],
    },
  ];

  const templateOptions = [
    {
      options: [
        { label: "Template 1", value: "TEMPLATE1" },
        { label: "Template 2", value: "TEMPLATE2" },
        { label: "Template 3", value: "TEMPLATE2" },
      ],
    },
  ];

  const sortbypriority = [
    {
      options: [
        { label: "Low", value: "LOW" },
        { label: "Medium", value: "MEDIUM" },
        { label: "High", value: "HIGH" },
      ],
    },
  ];



  useEffect(() => {
    if (tasksList && tasksList.length > 0) {
      setTaskList(tasksList)
    }
  }, [tasksList])
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div className="custom-option">
          <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
          <span>{props.data.label}</span>
        </div>
      </components.Option>
    );
  };
  return (
    <React.Fragment>
      {taskDetails && taskDetails.id ?
        <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
          <ModalHeader toggle={() => toggleEdit()} className="p-3 bg-primary-subtle"> {"Edit Task"} </ModalHeader>

          <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
            <ModalHeader toggle={toggleEdit} className="p-3 bg-primary-subtle">
              {"Edit Task"}
            </ModalHeader>

            <ModalBody>
              <div id="task-error-msg" className="alert alert-danger py-2"></div>

              <input type="hidden" id="taskid-input" className="form-control" />
              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">Task Title</label>
                <Input
                  type="text"
                  id="task-title-input"
                  className="form-control"
                  placeholder="Enter task title"
                  name="task"
                  onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
                  value={taskData.title}
                />
              </div>
              <div className="mb-2 gap-2 d-flex position-relative">
                <div>
                  <label htmlFor="task-assign-input" className="form-label">Assigned To:</label>
                </div>
                <div>
                  <Label>{assignedToList[0]?.label}</Label>
                </div>
                {/* <div className="select-element">
       <Select
         value={assignedToList}
         isMulti={false}
         onChange={handleSelectChange}
         options={users}
         classNamePrefix="js-example-basic-multiple mb-0"
         components={{ Option: CustomOption }}
       />
     </div> */}
              </div>
              <Row className="g-4 mb-3">
                <Col lg={6}>
                  <label htmlFor="task-status" className="form-label">Status</label>
                  <Input
                    name="status"
                    type="select"
                    className="form-select"
                    id="status-field"
                    onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
                    value={taskData.status}
                  >
                    {sortbystatus.map((item, key) => (
                      <React.Fragment key={key}>
                        {item.options.map((optionItem, optionKey) => (
                          <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </Input>
                </Col>
                <Col lg={6}>
                  <label htmlFor="priority-field" className="form-label">Priority</label>
                  <Input
                    name="priority"
                    type="select"
                    className="form-select"
                    id="priority-field"
                    onChange={(e) => setTaskData({ ...taskData, priority: e.target.value })}
                    value={taskData.priority}
                  >
                    {sortbypriority.map((sortItem, sortKey) => (
                      <React.Fragment key={sortKey}>
                        {sortItem.options.map((optionItem, optionKey) => (
                          <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </Input>
                </Col>
              </Row>
              <div className="mb-4">
                <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
                <Flatpickr
                  name="dueDate"
                  id="date-field"
                  className="form-control"
                  placeholder="Due date"
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                  }}
                  onChange={dates => {
                    const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                   const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                    setDueDate(formattedDate); // Set the formatted date to state
                    setTaskData({ ...taskDetails, dueDate: formattedDate }); // Update task details
                  }}
                  value={(taskDetails.dueDate)}
                />
              </div>
              {/* <div className="mb-4">
                        <label htmlFor="task-duedate-input" className="form-label">Template</label>
                        <Select
                                    placeholder="Choose template..."
                                    value={template}
                                    isMulti={false}
                                    onChange={(template: any) => {
                                        setTemplate(template);
                                    }}
                                    options={templateOptions}
                                    classNamePrefix="js-example-basic-multiple mb-0"
                                />
                        </div> */}
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" color='primary' className="btn btn-ghost-primary" onClick={() => {
                  toggleEdit();
                  setTaskData(taskDetails);
                }}>
                  <i className="ri-close-fill align-bottom"></i> Close
                </Button>
                <Button type="submit" color='primary' className="btn btn-primary" onClick={handleEditTask} id="editTodo">
                  {"Update Task"}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Modal >
        : null}
    </React.Fragment >
  );
};

export default EditTasks;