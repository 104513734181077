import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { CourseListGetRequest, courseCreateRequest } from "store/course/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
    

const AddEntry = ({ props, show, onCloseClick, dataFields }: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const OnAddEntry = (formValues: any) => {
    let data = formValues;
    const handleSuccess = (body: any) => {
        const message = () => toast(`Course Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
        message();
        onCloseClick()
        dispatch(CourseListGetRequest());
    }
    const handleError = () => {

    }
    dispatch(courseCreateRequest( data, handleSuccess ,handleError))
  }

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody>
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Entry'} />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

