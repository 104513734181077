import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ArchiveModal = ({ props, show, onCloseClick, data, record, onSave, model }: any) => {
    const [groupName, setGroupName] = useState('')
    return (
            <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
                <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                    {props.t("properties.create_a_new_property_group")} {model.toUpperCase()}
                </ModalHeader>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <p className="text-muted fs-15 mb-4">{props.t("properties.create_group_disclaimer")}</p>
                        <Input
                            type="text"
                            size={14}
                            className="search"
                            placeholder="Enter Group Name"
                            onChange={(e) => setGroupName(e.target.value)}
                            value={groupName}
                        />
                        <div className="hstack gap-2 justify-content-center mt-3">
                            <Button className="btn btn-primary" onClick={() => onSave(groupName)} disabled={!groupName}>
                                {props.t("properties.save")}
                            </Button>
                            <Button color="danger" onClick={() => onCloseClick()} outline>
                                {props.t("properties.cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
    ) as unknown as JSX.Element;
};

export default ArchiveModal;