import { Reducer } from "redux";
import { CalendarActionTypes, CalendarState } from "./types";
export const initialState: CalendarState = {
    data: null,
    loading: false,
    error: null,
    events: null,
    token: "",
    userData: null
};

const reducer: Reducer<CalendarState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case CalendarActionTypes.POST_GOOGLECALENDARSYNC_REQUEST: {
            return { ...state, loading: true };
        }
        case CalendarActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS: {
            console.log("In Calendar Sync Reducer :- " + action.payload.token);
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            return { ...state, loading: false, token: action.payload.token };
        }
        case CalendarActionTypes.POST_GOOGLECALENDARSYNC_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CalendarActionTypes.POST_CREATEGOOGLEEVENT_REQUEST: {
            return { ...state, loading: true };
        }
        case CalendarActionTypes.POST_CREATEGOOGLEEVENT_SUCCESS: {
            return { ...state, loading: false, token: action.payload.token };
        }
        case CalendarActionTypes.POST_CREATEGOOGLEEVENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CalendarActionTypes.GET_GOOGLECALENDARSYNC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CalendarActionTypes.GET_GOOGLECALENDARSYNC_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case CalendarActionTypes.GET_GOOGLECALENDARSYNC_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            case CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_SUCCESS:
            return {
                ...state,
                email: action.payload,
                loading: false,
            };
        case CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            case CalendarActionTypes.GET_GOOGLECALENDAREVENTS_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null
                };
            case CalendarActionTypes.GET_GOOGLECALENDAREVENTS_SUCCESS:
                return {
                    ...state,
                    events: action.payload.items,
                    loading: false,
                    error: null
                };
            case CalendarActionTypes.GET_GOOGLECALENDAREVENTS_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                };
                case CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_REQUEST:
                    return {
                        ...state,
                        loading: true,
                        error: null
                    };
                case CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_SUCCESS:
                    return {
                        ...state,
                        events: action.payload.items,
                        loading: false,
                        error: null
                    };
                case CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_ERROR:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload
                    };
                case CalendarActionTypes.SIGNUP_MICROSOFT_REQUEST:
                    return {
                    };
                case CalendarActionTypes.SIGNUP_MICROSOFT_SUCCESS:
                    return {
                        payload: action.payload
                    };
                case CalendarActionTypes.SIGNUP_MICROSOFT_ERROR:
                    return {
                        payload: action.payload,                    };
        default: {
            return state;
        }
    }
};

export { reducer as CalendarReducer };