export interface templatesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Template {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum TemplateActionTypes {
    GET_TEMPLATEMETADATA_REQUEST = "@@template/GET_TEMPLATEMETADATA_REQUEST",
    GET_TEMPLATEMETADATA_SUCCESS = "@@template/GET_TEMPLATEMETADATA_SUCCESS",
    GET_TEMPLATEMETADATA_ERROR = "@@template/GET_TEMPLATEMETADATA_ERROR",
    GET_TEMPLATE_REQUEST = "@@template/GET_TEMPLATE_REQUEST",
    GET_TEMPLATE_SUCCESS = "@@template/GET_TEMPLATE_SUCCESS",
    GET_TEMPLATE_ERROR = "@@template/GET_TEMPLATE_ERROR",
    GET_TEMPLATES_REQUEST = "@@template/GET_TEMPLATES_REQUEST",
    GET_TEMPLATES_SUCCESS = "@@template/GET_TEMPLATES_SUCCESS",
    GET_TEMPLATES_ERROR = "@@template/GET_TEMPLATES_ERROR",
    PUT_TEMPLATE_REQUEST = "@@template/PUT_TEMPLATE_REQUEST",
    PUT_TEMPLATE_SUCCESS = "@@template/PUT_TEMPLATE_SUCCESS",
    PUT_TEMPLATE_ERROR = "@@template/PUT_TEMPLATE_ERROR",
    DELETE_TEMPLATE_REQUEST = "@@template/DELETE_TEMPLATE_REQUEST",
    DELETE_TEMPLATE_SUCCESS = "@@template/DELETE_TEMPLATE_SUCCESS",
    DELETE_TEMPLATE_ERROR = "@@template/DELETE_TEMPLATE_ERROR",
    POST_TEMPLATE_REQUEST = "@@template/POST_TEMPLATE_REQUEST",
    POST_TEMPLATE_SUCCESS = "@@template/POST_TEMPLATE_SUCCESS",
    POST_TEMPLATE_ERROR = "@@template/POST_TEMPLATE_ERROR",
    GET_TEMPLATES_HIERARCHY_REQUEST = "@@template/GET_TEMPLATES_HIERARCHY_REQUEST",
    GET_TEMPLATES_HIERARCHY_SUCCESS = "@@template/GET_TEMPLATES_HIERARCHY_SUCCESS",
    GET_TEMPLATES_HIERARCHY_ERROR = "@@template/GET_TEMPLATES_HIERARCHY_ERROR",
}

export interface TemplatesState {
    readonly templates: Template[] | null;
    readonly template: Template | null;
    readonly pagination: Pagination | null;
    readonly templatesMetadata: templatesMetadata[];
    readonly templatelist: any;
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly hierarchytemplates: any;
    readonly loading: boolean;
    readonly dataloading: boolean;
    readonly metaloading: boolean;
}
