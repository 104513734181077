import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//Import Icons
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button
} from "reactstrap";
import { events, calenderDefaultCategories, defaultevent } from "../../common/data/calender"
import * as Yup from "yup";
import { useFormik } from "formik";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";
import { useSelector, useDispatch } from "react-redux";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Simple bar
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';
import listPlugin from '@fullcalendar/list';
import { createGoogleCalendarEvent, deleteGoogleEvent, getGoogleCalendarData, getGoogleCalendarEvents } from "store/calendarSync/action";
import { ApplicationState } from "store";
import moment from "moment";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";

const BYDAY: any = { "SU": 0, "MO": 1, "TU": 2, "WE": 3, "TH": 4, "FR": 5, "SA": 6 }
export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface PostEvent {
  summary: string;
  location: string;
  description: string;
  eventDate: Date | null;
  startDateTime: Date | null;
  endDateTime: Date | null;
  attendees: string[];
}

// Function to check if the required fields are empty
const checkObjectEmpty = (obj: PostEvent) => {
  // Destructure to exclude 'description'
  const { description, ...rest } = obj;

  // Check if any of the remaining fields are empty, null, or undefined
  return Object.values(rest).some(value => value === null || value === undefined || value === '');
};

const Meetings = () => {
  var date = new Date();
  var d = date.getDate();
  var m = date.getMonth();
  var y = date.getFullYear();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(true);
  const applicationDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
  const eventData = useSelector((state: ApplicationState) => state.calendarSync?.events);
  const [event, setEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [selectedNewDay, setSelectedNewDay] = useState(moment().format())
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditButton, setIsEditButton] = useState<boolean>(true);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteEvent, setDeleteEvent] = useState<string>('');
  const [eventName, setEventName] = useState<string>("");
  const [accountDetails, setAccountDetails] = useState<any>();
  const [newEvent, setNewEvent] = useState<string>("");
  const [newStartTime, setNewStartTime] = useState(moment().add(30, 'minutes').format());
  const [newEndTime, setNewEndTime] = useState(moment(newStartTime).add(60, 'minutes').format());
  const [disabled, setdisabled] = useState(true)
  const [newLocation, setNewLocation] = useState<any>();
  const [newDescription, setNewDescription] = useState<any>();
  const [dateStartTime, setDateStartTime] = useState(moment().add(30, 'minutes').format());
  const [dateEndTime, setDateEndTime] = useState(moment(dateStartTime).add(60, 'minutes').format());
  const [emails, setEmails] = useState<any[]>([]);
  const [studentId, setStudentId] = useState(null)
  const [info, setInfo] = useState<any>({});
  const [emailData, setEmailData] = useState<any>({
    to: ""
  })
  const [postEvent, setPostEvent] = useState<PostEvent>({
    summary: "",
    location: "",
    description: "",
    eventDate: selectedNewDay,
    startDateTime: dateStartTime,
    endDateTime: dateEndTime,
    attendees: [info.email]
  })

  // useEffect(() => {
  //   // If you want to reset to current date and time whenever modal opens
  //   setSelectedNewDay(moment(new Date()).format());
  //   setNewStartTime(moment(new Date()).format());
  //   setNewEndTime(moment(newStartTime).add(30, 'minutes').format());
  // }, []);
  
  const isJson = (str: any) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}

useEffect(() => {
  if (info.email) {
    // setEmailData((prevState: any) => ({
    //   ...prevState,
    //   to: info.email
    // }));
    setPostEvent(prevState => ({
      ...prevState,
      attendees: [info.email]
    }));
  }
  
}, [info.email]);

  useEffect(() => {
    if (applicationDetails && applicationDetails?.valuesJson?.students && applicationDetails?.valuesJson?.students.id) {
        setStudentId(applicationDetails?.valuesJson?.students.id);
        setEmailData((prevState: any) => ({
          ...prevState,
          to: applicationDetails?.valuesJson?.students.email
        }));
        setPostEvent(prevState => ({
          ...prevState,
          attendees: [applicationDetails?.valuesJson?.students.email]
        }));
    }
}, [applicationDetails]);

useEffect(() => {
  if (applicationDetails && applicationDetails.valuesJson) {
      let values = applicationDetails?.valuesJson && isJson(applicationDetails.valuesJson) ? JSON.parse(applicationDetails.valuesJson) : {}
      setInfo(values)

  }
}, [applicationDetails])

  const events: any = [];

  eventData?.forEach((event, index) => {
    const { id, attendees, summary, start, end, hangoutLink, recurrence, recurringEventId, location, description }: any = event;

    if ((recurrence && recurrence.length > 0) || recurringEventId) {
      if (recurrence && recurrence.length > 0) {
        const rule = recurrence[0]?.split(';').reduce((acc: any, item: string) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return acc;
        }, {});
        if (rule['RRULE:FREQ'] == 'WEEKLY') {
          const byday = rule?.BYDAY.split(',').map((item: string) => {
            return BYDAY[item]
          })
          events.push({
            id: id,
            attendees: attendees,
            location: location,
            title: summary,
            description: description,
            daysOfWeek: byday,
            start: start?.dateTime?.value || null,
            end: end?.dateTime?.value || null,
            endRecur: rule.UNTIL,
            className: "bg-info-subtle",
            meetingLink: hangoutLink,
          });
        } else {
          events.push({
            id: id,
            attendees: attendees,
            title: summary,
            description: description,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            location: location,
            start: start?.dateTime?.value || null,
            end: end?.dateTime?.value || null,
            endRecur: rule.UNTIL,
            className: "bg-info-subtle",
            meetingLink: hangoutLink,
          });
        }
      }
    } else {
      events.push({
        id: id,
        attendees: attendees,
        title: summary,
        description: description,
        daysOfWeek: null,
        location: location,
        start: start?.dateTime?.value || null,
        end: end?.dateTime?.value || null,
        className: "bg-info-subtle",
        meetingLink: hangoutLink,
      });
    }
  });
  useEffect(() => {
    dispatch(getGoogleCalendarData())
    setAccountDetails(googleConnect)
  }, [])
  useEffect(() => {
    // let queryParams: any = new URLSearchParams({});
    const email = googleConnect[0]?.email;
    // googleConnect && queryParams.set(googleConnect)
    dispatch(getGoogleCalendarEvents(email))

  }, [])
  const toggle = () => {
    if (modal) {
      setModal(false);
      // setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };
  /**
   * Handling date click on calendar
   */
  const handleCreateEvent = () => {
    const email = googleConnect[0]?.email;

    const emailArray = emails.map((item: any) => {
      return item.value
    });
    const emailList = JSON.stringify(emailArray);
    // const data = {
    //   summary: newEvent,
    //   location: validation.values.location ? validation.values.location : "Virtual",
    //   description: validation.values.description ? validation.values.description : "",
    //   eventDate: selectedNewDay,
    //   startDateTime: newStartTime,
    //   endDateTime: newEndTime,
    //   // attendees: JSON.stringify(emailArray)
    //   attendees: emailData.to
    // }
    const handleSuccess = () => {

      clearNewMeeting();
      dispatch(getGoogleCalendarEvents(email))
      toggle();
    }
    dispatch(createGoogleCalendarEvent(email, postEvent, handleSuccess))
  }

  const clearNewMeeting = () => {
    setPostEvent({
      summary: "",
      location: "",
      description: "",
      eventDate: selectedNewDay,
      startDateTime: dateStartTime,
      endDateTime: dateEndTime,
      attendees: [info.email],
    })
    setNewEvent('');
    setNewLocation('');
    setNewDescription('');
  }
  const handleDateClick = (arg: any) => {
    if (arg) {
        // Get the selected date
        const selectedDate = moment(arg.date);

        // Round up to the next hour
        let roundedStartTime = moment(selectedDate).startOf('hour');
        if (moment().isAfter(roundedStartTime)) {
            roundedStartTime.add(1, 'hour');
        }

        // Set end time to 30 minutes after the start time
        const endTime = moment(roundedStartTime).add(30, 'minutes');

        // Format the dates for the state
        const formattedStartDateTime = roundedStartTime.format();
        const formattedEndDateTime = endTime.format();

        setPostEvent((prevObject) => ({
            ...prevObject,
            eventDate: formattedStartDateTime,
            startDateTime: formattedStartDateTime,
            endDateTime: formattedEndDateTime,
        }));

        // Update state with new dates
        setSelectedNewDay(new Date(formattedStartDateTime));
        setNewStartTime(new Date(formattedStartDateTime));
        setNewEndTime(new Date(formattedEndDateTime));
    }

    toggle();
};

  const str_dt = function formatDate(date: any) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg: any) => {

    const events = arg.event;
    const st_date = events.start?.dateTime?.value;
    const ed_date = events.end?.dateTime?.value;
    const r_date =
      ed_date == null
        ? str_dt(st_date)
        : str_dt(st_date) + " to " + str_dt(ed_date);
    const er_date = ed_date === null ? [st_date] : [st_date, ed_date];

    setEvent({
      id: events.id,
      title: events.title,
      start: events.start,
      end: events.end,
      className: "bg-info-subtle",
      meetingLink: events.extendedProps.meetingLink,
      // category: events.classNames[0],
      location: events?._def.extendedProps.location ? events?._def.extendedProps.location : "No Loaction",
      description: events._def.extendedProps.description,
      defaultDate: er_date,
      datetag: r_date,
    });
    setEventName(events.title)
    setDeleteEvent(events.id);
    setIsEdit(true);
    setIsEditButton(false);
    toggle();
  };
  
  const handleDeleteEvent = (eventId: any) => {
    const email = googleConnect[0]?.email;
    const handleSuccess = () => {
      setDeleteModal(false)
      dispatch(getGoogleCalendarEvents(email))
      // toggle();
    }
    dispatch(deleteGoogleEvent(email, event.id, handleSuccess))

  };

  // events validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (events && events.id) || "",
      title: (events && events.title) || "",
      category: (event && event.category) || "",
      location: (events && events.location) || "",
      description: (events && events.description) || "",
      defaultDate: (event && event.defaultDate) || [],
      eventDate: (events && events.eventDate) || [],
      start: (events && events.start) || "",
      end: (events && events.end) || ''
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
      location: Yup.string().required("Please Enter Your Location"),
      description: Yup.string().required("Please Enter Your Description"),
      eventDate: Yup.date().required('Event date is required'),
      start: Yup.date().required('Start Time is required'),
      end: Yup.date().required('End Time is required'),
      defaultDate: Yup.array().of(Yup.date()).required('Date range is required').min(2, 'Select at least two dates'),
    }),
    onSubmit: (values) => {
      var updatedDay: any = "";
      if (selectedNewDay) {
        updatedDay = new Date(selectedNewDay[1]);
        updatedDay.setDate(updatedDay.getDate() + 1);
      }

      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          className: values.category,
          start: selectedNewDay ? selectedNewDay[0] : event.start,
          end: selectedNewDay ? updatedDay : event.end,
          location: values.location,
          description: values.description,
        };
        // update event
        // dispatch(onUpdateEvent(updateEvent));
        validation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedNewDay[0],
          end: updatedDay,
          className: values["category"],
          location: values["location"],
          description: values["description"],
        };
        // save new event
        // dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }

      // setSelectedDay(null);
      setSelectedNewDay(null);
      toggle();
    },
  });

  const handleCreateEventClick = (arg: any) => {
    // Create a moment object for the selected date
    const selectedDate = moment(arg);
    const currentTime = moment();
    
    // Round the selected date to the next whole hour
    let roundedStartTime = moment(selectedDate).startOf('hour');
    if (currentTime.isAfter(roundedStartTime)) {
        roundedStartTime.add(1, 'hour');
    }
    
    // Set end time to 30 minutes after the rounded start time
    const endTime = moment(roundedStartTime).add(30, 'minutes');
  
    // Format the dates for state updates
    const formattedStartDateTime = roundedStartTime.format();
    const formattedEndDateTime = endTime.format();
  
    // Update state
    setSelectedNewDay(new Date(formattedStartDateTime));
    setDateStartTime(formattedStartDateTime);
    setDateEndTime(formattedEndDateTime);
    setNewStartTime(new Date(formattedStartDateTime));
    setNewEndTime(new Date(formattedEndDateTime));
    
    // Toggle any additional logic or UI changes
    toggle();
  };

  const submitOtherEvent = () => {

    document.getElementById("form-event")?.classList.remove("view-event");

    document
      .getElementById("event-title")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-category")?.classList.replace("d-none", "d-block");
    (document.getElementById("event-start-date")?.parentNode as HTMLElement).classList.remove("d-none");
    document
      .getElementById("event-start-date")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-location")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-description")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date-tag")?.classList.replace("d-block", "d-none");
    document
      .getElementById("event-location-tag")?.classList.replace("d-block", "d-none");
    document
      .getElementById("event-description-tag")?.classList.replace("d-block", "d-none");

    setIsEditButton(true);
  };

  /**
   * On category darg event
   */
  const onDrag = (event: any) => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = (event: any) => {
    const date = event["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") === -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 1000),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      // dispatch(onAddNewEvent(modifiedData));
    }
  };

  const handleDateChange = (dateType: string, date: any) => {
    // Create a moment object for the selected date
    let selectedDate = moment(date);
    let currentTime = moment();
    
    let combinedDateTime: any;
    
    switch (dateType) {
      case 'date':
        // Round to the next whole hour
        combinedDateTime = moment(selectedDate).startOf('hour');
        if (moment().isAfter(combinedDateTime)) {
            combinedDateTime.add(1, 'hour');
        }
  
        // Format the rounded date-time
        const formattedStartDateTime = combinedDateTime.format();
        const formattedEndDateTime = moment(formattedStartDateTime).add(30, 'minutes').format();
  
        // Update state
        setPostEvent((prevObject) => ({
          ...prevObject,
          eventDate: formattedStartDateTime,
          startDateTime: formattedStartDateTime,
          endDateTime: formattedEndDateTime,
        }));
        setNewStartTime(new Date(formattedStartDateTime));
        setNewEndTime(new Date(formattedEndDateTime));
        break;
  
      case 'startTime':
        // Set the start time based on the existing event date
        combinedDateTime = moment(postEvent.eventDate).set({
          hour: selectedDate.hour(),
          minute: selectedDate.minute(),
          second: selectedDate.second(),
        });
  
        // Round to the next whole hour if the time is in the past
        if (moment().isAfter(combinedDateTime)) {
            combinedDateTime.add(1, 'hour');
        }
  
        // Format the updated start and end times
        const newStartDateTime = combinedDateTime.format();
        const newEndDateTime = moment(newStartDateTime).add(30, 'minutes').format();
  
        // Update state
        setNewStartTime(new Date(newStartDateTime));
        setNewEndTime(new Date(newEndDateTime));
  
        setPostEvent((prevObject) => ({
          ...prevObject,
          startDateTime: newStartDateTime,
          endDateTime: newEndDateTime,
        }));
        break;
  
      case 'endTime':
        // Set the end time based on the existing start time
        combinedDateTime = moment(postEvent.startDateTime).set({
          hour: selectedDate.hour(),
          minute: selectedDate.minute(),
          second: selectedDate.second(),
        });
  
        // Ensure end time is always after start time
        if (moment(combinedDateTime).isBefore(postEvent.startDateTime)) {
          combinedDateTime = moment(postEvent.startDateTime).add(30, 'minutes');
        }
  
        // Update state
        setPostEvent((prevObject) => ({
          ...prevObject,
          endDateTime: combinedDateTime.format(),
        }));
        break;
  
      default:
        break;
    }
  };

  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setEmails(newValue);
  };
  const filterSuggestions = (inputValue: string) => {
    return emails.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const promiseOptions = (inputValue: string) =>
    new Promise<SuggestionOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSuggestions(inputValue));
      }, 1000);
    });
    const handleDateSelect = (selectInfo: any) => {
      const calendarApi = selectInfo.view.calendar;
      const start = selectInfo.startStr;
      const end = selectInfo.endStr;
  
      // Open a prompt to get the event title from the user
      let title;
  
      if (title) {
        calendarApi.addEvent({
          title: title,
          start: start,
          end: end,
          allDay: false
        });
      }
    };
 console.log("Applicatio details",applicationDetails)
  document.title = "Calendar | Zilter";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteEvent(event.id)}
        onCloseClick={() => { setDeleteModal(false) }} recordId={event.id} />
      <div className="">
        <Row>
          <Col xs={12}>
            <Row>
              <Col xl={3}>
              {googleConnect && googleConnect.length !== 0 ? (
                <Card className="bg-light card-h-100">
                  <CardBody>
                    <Button
                      color="primary"
                      type="button"
                      className="text-nowrap btn btn-label"
                      id="btn-new-event"
                      onClick={() => handleCreateEventClick(new Date())}
                    >
                      <i className="ri-calendar-event-fill label-icon align-middle fs-16 me-2"></i> Create New Event
                    </Button>
                  </CardBody>
                </Card>
              ) : null }
{googleConnect && googleConnect.length !== 0 ? (
                  <div>
                  <h5 className="mb-1">Upcoming Events</h5>
                  <p className="text-muted">Don't miss scheduled events</p>
                  <SimpleBar
                    className="pe-2 me-n1 mb-3"
                    style={{ height: "400px" }}
                  >
                    <div id="upcoming-event-list">
                      {events &&
                        (events || []).map((event: any, key: any) => (
                          <div key={key}>
                            <UpcommingEvents event={event} />
                          </div>
                        ))}
                    </div>
                  </SimpleBar>
                </div>
                ) : (<>
                <Card>
                  <CardBody className="bg-info-subtle">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <FeatherIcon
                          icon="calendar"
                          className="text-info icon-dual-info"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-15">Welcome to your Calendar!</h6>
                        <p className="text-muted fs-14 fw-bold mb-0">
                        To create and view meetings you have to connect your calendar with social account in your profile.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/* <Label className="fs-16 text-warning fw-bold">To create and view meetings you have to connect your calendar in your profile.</Label> */}
                </>)}              </Col>

              <Col lg={9}>
                <Card className="bg-light card-h-100">
                  <CardBody>
                  <FullCalendar
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin
                      ]}
                      select={handleDateSelect}
                      initialView="dayGridMonth"
                      slotDuration={"00:15:00"}
                      // handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                      }}
                      events={events}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      drop={onDrop}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ clear: "both" }}></div>

            <Modal isOpen={modal} id="event-modal" centered>
              <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-primary-subtle modal-title">
                {!!isEdit ? eventName : "Add Event"}
              </ModalHeader>
              <ModalBody>
                <Form
                  className={!!isEdit ? "needs-validation view-event" : "needs-validation"}
                  name="event-form"
                  id="form-event"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {/* {!!isEdit ? (
                    <div className="text-end">
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-primary"
                        id="edit-event-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          submitOtherEvent();
                          return false;
                        }}>
                        Edit
                      </Link>
                    </div>

                  ) : null} */}

                  <div className="event-details">

                    <div className="d-flex mb-2">

                      <div className="flex-grow-1 d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="ri-calendar-event-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="d-block text-primary fw-semibold mb-0" id="event-start-date-tag">
                            {event ? moment(event?.start).format('DD MMM YYYY') : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0 me-3">
                        <i className="ri-time-line text-muted fs-16"></i>
                      </div>

                      <div className="flex-grow-1">

                        <h6 className="d-block text-primary fw-semibold mb-0">

                          <span id="event-timepicker1-tag">
                            {moment(event?.start).format('hh:mm A')}
                          </span> -
                          <span id="event-timepicker2-tag">
                            {moment(event?.end).format('hh:mm A')}
                          </span>
                        </h6>

                      </div>

                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0 me-3">
                        <i className="ri-map-pin-line text-muted fs-16"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="d-block text-primary fw-semibold mb-0">
                          <span id="event-location-tag">
                            {event?.location}

                            {/* <Link t></Link> */}
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex mb-3">
                      <div className="flex-shrink-0 me-3">
                        <i className="ri-discuss-line text-muted fs-16"></i>
                      </div>
                      <div className="flex-grow-1">
                        <p className="d-block text-primary mb-0" id="event-description-tag">
                          {events && event?.description !== undefined ? event?.description : "No Description"}
                        </p>
                      </div>

                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0 me-3">
                        <i className="ri-team-line text-muted fs-16"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="d-block text-primary fw-semibold mb-0">
                          <span id="event-location-tag">
                            {event?.attendees}
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div><Link to={event?.meetingLink} target="_blank" rel="noopener noreferrer" className="text-info fw-medium">
                      {" "}
                      Join meeting
                    </Link></div>
                  </div>
                  <Row className="event-form">
                    <Col xs={12}>
                    <div className="mb-3">
                        <Label className="form-label">Event Name</Label>
                        <Input
                          className={!!isEdit ? "d-none" : "d-block"}
                          placeholder="Enter event name"
                          type="text"
                          name="title"
                          id="event-title"
                          onChange={(e) => {
                            setNewEvent(e.target.value)
                            setPostEvent((prevObject) => ({
                              ...prevObject,
                              summary: e.target.value
                          }))
                            // validation.handleChange()
                          }
                          }
                          onBlur={validation.handleBlur}
                          value={postEvent.summary || ""} />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid" className="d-block">{!postEvent.summary ? validation.errors.title : null}</FormFeedback>)
                          : null}
                      </div>
                    </Col>

                    <Col xs={12}>
                    <div className="mb-3">
                      <Label>Event Date</Label>
                      <div className={!!isEdit ? "input-group d-none" : "input-group"}>
                        <Flatpickr
                        // defaultValue={selectedNewDay}
                          className="form-control"
                          id="event-start-date"
                          name="defaultDate"
                          placeholder="Select Date"
                          value={selectedNewDay}
                          options={{
                            // mode: "range",
                            dateFormat: "Y-m-d",
                            // minDate: new Date(),
                          }}
                          onChange={(date) => { handleDateChange("date", moment(date[0]))
                          }}
                        />
                        <span className="input-group-text">
                          <i className="ri-calendar-event-line"></i>
                        </span>
                      </div>
                      {validation.touched.eventDate && validation.errors.eventDate ? (
                        <FormFeedback type="invalid" className="d-block">{!selectedNewDay ? validation.errors.eventDate : null} </FormFeedback>
                      ) : null}
                    </div>
                    </Col>
                    <Col xs={6}>
                    <div className="mb-3">
                        <Label>Start Time</Label>
                        <div className="input-group">
                          <Flatpickr className="form-control"
                            name="start"
                            value={newStartTime}
                            onChange={(date) => {handleDateChange("startTime", moment(date[0]))
                              validation.setFieldValue("start", date[0]);
                            }}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                            }} />
                          <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                        </div>
                        {validation.touched.start && validation.errors.start ? (
                          <FormFeedback type="invalid" className="d-block">{!newStartTime ? validation.errors.start : null} </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label>End Time</Label>
                        <div className="input-group">
                          <Flatpickr className="form-control input-group"
                            name="end"
                            value={newEndTime}
                            onChange={(date) => {handleDateChange("endTime", moment(date[0]))
                              validation.setFieldValue("end", date[0])
                            }}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                            }} />
                          <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                        </div>
                        {validation.touched.end && validation.errors.end ? (
                          <FormFeedback type="invalid" className="d-block">{!newEndTime ? validation.errors.end : null} </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-2">
                        <Label htmlFor="exampleInputrounded" className="form-label">
                          Invitee Email
                        </Label>
                        <DtsEmailsInputs placeholder={"Invitee email"} defaultValue={`${emailData.to}`} setdisabled={setdisabled} onChange={(values: any) => {
                          setPostEvent((prevObject) => ({
                            ...prevObject,
                            attendees: [...values]
                        }))
                          setEmailData({ ...emailData, to: values })}} />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="event-location">Location</Label>
                        <div>
                          <Input
                            type="text"
                            className={!!isEdit ? "d-none" : "d-block"}
                            name="location"
                            id="event-location"
                            placeholder="Event location"
                            onChange={(e) => {
                              setNewLocation(e.target.value)
                              setPostEvent((prevObject) => ({
                                ...prevObject,
                                location: e.target.value
                            }))
                              // validation.handleChange()
                            }
                            }
                            onBlur={validation.handleBlur}
                            value={postEvent.location || ""} />
                          {validation.touched.location && validation.errors.location ? (
                            <FormFeedback type="invalid" className="d-block">{!postEvent.location ? validation.errors.location : null}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <textarea
                          className={!!isEdit ? "form-control d-none" : "form-control d-block"}
                          id="event-description"
                          name="description"
                          placeholder="Enter a description"
                          rows={3}
                          onChange={(e) => {
                            setNewDescription(e.target.value)
                            setPostEvent((prevObject) => ({
                              ...prevObject,
                              description: e.target.value
                          }))
                          }}
                          onBlur={validation.handleBlur}
                          value={postEvent.description || ""}></textarea>
                        {/* {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid" className="d-block">{!newDescription ? validation.errors.description : null}</FormFeedback>
                        ) : null} */}
                      </div>
                    </Col>
                  </Row>
                  <div className="hstack gap-2 justify-content-end">
                    {!!isEdit && (
                      <button
                        type="button"
                        className="btn btn-soft-danger"
                        id="btn-delete-event"
                        onClick={() => {
                          toggle();
                          setDeleteModal(true);
                        }}
                      >
                        <i className="ri-close-line align-bottom"></i> Delete
                      </button>
                    )}
                    {isEditButton &&
                      <button type="button" disabled={checkObjectEmpty(postEvent)} onClick={handleCreateEvent} className="btn btn-success" id="btn-save-event">
                        {!!isEdit ? "Edit Event" : "Add Event"}

                      </button>}
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
Meetings.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Meetings;