export interface coursesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Course {
    id: string,
    userId: string,
    valuesJson: string,
    notes: notes[],
    tasks: any,
    email: any,
    university: any
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum CourseActionTypes {
    GET_COURSEMETADATA_REQUEST = "@@course/GET_COURSEMETADATA_REQUEST",
    GET_COURSEMETADATA_SUCCESS = "@@course/GET_COURSEMETADATA_SUCCESS",
    GET_COURSEMETADATA_ERROR = "@@course/GET_COURSEMETADATA_ERROR",
    GET_COURSE_REQUEST = "@@course/GET_COURSE_REQUEST",
    GET_COURSE_SUCCESS = "@@course/GET_COURSE_SUCCESS",
    GET_COURSE_ERROR = "@@course/GET_COURSE_ERROR",
    GET_COURSES_REQUEST = "@@course/GET_COURSES_REQUEST",
    GET_COURSES_SUCCESS = "@@course/GET_COURSES_SUCCESS",
    GET_COURSES_ERROR = "@@course/GET_COURSES_ERROR",
    GET_FILTER_COURSES_REQUEST = "@@course/GET_FILTER_COURSES_REQUEST",
    GET_FILTER_COURSES_SUCCESS = "@@course/GET_FILTER_COURSES_SUCCESS",
    GET_FILTER_COURSES_ERROR = "@@course/GET_FILTER_COURSES_ERROR",
    PUT_COURSE_REQUEST = "@@course/PUT_COURSE_REQUEST",
    PUT_COURSE_SUCCESS = "@@course/PUT_COURSE_SUCCESS",
    PUT_COURSE_ERROR = "@@course/PUT_COURSE_ERROR",
    DELETE_COURSE_REQUEST = "@@course/DELETE_COURSE_REQUEST",
    DELETE_COURSE_SUCCESS = "@@course/DELETE_COURSE_SUCCESS",
    DELETE_COURSE_ERROR = "@@course/DELETE_COURSE_ERROR",
    POST_COURSE_REQUEST = "@@course/POST_COURSE_REQUEST",
    POST_COURSE_SUCCESS = "@@course/POST_COURSE_SUCCESS",
    POST_COURSE_ERROR = "@@course/POST_COURSE_ERROR",
}

export interface CourseState {
    readonly courses: Course[] | null;
    readonly course: Course | null;
    readonly pagination: Pagination | null;
    readonly coursesMetadata: coursesMetadata[];
    readonly courselist: any;
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly loading: any;
    readonly dataloading: any;
}
