import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Label,
    ListGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar10 from "../../../src/assets/images/users/avatar-10.jpg";
import Activities from "./Activities";
import Notes from "./Notes";
import EditableLabel from "../../../src/Components/Common/EditableLabel";
import Tasks from "./Tasks";
import Meetings from "./Meetings";
import NotesModal from "./Modals/NotesModal";
import EmailModal from "./Modals/EmailModal";
import Email from "./Email";
import TasksModal from "./Modals/TasksModal";
import StudentInfo from "./innerComponent/ApplicationInfo";
import { Note } from "./Modals/model";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ApplicationsListGetRequest, applicationDelete, applicationGetRequest, applicationsMetadataGetRequest, applicationUpdateRequest } from "store/applications/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "./innerComponent/DeleteModal";
import { StudentListGetRequest, studentDelete } from "store/student/action";
import Documents from "./Documents/Documents";
import MeetingsModal from "pages/StudentsDetails/Modals/MeetingsModal";
import ApplicationStatusHandler from "pages/Applications/ApplicationStatusComponent/ApplicationStatusHandler";
import OverviewPane from "./OverviewPane";
import Calls from "./Calls";
import { useCCP } from '../../CCPContext/CCPContext';
import { callAddRequest, callsListGetRequest } from "store/calls/action";
import Emails from "./Emails";
import { viewCreateRequest, ViewListGetRequest, viewUpdateRequest } from "store/views/action";
import ArrangeFields from "Components/Common/FieldsArranger/ArrangeComponent";
import {getAvailableStatuses} from "helpers/workflowStatusHelper";
import StatusFormModel from 'Components/Common/StatusFormModal';
import testSelector from './testSelector.json'

interface DropdownOption {
    id: number;
    label: string;
}

const Applications = (props: any) => {
    const { initializeCCP } = useCCP();
    const navigate = useNavigate();
    let { id } = useParams();
    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const [communicate, setCommunicate] = useState<boolean>(false);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const viewlist: any = useSelector((state: ApplicationState) => state.views.views);
    const metadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [info, setInfo] = useState<any>({});
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [viewId, setViewId] = useState<any>('');
    const [fields, setFields] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<any>("1");
    const [studentId, setStudentId] = useState(null);
    const [filterObject, setFilterObject] = useState<any>({});
    const [deleteAccess, setDeleteAccess] = useState<any>(false);
    const [edit, setEdit] = useState<any>(false);
    const [search, setSearch] = useState("");
    const [selectedStatus, setSelectedStatus]: any = useState(null);
    const [statusForm, setStatusForm] = useState<any>(null);

    const course_link = info.course_link;
    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleButtonClick = () => {
        initializeCCP();
        handleLogAWSCall();
    };

    useEffect(() => {
        if (viewlist && viewlist.length) {
            let view = viewlist.find((item: any) => (item.model === "applications" && item.deletedAt == null && item.location == 'about'))
            if (view && view.id) setViewId(view.id)
            let newFields: any = view && view.fields && isJson(view.fields) ? JSON.parse(view.fields) : {}
            setFields(newFields)
        }
    }, [viewlist])


    useEffect(() => {
        dispatch(ViewListGetRequest());
    }, [])

    const handleLogAWSCall = () => {

        const data = {
            applicationId: id,
            studentId: studentId,
            provider: "AWS"
        }
        const handleSuccess = (): void => {
            toast(`Call logged successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(callsListGetRequest(id));
        };
        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(callAddRequest(data, handleSuccess, handleError));
    }

    useEffect(() => {
        if (applicationDetails && applicationDetails?.valuesJson?.students && applicationDetails?.valuesJson?.students.id) {
            setStudentId(applicationDetails?.valuesJson?.students.id);
        }
    }, [applicationDetails]);
    const [activeSubTab, setActiveSubTab] = useState<string>("1");
    const toggleTab = (tab: any) => {
        if (activeSubTab !== tab) setActiveSubTab(tab);
    };
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [dropdownValues, setDropdownValues] = useState<DropdownOption[]>([]);
    const [initialData, setInitialData] = useState<[]>([]);
    useEffect(() => {
        const jsonData: DropdownOption[] = require("./dropdownValues.json");
        setDropdownValues(jsonData);
        dispatch(applicationGetRequest(id))
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
    }, []);

    useEffect(() => {
        if (applicationDetails && applicationDetails.valuesJson) {
            let values = applicationDetails?.valuesJson ? applicationDetails?.valuesJson : {}
            setInfo(values)
            handleExtensionData(values)
            setSelectedStatus(values?.application_status)
        }
    }, [applicationDetails])

    const handleExtensionData = (data: any) => {
        // Define data that you want to expose to the extension
        const dataToExpose: any = {
            formData: {
                student: {
                    ...data.students,
                    birthDate: "11/07/2009",
                    ext: "India +91",
                    birthDay: "12",
                    birthMonth: "January",
                    birthYear: "2010",
                    nationality: "India",
                    addressLine1: "S.B. Road",
                    addressLine2: "Chatushrungi area",
                    town: "Pune",
                    postalCode: "411036",
                    country: "India",
                    communicationMode: 'phone',
                    mobile: "9090909090"
                },
                course: {
                    ...data.courses,
                    course_name: "Anthropology-MA",
                    courseYear: "January 2025",
                    budgetINR: "India Rupee - INR - r",
                    budgetAmount: "4900000",
                    funding: "I have a scholarship / sponsorship",
                    currentlyLocated: "India",
                    ukHistoryNoButton: "No",
                    educationCounsellor: "No",
                    additionalInfo: "No",
                    visaRefusedUK: "No",
                    visaRefusedAny: "No",
                },
            },
            selector: testSelector,
            link: 'https://apply.intostudy.com/application/course/1QotJ49H368OKgsoeOuwGc/INTOQUB_BEL_PW_40?_gl=1*162qos6*_gcl_au*NDI2OTkxMzMzLjE3MTk5MjI4NzQ'
        };
    
        // Attach data to the global window object
        (window as any).zilterExtData = dataToExpose;

        // Dispatch a custom event to signal that the data is ready
        const event = new CustomEvent('webAppDataReady', {
            detail: dataToExpose
        });
        window.dispatchEvent(event);
    }

    const onChange = (fields: any, viewname: any) => {
        if (viewId) {
            setFields(fields)
            let data = {
                "fields": JSON.stringify(fields)
            }
            const handleSuccess = () => {

            }
            const handleFailure = () => {

            }
            dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))
        }
        else {
            setFields(fields)
            let data = {
                "name": viewname,
                "primary": "true",
                "model": "applications",
                "location": "about",
                "fields": JSON.stringify(fields),
                "filters": JSON.stringify([]),
            }
            const handleSuccess = () => {
                const message = () => toast(`About View Created Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
            }
            const handleFailure = () => {

            }
            dispatch(viewCreateRequest(data, handleSuccess, handleFailure))
        }
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(ApplicationsListGetRequest())
            setisDeleteOpen(false)
            navigate('/applications')
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(applicationDelete(id, handleSuccess, handleFailure))
    }

    const onClickview = () => {
        navigate('/fields')
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications",filters));
    }, [])

    const handleChange = (value: any) => {
        setSearch(value)
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setDeleteAccess(true)
            setEdit(true)
            setCommunicate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'delete') {
                            item.value == "" || item.value == "none" ? setDeleteAccess(false) : setDeleteAccess(true)
                        }
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                        }

                    })
                }
            }
            
            let communicateaccess = subLevelPermissionsList?.communicate;
            if(communicateaccess && communicateaccess?.length) {
                if(communicateaccess && communicateaccess.length) {            
                    communicateaccess.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'communicate') {
                            item.value == "" || item.value == "none" ? setCommunicate(false)  : setCommunicate(true) 
                       }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const onChangeStatus = (value: any) => {
        if(value?.fields?.length) {
            const formFields: any = []
            metadata.forEach((fieldJson: any) => {
                const field = fieldJson.valuesJson && isJson(fieldJson.valuesJson) ? JSON.parse(fieldJson.valuesJson) : {}
                const fieldExists = value?.fields.find((vField: any) => vField.value === field.key)
                if(fieldExists) {
                    if (field) {
                        if (!field.validate) {
                            field.validate = {}; // Initialize validate object if it doesn't exist
                        }
                        field.validate.required = fieldExists?.mandatory ?? false; // Set required based on mandatory, defaulting to false if undefined
                        formFields.push(field); // Push the updated field into formFields
                    }
                }
            })
            setStatusForm({fields: formFields, id, status: value})
        } else {
            setSelectedStatus(value.value);
            const handleSuccess = (body: any) => {
                dispatch(applicationGetRequest(id));
            }
            dispatch(applicationUpdateRequest(id, {application_status: value.value}, handleSuccess, () => { }))
        }
    };
    const onUpdate = (formValues: any, form: any) => {
        const {id, status} = form;
        setSelectedStatus(status.value);
        const handleSuccess = (body: any) => {
            dispatch(applicationGetRequest(id));
        }
        const data = {...formValues, ...{application_status: status.value}}
        dispatch(applicationUpdateRequest(id, data, handleSuccess, () => { }))
        setStatusForm(null)
    }

    let statusField: any = null;
    metadata.forEach((meta: any) => {
        const valuesJson = isJson(meta.valuesJson);
        if(valuesJson.key === 'application_status') {
            statusField = valuesJson;
        }
    })
    const {statuses, status} = getAvailableStatuses(statusField?.process_flows || [], selectedStatus, info?.process_flow);
    return (
        <React.Fragment>
            <ToastContainer />
            <NotesModal
                props={props}
                notesModals={notesModals}
                setNotesModals={setNotesModals}
                onAddNote={setOnAddNote}
            />

            <EmailModal
                props={props}
                emailModals={emailModals}
                setEmailModals={setEmailModals}
            />

            <TasksModal
                props={props}
                tasksModals={tasksModals}
                setTasksModals={setTasksModals}
            />
            <DeleteModal
                props={props}
                show={isDeleteOpen}
                onCloseClick={setisDeleteOpen}
                record={info}
                onDelete={onDelete}
            />
            <MeetingsModal
                info={info}
                props={props}
                meetingsModals={meetingsModals}
                setMeetingsModals={setMeetingsModals}
            />
            {statusForm && <StatusFormModel form={statusForm} onUpdate={onUpdate} onToggle={() => setStatusForm(null)}  />}
            <Col xxl={3} md={3} lg={3}>
                <Card className="mt-1">
                    <CardBody className="p-4">
                        <ListGroup className="mb-3 justify-content-between" horizontal>
                            <Link to="/applications" color="primary" className="btn-sm">
                                <span>
                                    <FeatherIcon icon="chevron-left" className="icon-sm" />
                                    <strong>{props.t("applications.applications_link")}</strong>
                                </span>
                            </Link>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    href="#"
                                    className="btn btn-ghost-primary btn-sm dropdown"
                                    tag="button"
                                >
                                    <span>
                                        <strong className="fs-14 fw-bold">{props.t("student.actions_link")}</strong>
                                        <FeatherIcon icon="chevron-down" className="icon-sm" />
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem className="dropdown-item" href={`/fields`}>
                                        <span className="fs-12 fw-normal"  >
                                            {props.t("applications.view_all_properties_button")}
                                        </span>
                                    </DropdownItem>
                                    {
                                        deleteAccess ?
                                            <DropdownItem className="dropdown-item" onClick={() => setisDeleteOpen(true)} >
                                                <span className="fs-12">
                                                    Delete
                                                </span>
                                            </DropdownItem> : null}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ListGroup>
                        <div className="text-center d-flex flex-column gap-2">
                            <h5>{info?.course_name}</h5>
                            <ul id="auto-apply-button" className="list-inline mb-0">
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="notes"
                                        to="#"
                                        onClick={() => setNotesModals(true)}
                                        className="avatar-title bg-primary-subtle text-primary fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="notes">
                                            Notes
                                        </UncontrolledTooltip>
                                        <i className="ri-sticky-note-line"></i>
                                    </Link>
                                </li>
                                {communicate ?<li className="list-inline-item avatar-xs">
                                    <Link
                                        id="email"
                                        to="#"
                                        onClick={() => setEmailModals(true)}
                                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="email">
                                            Email
                                        </UncontrolledTooltip>
                                        <i className="ri-mail-line"></i>

                                    </Link>
                                </li>: null}
                                {communicate ?<li className="list-inline-item avatar-xs">
                                    <Link
                                        onClick={handleButtonClick}
                                        id="call"
                                        to="#"
                                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="call">
                                            Call
                                        </UncontrolledTooltip>
                                        <i className="ri-phone-line"></i>
                                    </Link>
                                </li>: null}
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="task"
                                        to="#"
                                        onClick={() => setTasksModals(true)}
                                        className="avatar-title bg-info-subtle text-info fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="task">
                                            Task
                                        </UncontrolledTooltip>
                                        <i className="ri-task-line"></i>
                                    </Link>
                                </li>
                                {communicate ?<li className="list-inline-item avatar-xs">
                                    <Link
                                        id="meeting"
                                        to="#"
                                        onClick={() => setMeetingsModals(true)}
                                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="meeting">
                                            Meetings
                                        </UncontrolledTooltip>
                                        <i className="ri-calendar-check-line"></i>
                                    </Link>
                                </li>: null}
                            </ul>
                            <div>
                                <ButtonGroup>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            tag="button"
                                            className="btn btn-soft-primary text-left d-flex align-items-center justify-content-between"
                                            style={{ width: '200px' }}
                                        >
                                            {status?.label} <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdownmenu-primary">
                                            {statuses.map((status: any, index: number) => (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={(e) => onChangeStatus(status)}
                                                >
                                                    {status?.label}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ButtonGroup>
                            </div>
                        </div>
                        <hr></hr>

                        <div className="mb-3 hstack justify-content-between">
                            <h5>{props.t("applications.about")} </h5>
                            <ArrangeFields metadata={metadata} onChange={onChange} fields={fields} />
                        </div>
                        <StudentInfo fields={fields} />
                    </CardBody>
                    <CardFooter className="hstack gap-2">
                        <Button color="info" className="btn-sm w-100" onClick={() => onClickview()}> {props.t("student.view_all_properties_button")}
                        </Button>
                    </CardFooter>
                </Card>
            </Col>

            <Col xxl={9} md={9} lg={9}>
                <Card className="mt-xxl-1">
                    <CardHeader>
                        <Nav
                            className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-home-3-line"></i>{" "}
                                    <strong>{props.t("applications.overview_tab")}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-discuss-line"></i>{" "}
                                    <strong>{props.t("applications.activities_tab")}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "3" })}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-file-line"></i>{" "}
                                    <strong>{props.t("applications.documents")}</strong>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className="p-2">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <OverviewPane status={status} />
                            </TabPane>
                            <TabPane tabId="2">
                                {activeTab == "2" &&
                                    <Row className="g-2">
                                        <Col lg={12}>
                                            <ListGroup
                                                className="mb-3 justify-content-between"
                                                horizontal
                                            >
                                                <div className="search-box mb-1">
                                                    <Input
                                                        type="text"
                                                        size={14}
                                                        className="search"
                                                        placeholder="Search activities"
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        value={search}
                                                    />
                                                    {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                                </div>
                                                {/* Commented Collapse All Button for further enhancement */}
                                                {/* <div className="justify-content-end">
                                <Button className="btn-soft" size="sm">
                                  {props.t("student.collapse_all_button")}
                                  <span>
                                    <FeatherIcon
                                      icon="chevron-down"
                                      className="icon-sm"
                                    />
                                  </span>
                                </Button>
                              </div> */}
                                            </ListGroup>
                                        </Col>

                                        <CardHeader className="my-n3">
                                            <Nav
                                                className="nav-custom-light rounded card-header-tabs nav-border-top"
                                                role="tablist"
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("1");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.activity")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("2");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.notes")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("3");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.email")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "4",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("4");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.calls")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "5",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("5");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.tasks")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "6",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("6");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.meetings")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>

                                        <CardBody className="p-4">
                                            <TabContent activeTab={activeSubTab}>
                                                <TabPane tabId="1" id={"actiivtytab"}>
                                                    {/* <Activities /> */}
                                                    {activeSubTab == "1" && <Activities />}

                                                </TabPane>

                                                <TabPane tabId="2">
                                                    {activeSubTab == "2" && <Notes initialData={initialData} />}
                                                </TabPane>

                                                <TabPane tabId="3" id={"emailtab"}>
                                                    {activeSubTab == "3" && <Emails />}
                                                </TabPane>

                                                <TabPane tabId="4">
                                                    <Calls info={info} />
                                                </TabPane>
                                                <TabPane tabId="5">
                                                    {activeSubTab == "5" && <Tasks />}
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    {activeSubTab == "6" && <Meetings />}
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Row>
                                }
                            </TabPane>
                            <TabPane tabId="3" id={"documenttab"}>
                                {activeTab == "3" && <Documents />}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default withTranslation()(Applications);
