import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
    Accordion,
    AccordionItem,
    Collapse,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
    UncontrolledAlert,
    Table,
} from "reactstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import StudentInfo from './StudentInfo';
import UserAvatar from "./UserAvatarpreview";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
interface PreviewModalProps {
    show?: boolean;
    open?: string;
    onCloseClick: () => void;
    ontoggle: (e: any) => void;
    setrecord: (e: any) => void;
    record: {};
    props: any;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ show, onCloseClick, record, ontoggle, open, setrecord, props }: any) => {
    const studentDetails: any = useSelector((state: ApplicationState) => state.student.student);
    let info = studentDetails?.valuesJson
    useEffect(() => {
        setrecord(studentDetails)
    }, [])
    return (<>
        {
            record && record?.name ?
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    backdrop={false}
                    toggle={onCloseClick}
                >
                    <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
                        {record?.name}
                    </OffcanvasHeader>
                    {/* <form action="" className="d-flex flex-column justify-content-end h-100"> */}
                        <OffcanvasBody>
                            <Col xxl={12} md={12} lg={12}>
                                <Card id="contact-view-detail">
                                    <CardBody className="text-center">
                                        <div className="text-center">
                                            <div className="profile-user position-relative d-inline-block mx-auto">
                                                <div className="col-md-auto">
                                                    <div className="avatar-md">
                                                        <div className="avatar-title bg-white rounded-circle">
                                                            <UserAvatar img={info && info.img} firstName={info?.name} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="avatar-lg p-0 rounded-circle profile-photo-edit">
                                                    <Input
                                                        id="profile-img-file-input"
                                                        type="file"
                                                        className="profile-img-file-input"
                                                    />
                                                    <Label
                                                        htmlFor="profile-img-file-input"
                                                        className="profile-photo-edit avatar-xxs"
                                                    >
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </Label>
                                                </div>
                                            </div>
                                            <h5 className="mt-4 mb-1">{info?.name}</h5>
                                            <p className="text-muted">{info?.email}</p>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <div>
                                <UncontrolledAccordion toggle={() => { }} defaultOpen={["notes"]}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="about">
                                            <h6 className="fw-bold fs-16">{props.t("student.about_this_student")}</h6>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="about">
                                            <StudentInfo record={info} />
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                            <Button
                                color="primary"
                                className="btn w-100"
                                onClick={() => onCloseClick()}
                            >
                                Cancel
                            </Button>
                        </div>
                    {/* </form> */}
                </Offcanvas>
                : null
        }
    </>
    );
};

export default withTranslation()(PreviewModal);
