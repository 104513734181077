import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Keyinfo from './Keyinfo.json'
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import CourseInfoEdit from './CourseInfoEdit';
import FeatherIcon from "feather-icons-react";

const OverviewTab = ({ info, levelhandle }: any) => {
    const navigate = useNavigate();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [editaccess, seteditaccess] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState(false);
    const formatNumber = (numbervalue: any) => {
        let number = parseFloat(numbervalue);
        if (!isNaN(number)) {
            let formattedNumber = number.toLocaleString();
            return formattedNumber;
        }
        return numbervalue
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            seteditaccess(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.courses;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? seteditaccess(false) : seteditaccess(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const valueRender = (key: any, value: any) => {
        let renderValue = ""
        switch (key) {
            case "duration": renderValue = `${info.course_duration ? info.course_duration : ""}` + ` ${info.course_duration_type ? info.course_duration_type : ""}`;
                break;
            case "course_type": renderValue = levelhandle(value);
                break;
            case "department_name": renderValue = `${info.department_name ? info.department_name.charAt(0).toUpperCase() + info.department_name.slice(1) : ''}`
                break;
            case "fees": renderValue = ` ${info.currency ? info.currency : ""}` + ` ${info.international_fee ? formatNumber(info.international_fee) : ""}`
                break;
            default: renderValue = value;
                break
        }
        return renderValue;
    }
    const goBack = () => {
		navigate(-1);
    };
    const DetailsList = ({ item, key }: any) => {
        return (
            <React.Fragment>
                <Col lg={4} sm={6} Key={key}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-muted mb-1">{item.label} :</p>
                                <h5 className="mb-0">{valueRender(item.key, info[item.key])}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <Col className='hstack justify-content-end'>
                            {
                                isEdit ? <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="danger"
                                    onClick={() => setIsEdit(false)}
                                    outline
                                >
                                    <i className="ri-close-fill"></i>{" "}
                                    Close
                                    {/* {props.t("channel.update")} */}
                                </Button> :
                                    editaccess ?
                                        <Button
                                            type="button"
                                            size='sm'
                                            className="py-2"
                                            color="primary"
                                            onClick={() => setIsEdit(true)}
                                        >
                                            <i className="ri-pencil-fill"></i>{" "}
                                            Update
                                            {/* {props.t("channel.update")} */}
                                        </Button> : null
                            }
                        </Col>
                        <CardBody>
                            <div className="text-muted">
                                <h6 className="mb-3 fw-bold text-uppercase">Summary</h6>
                                <p>{info.about}</p>
                                {
                                    info?.course_link ?
                                        <div>
                                            <Link to={info?.course_link} target="_blank" className="btn btn-link link-success p-0">Read more</Link>
                                        </div> : null}
                                {
                                    isEdit ? <CourseInfoEdit /> :
                                        <div className="pt-3 border-top border-top-dashed mt-4">
                                            <Row className="gy-3">
                                                {Keyinfo.map((item: any, key: any) => {
                                                    return <DetailsList
                                                        item={item}
                                                        key={key}
                                                    />
                                                })}
                                            </Row>
                                        </div>}
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;