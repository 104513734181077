// import usFlag from "../assets/images/flags/us.jpg"
// import spain from "../assets/images/flags/spain.jpg"
// import germany from "../assets/images/flags/germany.jpg"
// import italy from "../assets/images/flags/italy.jpg"
// import russia from "../assets/images/flags/russia.jpg"

import flagus from "../assets/images/flags/us.svg";
import flagspain from "../assets/images/flags/spain.svg";
import flaggermany from "../assets/images/flags/germany.svg";
import flagitaly from "../assets/images/flags/italy.svg";
import flagrussia from "../assets/images/flags/russia.svg";
import flagchina from "../assets/images/flags/china.svg";
import flagindia from "../assets/images/flags/in.svg";
import flagarabic from "../assets/images/flags/ae.svg";

const currency = {
    in: {
        label: "INR (₹)",
        flag: flagindia,
        symbol: "₹",
      },
  sp: {
    label: "Euro (€)",
    flag: flagspain,
    symbol: "€",
  },
  rs: {
    label: "RUB (₽)",
    flag: flagrussia,
    symbol: "₽",
  },
  en: {
    label: "USD ($)",
    flag: flagus,
    symbol: "$",
  },
  cn: {
    label: "CNY (¥)",
    flag: flagchina,
    symbol: "¥",
  },
};

export default currency;
