import React, { useEffect, useState } from "react";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Label, Table } from "reactstrap";
import FeatherIcon from "feather-icons-react";


const ApplicationInfo = ({ onCloseClick, record }: any) => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [metaData, setMetaData] = useState<{ [key: string]: any }>({});
  const [datafields, setdatafields] = useState<any>([])
  const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
  const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
  const studentMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
  const onChange = (option: any, key: any) => {
    if(typeof option == 'object') setFormValues({...formValues, [key]: option.value})
    else setFormValues({...formValues, [key]: option})
  };

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value, type } = e.target;
    let fieldValue: any = value;
    if (type === "checkbox") {
      const target = e.target as HTMLInputElement;
      fieldValue = target.checked;
    }

    setFormValues({
      ...formValues,
      [name]: fieldValue,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    onCloseClick();
  };
  useEffect(() => {
    setMetaData(studentMetadata)
  }, [studentMetadata != undefined])

  const handleAccess = (permissionJson : any, fields: any) => {       
    let userRole = userProfile?.role?.title;
    let roleid = userProfile?.role?.id;        
    const permissions = permissionJson?.permissions;
    if(userRole === "Owner") return true 
    else if(permissions) {
        switch(permissions) {
            case "Owner": 
            case "owner": if(userRole === "Owner") return true;
                            else return false;
            case "view": return true;
            case "view_edit": return true;
            case "role_based":  const items = permissionJson.item || [];
                                let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                return isview && isview.length;
            default: return false;
        }
  
    }
  }
  const handleIconClick = (url: any) => {
    window.open(url, '_blank');
}; 

  useEffect(() => {
    if (metaData && metaData.length > 0) {
      let datafields: any = []
      metaData.map((fieldName: any) => {      
        let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}                  
        let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) :  true;    
        if(fields && isFieldviewaccess) datafields.push(fields)
      });
      setdatafields(datafields)}
  }, [metaData]);

// useEffect(() => {
//   if (metaData && metaData.length > 0) {
//     let datafields = metaData.map((fieldName: any) => {
//         let fields =  fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {}
//        return fields
//     });
//     setdatafields(datafields)}
// }, [metaData]);
const isJson = (str: any) => {
  try {
      let options = JSON.parse(str);
      return options
  } catch (e) {
      return false;
  }
}
const handleselectValue = (value: any, options: any) => {  
  if (options && options.length) {
      const foundItem = options.find((item: any) => item.value === value);
      if (foundItem) {
          return foundItem.label;
      }
  }
  return null; // or any default value you prefer
}
return (
  <Table className="table table-borderless mb-0">
    {datafields && datafields.length && datafields.map((field: any) => {
      let rendervalue = record?.[field.key];
      if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
        rendervalue = handleselectValue(record?.[field.key], field.values);
      }      
      else if (field.key == 'course_link' && record[field.model] && record[field.model][field.key] !== "NA") {
      return  <tr className="border border-0 border-bottom">
        <td className="fw-semibold">
          {field.label}
        </td>
        <td className="flex-wrap" style={{ maxWidth: 300}}>
            <span><FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(record[field.model][field.key])} /></span>
        </td>
      </tr>
      }
      else if (field.optionLabel) {
        rendervalue = record?.[field.key] && record[field.model]?.[field.optionLabel] ? record[field.model]?.[field.optionLabel] : 'NA'
      }
      return <tbody>
        <tr className="border border-0 border-bottom">
          <td className="fw-semibold">
            {field.label}
          </td>
          <td className="flex-wrap" style={{ maxWidth: 300 }}>{rendervalue}</td>
        </tr>
      </tbody>
    })}
  </Table>
);
};

export default ApplicationInfo;





{/* {datafields && datafields.length && datafields.map((field: any) => {
    return <FormBuilder                            
        placeholder={field.placeholder}
        onChange={(e : any) => onChange(e, field.key)}
        options={field.values && field.values.reverse() || []}
        label={field.label}
        type={field.type}
        value={record[field.key]}
        className="w-100 h-100 text-start"
    />
})} */}

