import { Reducer } from "redux";
import { FinanceActionTypes, FinanceState } from "./types";


export const initialState: FinanceState =
{
    financeMetadata: [],
    finance: null,
    channelfinances: null,
    universityfinances: null,
    pagination: null,
    financelist: [],
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    loading: false,
}
const reducer: Reducer<FinanceState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case FinanceActionTypes.GET_FINANCEMETADATA_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_FINANCEMETADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                financeMetadata: action.payload.content
            };
        }
        case FinanceActionTypes.GET_FINANCEMETADATA_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case FinanceActionTypes.GET_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                finance: action.payload
            };
        }
        case FinanceActionTypes.GET_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case FinanceActionTypes.POST_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.POST_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case FinanceActionTypes.POST_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case FinanceActionTypes.GET_CHANNEL_FINANCES_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_CHANNEL_FINANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                channelfinances: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_CHANNEL_FINANCES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                universityfinances: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case FinanceActionTypes.PUT_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.PUT_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                financelist: action.payload.content
            };
        }
        case FinanceActionTypes.PUT_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case FinanceActionTypes.DELETE_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.DELETE_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case FinanceActionTypes.DELETE_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as FinanceReducer };
