import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import CheckboxTree from 'react-checkbox-tree';

// Define the type for the options used in the Select component
interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

// Props for the Tree
interface TreeNode {
    value: string;
    label: string;
    children?: TreeNode[]; // Optional children property
}

// Props for the SelectDropdown component
interface SelectTreeProps {
    options: TreeNode[];
    values: any;
    showValues?: boolean;
    placeholder?: string;
    optionsLoading?: boolean;
    setValues: (values: TreeNode[]) => void;
    onFocus: () => void;
}

// Functional component that renders the custom dropdown with actions
const SelectTree: React.FC<SelectTreeProps> = ({options, values, showValues, setValues, onFocus, placeholder, optionsLoading}) => {
    const [checked, setChecked] = useState<any>([]);
    const [expanded, setExpanded] = useState<any>([]);
    const [search, setSearch] = useState<any>('');
    const [filteredTree, setFilteredTree] = useState<TreeNode[]>([]);

    useEffect(() => {
        setFilteredTree(options)
    }, [options])

    useEffect(() => {
        if(values?.length) {
            const checked = values?.map((value: any) => value.value)
            setChecked(checked)
        }
    }, [values])

    // Function to find multiple nodes by a list of ids
    const findNodesByIds: any = (tree: any, ids: any) => {
        let foundNodes: any = [];
    
        for (let node of tree) {
            if (ids.includes(node.value)) {
                foundNodes.push({value: node.value, label: node.label});
            }
        
            if (node?.children?.length > 0) {
                foundNodes = foundNodes.concat(findNodesByIds(node.children, ids));
            }
        }
    
        return foundNodes;
    }

    // Recursive filtering function in TypeScript
    function filterTree(tree: TreeNode[], predicate: (node: TreeNode) => boolean): TreeNode[] {
        return tree
        .map((node) => {
            if (predicate(node)) {
            // Return node with filtered children if present
            return {
                ...node,
                children: node.children ? filterTree(node.children, predicate) : undefined,
            };
            } else if (node.children) {
            // If node doesn't match but its children might, filter them
            const filteredChildren = filterTree(node.children, predicate);
            if (filteredChildren.length > 0) {
                return { ...node, children: filteredChildren };
            }
            }
            // Return null if neither the node nor its children match
            return null;
        })
        .filter((node) => node !== null); // Filter out null values without using type guards
    }

    // Case-insensitive predicate function
    const caseInsensitivePredicate = (value: string) => (node: TreeNode) =>
        node.label.toLowerCase().includes(value.toLowerCase());

    const onSearchHandler = (value: string) => {
        const filteredTree = filterTree(options, caseInsensitivePredicate(value));
        setFilteredTree(filteredTree);
        setSearch(value)

    }
    const onSelectHandler = (checked: any) => {
        setChecked(checked)
        const nodesSelected = findNodesByIds(options, checked);
        setValues(nodesSelected);
    }
    const onClearInput = () => {
        setChecked([])
        setValues([]);
    }
    return (
        <div className='d-flex dts-select-tree'>
            <ButtonGroup className='w-100'>
                <UncontrolledDropdown className='w-100'>
                    {showValues ? <DropdownToggle tag="button" className="d-flex align-items-center justify-content-between btn btn-soft-light w-100 border border-dark-subtle gap-1 bg-white" onFocus={() => onFocus()}>
                        {values?.length ?
                            <div className='d-flex gap-2 border-end border-dark-subtle pe-1 align-items-center'>
                                <div className='d-flex gap-2 flex-wrap dts-select-tree-input'>
                                    {
                                        values.map((value: any) => <span className="d-flex align-items-center gap-2 badge bg-primary-subtle text-primary">
                                            {value.label}
                                        </span>)
                                    }
                                </div>
                                <i className="fs-5 ri-close-line text-muted" onClick={() => onClearInput()}></i>
                            </div> : <span className='text-dark'>Select...</span>
                        }
                         <i className="fs-5 ri-arrow-down-s-line text-muted"></i>
                    </DropdownToggle> : <div className='d-flex'>
                        <DropdownToggle tag="button" className="d-flex align-items-center justify-content-between btn btn-soft-primary w-100 gap-1 p-1" onFocus={() => onFocus()}>
                            {<span className='fs-16 fw-semibold text-truncate'>{checked?.length ? `(${checked?.length}) ${placeholder}` : placeholder}</span>}
                            <i className="fs-5 ri-arrow-down-s-line"></i>
                        </DropdownToggle>
                        {checked?.length ? (
                            <Button
                                size='sm'
                                onClick={() => onClearInput()}
                                color="danger"
                                className="btn-icon-custom btn-soft-danger rounded-start-0"
                            >
                                <i className="ri-close-line label-icon align-middle fs-5 fw-semibold"></i>
                            </Button>
                        ) : null}
                    </div>}
                    <DropdownMenu className="dropdown-menu p-2 w-100" >
                        <div className="form-icon left p-2">
                            <Input
                                type="text"
                                className="form-control form-control-icon fs-16"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => onSearchHandler(e.target.value)}
                            />
                            <i className="fs-16 ri-search-line text-muted"></i>
                        </div>
                        {optionsLoading ? <p className="d-flex align-items-center justify-content-center p-2 text-center text-muted">Loading...</p> : 
                        <CheckboxTree
                            nodes={filteredTree}
                            checked={checked}
                            expanded={expanded}
                            checkModel="all"
                            onCheck={(checked) => onSelectHandler(checked)}
                            onExpand={expanded => setExpanded(expanded)}
                            showExpandAll={true}
                            showNodeIcon={false}
                            icons={{
                                check: <i className="fs-6 ri-checkbox-fill text-primary" />,
                                uncheck: <i className="fs-6 ri-checkbox-blank-line" />,
                                halfCheck: <i className="fs-6 ri-checkbox-indeterminate-fill text-primary" />,
                                expandClose: <i className="fs-6 ri-arrow-right-s-line text-dark-emphasis" />,
                                expandOpen: <i className="fs-6 ri-arrow-down-s-line text-dark-emphasis" />,
                                parentClose: null,
                                parentOpen: null,
                                leaf: null,
                            }}
                        />}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ButtonGroup>
        </div>
    );
};

export default SelectTree;
