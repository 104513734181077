import {Reducer} from "redux";
import {CustomFormActionTypes, CustomFormState} from "./types";


export const initialState: CustomFormState = { 
    errors: undefined,
    loading: false,
    response:{},
    token: undefined,
    pagination: null,
    customformDetails:{},
    metadata: [],
    formloading: false
};

const reducer: Reducer<CustomFormState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CustomFormActionTypes.GET_CUSTOMFORMS_REQUEST: {
            return {...state, loading: true};
        }
        case CustomFormActionTypes.GET_CUSTOMFORMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CustomFormActionTypes.GET_CUSTOMFORMS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case CustomFormActionTypes.GET_CUSTOMFORM_REQUEST: {
            return {...state, formloading: true};
        }
        case CustomFormActionTypes.GET_CUSTOMFORM_SUCCESS: {
            return {
                ...state,
                formloading: false,
                customformDetails: action.payload
            };
        }
        case CustomFormActionTypes.GET_CUSTOMFORM_ERROR: {
            return {...state, formloading: false, errors: action.payload};
        }

        
        case CustomFormActionTypes.GET_METADATA_REQUEST: {
            return {...state, loading: true};
        }
        case CustomFormActionTypes.GET_METADATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                metadata: action.payload.content
            };
        }
        case CustomFormActionTypes.GET_METADATA_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }

        case CustomFormActionTypes.PUT_CUSTOMFORM_REQUEST: {
            return {...state, loading: true};
        }
        case CustomFormActionTypes.PUT_CUSTOMFORM_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case CustomFormActionTypes.PUT_CUSTOMFORM_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }        
        case CustomFormActionTypes.POST_CUSTOMFORM_REQUEST: {
            return {...state, loading: true};
        }
        case CustomFormActionTypes.POST_CUSTOMFORM_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }     
        case CustomFormActionTypes.DELETE_CUSTOMFORM_REQUEST: {
            return {...state, loading: true};
        }
        case CustomFormActionTypes.DELETE_CUSTOMFORM_SUCCESS: {
            return {...state, loading: false, data: action.payload};
        }
        case CustomFormActionTypes.DELETE_CUSTOMFORM_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as CustomFormReducer};
