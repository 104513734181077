import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useCallback, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Breadcrumb,
  Card,
  CardBody,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import avtarImage2 from "../../../assets/images/round-person-button.jpg";
import avtarImage3 from "../../assets/images/users/user-dummy-img.jpg";
import classnames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import Preferences from "./Preferences";
import Access from "./Access";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userGetRequest } from "store/auth/action";
import { permissionsGetRequest } from "store/role/action";
import Upload from "../Upload/Upload";
import UserProfile from "../UserProfile";

interface RolesProps extends WithTranslation {
  // onCloseClick: (e: any) => void;
  // show: boolean;
  userInputs: any;
  setUserInputs: (e: any) => void;
  // id: any;
  // setId?: (e: any) => void;
}
type RolePermission = {
  id: string;
  name: string;
};

type UserRole = {
  rolePermissions: RolePermission[];
};

type User = {
  role: UserRole;
};
const UserDetailsModal = ({ userInputs, setUserInputs }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

  const permissionsdata = useSelector(
    (state: ApplicationState) => state.role.permissionsdata
  );
  const roleDetails = useSelector(
    (state: ApplicationState) => state.role.roleDetails
  );
  const user = useSelector((state: ApplicationState) => state.auth.userProfile);
  const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
  const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
  const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
  const [picture, setPicture] = useState<string>("")
  const [modalFile, setModalFile] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [pictureLoading, setPictureLoading] = useState<boolean>(false);
  const [data, setData] = useState(0)
  const [fileInfo, setFileInfo] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({});
  const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);

  const fileToggle = useCallback(() => {
    if (modalFile) {
        setModalFile(false);
    } else {
        setModalFile(true);
    }
}, [modalFile]);

  const toggleArrowTab = (tab: number) => {
    if (activeArrowTab !== tab) {
      const modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  };
  const [customActiveTab, setcustomActiveTab] = useState<any>(1);
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      const modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setcustomActiveTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
    // if (customActiveTab !== tab) {
    //     setcustomActiveTab(tab);
    // }
  };

  useEffect(() => {
    if (user) {
      dispatch(userGetRequest());
      dispatch(permissionsGetRequest());
    }
  }, [user.profilePic]);

  useEffect(() => {
    if(googleResponse) setPicture(googleResponse[0]?.socialDataDTO?.userProfile?.picture)
   },[googleResponse])
  console.log(permissionsdata);

  const handleFileClicks = () => {
    setModalFile(!modalFile);
    fileToggle();
};
console.log("User",user)
  return (
    <>
    <div className="border-rounded-3 bg-primary-subtle shadow-lg">
    <UserProfile />
    </div>
    </>
  );
};

export default withTranslation()(UserDetailsModal);
