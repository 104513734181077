import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";
import { Alert, Button, Card, CardBody, Col, Input, Label, Modal } from "reactstrap";
import { ApplicationState } from "store";
import { setResponse } from "store/auth/action";
import { roleListGetRequest, roleNameListRequest } from "store/role/action";
import { userInviteRequest, usersListGetRequest } from "store/user/action";

interface InviteUsersProps {
  inviteUser: boolean;
  setInviteUser: (event: boolean) => void;
  usersQueryParams: any;
}
export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}
const InviteUser: React.FC<InviteUsersProps> = ({
  inviteUser,
  setInviteUser,
  usersQueryParams
}) => {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const roleList = useSelector((state: ApplicationState) => state.role.list);
  const [roles, setRoles] = useState<any[]>([])
  const [emails, setEmails] = useState<any[]>([]);
  const [role, setRole] = useState<any>("");
  const [selectedRole, setSelectedRole] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inviteeName, setInviteeName] = useState("")
  const [isSendInviteDisabled, setIsSendInviteDisabled] = useState<boolean>(false);

  useEffect(() => {
    dispatch(roleListGetRequest());
  }, []);
  console.log(roleList)
  useEffect(() => {
    const roleValues: any = [];
    roleList && roleList.filter((item: any) => item.title !== "Owner").map((item: any) => {
      roleValues.push({ value: item.id, label: item.title });
    });
    setRoles(roleValues);
  }, [roleList]);

  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setEmails(newValue);
  };

  const handleRoleSearch = (option: any, name: string) => {
    let queryParams: any = new URLSearchParams({});
    switch (name) {
      case 'searchRole':
        if (option) {
          queryParams.set('search', option.toString());
        }
        break;
    }
    dispatch(roleNameListRequest(queryParams.toString()));
  }
  const handleInputChange = (inputValue: any) => {
    handleRoleSearch(inputValue, "searchRole");
    console.log(inputValue);
  };
  
  const handleRoleSelect = (sortBy: any) => {
    setRole(selectedRole);
    setSelectedRole(sortBy.value);
    setMenuIsOpen(false);
  };
  const onSendInvite = (): void => {
   setIsSendInviteDisabled(true)
    const emailArray = emails.map(item => item.value).join(',');
    const emailList = JSON.stringify(emailArray);
    const data = {
      "attributes": [
        {
          "key": "name",
          "value": inviteeName
        },
        {
          "key": "email",
          "value": emailList.slice(1, -1)
        },
        {
          "key": "role_Id",
          "value": selectedRole
        }
      ]
    };
    const handleSuccess = (body: any): void => {
      handleClear()
      dispatch(usersListGetRequest(usersQueryParams));
      const message = () => toast(`Invite sent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      dispatch(setResponse(body));
    };
    const handleError = (body: any): void => {
      setIsSendInviteDisabled(false)
      const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      message();
    };

    dispatch(userInviteRequest(data, handleSuccess, handleError));
  };
  const filterSuggestions = (inputValue: string) => {
    return emails.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<SuggestionOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSuggestions(inputValue));
      }, 1000);
    });

  const handleClear = () => {
    setIsSendInviteDisabled(false)
    setInviteUser(false);
    setSelectedRole("");
    setInviteeName("")
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        color: 'auto',
        fontWeight: 900,
        border: state.isFocused ? '0.5px solid #2A5F8D' : 'auto',
        borderRadius: 8,
        // backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover': {
            border: '0.5px solid #2A5F8D',
        },
    }),
    
    multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: "#2A5F8D",
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: "white",
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color:"white",
      }),
}

  return (
    <>
      <Modal
        id="loginModals"
        tabIndex={1}
        isOpen={inviteUser}
        centered
      >
        <Card className="mb-0 card-light">
          <CardBody>
            <div className="bg-light">
              <h5>Invite User</h5>
            </div>
            <hr />
            <div className="d-flex align-items-center">
              <div className="w-100">
              <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                    Invitee Name<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    <Input placeholder="Enter invitee name"
                    value={inviteeName}
                    onChange={(e: any) => {
                      setInviteeName(e.target.value)
                    }}
                    ></Input>
                  </div>
                </div>
                <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                    Invitee Email<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    <AsyncCreatableSelect
                      onChange={handleSelectChange}
                      isMulti={true}
                      isClearable={true}
                      options={emails}
                      styles={customStyles}
                      loadOptions={promiseOptions}
                      placeholder="Enter invitee email"
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                    Assign Role<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    {/* <Col lg={12}> */}
                      <Select
                        placeholder="Select Role"
                        value={role.label}
                        onInputChange={handleInputChange}
                        onChange={handleRoleSelect}
                        options={roles}
                        classNamePrefix="js-example-data-array"
                        isLoading={false}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                      />
                    {/* </Col> */}

                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  className="btn-sm"
                  color="danger"
                  outline
                  onClick={() => {
                    handleClear()
                    setInviteUser(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  className="btn-sm"
                  color="success"
                  onClick={onSendInvite}
                  disabled={isSendInviteDisabled || (!inviteeName || emails.length === 0 || !selectedRole)}
                >
                  Send Invite
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default InviteUser;
