import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { withTranslation } from 'react-i18next';
import { viewCreateRequest, viewDelete, ViewListGetRequest } from 'store/views/action';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { toast, ToastContainer } from 'react-toastify';
import AddView from './innerComponent/AddView';
import AddEntryModal from './innerComponent/AddEntryModal';
import DeleteViewModal from './innerComponent/DeleteViewModal';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/ListingLoader';
import Student from './Students';
import classnames from "classnames";
import Upload from './Upload/Upload';


const StudentsTab = (props:any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const viewlist:any= useSelector((state: ApplicationState) => state.views.views);
    const studentMetadata = useSelector((state: ApplicationState) => state.student.studentsMetadata);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const permissionloading = useSelector((state: ApplicationState) => state.auth.permissionloading);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [activeTab, setActiveTab] = useState<any>('zilter_default');
    const [views, setViews] = useState<any>([]);
    const [isAddviewopen, setAddviewOpen] = useState<any>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [viewname, setViewname] = useState<string>('');
    const [defaultFields, setFields] = useState<any>([]);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [record, setRecord] = useState<any>([]);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [viewId, setviewId] = useState<string>('zilter_default');
    const [create, setCreate] = useState<boolean>(false);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [data, setData] = useState(0)
    const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [fileInfo, setFileInfo] = useState<any>({});
    const [templateUploading, setTemplateUploading] = useState<boolean>(false);
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    useEffect(() => {
        dispatch(ViewListGetRequest());
    }, [])

    useEffect(() => {
        if(viewlist && viewlist.length) {                 
            let newList = viewlist.filter((item: any) => (item.model === "students" && item.deletedAt == null  && item.location == 'listing'))
            setViews(newList)
        }
    }, [viewlist])

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }

    const onCreate = () => {        
        let data = {            
            "name": viewname,
            "primary": "true",
            "model": "students",
            "location": "listing",
            "fields": JSON.stringify(defaultFields),
            "filters": JSON.stringify([]),
        }  
        const handleSuccess = () => {
            const message = () => toast(`View Created Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            setViewname("")
            dispatch(ViewListGetRequest());
            setAddviewOpen(false)
        }
        const handleFailure = () => {

        }
        dispatch(viewCreateRequest(data, handleSuccess, handleFailure))  
    }
    

       const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);
    
       const handleFileClicks = () => {
        setModalFile(!modalFile);
        fileToggle();
    };
    
    useEffect(() => {
        let defaultFields: any = [];
        studentMetadata && studentMetadata.length && 
        studentMetadata.map((field: any) => {
            let item = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson)  : {}
            defaultFields.push({id: field.id, extras: {key:item.key, show: item.tablecolumn}})
        })
        setFields(defaultFields)
    }, [studentMetadata])
    const onDelete = (id: any) => {
        const handleSuccess = () => {
            const message = () => toast(`View Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(ViewListGetRequest());
            setisDeleteOpen(false);
        }
        const handleFailure = () => {
            
        }
        dispatch(viewDelete(id, handleSuccess, handleFailure));
    }

    useEffect(() => {
        setviewId(activeTab)
    }, [activeTab])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.students;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                        }
                        if(item.name.toLowerCase() == 'create') {
                                item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                        }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])
    return (
        <div> 
            <ToastContainer closeButton={false} />
            {   
                !authLoading ?   
                viewAccess ? 
                    <React.Fragment>
                        
                        <Modal backdrop="static" id="createFileModal" isOpen={modalFile}  toggle={() => { fileToggle(); setselectedFiles([]); }}  modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader  toggle={() => { fileToggle(); setselectedFiles([]); }}  className="p-3 bg-primary-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload setModalFile={setModalFile} setPictureLoading={setTemplateUploading} setData={setData} setDataPreviewModal={setDataPreviewModal} filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>
                        <AddView 
                            onCreate={onCreate}
                            viewname={viewname}
                            setViewname={setViewname}
                            props={props}
                            show={isAddviewopen}
                            onCloseClick={() =>setAddviewOpen(false)}
                        />  
                        <DeleteViewModal
                            show={isDeleteOpen}
                            onCloseClick={() => setisDeleteOpen(false)}
                            props={props}
                            record={record}
                            onDelete={onDelete}
                        />
                        <Row className='hstack jsutify-content-between'>
                            <Col>
                                <BreadCrumb title={props.t("student.students")} pageTitle={props.t("student.students")}  icon={"ri-list-check"} />
                            </Col>
                            <Col  className='hstack justify-content-end gap-2'>     
                            {
                                create ? 
                                <>
                                <Button
                                    onClick={() => handleFileClicks()}
                                    color="primary"
                                    size='sm'
                                    className="btn-label">
                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t("student.bulk_entry")}
                                </Button>
                                <Button
                                    onClick={() => setCreateOpen(true)}
                                    color="primary"
                                    size='sm'
                                    className="btn-label">
                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t("student.create_entry")}
                                </Button>
                                </>
                            :         
                                <Button
                                    // onClick={() => setCreateOpen(true)}
                                    color="primary"
                                    size='sm'
                                    disabled
                                    className="btn-label">
                                        <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t("student.create_entry")}
                                </Button>}
                            </Col>
                        </Row>
                        
                        <div>
                            <div className='hstack justify-content-start'>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem className={activeTab === 'zilter_default' ? 'hstack justify-content-between border border-bottom-0 border-primary border-end-0' :'hstack justify-content-between border  border-primary border-end-0'}>
                                            <NavLink                                
                                                className={activeTab === 'zilter_default' ? "fw-medium fs-14 p-1 px-3 py-2" : "fw-medium fs-14 p-1 px-3  py-2"}
                                                onClick={() => { toggleTab('zilter_default'); }}
                                                href="#">
                                                Default
                                            </NavLink>
                                        </NavItem>
                                    {views.map((item: any) => {
                                    return <NavItem className={activeTab === `${item.id}` ? 'hstack justify-content-between border border-bottom-0  border-primary border-end-0' :'hstack justify-content-between border  border-primary border-end-0'}>
                                            <NavLink                                
                                                className={activeTab === `${item.id}` ? "fw-medium fs-14 p-1 px-3 py-2" : "fw-medium fs-14 p-1 px-3  py-2"}
                                                onClick={() => { toggleTab(`${item.id}`); }}
                                                href="#">
                                                {item.name}
                                            </NavLink>
                                            {
                                                item.name.toLowerCase() == 'zilter_default' ? null :
                                            
                                            <span onClick={() => {setisDeleteOpen(true); setRecord(item)}} className={activeTab === `${item.id}` ? "cursor-pointer" : "cursor-pointer" }>
                                                <i className="ri-close-line label-icon align-middle fs-13 me-2"></i>
                                            </span>}
                                        </NavItem>
                                    })}           
                                </Nav>
                                <Button
                                    onClick={() => setAddviewOpen(true)}
                                    color="primary"
                                    className="btn-label border border-primary rounded-0">
                                    <i className=" ri-add-line label-icon align-middle fs-16 me-2"></i>
                                    {props.t("student.add_view")}
                                </Button>
                            </div>    
                            <div>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={'zilter_default'} key={'zilter_default'}>
                                        <Student                                        
                                            iscreateModal={iscreateModal}
                                            setCreateOpen={() => setCreateOpen(false)}
                                        />
                                    </TabPane>
                                    {views && views.length ? views.map((item : any, tab: any) => {
                                    if(`${item.id}` == viewId )
                                        return <TabPane tabId={`${item.id}`} key={tab}>
                                            {/* <StudentListing viewId={viewId} /> */}
                                            <Student                                              
                                                iscreateModal={iscreateModal}
                                                setCreateOpen={() => setCreateOpen(false)}
                                                viewId={viewId}
                                            />
                                        </TabPane>
                                    }): null}
                                </TabContent>
                            </div>            
                            <Row>
                                
                            </Row>
                        </div>
                    </React.Fragment> : <AccessDenied /> 
                    :  <ListingLoader 
                    title={props.t("student.students")}      
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    thClass="border-bottom-1 table-soft-primary"
                />
            } 
        </div>
    );
};

export default withTranslation()(StudentsTab);