import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalHeader,
    Card,
} from "reactstrap";
import Select from "react-select";
import { components } from "react-select";
import SimpleBar from "simplebar-react";
import { ApplicationsListGetRequest, applicationGetRequest, applicationUpdateRequest, applicationsMetadataGetRequest } from "store/applications/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import DtsForm from "Components/Common/FormBuilder/Form";

const ApplicationStatusHandler = ({edit}: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.applicationMetadata);
    const applicationsDetails: any = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const [applictionStatus, setapplictionstutus] = useState<any>("");
    const [statusOption, setStatusOption] = useState<any>({});
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [appModel, setAppModel] = useState<any>(false);
    const onChange = (option: any) => {
        let newstatusMetaData: any = []
        let valueJson = {}
        applicationsMetadata && applicationsMetadata.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            valueJson=item;
            if(item?.applicationstatusLinked == option.value ) return newstatusMetaData.push(field)
        })
        setStatusMetaData(newstatusMetaData)
        setapplictionstutus(option.value)
        setStatusOption(option)
        if(newstatusMetaData && !newstatusMetaData.length) {
            onUpdateStatus(valueJson,option.value)
        }
        else {
            setAppModel(true)
        }
    }
    const onUpdateStatus = (formValues: any, status: any) => {
        let data = { ...formValues, application_status: status };
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application status updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            setAppModel(false)
            dispatch(applicationGetRequest(id))
        }
        dispatch(applicationUpdateRequest(id, data, handleSuccess, () => { }))
    }
    
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className='hstack justify-content-between'>
                <div className="text-start">{props.children}</div>
                {props.isSelected && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };

    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    // color: 'black',
                    fontWeight: 900,
                    border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 8,
                    boxShadow: 'none',
                    '&:hover': {
                    border: '0.5px solid lightgrey',
                    },
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: 'white',
          borderRadius: 8,
        //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        //   border: '1px solid #ccc',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'white' : 'white', // Custom background color for selected option
          color: state.isSelected ? 'black' : 'black', // Custom text color for selected option
          '&:hover': {
            backgroundColor: 'lightgrey', // Custom background color on hover
          },
        }),
    }

    const onUpdate = (formValues: any) => {
        let data = { ...formValues, application_status: statusOption.value };
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            setAppModel(false)
            dispatch(applicationGetRequest(id))
        }
        dispatch(applicationUpdateRequest(id, data, handleSuccess, () => { }))
    }
    
    const statusoptions = [
        {
            label: 'Application',
            value: 'application',
            in_forms: true
        },
        {
            label: 'Complete Document',
            value: 'complete_document',
            in_forms: true
        },
        {
            label: 'Offer Letter Conditional',
            value: 'Offer_Letter_Conditional',
            in_forms: true
        },
        {
            label: 'University Assessment/Interview',
            value: 'university_assessment_interview',
            in_forms: true
        },
        {
            label: "Unconditional Offer Letter",
            value: "unconditional_Offer_letter",
            in_forms: true
        },
        {
            label: "Fee Deposit",
            value: "fee_deposit",
            in_forms: true
        },
        {
            label: "Visa Appointment Book",
            value: "VisaAppointmentBook",
            in_forms: true
        },
        {
            label: "Visa Documents Checked",
            value: "Visa_documents_checked",
            in_forms: true
        },
        {
            label: "Visa Applied",
            value: "visa_applied",
            in_forms: true
        },
        {
            label: "Visa Granted",
            value: "visa_granted",
            in_forms: true
        },
        {
            label: "Visa Rejected",
            value: "visa_rejected",
            in_forms: true
        },
        {
            label: "Offer Accepted",
            value: "offer_accepted",
            in_forms: true
        },
        {
            label: "Balance fee payment",
            value: "balance_fee_payment",
            in_forms: true
        },
        {
            label: "Pre Departure Pack",
            value: "pre_departure_pack",
            in_forms: true
        },
        {
            label: "Enrolled To University",
            value: "enrolled_to_university",
            in_forms: true
        },
        {
            label: "Post Arrival Services",
            value: "post_arrival_services",
            in_forms: true
        },
        {
            label: "Invoice",
            value: "invoice",
            in_forms: true
        }
    ]
    useEffect(() =>{
        const filters = {pageSize: 100}
        dispatch(applicationsMetadataGetRequest("applications",filters));
        dispatch(applicationGetRequest(id));
    }, [])
    useEffect(() => {
        if(applicationsDetails) {
            if(statusoptions && statusoptions.length) {
                statusoptions.map((item: any) => {
                    let valuesJson = applicationsDetails.valuesJson && isJson(applicationsDetails.valuesJson) ? JSON.parse(applicationsDetails.valuesJson) : {}
                    if(valuesJson?.application_status == item.value ) return setStatusOption(item)
                })
            }
          
        }
    }, [applicationsDetails])
    return (
        <div>
            <ToastContainer />
        <Form action="" className="d-flex flex-column justify-content-end h-100">
            <div className="live-preview vstack gap-2 mb-2">     
                <Label className="form-label">{"Application Status"}<span className="d-none fw-bold text-danger">{" "}*</span></Label>
                <Select
                    styles={customStyles}
                    components={{ Option: CustomOption }}
                    className='form-select-sm p-0'
                    placeholder={`Select status`}
                    onChange={(selectedOption: any) => onChange(selectedOption)}
                    value={statusOption}
                    options={statusoptions} 
                    isDisabled={!edit}             
                >                        
                </Select>
            </div> 
        </Form>
        <Modal backdrop="static" isOpen={appModel} toggle={() => setAppModel(false)} centered={true}>
            <ModalHeader className="p-3 bg-light" toggle={() => setAppModel(false)}>
                    {statusOption.label}
            </ModalHeader>
            <ModalBody className="p-4">       
                <Card>
                    { statusMetaData && statusMetaData.length ?
                    <SimpleBar autoHide={false} className="" style={{ maxHeight: "400px" }}>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <div className="live-preview vstack gap-2">
                                <div className="live-preview vstack gap-2">
                                    <DtsForm dataFields={statusMetaData} btnAction={onUpdate} showbtn={false} data={applicationsDetails} btn_text={"Save"} />
                                </div>
                            </div>
                        </form>
                    </SimpleBar>
                    :<div className="p-3"><h5>No Details to show for current stage</h5></div>}
                </Card>
            </ModalBody>
        </Modal>
        </div>
    );
};

export default ApplicationStatusHandler;

