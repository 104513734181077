export interface banksMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Banks {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum BanksActionTypes {
    GET_BANKMETADATA_REQUEST = "@@bank/GET_BANKMETADATA_REQUEST",
    GET_BANKMETADATA_SUCCESS = "@@bank/GET_BANKMETADATA_SUCCESS",
    GET_BANKMETADATA_ERROR = "@@bank/GET_BANKMETADATA_ERROR",
    GET_BANK_REQUEST = "@@bank/GET_BANK_REQUEST",
    GET_BANK_SUCCESS = "@@bank/GET_BANK_SUCCESS",
    GET_BANK_ERROR = "@@bank/GET_BANK_ERROR",
    GET_BANKS_REQUEST = "@@bank/GET_BANKS_REQUEST",
    GET_BANKS_SUCCESS = "@@bank/GET_BANKS_SUCCESS",
    GET_BANKS_ERROR = "@@bank/GET_BANKS_ERROR",
    PUT_BANK_REQUEST = "@@bank/PUT_BANK_REQUEST",
    PUT_BANK_SUCCESS = "@@bank/PUT_BANK_SUCCESS",
    PUT_BANK_ERROR = "@@bank/PUT_BANK_ERROR",
    DELETE_BANK_REQUEST = "@@bank/DELETE_BANK_REQUEST",
    DELETE_BANK_SUCCESS = "@@bank/DELETE_BANK_SUCCESS",
    DELETE_BANK_ERROR = "@@bank/DELETE_BANK_ERROR",
    POST_BANK_REQUEST = "@@bank/POST_BANK_REQUEST",
    POST_BANK_SUCCESS = "@@bank/POST_BANK_SUCCESS",
    POST_BANK_ERROR = "@@bank/POST_BANK_ERROR",
    GET_BANKS_HIERARCHY_REQUEST = "@@bank/GET_BANKS_HIERARCHY_REQUEST",
    GET_BANKS_HIERARCHY_SUCCESS = "@@bank/GET_BANKS_HIERARCHY_SUCCESS",
    GET_BANKS_HIERARCHY_ERROR = "@@bank/GET_BANKS_HIERARCHY_ERROR",
}

export interface BanksState {
    readonly banks: Banks[] | null;
    readonly bank: Banks | null;
    readonly pagination: Pagination | null;
    readonly banksMetadata: banksMetadata[];
    readonly banklist: any;
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly hierarchybanks: any;
    readonly loading: boolean;
}
