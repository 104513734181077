import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  Col,
  Collapse,
  Container,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import classnames from "classnames";

import { Link } from "react-router-dom";
import CircleBadge from "./CircleBadge";
import EmailModal from "./Modals/EmailModal";
import LogEmailModal from "./Modals/LogEmailModal";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

import FeatherIcon from "feather-icons-react";
import EmailToolbar from "./Emails";

const Email = (props: any) => {
  const [emailModals, setEmailModals] = useState<boolean>(false);
  const [logEmailModals, setLogEmailModals] = useState<boolean>(false);
  const [reply, setReply] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(true);
  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  const [fillCol1, setfillCol1] = useState<boolean>(false);

  const t_fillCol1 = () => {
    setfillCol1(!fillCol1);
  };

  const handleClick = () => {
    setEmailModals(true);
  };
 

  return (
    <React.Fragment>
      {/* <Container fluid> */}
        <div className="">
          <EmailToolbar communicate={props.communicate} />
        </div>
      {/* </Container> */}
    </React.Fragment>
  );
};

export default withTranslation()(Email);
