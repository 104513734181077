import React, { useState } from 'react';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Label, Modal, Row } from 'reactstrap';

// Define an interface for file objects
interface FileItem {
    preview: string;
    formattedSize: string;
    size: number;
    name: string;
}

interface FProps {
    setselectedFiles: (files: FileItem[]) => void;
    selectedFiles: FileItem[];
    SubmitUpload: (str: boolean) => void;
    handleDownloadTemplate: (str: boolean) => void;
}

const New: React.FC<FProps> = ({ setselectedFiles, selectedFiles, SubmitUpload, handleDownloadTemplate }) => {

    const [modal_small, setmodal_small] = useState<boolean>(false);

    const formatBytes = (bytes: number, decimals = 2): string => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleAcceptedFiles = (files: File[]) => {
        const filteredFiles = files.map((file: File) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        ) as FileItem[];
        setselectedFiles(filteredFiles);
    };

    const handleDisable = () => {
        return !selectedFiles.length
    };

    const tog_small = () => setmodal_small(!modal_small);

    const isDisabled = handleDisable();

    return (
        <div>
            <div className='d-flex justify-content-end mt-0'>
                <Link
                    to="#"
                    onClick={()=> handleDownloadTemplate(true)}
                    color="primary"
                    className="fw-medium">
                    {/* <i className="ri-file-download-line label-icon align-middle fs-16 me-2"></i> */}
                    {"Download Template"}
                </Link>
            </div>
            <div className='mt-3'>

                <Dropzone
                    onDrop={(acceptedFiles: File[]) => handleAcceptedFiles(acceptedFiles)}
                    accept={{
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // For xlsx
                    }}
                >
                    {({ getRootProps, getInputProps }: DropzoneState) => (
                        <div className="dropzone dz-clickable">
                            <div
                                className="dz-message needsclick pt-4"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                </div>
                                <h4>Drop .xlsx files here or click to upload.</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
            <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f, i) => (
                    <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-primary dz-complete"
                        key={i}
                    >
                        <div className="p-2">
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                    />
                                </Col>
                                <Col>
                                    <Link to="#" className="text-muted font-weight-bold">
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                ))}
            </div>
            <div className="d-flex align-items-start gap-3 mt-2">
                <div className='hstack gap-1'>
                    <Button
                        onClick={() => SubmitUpload(true)}
                        disabled={isDisabled}
                        type="button"
                        color="primary"
                        className="btn-label right ms-auto nexttab"
                    >
                        <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i>
                        Upload
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default New;
