import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
import university from '../../../src/assets/images/dtsimg/svg/university.svg';
import OverviewTab from './OverviewTab';
import { useNavigate, useParams } from 'react-router';
import { useFetcher } from 'react-router-dom';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { courseGetRequest } from 'store/course/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import UserAvatar from 'Components/Common/UserAvtar/UserAvatar';

const Section = () => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const CourseDetail: any = useSelector((state: ApplicationState) => state.courses.course)
    const [activeTab, setActiveTab] = useState<string>('1');
    const [info, setinfo] = useState<any>({});
	const navigate = useNavigate();
    const [universityinfo, setuniversityinfo] = useState<any>({});
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const levelhandle = (levelvalue: any) => {
        let level = ""
        switch(levelvalue) {
            case 1:
            case "1":
            case "1.0": level ='Undergraduate';            
                    break
            case 2:
            case "2":
            case "2.0": level ='Postgraduate';            
                    break
            case 3:
            case "3":
            case "3.0": level ='Foundation';            
                    break
            case 4:
            case "4":
            case "4.0": level ='Research';            
                    break  
            default: level ='NA';            
                    break  
        }
        return level;
    }

    useEffect(() => {
        dispatch(courseGetRequest(id))
    },[]) 

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    
    useEffect(() => {
        let details: any = CourseDetail && CourseDetail.valuesJson  && isJson(CourseDetail.valuesJson) ? JSON.parse(CourseDetail.valuesJson) : {}
        let universitydetails = CourseDetail && CourseDetail.university && CourseDetail.university.valuesJson && isJson(CourseDetail.university.valuesJson) ? JSON.parse(CourseDetail.university.valuesJson) : {}
        setinfo(details)
        setuniversityinfo(universitydetails)
    },[CourseDetail]) 

    const goBack = () => {
		navigate(-1);
	  };
      
    console.log("universityinfo", universityinfo)
    return (
        <React.Fragment>
            <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                    <UserAvatar   firstName={info?.course_name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.course_name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className=" ri-bank-fill align-bottom me-1 fs-16"></i>{universityinfo?.university_name}</div>
                                                        <div><i className="bx bxs-graduation align-bottom me-1 fs-16"></i>{levelhandle(info?.course_type)}</div>
                                                        <div className="vr"></div>
                                                        {CourseDetail?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(CourseDetail?.createdAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {CourseDetail?.updatedAt ?<div>Updated Date : <span className="fw-medium">{moment(CourseDetail?.updatedAt).format('lll')}</span></div> : null}
                                                         <div className="vr"></div>
                                                        {/* <div className="badge rounded-pill bg-info fs-12">New</div>
                                                        <div className="badge rounded-pill bg-danger fs-12">High</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                    {/* <div className="col-md-auto">
                                        <div className="hstack gap-1 flex-wrap">
                                            <button type="button" className="btn py-0 fs-16 favourite-btn active">
                                                <i className="ri-star-fill"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-share-line"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-flag-line"></i>
                                            </button>
                                        </div>
                                    </div> */}
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-bold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Modules
                                        </NavLink>
                                    </NavItem> */}
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-bold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Finance
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row>
                <Col lg={12}>
                    <Button color="primary" className="w-auto btn-sm" onClick={() => goBack()}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <OverviewTab info={info} levelhandle={levelhandle}/>
                    </TabPane>
                    {/* <TabPane tabId="2">
                        <DocumentsTab />
                    </TabPane>
                    <TabPane tabId="3">
                        <ActivitiesTab />
                    </TabPane>
                    <TabPane tabId="4">
                        <TeamTab />
                    </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;