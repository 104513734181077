import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';
import { get, map } from "lodash";

//i18n
import i18n from "../../i18n";
import currency from "../../common/currency";


const CurrencyDropdown = () => {
    // Declare a new state variable, which we'll call "menu"
    const [selectedCurrency, setSelectedCurrency] = useState<any>("");

    useEffect(() => {
        const currentCurrency = localStorage.getItem("I18N_LANGUAGE");
        setSelectedCurrency(currentCurrency);
    }, []);

    const changeCurrencyAction = (curr:any) => {
        //set language as i18n
        // i18n.changeLanguage(lang);
        localStorage.setItem("I18N_LANGUAGE", curr);
        setSelectedCurrency(curr);
    };


    const [isCurrencyDropdown, setIsCurrencyDropdown] = useState<boolean>(false);
    const toggleLanguageDropdown = () => {
        setIsCurrencyDropdown(!isCurrencyDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isCurrencyDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="text-nowrap btn rounded btn-icon btn-topbar btn-ghost-secondary">
                    {/* <img
                        src={get(currency, `${selectedCurrency}.flag`)}
                        alt="Header Language"
                        height="20"
                        className="rounded"
                    /> */}
                    <Label className='mb-0'>{get(currency, `${selectedCurrency}.label`)}</Label>
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    {map(Object.keys(currency), key => (
                        <DropdownItem
                            key={key}
                            onClick={() => changeCurrencyAction(key)}
                            className={`notify-item ${selectedCurrency === key ? "active" : "none"
                                }`}
                        >
                            <img
                                src={get(currency, `${key}.flag`)}
                                alt="Skote"
                                className="me-2 rounded"
                                height="18"
                            />
                            <span className="align-middle">
                                {get(currency, `${key}.label`)}
                            </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default CurrencyDropdown;