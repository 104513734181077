import React from "react";
import { Container, Row } from "reactstrap";
// import SkeletonLoader from "./Skeleton/SkeletonLoader";
import Application from "./Applications";

const Applications = () => {
  document.title = "DTS | Zilter";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Application />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Applications;
