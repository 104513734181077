import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, verifyPasswordLink, getUserToken } from "store/auth/action";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import ResetSuccess from './ResetSuccess';
import { jwtDecode } from 'jwt-decode';
import ResetError from './ResetError';
import { ToastContainer, toast } from 'react-toastify';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps { 
    t: (key: string) => string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({t}) => {
    document.title = "Create New Password | Zilter";    
    const { id } = useParams<{ id: string }>();
    console.log("id ID:", id);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [isValid, setISValid] = useState<boolean>(true);
    const [token, setToken] = useState<boolean>(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [inputs, setInputs] = useState<Inputs>({
        password: '',
        confirm_password: ''
    })

    const onSubmit = () => {       
        const data=   {
            "attributes": [
                {
                    "key": "reset_token",
                    "value":  id
                },
                {
                  "key": "password",
                  "value":  inputs.password
                }
              ]
            }
        const handleSuccess = (body: any): void => {
            setIsConfirm(true)
        }
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(resetPassword(data, handleSuccess, handleError));
    }

    const handleInputChange = (name: string, value: string) => {
        setInputs({ ...inputs, [name]: value });
    };

    
    const islen = () => {
        let str = inputs.password;
        if (str && str.length >= 8) return true;
        else return false;
    };

    useEffect(() => {
        const handleSuccess = (body: any): void => {
            console.log("body", body);
            console.log("id",id);
            setISValid(true)
        }
        const handleError = (body: any): void => {
            console.log("body", body);
            setISValid(false)
        }
        dispatch(verifyPasswordLink(id, handleSuccess, handleError));   
    }, []);

    
    useEffect(() => {
        setDisabled(islen() &&  /[a-z]/.test(inputs.password) &&
            inputs.password !== "" && /[A-Z]/.test(inputs.password) && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password) && inputs.password == inputs.confirm_password)
    }, [inputs.password, inputs.confirm_password])


    return (
        <ParticlesAuth>
            <ToastContainer />
            <div className="auth-page-content">
                {
                    isConfirm ?
                    <ResetSuccess />
                :
                <Container>
                    {
                        isValid ? <>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/#" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="50" />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">

                                        <CardBody className="p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">{t("resetpassword.create_new_password")}</h5>
                                                <p className="text-muted">Your new password must be different from previous used password.</p>
                                            </div>

                                            <div className="p-2">
                                                <Form>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="password-input">Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Input
                                                                type={passwordShow ? "text" : "password"}
                                                                className="form-control pe-5 password-input"
                                                                placeholder="Enter password"
                                                                id="password-input"
                                                                name={inputs.password}  
                                                                value={inputs.password}  
                                                                onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                            />
                                                            {passwordShow ? <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye" className="m-1 icon-xs icon-dual-info" /></button> :
                                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye-off" className="m-1 icon-xs icon-dual-info" /></button>}
                                                            {/* <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                id="password-addon"><i className="ri-eye-fill align-middle"></i></Button> */}
                                                        </div>
                                                        <div id="passwordInput" className="form-text">Must be at least 8 characters.</div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="confirm-password-input">Confirm Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                type={confrimPasswordShow ? "text" : "password"}
                                                                className="form-control pe-5 password-input"
                                                                placeholder="Confirm password"
                                                                id="confirm-password-input"
                                                                onChange={(e) => setInputs({ ...inputs, confirm_password: e.target.value })}
                                                                value={inputs.confirm_password}
                                                                name={inputs.confirm_password}     />
                                                         {confrimPasswordShow ? <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}><FeatherIcon icon="eye" className="m-1 icon-xs icon-dual-info" /></button> :
                                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}><FeatherIcon icon="eye-off" className="m-1 icon-xs icon-dual-info" /></button>}
                                                          
                                                            </div>
                                                    </div>
                                                    <div className="p-1 mt-1 mb-1">
                                                            <div className="fs-14 fw-bold">{t("resetpassword.password_must_contain")}:</div>
                                                            <div className="fs-14 p-1">
                                                                {islen() ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                {" "}{t("resetpassword.at_least_8_characters")}.
                                                            </div>
                                                            <div className="fs-14 p-1">
                                                                {inputs.password !== "" && /[a-z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                {" "}{t("resetpassword.one_lower_case")}.</div>
                                                            <div className="fs-14 p-1">
                                                                {inputs.password !== "" && /[A-Z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                {" "}{t("resetpassword.one_upper_case")}.
                                                            </div>
                                                            <div className="fs-14 p-1">
                                                                {inputs.password !== "" && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                {" "}{t("resetpassword.one_number_symbols_character")}.
                                                            </div>
                                                        </div>

                                                    {/* <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                    </div> */}

                                                    <div className="mt-4">
                                                        <Button color="success" className="w-100" onClick={onSubmit} disabled={!disabled}>Reset Password</Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Wait, I remember my password... <Link to="/signin" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        <ResetError />
                    }
                </Container>
                }
            </div>
        </ParticlesAuth>
    );
};

export default withTranslation()(ResetPassword);