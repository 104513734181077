import React, { useCallback, useEffect, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Input, Label, UncontrolledAccordion } from 'reactstrap';
import conditionsJson from "../../../Components/Common/Filter/conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "../../../Components/Common/SelectTree/SelectTree";
import DtsDataSelectInput from "./DtsDataSelectInput";
import ConditionalFilter from "./ConditionalFilter";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

// Helper function to check if a string is valid JSON
const isJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return [];
    }
};
const Conditional = ({setEditInputs, EditInputs, fieldJson,model, setFieldJson, dataFields, onFilterSelected, selectedGroup, filter, setSelectedGroupIndex }: any) => {  
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [openpermission, setOpenpermission] = useState([]);
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [properties, setProperties] = useState<PropertyProps[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<any>({});
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<any>("");
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);
    const [isDefaultValue, setIsDefaultValue] = useState<boolean>(false);
   

    // Map data fields to properties on component load
    useEffect(() => {
        const mappedProperties = dataFields?.map((item: any) => ({
            label: item.Header,
            value: item.accessor,
            type: item?.valueJson?.type || "datetime",
            key: item?.valueJson?.key,
            valueJson: item.valueJson || {}
        })) || [];
        setProperties(mappedProperties);
    }, [dataFields]);


    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj?.length) {
            if(selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj.map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj.map((option: any) => {
                    const optionsJson = isJson(option.valuesJson);
                    return { label: optionsJson[selectedProperty?.valueJson?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = JSON.parse(node.valuesJson);
      
        // Create the base transformed node
        const transformedNode: TransformedNode = {
          label: values.name,
          value: node.id,
          createdBy: node.createdBy,
          createdAt: node.createdAt,
          updatedAt: node.updatedAt,
          deletedAt: node.deletedAt,
          parentId: node.parentId,
          parent: node.parent,
        };
      
        // Recursively transform children if they exist and are not empty
        if (node.children && node.children.length > 0) {
          transformedNode.children = node.children.map(transformTree);
        }
      
        return transformedNode;
    }

    // Handle property selection
    const onSelectProperty = (property: PropertyProps) => {
        if (property) {
            setSelectedProperty(property);
            const conditionOptions = conditionsJson[property?.valueJson?.type as keyof typeof conditionsJson];
            if(conditionOptions) {
                setConditions(conditionOptions);
                setSelectedCondition(conditionOptions[0]); // Set the first condition by default
                setEditInputs({...EditInputs, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, when: property?.value, condition: conditionOptions[0].value}})
                setFieldJson({...fieldJson, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, when: property?.value, condition: conditionOptions[0].value}})
            }
            else {
                setEditInputs({...EditInputs, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, when: property?.value}})
                setFieldJson({...fieldJson, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, when: property?.value}})
            }
            resetValueStates();
        }
    };

    useEffect(() => {
        setEditInputs({...EditInputs, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, values: values}})
        setFieldJson({...fieldJson, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, values: values}})
    }, [values])

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        if (selectedProperty?.valueJson?.model) {
            dispatch(getOptions(selectedProperty.valueJson.model, {}));
        }
    };

    // Render input field based on property type
    const renderValueInput = () => {
        switch (selectedProperty?.valueJson?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isClearable
                        isSearchable
                        name="values"
                        options={options}
                        onChange={setValues}
                        value={values}
                        loading={optionsLoading}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isClearable
                        isSearchable
                        name="values"
                        options={selectedProperty?.valueJson?.values}
                        onChange={setValues}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <Input
                    name="label"
                    value={values}
                    onChange={(e: any) => setValues({value: e.target.value})}
                    placeholder='Enter value'
                />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: value ? value : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => setValue(e.target.value)}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        options={options}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                    />
                );
            default:
                return null;
        }
    };
    const onChange = (value: any) => {
        setIsDefaultValue(value)
        let property: any ={ label: fieldJson.label, value: fieldJson.key, valueJson: fieldJson }
        onSelectProperty(property)
        setEditInputs({...EditInputs, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, isDefaultValue: value}})
        setFieldJson({...fieldJson, ConditionalDefaultValue:{...EditInputs.ConditionalDefaultValue, isDefaultValue: value}})
    }

    useEffect(() => {       
        if(EditInputs?.ConditionalDefaultValue) setIsDefaultValue(EditInputs?.ConditionalDefaultValue?.isDefaultValue)
        let property: any ={ label: EditInputs.label, value: EditInputs.key, valueJson: EditInputs }
        setSelectedProperty(property)
    }, [EditInputs?.ConditionalDefaultValue?.isDefaultValue])

    useEffect(() => {       
        if(EditInputs?.ConditionalDefaultValue?.values) setValues(EditInputs?.ConditionalDefaultValue?.values) 
    }, [EditInputs?.ConditionalDefaultValue?.values])

    return (
        <div>
            <React.Fragment>
            <div className="vstack gap-2 p-3">
                <div className="hstack align-content-center gap-1 mt-1">
                    <div className='hstack my-2 justify-content-around'>                        
                            <div className="form-check-primary border-primary form-switch align-items-stretch">
                                <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                    onChange={(e : any) => onChange(e.target.checked)}
                                    checked={isDefaultValue} />
                                    <div className='fw-bold fs-14 vstack align-self-center'>
                                    <h6>Will this field will be rendered Default Value.</h6>
                            </div>
                        </div>                        
                    </div>
                </div> 
                <div>
                    {isDefaultValue && selectedProperty && (
                        <div className="vstack gap-2">
                            <div className="vstack gap-2">
                                {renderValueInput()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </React.Fragment>
        </div>
    );
};

export default Conditional;
