import React, { useState, useEffect } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
    TabContent,
    TabPane,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import Filter from "./innerComponent/Filter";
import AddedFilters from "./innerComponent/AddedFilter";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import Start from "./innerComponent/Start";


const DynamicFilters = ({show, onCloseClick, props, dataFields, filterJson,setFilterJson, filterCount, setFilterCount}: any) => {    
    const [newFilter, setNewFilter] = useState<any>({});
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [filterObject, setFilterObject] = useState<any>([]);
    const toggleArrowTab = (tab: any) => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    const nextClicked = () => {
        toggleArrowTab(activeArrowTab + 1)
     }

     const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1)
     }   
     useEffect(() => {
        if(filterCount == 0) toggleArrowTab(1)
         else toggleArrowTab(3)
     }, [])
     
    return (
        <Offcanvas
            direction="end"
            className="w-50"
            isOpen={show}
            id="offcanvasWithBothOptions"
            toggle={onCloseClick}            
            backdrop={false}
         ><ToastContainer />
            <OffcanvasHeader className="bg-info bg-gradient p-3 offcanvas-header-dark" id="offcanvasWithBothOptions" toggle={onCloseClick}>
                <span className="m-0 me-2 text-white">{props.t("applications.advanced_filter")}</span>
            </OffcanvasHeader>
            <OffcanvasBody id="offcanvasWithBothOptions">
                <Card>
                    <CardBody>
                        <TabContent activeTab={activeArrowTab} className="mt-4">
                            <TabPane id="basic-info" tabId={1}>
                                <Start nextClicked={nextClicked} filterJson={filterJson} />
                            </TabPane>
                            <TabPane  id="field-type" tabId={2}>
                                <Filter  dataFields={dataFields} nextClicked={nextClicked} setFilterObject={setFilterObject} filterObject={filterObject} filterJson={filterJson} setFilterJson={setFilterJson} newFilter={newFilter} filterCount={filterCount} setFilterCount={setFilterCount} />
                            </TabPane>
                            <TabPane  id="rules" tabId={3}>
                                <AddedFilters toggleArrowTab={toggleArrowTab} filterJson={filterJson}  setFilterObject={setFilterObject} filterObject={filterObject} newFilter={newFilter} setNewFilter={setNewFilter} filterCount={filterCount} setFilterCount={setFilterCount} />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </OffcanvasBody>                
        </Offcanvas>
    );
};

export default DynamicFilters;

