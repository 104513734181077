import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";
import Select from "react-select";

import FeatherIcon from 'feather-icons-react';

const Text = ({property, setConditionjson, conditionjson, setFilterObject, filterObject}: any) => {  
    const [condition, setCondition]= useState<any>({});
    const [option, setOption]= useState<any>({});
    const handleChange = (value: any, name: any, label: any) => {
        setConditionjson({})
        if(value == 'on') {
            setCondition({value:name, label: label })
            let newconditionjson = {propertylabel: property.label, propertyvalue: property.value, conditionLabel: label}
            setConditionjson(newconditionjson)
        }
    }

    const handleTextChange = (option: any, name: any) => {
        let newconditionjson = {...conditionjson, value: option.value}
        setOption(option);
        setConditionjson(newconditionjson)
    }
    useEffect(() => {
    }, [conditionjson])   
    
    return (
        <div className="vstack gap-2"> 
            <div className="vstack gap-1">
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="equal" checked={condition.value == 'equal'} onChange={(e) => handleChange(e.target.value,"equal", "contains exactly")} />
                <Label className="form-check-label" htmlFor="equal">
                    contains exactly
                </Label>
                {condition.value == 'equal' ?  <Select
                            placeholder='Select property type'
                            className="fs-16 fw-medium"
                            value={option}
                            onChange={(option: any) => handleTextChange(option,"equal")}
                            options={property.options}
                            name="choices-single-default"
                        ></Select> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="notequal" checked={condition.value == "notequal"} onChange={(e) => handleChange(e.target.value,"notequal", "doesn't contains exactly")}/>
                <Label className="form-check-label" htmlFor="notequal">
                    doesn't contains exactly
                </Label>
                {condition.value == "notequal" ?  <Select
                            placeholder='Select property type'
                            className="fs-16 fw-medium"
                            value={option}
                            onChange={(option : any) => handleTextChange(option,"notequal")}
                            options={property.options}
                            name="choices-single-default"
                        ></Select> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="known" id="known" checked={condition.value == "known"} onChange={(e) => handleChange(e.target.value,"known", "is known")}/>
                <Label className="form-check-label" htmlFor="known">
                    is known
                </Label>
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="unknown" id="unknown" checked={condition.value == "unknown"} onChange={(e) => handleChange(e.target.value,"unknown", "is unknown")}/>
                <Label className="form-check-label" htmlFor="unknown">
                    is unknown
                </Label>
            </div> 
        </div>
    );
};

export default Text;
