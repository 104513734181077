import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';
import AdvanceFilter from "./innerComponent/AdvanceFilter";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { BankListGetRequest, bankDelete, bankGetRequest, banksMetadataGetRequest } from 'store/banks/action';
import { ToastContainer, toast } from 'react-toastify';
import MoreFilters from './innerComponent/MoreFilters';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import { propertyListGetRequest } from 'store/properties/action';
import AccessDenied from '../../AccessDenied/Denied';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Banks = (props: any) => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const bankMetadata = useSelector((state: ApplicationState) => state.banks.banksMetadata);
    const bankList = useSelector((state: ApplicationState) => state.banks.banks);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.banks.pagination); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])  
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [metaData, setMetaData] = useState(bankMetadata);
    const [bank, setBank] = useState([]);
    const [bankJson, setbankJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(BankListGetRequest(id,filters, 0));
        setbankJson([])
    }

    useEffect(() => {
        const filters = {pageSize: 100}
        dispatch(banksMetadataGetRequest(filters));
        setMetaData(bankMetadata)
    }, [])

    useEffect(() => {
        setMetaData(bankMetadata);
    }, [bankMetadata]);


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(bankGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.banks;
            setAccess(access)
            setViewAccess(true)
        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(BankListGetRequest(id,filterObject, newPageNo));
        setbankJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Bank Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(BankListGetRequest(id,))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(bankDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const toggleEnable = (el1: any) => {
        let a: any = document.getElementById(el1.target.id)
        a.disabled = true;
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }
    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson)  : fieldName?.valuesJson   
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}                
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;           
                if(isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: fields.tablecolumn,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if(fields.type=='select' || fields.type=='radio' || fields.type=='checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            return  <div className="d-flex align-items-center justify-content-between">                                      {
                                            fields.key == "name" ? <Row style={{width: 250}} className='justfy-content-start align-items-center'>                                           
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                    <Link to={`${location.pathname}/banks/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >                                            
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                </Col>                                           
                                            </Row> : 
                                            <div>
                                                {rendervalue}
                                            </div>
                                        }
                                    </div>
                        }
                    }
                    columns.push(newColumn)
                }   
            });
            const nameColumnIndex = columns.findIndex(column => column.accessor === "name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            columns.push({
                Header:"Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                    ''}
                        </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile]);

    useEffect(() => {
        setMetaData(bankMetadata)
    }, [bankMetadata != undefined])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

   
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {       
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(BankListGetRequest(id,filters));
            setbankJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const ClearFilter = () => {
        setsearch("")
        setstatusvalue("")
        setApplyFilter(false);
        setFilterJson([])
        setFilterCount(0)
        setPageSize(pageSize)
        let filter= {pageSize: pageSize}
        setFilterObject(filter)
        setPagination({page: 0})
        dispatch(BankListGetRequest(id,filter));
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'bank_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a= isEqual(bankList, bankJson)
        return !a
     }

    useEffect(() => {
        const parsedData: any = bankList && bankList.length ? bankList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, id: item.id , createdAt: item.createdAt}
            return values
        }) : []
        let bank = parsedData && parsedData.length && parsedData.reverse() 
        setBank(bank)
        setbankJson(bankList)
    }, [execute()])

    
    useEffect(() => {
        dispatch(BankListGetRequest(id,))
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(BankListGetRequest(id,filters,0));
        setbankJson([])
    }
    
    const onChangeIndex = (column: any,index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.banks;
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort} 
          setFilterObject(filters)         
          dispatch(BankListGetRequest(id,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(BankListGetRequest(id,filters, pagination.page));
        }
      };

    document.title = "DTS | Zilter";
    return ( 
        <>
            {
                viewAccess ?
                <React.Fragment>
                        {/* <ToastContainer />    */}
                        {isExportCSV && <ExportDataModal
                        show={isExportCSV}
                        onCloseClick={() => setIsExportCSV(false)}
                        // data={data}
                        props={props}
                    />}
                    {isFilterModalOpen && <AdvanceFilter
                        show={isFilterModalOpen}
                        onCloseClick={() => setisFilterModalOpen(false)}
                        dataFields={columns}
                        props={props}
                        setFilterObject={setFilterObject}
                        filterObject={filterObject}
                        applyFilters={applyFilters}
                    />}
                    {isDynamicModalOpen && <DynamicFilters
                        show={isDynamicModalOpen}
                        onCloseClick={() => setIsDynamicModalOpen(false)}
                        dataFields={columns}
                        props={props}
                        setFilterObject={setFilterObject}
                        filterObject={filterObject}
                        applyFilters={applyFilters}
                        filterJson={filterJson}
                        setFilterJson={setFilterJson}
                        filterCount={filterCount}
                        setFilterCount={setFilterCount}
                    />}
                    {previewModal && <PreviewModal
                        show={previewModal}
                        onCloseClick={() => setpreview(false)}
                        record={record}
                        ontoggle={toggle}
                        open={open}
                        setrecord={setrecord}
                        metaData={metaData}
                    />}
                    {editModal && <EditModal
                        show={editModal}
                        onCloseClick={() => setEditOpen(false)}
                        onApplyClick={onApplyClick}
                        onchange={onchange}
                        columns={columns}
                        visibleColumns={visibleColumns}
                        toggleColumn={toggleColumn}
                        onClickmove={onClickmove}
                        deSelectColumnall={deSelectColumnall}
                        onChangeIndex={onChangeIndex}
                        tempcolumns={tempcolumns}
                        setTempColumns={setTempColumns}
                    />}
                    {iscreateModal && <AddEntryModal
                        show={iscreateModal}
                        onCloseClick={() => setCreateOpen(false)}
                        dataFields={metaData}
                        props={props}
                    />}
                    {isImportCSV && <ImportDataModal
                        show={isImportCSV}
                        onCloseClick={() => setIsImportCSV(false)}
                        props={props}
                    />}
                    {isDeleteOpen && <DeleteModal
                        show={isDeleteOpen}
                        onCloseClick={() => setisDeleteOpen(false)}
                        props={props}
                        record={record}
                        onDelete={onDelete}
                    />}
                    <Row  className='h-100'>
                        <Col xl={12} lg={12} md={12} className='h-100'>
                            <div>
                                <Card className='p-3'>
                                    <Row className='hstack gap-3 mb-2'>
                                        <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                            <div className="search-box">
                                                <Input
                                                    type="text"
                                                    size={14}
                                                    className="search"
                                                    placeholder={props.t("bank.search_for")}
                                                    onChange={(e) => handleChange(e.target.value)}
                                                    value={search}
                                                />
                                                {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='hstack gap-2 justify-content-end'>
                                                <div className='hstack gap-2'> 
                                                    {
                                                        create ? 
                                                        <Button
                                                            onClick={() => setCreateOpen(true)}
                                                            color="primary"
                                                            className="btn-label">
                                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t("sources.create_entry")}
                                                        </Button>
                                                        :         
                                                        <Button
                                                            // onClick={() => setCreateOpen(true)}
                                                            color="primary"
                                                            disabled
                                                            className="btn-label">
                                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t("applications.create_entry")}
                                                        </Button>
                                                    }
                                                   
                                                    <Button
                                                        onClick={() => setEditOpen(true)}
                                                        color="primary"
                                                        className="btn-label btn-soft-primary">
                                                            <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                            {props.t("sources.edit_columns")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>                        
                                    <Row>
                                        <div>                         
                                            <MoreFilters 
                                                dataFields={metaData}                                 
                                                setFilterObject={setFilterObject}
                                                filterObject={filterObject}
                                                applyFilters={applyFilters}
                                                ClearFilter={ClearFilter}
                                                setApplyFilter= {setApplyFilter}
                                                setIsDynamicModalOpen = {setIsDynamicModalOpen}
                                                filterCount={filterCount}
                                                props={props}
                                            />
                                        </div>
                                    </Row>
                                    <Row className='students-table mt-2'>
                                        <TableContainer
                                            columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                            data={bank || []}
                                            customPageSize={pageSize}
                                            divClass="table-responsive"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light text-muted"
                                            thClass="border-bottom-1 table-soft-primary"
                                            SearchPlaceholder='Search...'
                                            isPageSizeChange={true}
                                            isBordered={true}
                                            handleEditClick={handleEditClick}
                                            handleLeadClick={handleLeadClick}
                                            setisDeleteOpen={setisDeleteOpen}
                                            setIsMultiDeleteButton={setIsMultiDeleteButton}
                                            setselectedcount={setselectedcount}
                                            pageChanged={pageChanged}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            pagesList={pagesList}
                                            onChangePageSize={onChangePageSize}
                                            pageSize={pageSize}
                                            setpreview={setpreview}
                                            setNotesModals={setNotesModals}
                                            handleSort={handleSort}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                            props={props}
                                            access={access}
                                        />
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
                : <AccessDenied />
            }
        </>
    );
};
export default withTranslation()(Banks);


