import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ExportModal = ({ props, show, onCloseClick, onCreate, viewname, setViewname}: any) => {
    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader toggle={onCloseClick}>Create View</ModalHeader>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <div  className="mb-3 text-left">
                            <Label>
                                View Name
                            </Label>
                            <Input type={"text"} className="form-control w-100" placeholder={"Enter View name"} value={viewname} onChange={(e: any) => setViewname(e.target.value)} />                      
                        </div>
                       <div className="hstack gap-2 justify-content-center">
                          
                            <Button
                                onClick={() => onCreate()}
                                disabled={!viewname}
                                color="primary"
                                // size='sm'
                                className="btn-soft-primary">
                                    {props.t("properties.create")}
                            </Button>
                            <Button color="danger" onClick={() => onCloseClick()} outline>
                                {props.t("properties.cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ExportModal;