import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, CardBody, Input, Label } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import {
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import { Link } from "react-router-dom";
import data from "./UsersListData.json";
import FeatherIcon from "feather-icons-react";
import EditModal from "./EditColumnModal";
import moment from "moment";
import _ from "lodash";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { usersListGetRequest } from "store/user/action";
interface GlobalFilterProps {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  SearchPlaceholder?: string;
  isProductsFilter?: boolean;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  isLeadsFilter?: any;
}

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
}: GlobalFilterProps) {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

  const user = useSelector((state: ApplicationState) => state.user.users);
  const [value, setValue] = React.useState(globalFilter);
  const [searchvalue, setsearchvalue] = useState<string>("");
  const [searchData, setsearchData] = useState<any>([]);
  const [editModal, setEditOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [visibleColumns, setvisibleColumns] = useState<any>([]);
  const [previewModal, setpreview] = useState<boolean>(false);
  const [record, setrecord] = useState<any>({});
  const [inputs, setInputs] = useState<any>({});
  const [focusid, setfocused] = useState<number>(-1);

  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const searchDatacall = (e: any) => {
    setsearchvalue(e.target.value);
    const updatedData = data.filter((item: any) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
        item.email.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
        item.phone_number.toLowerCase().search(e.target.value.toLowerCase()) !==
        -1 ||
        item.source.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    setsearchData(updatedData);
  };
  const handleChange = () => {
    setsearchData(searchData);
  };
  const onchange = (column: { accessor: string }) => {
    let index = visibleColumns.findIndex(
      (x: { accessor: string }) => x.accessor === `${column.accessor}`
    );
    let newCols = visibleColumns;
    if (
      column.accessor == "created_date" ||
      column.accessor == "updated_date"
    ) {
      newCols[index] = {
        Header: _.startCase(column.accessor),
        accessor: column.accessor,
        filterable: true,
        disableFilters: true,
        show: true,
        Cell: (cellProps: any) => {
          return moment(cellProps.row.original.heading).format("DD MMM, YYYY");
        },
      };
    } else {
      newCols[index] = {
        Header: _.startCase(column.accessor),
        accessor: column.accessor,
        filterable: true,
        disableFilters: true,
        show: true,
      };
    }
    setvisibleColumns(newCols);
  };
  const onApplyClick = () => {
    let fixedcolumns: any = [];
    visibleColumns.map((column: any) => {
      if (column.show) fixedcolumns.push(column);
    });
    setColumns(fixedcolumns);
    setEditOpen(false);
  };
  const onClickmove = (column: { accessor: string }) => {
    const newCols = [...visibleColumns];
    const index = visibleColumns.findIndex(
      (obj: { accessor: string }) => obj.accessor === column.accessor
    );
    if (
      column.accessor == "created_date" ||
      column.accessor == "updated_date"
    ) {
      newCols[index] = {
        Header: _.startCase(column.accessor),
        accessor: column.accessor,
        filterable: true,
        disableFilters: true,
        show: false,
        Cell: (cellProps: any) => {
          return moment(cellProps.row.original.heading).format("DD MMM, YYYY");
        },
      };
    } else {
      newCols[index] = {
        Header: _.startCase(column.accessor),
        accessor: column.accessor,
        filterable: true,
        disableFilters: true,
        show: false,
      };
    }
    setvisibleColumns(newCols);
  };
  const deSelectColumnall = () => {
    let tempcolumns: any = [];
    tempcolumns = visibleColumns.map((column: any, index: number) => {
      if (column.accessor == "name")
        return {
          Header: _.startCase(column.accessor),
          accessor: column.accessor,
          filterable: true,
          disableFilters: true,
          show: true,
          Cell: (cellProps: any) => {
            return (
              <div
                className="hstack"
                onClick={() => {
                  setpreview(true);
                  setrecord(cellProps.row.original);
                }}
              >
                <span>{cellProps.row.original.name}</span>
                <Button
                  size="sm"
                  color="dark"
                  outline={true}
                  className={"btn_preview"}
                >
                  Preview
                </Button>
              </div>
            );
          },
        };
      else
        return {
          Header: _.startCase(column.accessor),
          accessor: column.accessor,
          filterable: true,
          disableFilters: true,
          show: false,
          Cell: (cellProps: any) => {
            if (
              cellProps.row.original.heading == "created_date" ||
              cellProps.row.original.heading == "updated_date"
            ) {
              return moment(cellProps.row.original.heading).format(
                "DD MMM, YYYY"
              );
            } else
              return (
                <div className="hovered">{cellProps.row.original.heading} </div>
              );
          },
        };
    });
    let fixedcolumns: any = [];
    columns.map((column: any, index: number) => {
      if (column.accessor == "name")
        fixedcolumns.push({
          Header: _.startCase(column.accessor),
          accessor: column.accessor,
          filterable: true,
          disableFilters: true,
          show: true,
          Cell: (cellProps: any) => {
            return (
              <div
                className="hstack"
                onClick={() => {
                  setpreview(true);
                  onClickrow(cellProps.row.original, index);
                }}
              >
                <span>{cellProps.row.original.name}</span>
                <Button
                  size="sm"
                  color="dark"
                  outline={true}
                  className={"btn_preview"}
                >
                  Preview
                </Button>
              </div>
            );
          },
        });
    });
    setvisibleColumns(tempcolumns);
    setColumns(fixedcolumns);
    setEditOpen(false);
  };
  const onClickrow = (data: any, index: any) => {
    setrecord(data);
    setInputs(data);
    setfocused(index);
  };
  return (
    <React.Fragment>
      <form>
        <div
          className={
            isProductsFilter ||
            isContactsFilter ||
            isCompaniesFilter ||
            isNFTRankingFilter
            // ? "search-box me-2 mb-2 d-inline-block"
            // : "search-box me-2 mb-2 d-inline-block col-12"
          }
        >
         
        </div>

        {isProductsFilter && <ProductsGlobalFilter />}
        {isCustomerFilter && <CustomersGlobalFilter />}
        {isOrderFilter && <OrderGlobalFilter />}
        {isContactsFilter && <ContactsGlobalFilter />}
        {isCompaniesFilter && <CompaniesGlobalFilter />}
        {isLeadsFilter && <LeadsGlobalFilter onClickDelete={undefined} />}
        {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
        {isInvoiceListFilter && <InvoiceListGlobalSearch />}
        {isTicketsListFilter && <TicketsListGlobalFilter />}
        {isNFTRankingFilter && <NFTRankingGlobalFilter />}
        {isTaskListFilter && <TaskListGlobalFilter />}
      </form>
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isAddOptions?: any;
  divClassName?: any;
  tableClassName?: any;
  theadClassName?: any;
  tableClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  isAddUserList?: any;
  handleOrderClicks?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  className?: any;
  customPageSizeOptions?: any;
  iscustomPageSize?: boolean;
  SearchPlaceholder: string;
  isProductsFilter?: boolean;
  isPageSizeChange?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isLeadsFilter?: any;
  isPagination?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  handleEditClick: (e: any) => void;
  onClickDelete: (e: any) => void;
  handleLeadClick: (e: any) => void;
  setisDeleteOpen: (e: any) => void;
  handleSelectChange?: (e: any) => void;
  edit: any;
}

const TableContainer = ({
  columns,
  data,
  isPageSizeChange,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  handleEditClick,
  onClickDelete,
  handleLeadClick,
  setisDeleteOpen,
  edit
}: TableContainerProps) => {
  const {
    
    onPageSizeChange,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    // preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 1000,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    // useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const handleSelectChange = (e: any) => {
    const pageSize = parseInt(e.target.value);
    onPageSizeChange(pageSize); // Call the provided function with the new page size
  };
  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const checkedAll = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".leadsCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    // deleteCheckbox();
  };

  return (
    <Fragment>
      <Row className="mb-3 mx-n4">
        {isGlobalFilter && (
          <GlobalFilter
            // preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    className={thClass}
                    // {...(column.isSortable
                    //   ? { ...column.getSortByToggleProps() }
                    //   : "")}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      let classList = cell.column.className;
                      return (
                        <td
                          key={cell.id}
                          className={`${classList}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

    
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
