import React, { useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form,
  Button
} from "reactstrap";
import Select from "react-select";


const Start = ({nextClicked, filterJson}: any) => {      
    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
            <Col lg={12} className="d-flex gap-2 flex-column justify-content-center align-items-center">    
                {filterJson.length ? null : <h4>This view has no advanced filters</h4>}
            </Col>
            <Col lg={4} md={6} className="d-flex gap-2 flex-column justify-content-center">    
                <Button                            
                    className="btn btn-success w-auto"
                    onClick={() => nextClicked()}
                >
                    Add Filters
                </Button>
            </Col>
            </Row>
        </div>
    );
};

export default Start;
