import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";
import Select from "react-select";
import TextPreviewComponent from "./TextPreviewComponent";
import PhoneNumberPreview from "./PhoneNumberPreview";
import SingleCheckbox from "./SingleCheckbox";
import MultipleCheckbox from './MultipleCheckBoxPreview';
import DropdownSelectPreview from "./DropdownSelectPreview";
import RadioPreview from "./RadioPreview";
import DatePickerPreview from "./DatePickerPreview";
import NumberPreview from "./NumberPreview";
import FieldArray from '../Inputs.json';
import DataSetDropdown from "./DataSetDropdown";
import { components } from "react-select";
import TextEditorPreview from "./TextEditorPreview";

const BasicInfo = ({setCreateInputs, createInputs, setFieldJson, fieldJson}: any) => {  
    const [selectedFieldtype, setSelectedFieldtype] = useState<any>(null);
    const [selectedStatustype, setSelectedStatustype] = useState<any>(null);

    const handleselected = (option: any ) => {
        setSelectedFieldtype(option);
        let fieldvalue = option.value;
        let field = FieldArray.filter((field: any) => field.type === option.value)[0];
        let newfield = {...field, label: createInputs.label, key: createInputs.key, description: createInputs.description, placeholder: ""}
        setCreateInputs({...createInputs, fieldtype: fieldvalue,  placeholder: ""})
        setFieldJson(newfield)
    }
    const handlestatusselected = (option: any,key: any ) => {
        setSelectedStatustype(option);
        let fieldvalue = option.value;
        let newfield = {...fieldJson,[key] : fieldvalue }
        setCreateInputs({...createInputs, [key] : fieldvalue })
        setFieldJson(newfield)
    }

    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
                { label: "Text Editor", value: "texteditor" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }

    ];

    const handlePreviewComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : '';
        switch(renderfieldtype) { 
            case 'email':  
            case 'textarea':   
            case 'textfield': return <TextPreviewComponent createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype} fieldJson={fieldJson} />
            case 'texteditor': return <TextEditorPreview createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype} fieldJson={fieldJson} />
            case 'phone': return <PhoneNumberPreview createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'checkbox': return <SingleCheckbox createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'selectboxes': return <MultipleCheckbox setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'select': return <DropdownSelectPreview setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'datasetselect': return <DataSetDropdown setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'radio': return <RadioPreview setFieldJson={setFieldJson} fieldJson={fieldJson}  createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype}   />
            case 'datetime': return <DatePickerPreview createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype}  fieldJson={fieldJson} />
            case 'file': return null
            case 'number': return <NumberPreview createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} />
            default: return null
        }
    }

    const handlePlaceholderComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : ''
        switch(renderfieldtype) {  
            case 'textarea':   
            case 'textfield':
            case 'phone': 
            case 'datetime': 
            case 'email': 
            case 'number': return Placeholder()
            default: return null
        }
    }

    const Placeholder = () => {
        return <div>
                <Label className="fs-16 fw-medium my-1">Enter Placeholder</Label>                            
                <Input
                    type={"text"}
                    name={"label"}
                    className="fs-16 fw-medium"
                    value={createInputs.placeholder}
                    onChange={(e) => setCreateInputs({...createInputs, placeholder: e.target.value})}                                                         
                />
            </div>
    }

    useEffect(() => {
        let newfield = {...fieldJson, placeholder: createInputs.placeholder}
        setFieldJson(newfield)
    }, [createInputs])
    
    const statusoptions = [
        {
            label: 'Application',
            value: 'application',
            in_forms: true
        },
        {
            label: 'Complete Documents',
            value: 'complete_documents',
            in_forms: true
        },
        {
            label: 'Offer Letter Conditional',
            value: 'Offer_Letter_Conditional',
            in_forms: true
        },
        {
            label: 'University Assessment/Interview',
            value: 'university_assessment_interview',
            in_forms: true
        },
        {
            label: "Unconditional Offer Letter",
            value: "unconditional_Offer_letter",
            in_forms: true
        },
        {
            label: "Fee Deposit",
            value: "fee_deposit",
            in_forms: true
        },
        {
            label: "Visa Appointment Book",
            value: "VisaAppointmentBook",
            in_forms: true
        },
        {
            label: "Visa Documents Checked",
            value: "Visa_documents_checked",
            in_forms: true
        },
        {
            label: "Visa Applied",
            value: "visa_applied",
            in_forms: true
        },
        {
            label: "Visa Granted",
            value: "visa_granted",
            in_forms: true
        },
        {
            label: "Visa Rejected",
            value: "visa_rejected",
            in_forms: true
        },
        {
            label: "Offer Accepted",
            value: "offer_accepted",
            in_forms: true
        },
        {
            label: "Balance fee payment",
            value: "balance_fee_payment",
            in_forms: true
        },
        {
            label: "Pre Departure Pack",
            value: "pre_departure_pack",
            in_forms: true
        },
        {
            label: "Enrolled To University",
            value: "enrolled_to_university",
            in_forms: true
        },
        {
            label: "Post Arrival Services",
            value: "post_arrival_services",
            in_forms: true
        },
        {
            label: "Invoice",
            value: "invoice",
            in_forms: true
        }
    ]
    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className='hstack justify-content-between'>
                {props.children}
                {props.isSelected && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };

    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    // color: 'black',
                    fontWeight: 900,
                    border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 8,
                    boxShadow: 'none',
                    '&:hover': {
                    border: '0.5px solid lightgrey',
                    },
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: 'white',
          borderRadius: 8,
        //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        //   border: '1px solid #ccc',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'white' : 'white', // Custom background color for selected option
          color: state.isSelected ? 'black' : 'black', // Custom text color for selected option
          '&:hover': {
            backgroundColor: 'lightgrey', // Custom background color on hover
          },
        }),
    }
    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">    
                        {createInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-semibold">Field Type</Label>                            
                        <Select
                            defaultOptions
                            placeholder={'Select field type'}
                            className="fs-16 fw-medium"
                            value={selectedFieldtype}
                            onChange={(option : any) => handleselected(option)}
                            options={fieldtype}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        {handlePlaceholderComponent()}
                    </div>
                    { createInputs.objectType == "applications" ?
                        <div className="live-preview vstack gap-2 mb-2">     
                        <Label className="form-label">{"Status"}<span className="d-none fw-bold text-danger">{" "}*</span></Label>
                        <p>Select status if field is linked to any specific application status else leave it blank. </p>
                        <Select
                            styles={customStyles}
                            components={{ Option: CustomOption }}
                            className='form-select-sm p-0'
                            placeholder={`Select status`}
                            onChange={(selectedOption: any) => handlestatusselected(selectedOption, "applicationstatusLinked")}
                            options={statusoptions}              
                        >                        
                        </Select>
                    </div> : null 
                    }
                    <div>
                        {handlePreviewComponent()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BasicInfo;
