import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner ,Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Nav, TabContent, TabPane, NavLink, NavItem, ButtonGroup} from 'reactstrap';
import _ from 'lodash';
import TableContainer from './optiontablecomponent';
import dataFields from './tablefields.json';
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import { Link } from 'react-router-dom';
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";

const RadioPreview = ({createInputs, setCreateInputs, setFieldJson, fieldJson, fieldType} : any) => {
    const [columns, setColumns] = useState<any>([])
    const [counter, setCounter] = useState<number>(1)
    const [searchvalue, setSearchvalue] = useState<any>('')
    const [selectedsortOption, setselectedsortOption] = useState<any>({label: 'Custom', value: 'custom'})
    const [row, setRow] = useState<any>({})
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [actionoptions, setactionoptions] = useState<any>([])
    const [value, setValue] = useState()
    const [rows, setRows] = useState<any>([
        {
            "id":1,
            "label": "",
            "value": "",
            "in_forms": true
        }
    ])
    const [pageSize, setPageSize] = useState<number>(30)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [Selectedcount, setselectedcount] = useState<number>(0)

    const onchange = (checked: any) => {
        setValue(checked)
    }

    useEffect(() => {
        const columns: any = dataFields && dataFields.fields ? dataFields.fields.map((fields,index) =>{     
            if(fields.name == 'id') return {
                    Header: _.startCase(fields.name),
                    accessor: fields.name,
                    filterable: true,
                    isSortable:false,
                    disableFilters: true,
                    show: false,
            }
            else if(fields.type == 'text')  return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {                 
                    return <Input placeholder={fields.placeholder} value={cellProps.row.original[fields.name]} name={fields.name} onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.value)} />
                  }
            }     
            else if(fields.type == 'radio') return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                isSortable:true,
                disableFilters: true,
                show: true,
                className: 'text-center',
                Cell: (cellProps: any) => {
                    return <div className="form-check form-switch form-check-right align-items-center d-flex mt-1">
                    <Input className="form-check-input" name={fields.name} type="checkbox" role="switch" defaultChecked={cellProps.row.original.in_forms} id="flexSwitchCheckRightDisabled" onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.checked)}/>
                </div>                  
                }
            }
        }) : []
        setColumns(columns)
    }, []); 


    const handleCellValueChange = (cellprops: any, field: any, value: any) => {
        if(field == "label") {
            setRows((prevState: any) =>
            prevState.map((item: any) =>
                item.id === cellprops.id ? { ...item, [field]: value, value: value.toLowerCase().replace(/ /g, "_")} : item
            )
        );
        } 
        else {
            setRows((prevState: any) =>
            prevState.map((item: any) =>
                item.id === cellprops.id ? { ...item, [field]: value} : item
            )
        );
        } 
        
    };
    
     
    const addoption = () => {
        setCounter(counter + 1)
        let updatedrows = [...rows]
        const row = {
            "id":counter + 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        updatedrows.push(row)
        setRows(updatedrows);
    }

    const deleteAllOption = () => {
        setCounter(1)
        const row = {
            "id":1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        setRows([row]);
    }

    const sortbyoptions = [
        {label: 'Custom', value: 'custom'},
        {label: 'alphabetically', value: 'a-z'}
    ]


    
    const handleselect = (obj: any, value: boolean) => {
        if(value) {
            let newList = actionoptions;
            newList.push(obj)           
            setactionoptions(newList)            
            handleCheckboxChange(obj.id, true)
        }
        else {
            let newList = actionoptions.filter((item: any) => item.id !== obj.id);       
            setactionoptions(newList)            
            handleCheckboxChange(obj.id, false)
        }
    }

    
    const handleCheckboxChange = (id: any, value: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: value }));
    };

    useEffect(() => {
        let options= rows.sort((a: any, b: any) => a.id - b.id);
        setCreateInputs({...createInputs, options});
        setRows(options);
        setCreateInputs({...createInputs, options: options})
        let newfield = {...fieldJson, values: options}
       setFieldJson(newfield)  
    }, [rows]); 

    const deleteOption = () => {
        let newrows = rows;
        actionoptions && actionoptions.map((object: any) => {
            newrows = newrows.filter((item: any) => item.id !== object.id)  
        })
        setRows(newrows)
        setCheckedRows([])   
        setactionoptions([])            
    }
    document.title="DTS | Zilter";
    return (
        <React.Fragment>   
            <Row className='mt-1'>
                <Col>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0">
                            <div  className={"students-table"}>
                                <TableContainer
                                    columns={columns}
                                    data={rows.sort((a: any, b: any) => a.id - b.id) || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    isPageSizeChange={false}
                                    isBordered={true}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    handleselect={handleselect}
                                    checkedRows={checkedRows}
                                 
                                    
                                />
                            </div>
                            <div className="hstack gap-3 px-3">
                                <Link
                                    to="#"
                                    className="fw-bold text-info w-auto"
                                    onClick={() => addoption()}
                                >
                                    <FeatherIcon icon="plus" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} />
                                    Add option                  
                                </Link>
                                <Link
                                    to="#"
                                    className="fw-bold text-info w-auto"
                                    onClick={() => deleteAllOption()}
                                >
                                    <FeatherIcon icon="trash-2" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} /> 
                                     Clear all                   
                                </Link>
                                {
                                    actionoptions && actionoptions.length ?
                                        <Link
                                            to="#"
                                            className="fw-bold text-info w-auto"
                                            onClick={() => deleteOption()}
                                        >
                                            <FeatherIcon icon="trash-2" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} /> 
                                            Delete options                   
                                        </Link> 
                                    : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div id="myDivparent">
                        <div  className="fs-16 fw-semibold">Preview</div>
                        <Card className="m-3 vstack" id="myDivChild">
                            <Label className="fs-16 fw-semibold">{createInputs.label}</Label>
                            <div className="hstack justify-content-start gap-2">
                            {rows.map((item: any) => {
                                    if(!item.in_forms) return 
                                    return (<div className="form-check mb-2">
                                             <Input
                                                className="form-check-input"
                                                type="radio"
                                                id={item.value}
                                                checked={item.value == value}
                                                onChange={(e) => onchange(item.value)}
                                            />
                                            <Label className="form-check-label">
                                                {item.label}
                                            </Label>
                                        </div>
                                        )
                                })} 
                            </div>                       
                        </Card>
                    </div>
                </Col>
            </Row>               
        </React.Fragment>
    );
};
export default RadioPreview;
