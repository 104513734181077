import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import UniversitiesListing from './University';
import { withTranslation } from 'react-i18next';

const Universities = (props: any) => {
    
    document.title="DTS | Zilter";
    const capitalizeFirstLetter = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title={capitalizeFirstLetter(props.t("institute.institutes"))} pageTitle={capitalizeFirstLetter(props.t("institute.institutes"))} icon={"ri-building-4-line"} />
                    <Row>
                        <UniversitiesListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Universities);