import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
import university from '../../../src/assets/images/dtsimg/svg/university.svg';
import OverviewTab from './OverviewTab';
import { useNavigate, useParams } from 'react-router';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { universityGetRequest } from 'store/university/action';
import moment from 'moment';
import Finance from './Finanace//Finance';
import { ToastContainer } from 'react-toastify';
import UserAvatar from 'Components/Common/UserAvtar/UserAvatar';

const Section = () => {
    const {id} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const universityDetail = useSelector((state: ApplicationState) => state.universities.university)
    const [activeTab, setActiveTab] = useState<string>('1');
	const navigate = useNavigate();
    const [info, setinfo] = useState<any>({});
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const levelhandle = (levelvalue: any) => {
        let level = ""
        switch(levelvalue) {
            case 1: level ='Undergraduate';            
                    break
            case 2: level ='Postgraduate';            
                    break
            case 3: level ='Foundation';            
                    break
            case 4: level ='Research';            
                    break  
            default: level ='NA';            
                    break  
        }
        return level;
    }
    
    useEffect(() => {
        dispatch(universityGetRequest(id))
    },[]) 
    
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }


    useEffect(() => {
        let details = universityDetail && universityDetail.valuesJson && isJson(universityDetail.valuesJson) ? JSON.parse(universityDetail.valuesJson) : {}
        setinfo(details)
    },[universityDetail]) 

    const goBack = () => {
		navigate(-1);
	  };

    return (
        <React.Fragment>
            <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar firstName={info?.institute_name || info?.name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.institute_name || info?.name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.universityaddress?.length ? info?.universityaddress[0].city_name: ""}</div>
                                                        <div><i className="ri-calendar-event-fill align-bottom me-1 fs-16"></i>{info.established_year}</div>
                                                        <div className="vr"></div>
                                                        <div className="vr"></div>
                                                        {universityDetail?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(universityDetail?.createdAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {universityDetail?.updatedAt ?<div>Updated Date : <span className="fw-medium">{moment(universityDetail?.updatedAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {/* <div className="badge rounded-pill bg-info fs-12">New</div>
                                                        <div className="badge rounded-pill bg-danger fs-12">High</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-bold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Finance
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>            
            <Row>
                <Col lg={12}>
                    <Button color="primary" className="w-auto btn-sm" onClick={() => goBack()}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    {activeTab === '1' && <TabPane tabId="1">
                        <OverviewTab info={info} levelhandle={levelhandle}/>
                    </TabPane>}
                    {activeTab === '2' && <TabPane tabId="2">
                        <Finance />
                    </TabPane>}
                    {/* <TabPane tabId="3">
                        <ActivitiesTab />
                    </TabPane>
                    <TabPane tabId="4">
                        <TeamTab />
                    </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;