import {Reducer} from "redux";
import {PropertiesActionTypes, PropertiesState} from "./types";

export const initialState: PropertiesState = { 
    errors: undefined,
    loading: false,
    response:{},
    token: undefined,
    pagination: null,
    achivedpagination: null,
    achivedlist:[],
    property: null,
    groups: [],
    groupsForm: [],
    allGroups: [],
    groupspagination: [],
    groupsloading: false,
    archivedloading: false,
    list: []
};

const reducer: Reducer<PropertiesState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case PropertiesActionTypes.GET_PROPERTIES_REQUEST: {
            return {...state, loading: true, list:[]};
        }
        case PropertiesActionTypes.GET_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case PropertiesActionTypes.GET_PROPERTIES_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_REQUEST: {
            return {...state, archivedloading: true};
        }
        case PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_SUCCESS: {
            return {
                ...state,
                archivedloading: false,
                achivedlist: action.payload.content,
                achivedpagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_ERROR: {
            return {...state, archivedloading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_PROPERTY_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.GET_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                property: action.payload
            };
        }
        case PropertiesActionTypes.GET_PROPERTY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_GROUPS_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.GET_GROUPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                groups: action.payload.content,
                groupspagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable?.offset
                }
            };
        }
        case PropertiesActionTypes.GET_GROUPS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_GROUPS_MODEL_REQUEST: {
            return {...state, groupsloading: true};
        }
        case PropertiesActionTypes.GET_GROUPS_MODEL_SUCCESS: {
            return {
                ...state,
                groupsloading: false,
                groups: action.payload.content,
                groupspagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable?.offset
                }
            };
        }
        case PropertiesActionTypes.GET_GROUPS_MODEL_ERROR: {
            return {...state, groupsloading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_GROUPS_STUDENTFORM_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.GET_GROUPS_STUDENTFORM_SUCCESS: {
            return {
                ...state,
                loading: false,
                groupsForm: action.payload
            };
        }
        case PropertiesActionTypes.GET_GROUPS_STUDENTFORM_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_GROUP_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.GET_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                groupDetails: action.payload
            };
        }
        case PropertiesActionTypes.GET_GROUP_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.GET_ALLGROUPS_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.GET_ALLGROUPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                allGroups: action.payload.content
            };
        }
        case PropertiesActionTypes.GET_ALLGROUPS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case PropertiesActionTypes.PUT_PROPERTY_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.PUT_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case PropertiesActionTypes.PUT_PROPERTY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }  
        case PropertiesActionTypes.POST_PROPERTY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }       
        case PropertiesActionTypes.POST_PROPERTY_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.POST_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }        

        
        case PropertiesActionTypes.POST_GROUP_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }       
        case PropertiesActionTypes.POST_GROUP_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.POST_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }        

        case PropertiesActionTypes.DELETE_PROPERTY_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.DELETE_PROPERTY_SUCCESS: {
            return {...state, loading: false, data: action.payload};
        }
        case PropertiesActionTypes.DELETE_PROPERTY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }  
        case PropertiesActionTypes.RESTORED_PROPERTY_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.RESTORED_PROPERTY_SUCCESS: {
            return {...state, loading: false, data: action.payload};
        }
        case PropertiesActionTypes.RESTORED_PROPERTY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        } 
        case PropertiesActionTypes.DELETE_GROUP_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.DELETE_GROUP_SUCCESS: {
            return {...state, loading: false, data: action.payload};
        }
        case PropertiesActionTypes.DELETE_GROUP_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        
        case PropertiesActionTypes.PUT_GROUP_REQUEST: {
            return {...state, loading: true};
        }
        case PropertiesActionTypes.PUT_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case PropertiesActionTypes.PUT_GROUP_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }  

        default: {
            return state;
        }
    }
};

export {reducer as PropertiesReducer};
