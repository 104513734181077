import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Label } from 'reactstrap';
import conditionsJson from "../../../Components/Common/Filter/conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "../../../Components/Common/SelectTree/SelectTree";
import DtsDataSelectInput from "./DtsDataSelectInput";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

// Helper function to check if a string is valid JSON
const isJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return [];
    }
};
const ConditionalFilter = ({setEditInputs, EditInputs, fieldJson,model, setFieldJson, dataFields, onFilterSelected, selectedGroup, filter, setSelectedGroupIndex }: any) => {  
    const [isFilteredData, SetIsFilteredData] = useState<boolean>(false);
    const [filterFieldValue, setFilterFieldValue]= useState<any>({});
    const [filterField, setFilterField]= useState<any>({});

    const handleselected = (option: any, name: string, ) => {
        switch(name) {
            case 'filterField': setFilterField(option)
                setEditInputs({...EditInputs, conditionalFilter:{...EditInputs.conditionalFilter, filterField: option.value}})
                setFieldJson({...fieldJson, conditionalFilter:{...EditInputs.conditionalFilter, filterField: option.value}})
                break
            case 'filterFieldValue': setFilterFieldValue(option)       
                setEditInputs({...EditInputs, conditionalFilter:{...EditInputs.conditionalFilter, filterFieldValue: option.value}})
                setFieldJson({...fieldJson, conditionalFilter:{...EditInputs.conditionalFilter, filterFieldValue: option.value}})
                break
        }
    }

    const onChange = (value: any) => {
        SetIsFilteredData(value)
        setEditInputs({...EditInputs, conditionalFilter:{...EditInputs.conditionalFilter, isFilteredData: value}})
        setFieldJson({...fieldJson, conditionalFilter:{...EditInputs.conditionalFilter, isFilteredData: value}})
    }
        
    useEffect(() => {       
        if(EditInputs?.conditionalFilter?.isFilteredData) { 
            SetIsFilteredData(EditInputs?.conditionalFilter?.isFilteredData)
            setFilterFieldValue(EditInputs?.conditionalFilter?.filterFieldValue)
            setFilterField(EditInputs?.conditionalFilter?.filterField) 
        }   
     }, [EditInputs?.conditionalFilter?.isFilteredData, EditInputs?.conditionalFilter?.isFilteredData,EditInputs?.conditionalFilter?.filterField])

    return (
        <div className="vstack gap-2 p-3">
            <div className="hstack align-content-center gap-1 mt-1">
                <div className='hstack my-2 justify-content-around'>                        
                        <div className="form-check-primary border-primary form-switch align-items-stretch">
                            <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                onChange={(e : any) => onChange(e.target.checked)}
                                checked={isFilteredData} />
                                <div className='fw-bold fs-14 vstack align-self-center'>
                                <h6>Show this field with filtered data.</h6>
                        </div>
                    </div>                        
                </div>
            </div> 
            {isFilteredData ?
            <div>
                <div>
                    <DtsDataSelectInput
                        placeholder={'Select Field name'}
                        onChange={(option : any) => handleselected(option, 'filterFieldValue')}
                        label={'Dependent on Field Value'}
                        value={filterFieldValue}
                        rest={{...fieldJson, model: EditInputs?.objectType}}
                        disabled={!model}
                        defaultValue={EditInputs?.conditionalFilter?.filterFieldValue}
                    />
                </div>  
                <div>
                    <DtsDataSelectInput
                        placeholder={'Select Field name'}
                        onChange={(option : any) => handleselected(option, 'filterField')}
                        label={'Filter Field'}
                        value={filterField}
                        rest={{...fieldJson, model: EditInputs?.model}}
                        defaultValue={EditInputs?.conditionalFilter?.filterField}
                    />
                </div>  
            </div> : null}
        </div>
    );
};

export default ConditionalFilter;
