import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { applicationGetRequest, noteAddRequest, noteGetRequest, notesListGetRequest } from "store/applications/action";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Select from "react-select";
import Comments from "./Modals/Comments";
import AddTask from "./Modals/TasksModal";
import FollowUpTask from "./Modals/FollowUpTask";

const Notes = (props: any) => {
  const noteType = [
    {
      options: [
        { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
        { label: "Follow-up call", value: "FOLLOW-UP CALL" },
        { label: "Incoming Call", value: "INCOMING CALL" },
        { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
        { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
        { label: "Course Notes", value: "COURSE NOTES" },
        { label: "Finance Notes", value: "FINANCE NOTES" },
        { label: "Others", value: "OTHERS" }
      ],
    },
  ];
  let { id } = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
  const notes = useSelector((state: ApplicationState) => state.applications.notes);
  const [tasksModals, setTasksModals] = useState<boolean>(false);
  const [addShortNoteValue, setAddShortNoteValue] = useState("");
  const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
  const [noteData, setNoteData] = useState({ notes })
  const [studentId, setStudentId] = useState<any>("");
  const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [newNoteId, setNewNoteId] = useState<string | null>(null)
  const [newNote, setNewNote] = useState("");
  const [followupRequired, setFollowupRequired] = useState(false)
  const [transcribing, setTranscribing] = useState("parent")
  const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
  const [filterObject, setFilterObject] = useState<any>({
    applicationId: id
  });

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({transcribing: transcribing == "parent"});

  useEffect(() => {
    dispatch(applicationGetRequest)
  }, [applicationDetails])

  useEffect(() => {
    if (applicationDetails && applicationDetails.id) {
      let studentsId = applicationDetails && applicationDetails?.valuesJson?.students && applicationDetails?.valuesJson?.students.id ? applicationDetails?.valuesJson?.students.id : "";
      setStudentId(studentsId)
      dispatch(notesListGetRequest(studentsId, filterObject))
    }
  }, [applicationDetails != null])

  useEffect(() => {
    if(id) {
      dispatch(applicationGetRequest(id))
    }
  }, [id])

  useEffect(() => {
    setNewNote(transcript);
  }, [transcript])

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
    setTranscribing("parent");
    SpeechRecognition.startListening({continuous: true});
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

    
  const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
    setReplyNoteId("");
  };

  const handleAddNoteTypeChange = (selectedOption: any) => {
    setSelectedAddNoteType(selectedOption);
    setAddShortNoteValue(selectedOption.label)
    // You can also update the formData here if needed
  };

  const handleReset = () => {
    setNewNote("");
      setSelectedAddNoteType(null)
      setIsAddNoteButtonDisabled(false);
    resetTranscript();
    setAddShortNoteValue('')
  };

  const handleAddNote = () => {
    stopListening();
    setIsAddNoteButtonDisabled(true);
    const data =
    {
      studentId: studentId,
      shortNote: addShortNoteValue,
      applicationId: id,
      parentId: "",
      note: newNote
    }
    const handleSuccess = (body: any): void => {
      createFollowUp();
      handleReset();
      const message = () => toast(`Note added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      dispatch(notesListGetRequest(studentId, filterObject));
    };

    const handleError = (body: any): void => {
      const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      message();
    };

    dispatch(noteAddRequest(data, handleSuccess, handleError));
  };

  const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupRequired(event.target.checked);
  };

  const createFollowUp = () => {
    setTasksModals(followupRequired)
  }

  return (
    <React.Fragment>
         <FollowUpTask
                props={props}
                tasksModals={tasksModals}
                setTasksModals={setTasksModals}
            />
      {/* Your existing JSX code */}
      <Card>
        <CardBody>
          <>
            <form className="mt-1">
              <Row className="g-3">
                <Col xs={12} >
                  <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" value={newNote}
                    onChange={handleNewNoteChange} rows={3} placeholder={props.t("applications.add_note")}></textarea>
                </Col>
                <Col xs={12} className="text-start">
                  {/* <Dictaphone /> */}
                  <div className='d-flex justify-content-end gap-2'>
                    <div className="form-check mb-0 align-self-center">
                      <Input className="form-check-input" type="checkbox" onChange={handleFollowupCheck} id="formCheck6" />
                      <Label className="form-check-label" for="formCheck6">
                        Follow-up required ?
                      </Label>
                    </div>
                    <div style={{ minWidth: '250px' }}>
                <Select
                  placeholder="Select Note Type"
                  classNamePrefix="js-example-data-array"
                  isLoading={false}
                  options={noteType}
                  value={selectedAddNoteType}
                  onChange={handleAddNoteTypeChange}
                  menuPlacement="auto" // You can also try "bottom" or "top"
                  menuPosition="fixed"
                />
                </div>
                    {listening ? (
                      <>
                        <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                      </>
                    ) : (
                      <>
                        <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                      </>
                    )}

                    {/* <button type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button> */}
                    <Button color="primary" className="ms-2 btn btn-primary"
                      disabled={isAddNoteButtonDisabled || (!newNote || !selectedAddNoteType) && (!transcript || !selectedAddNoteType)}
                      onClick={handleAddNote}>{props.t("applications.add_note")}</Button>
                  </div>
                </Col>
              </Row>
            </form>
          </>
          <Comments studentId={studentId} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing}/>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Notes);