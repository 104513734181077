import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import moment from 'moment';
import { useCCP } from '../../CCPContext/CCPContext';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { productListGetRequest, productDelete, productGetRequest, productUpdateRequest } from 'store/products/action';
import { ToastContainer, toast } from 'react-toastify';
import UserAvatar from './innerComponent/UserAvatar';
import AccessDenied from '../AccessDenied/Denied';
import { callAddRequest, callsListGetRequest } from 'store/calls/action';
import ArrangeFields from 'Components/Common/FieldsArranger/ArrangeFields';
import { viewGetRequest, viewUpdateRequest } from 'store/views/action';
import QuickFilter from '../../Components/Common/Filter/QuickFilter';
import AddEntryModal from './innerComponent/AddEntryModal';
import ListingLoader from 'Components/Common/FormBuilder/ListingLoader';
import { propertyListGetRequest } from 'store/properties/action';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Products = (props: any) => {
    const { viewId } = props;
    const { initializeCCP } = useCCP();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const productMetadata = useSelector((state: ApplicationState) => state.properties.list);
    const metaLoading = useSelector((state: ApplicationState) => state.properties.loading);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const view: any = useSelector((state: ApplicationState) => state.views.view);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const viewsloading = useSelector((state: ApplicationState) => state.views.loading);
    const productList = useSelector((state: ApplicationState) => state.products.products);
    const paginationDetails = useSelector((state: ApplicationState) => state.products.pagination);    
    const loading = useSelector((state: ApplicationState) => state.products.loading);    
    const dataloading = useSelector((state: ApplicationState) => state.products.dataloading);    
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])  
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [create, setCreate] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [open, setOpen] = useState('1');
    const [arrangeopen, setArrangeopen] = useState(false);
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [metaData, setMetaData] = useState([]);
    const [products, setProduct] = useState([]);
    const [productsJson, setproductsJson] = useState<any>([]);
    const [pagesList, setPagesList] = useState<any>([]);
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({});
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [updateRow, setUpdateRow] = useState<any>(null);
    const [fields, setFields] = useState<any>(null);
    const [communicate, setCommunicate] = useState<any>(false);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [filter, setFilter]: any = useState(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [selectedSearchField, setSelectedSearchField]: any = useState({value: 'all', label: 'All'});
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value })
    }

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("products", filters));
    }, [])


    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCommunicate(true)
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.products;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
            }
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                        }
                        if(item.name.toLowerCase() == 'create') {
                                item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                        }
           
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])
    
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
    
        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };

        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            dispatch(productListGetRequest(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi])

    const clearFilter = () => {
        setSearch("")
        setPagination({
            size: pagination?.size,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        })
    }

    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(productGetRequest(lead.id))
    };

    const handleNoteModal = (record: any) => {
        setrecord(record)
        setNotesModals(true)
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(productDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleLogAWSCall = (record: any) => {
        const data = {
            productId: record.id,
            provider: "AWS"
        }
        const handleSuccess = (): void => {
            toast(`Call logged successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(callsListGetRequest(record.id));
        };
        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(callAddRequest(data, handleSuccess, handleError));
    }
    const handleAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner": 
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleButtonClick = (record: any) => {
        initializeCCP();
        handleLogAWSCall(record);
    };
    
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

    const onChangestatus = (values: any, id: any) => {        
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        dispatch(productUpdateRequest(id, values, handleSuccess, () => { }))
    };

    const handleEditAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner": 
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };


    useEffect(() => {
        let columns: any[] = [];
            if (metaData && metaData.length > 0) {
                metaData.map((fieldName: any) => {   
                        let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {} 
                        let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                        let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                        if (isFieldviewaccess) {
                            let newColumn = {
                                Header: _.startCase(fields.label),
                                accessor: fields.key,
                                id: fields.key,
                                fieldid: fieldName.id,
                                filterable: true,
                                isSortable: false,
                                disableFilters: true,
                                valueJson: fields,
                                meta: fieldName,
                                show: fields.tablecolumn,
                                isEditAccess: isFieldEditAccess,
                                Cell: (cellProps: any) => {
                                    let rendervalue = cellProps.row.original[fields.key];                            
                                    if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                        rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                    }
                                    else if (fields.optionLabel) {
                                         rendervalue = cellProps?.row?.original[fields.key] && cellProps?.row?.original[fields.key][fields.optionLabel] ? cellProps?.row?.original[fields.key][fields.optionLabel] : 'NA'
                                    }                                  
                                    return <div className="d-flex align-items-center justify-content-between">                                      {
                                        fields.key == "name" ?   
                                        <Row style={{width: 300}} className='justfy-content-start align-items-center'>
                                            <Col md={12} lg={12} className={'hstack justify-content-between'}>
                                                <Link to={`/products/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis">     
                                                    <div className='hstack gap-1 '>                                                       
                                                        <UserAvatar img={record && record.img} firstName={cellProps.row.original[fields.key]} />
                                                        {cellProps.row.original[fields.key]}
                                                    </div>                                    
                                                </Link>
                                            </Col>                                                    
                                        </Row> 
                                        :
                                            <div>
                                                {rendervalue}
                                            </div>
                                    }
                                    </div>
                                }
                            }
                            columns.push(newColumn)
                        }
                });
            }     
        const nameColumnIndex = columns.findIndex(column => column.accessor === "name");
        if (nameColumnIndex !== -1) {
            const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
            columns.unshift(nameColumn); // Add it to the beginning
        }
        if(columns && columns.length > 0) {
            columns.push({
                Header:"Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                                {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                    ''}
                        </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile, fields, communicate]);

    useEffect(() => {
        setMetaData(productMetadata)
    }, [productMetadata])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {       
        setColumns(tempcolumns)
        if(viewId) {
            let fields: any = []
            tempcolumns && tempcolumns.length && 
                tempcolumns.map((field: any) => {
                    let item = field?.meta && isJson(field?.meta) ? JSON.parse(field?.meta) : {}
                    if (item.field == 'name') fields.push({ id: field.fieldid, extras: { key: item.key, show: true } })
                    else fields.push({ id: field.fieldid, extras: { key: item.key, show: field.show } })
                })
            setFields(fields)
        }
        setEditOpen(false)
    }

    const deSelectColumnall = () => {
        let tempcolumnsnew: any = []
        tempcolumnsnew = columns.map((column: any, index: number) => {
            if (column.accessor == 'name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsnew)
        setTempColumns(tempcolumnsnew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a = isEqual(productList, productsJson)
        return !a
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    useEffect(() => {
        const parsedData: any = productList && productList.length ? productList.map((item: any) => {            
            const valuesJson = item.valuesJson;
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let products = parsedData && parsedData.length && parsedData.reverse() 
        setProduct(products)
        setproductsJson(productList)
    }, [execute()])

    useEffect(() => {
        if(viewId) dispatch(viewGetRequest(viewId))
    }, [viewId])

    useEffect(() => {
        if(view) {
            let newFields: any = view && view.fields && isJson(view.fields) ? JSON.parse(view.fields) : {}
            setFields(newFields)
            let filtersObj: any = view && view.filters && isJson(view.filters)  ? JSON.parse(view.filters) : null
            if(filtersObj) {
                const { filters, sorts } = filtersObj;
                if(filters?.length) {
                    if(filters[0]?.search) {
                        setSearch(filters[0].search?.term || '')
                    }
                    if(filters[0]?.search) {
                        setSelectedSearchField(filters[0].search?.valuesLabel || '')
                    }
                    const filter = {
                        quick: [],
                        advance: []
                    }
                    if(filters[0]?.quick) {
                        filter.quick = filters[0]?.quick
                    }
                    if(filters[0]?.advance) {
                        filter.advance = filters[0]?.advance
                    }
                    setFilter(filter)
                }
                if(sorts?.length) {
                    setSortBy(sorts[0].field)
                    setSortOrder(sorts[0].order)
                }
            }
        }
    }, [view])
    
    const onChangeIndex = (column: any, index: any) => {
        if (index !== -1) {
            // Remove the column from its current position
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            // Insert the column at the desired index
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
        }
    };
      
    const saveview = () => {
            const filtersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value,
                    valuesLabel: selectedSearchField
                }
            }
            let data = {
                "fields": JSON.stringify(fields),
                "filters": JSON.stringify(filtersData)
            }         
            const handleSuccess = () => {
                const message = () => toast(`View Saved Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
            }
            const handleFailure = () => {

        }
        dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))
    }
    document.title = "DTS | Zilter";
    return ( 
        <React.Fragment>             
            <AddEntryModal
                show={iscreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={productMetadata}
                setTriggerApi={setTriggerApi}
                props={props}
            />
            {isExportCSV && <ExportDataModal
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                // data={data}
                props={props}
            />}
            <PreviewModal
                show={previewModal}
                onCloseClick={() => setpreview(false)}
                record={record}
                ontoggle={toggle}
                open={open}
                setrecord={setrecord}
                props={props}
            />
            {editModal && <EditModal
                show={editModal}
                onCloseClick={() => setEditOpen(false)}
                onApplyClick={onApplyClick}
                onchange={onchange}
                columns={columns}
                visibleColumns={visibleColumns}
                toggleColumn={toggleColumn}
                onClickmove={onClickmove}
                deSelectColumnall={deSelectColumnall}
                onChangeIndex={onChangeIndex}
                tempcolumns={tempcolumns}
                setTempColumns={setTempColumns}
            />}

            {arrangeopen && <ArrangeFields
                show={arrangeopen}
                onCloseClick={() => setArrangeopen(false)}
                dataFields={metaData}
                props={props}
            />}

            {isImportCSV && <ImportDataModal
                show={isImportCSV}
                onCloseClick={() => setIsImportCSV(false)}
                props={props}
            />}
            {isDeleteOpen && <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />}
               {   
                !authLoading ?   
                viewAccess ? 
                
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                        <Row className='hstack gap-3'>
                            <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                <InputGroup className='border rounded'>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-light">
                                                {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => setSelectedSearchField({value: 'all', label: 'All'})}>All</DropdownItem>
                                                <DropdownItem divider />
                                                {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                    <Input className='border-0' placeholder="Search..." style={{padding: '1.2rem 0.9rem'}} onChange={onSearchHandle} value={search}></Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div className='hstack gap-2 justify-content-end'>
                                    <div className='hstack gap-2'>                                   
                                    {
                                        create ? 
                                        <Button
                                            onClick={() => setCreateOpen(true)}
                                            color="primary"
                                            className="btn-label">
                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("product.create_entry")}
                                        </Button>
                                    :         
                                        <Button
                                            // onClick={() => setCreateOpen(true)}
                                            color="primary"
                                            disabled
                                            className="btn-label">
                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("product.create_entry")}
                                        </Button>}
                                        <Button
                                            onClick={() => setEditOpen(true)}
                                            color="primary"
                                            // size='sm'
                                            className="btn-label btn-soft-primary">
                                                <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("product.edit_columns")}
                                        </Button>
                                    </div>
                                </div>
                            </Col>                            
                        </Row>
                        <Row className=''>
                            {metaData && metaData.length ? 
                                <QuickFilter
                                    dataFields={metaData}
                                    columns={columns}
                                    translation={props}
                                    onFilter={onFilter}
                                    clearFilterParent={clearFilter}
                                    filter={filter}
                                /> : ""}
                        </Row>
                        <Row className='products-table'>
                            <TableContainer
                                columns={columns && columns.length ? columns.filter((column: any) => column.show == true): []}
                                data={products || []}
                                customPageSize={pagination?.size}
                                divClass="table-responsive"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                thClass="border-bottom-1 table-soft-primary"
                                SearchPlaceholder='Search...'
                                isPageSizeChange={true}
                                isBordered={true}
                                handleEditClick={handleEditClick}
                                handleLeadClick={handleLeadClick}
                                setisDeleteOpen={setisDeleteOpen}
                                setIsMultiDeleteButton={setIsMultiDeleteButton}
                                setselectedcount={setselectedcount}
                                pageChanged={pageChanged}
                                pagination={pagination}
                                setPagination={setPagination}
                                pagesList={pagesList}
                                onChangePageSize={onChangePageSize}
                                setpreview={setpreview}
                                setNotesModals={setNotesModals}
                                handleSort={handleSort}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                props={props}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                access={access}
                                loading={loading}
                                onChange={onChangestatus}
                                formValues={formValues}
                                updateRow={updateRow}
                                setUpdateRow={setUpdateRow}
                                viewsloading={viewsloading}
                                dataloading={dataloading}
                                metaLoading={metaLoading}
                            />
                        </Row>                            
                        </CardBody>
                    </Card>
                </Col>
            </Row> : <AccessDenied /> 
                    :  <ListingLoader 
                    title={props.t("product.products")}      
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    thClass="border-bottom-1 table-soft-primary"
                />
            } 
        </React.Fragment>
    );
};
export default withTranslation()(Products);


