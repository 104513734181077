import React from "react";
import { Container, Row } from "reactstrap";
// import SkeletonLoader from "./Skeleton/SkeletonLoader";
import Students from "./Students";

const Studensts = () => {
  document.title = "DTS | Zilter";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Students" pageTitle="Students" /> */}
          {/* <SkeletonLoader className="flex gap-2 my-2 w-80"> */}
          <Row>
            <Students />
          </Row>
          {/* </SkeletonLoader> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Studensts;
