import {FormActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const createForm: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<void> => {        
        localStorage.clear();
        dispatch({
            type: FormActionTypes.SET_FORM_DATA,
            payload: data
        });
    };
};


export const roleUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FormActionTypes.PUT_FORM_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/roles/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FormActionTypes.PUT_FORM_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: FormActionTypes.PUT_FORM_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const roleCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: FormActionTypes.POST_FORM_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/roles`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FormActionTypes.POST_FORM_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: FormActionTypes.POST_FORM_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};