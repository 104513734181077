import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { UniversityListGetRequest, universityCreateRequest } from "store/university/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { SourceHierachyGetRequest, SourceListGetRequest, sourceCreateRequest } from "store/sources/action";
import axios from "axios";
import config from "../../../config";
import { ApplicationState } from "store";
const { DTS_API_STUDENT } = config.api;


const AddEntry = ({ props, show, onCloseClick, dataFields }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [newdataFields, setnewdataFields] = useState([])
    const [sourcesOptions, setSourcesOptions] = useState([])
    const [options, setoptions] = useState<any>([]);
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const sourcesHierachy = useSelector((state: ApplicationState) => state.sources.hierarchysources)
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const OnAddEntry = (formValues: any) => {
        let data = formValues;
        let parent = formValues.parent ? formValues.parent : null
        const handleSuccess = (body: any) => {
            const message = () => toast(`Source Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            onCloseClick()
            dispatch(SourceListGetRequest());
            dispatch(SourceHierachyGetRequest())
        }
        const handleError = () => {

        }
        dispatch(sourceCreateRequest(parent, data, handleSuccess, handleError))
    }

    const convertToOptions = (data: any) => {
        return data.map((item: any) => {           
            const values = JSON.parse(item.valuesJson);
            const option: any = { label: values.name, value: item.id };
            if (item.children && item.children.length > 0) {

                let childrens = convertToOptions(item.children);
                return { ...option, children: childrens }
            }
            return option;
        });
    }
    useEffect(() => {
        if (sourcesHierachy && sourcesHierachy.length) {
            let options = convertToOptions(sourcesHierachy)
            setoptions(options)
        }
    }, [sourcesHierachy])

    useEffect(() => {
        let Fields: any = [...dataFields];
        let newFields = {
            "label": "Parent",
            "key": "parent",
            "placeholder": "Select Parent",
            "quickCreate": true,
            "type": "treeselect",
            "values": options,
            "model": "sources",
            "optionLabel":"name"
        }
        let field = JSON.stringify(newFields);
        Fields[Fields.length] = { valuesJson: field };
        setSourcesOptions(sourcesHierachy)
        setnewdataFields(Fields)
    }, [sourcesHierachy])

    useEffect(() => {
        dispatch(SourceHierachyGetRequest())
    }, [])

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >

            <OffcanvasHeader className="bg-light" toggle={() => { onCloseClick(!show) }}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card>
                    <CardBody>
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={newdataFields} btnAction={OnAddEntry} btn_text={'Add Entry'} />
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

