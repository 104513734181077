import { Reducer } from "redux";
import { CurrencyActionTypes, CurrencyState } from "./types";


export const initialState: CurrencyState =
{

    currencies: [],
    currency: null,
    loading: false,
}
const reducer: Reducer<CurrencyState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case CurrencyActionTypes.GET_CURRENCY_REQUEST: {
            return { ...state, loading: true };
        }
        case CurrencyActionTypes.GET_CURRENCY_SUCCESS: {
            return {
                ...state,
                loading: false,
                task: action.payload
            };
        }
        case CurrencyActionTypes.GET_CURRENCY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CurrencyActionTypes.GET_CURRENCIES_REQUEST: {
            return { ...state, loading: true };
        }
        case CurrencyActionTypes.GET_CURRENCIES_SUCCESS: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                loading: false,
                currencies: action.payload.content,
            };
        }
        case CurrencyActionTypes.GET_CURRENCIES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as CurrencyReducer };
