import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
    Accordion,
    AccordionItem,
    Collapse,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
    UncontrolledAlert,
    Table,
} from "reactstrap";
import classnames from "classnames";
import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import avatar10 from "../../../assets/images/users/user-dummy-img.jpg";
// import avatar10 from "../../../assets/images/dtsimg/svg/logo_placeholder.png";
import { ApplicationState } from "store";
import CompanyInfo from './CompanyInfo';
import { Link, useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { companyGetRequest } from "store/companies/action";
import { propertyListGetRequest } from "store/properties/action";
interface PreviewModalProps {
    show ?: boolean;
    open ?: string;
    onCloseClick : () => void;
    ontoggle:  (e: any) => void;
    setrecord:  (e: any) => void;
    record: {};
    metaData: any;
  }
  
  const PreviewModal: React.FC<PreviewModalProps> = ({ metaData, show, onCloseClick, record ,ontoggle, open, setrecord}: any) => {    
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const universityDetails: any = useSelector((state: ApplicationState) => state.universities.university);
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    let info =  universityDetails && universityDetails.valuesJson  && isJson(universityDetails.valuesJson) ? JSON.parse(universityDetails.valuesJson) : {}
   
    useEffect(() => {
        setrecord(universityDetails)
        dispatch(companyGetRequest(id))
        const filters = {pageSize: 100}
        dispatch(propertyListGetRequest("companies",filters));
    }, [])
   return ( <>
         {
                record ? 
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    toggle={onCloseClick}
                >
                    <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
                        {record.name}
                    </OffcanvasHeader>
                    <form action="" className="d-flex flex-column justify-content-end h-100">
                        <OffcanvasBody> 
                        <Col xxl={12} md={12} lg={12}>
                            <Card id="contact-view-detail">
                                <CardBody className="text-center">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto">
                                            <img
                                            src={avatar10}
                                            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                            alt="user-profile"
                                            />                                                
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                            <Input
                                                id="profile-img-file-input"
                                                type="file"
                                                className="profile-img-file-input"
                                            />
                                            <Label
                                                htmlFor="profile-img-file-input"
                                                className="profile-photo-edit avatar-xxs"
                                            >
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                <i className="ri-camera-fill"></i>
                                                </span>
                                            </Label>
                                            </div>
                                        </div>
                                        {/* <EditableLabel label={name} onSave={handleNameSave}/> */}
                                        <h5 className="mt-4 mb-1">{info.name}</h5>
                                        <p className="text-muted">{info.email}</p>
                                        </div>
                                </CardBody>
                            </Card>
                            </Col>
                            <div>
                                <UncontrolledAccordion toggle={()=>{}} defaultOpen={["about"]}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="about">
                                            About this University
                                        </AccordionHeader>
                                        <AccordionBody accordionId="about">
                                            <CompanyInfo record={info} metaData={metaData} />
                                        </AccordionBody>
                                    </AccordionItem>
                                    {/* <AccordionItem>
                                        <AccordionHeader targetId="notes">
                                            Notes
                                        </AccordionHeader>
                                        <AccordionBody accordionId="notes">
                                            <Notes notes={universityDetails && universityDetails.notes ? universityDetails.notes : []}/>
                                        </AccordionBody>
                                    </AccordionItem> */}
                                </UncontrolledAccordion>
                            </div>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                            <Button
                                className="btn btn-light w-100"
                                onClick={() => onCloseClick()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Offcanvas>
                : null
            }
        </>
    );
};

export default PreviewModal;
