import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";

import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest, notesListGetRequest } from "store/note/action";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Comments from "./Comments";
import AddTask from "./AddTask";
import FollowUpTask from "./FollowUpTask";

interface FProps {
  props: any;
  notesModals: boolean;
  setNotesModals: (event: boolean) => void;
  onAddNote: (content: string) => void;
}

const noteType = [
  {
    options: [
      { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
      { label: "Follow-up call", value: "FOLLOW-UP CALL" },
      { label: "Incoming Call", value: "INCOMING CALL" },
      { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
      { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
      { label: "Course Notes", value: "COURSE NOTES" },
      { label: "Finance Notes", value: "FINANCE NOTES" },
      { label: "Others", value: "OTHERS" }
    ],
  },
];

const NotesModal: React.FC<FProps> = ({
  props,
  notesModals,
  setNotesModals,
  onAddNote,
  
}) => {
  let { id } = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const [noteInput, setNoteInput] = useState<string>("");
  const [newNote, setNewNote] = useState("");
  const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
  const [addShortNoteValue, setAddShortNoteValue] = useState("");
  const [transcribing, setTranscribing] = useState("modalparent");
  const [followupRequired, setFollowupRequired] = useState(false);
  const [tasksModals, setTasksModals] = useState<boolean>(false);
  const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({ transcribing: transcribing == "modalparent" });

  useEffect(() => {
    dispatch(notesListGetRequest(id));
  }, [id]);

  useEffect(() => {
    setNewNote(transcript);
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
    SpeechRecognition.startListening({continuous: true});
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };


  const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
  };

  const handleAddNoteTypeChange = (selectedOption: any) => {
    setSelectedAddNoteType(selectedOption);
    setAddShortNoteValue(selectedOption.label)
  };

  const handleAddNote = () => {
    stopListening();
    setIsAddNoteButtonDisabled(true);

    const data = {
      studentId: id,
      shortNote: addShortNoteValue,
      parentId: "",
      note: newNote,
    };

    const handleSuccess = (body: any): void => {
      createFollowUp();
      handleReset();
      const message = () => toast(`Note added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      dispatch(notesListGetRequest(id));
    };
  
    const handleError = (body: any): void => {
      setIsAddNoteButtonDisabled(false);
      // Handle error message here
    };
  
    dispatch(noteAddRequest(data, handleSuccess, handleError));
  };

  const handleReset = () => {
    setNewNote("");
      setSelectedAddNoteType(null)
      setNotesModals(false);
      setIsAddNoteButtonDisabled(false);
    resetTranscript();
    setAddShortNoteValue('')
  };

  const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupRequired(event.target.checked);
  };

  const createFollowUp = () => {
    setTasksModals(followupRequired);
  };

  return (
    <>
      <FollowUpTask
      followupRequired={followupRequired}
      setFollowupRequired={setFollowupRequired}
      record={id}
        props={props}
        tasksModals={tasksModals}
        setTasksModals={setTasksModals}
      />
      <Col lg={9}>
        <Modal className="w-75" id="modalForm" autoFocus={true} isOpen={notesModals} toggle={() => {
          setNotesModals(false);
        }} centered={true} size="lg">
          <ModalHeader className="p-2 bg-primary-subtle" toggle={() => {
            setNotesModals(false);
          }}>
            {"Add New Note"}
          </ModalHeader>
          <ModalBody className="my-2 p-1">
            <Row className="g-3">
              <Col xs={12} >
                <div className="mx-3">
                  <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" value={newNote}
                    onChange={handleNewNoteChange} rows={3} placeholder={props.t("applications.add_note")}></textarea>
                </div>
              </Col>
              <Col xs={12} className="text-start">
                {/* <Dictaphone /> */}
                <div className='mx-3 d-flex justify-content-end gap-2'>
                  <div className="form-check mb-0 align-self-center">
                    <Input className="form-check-input" type="checkbox" onChange={handleFollowupCheck} id="formCheck6" />
                    <Label className="form-check-label" for="formCheck6">
                      Follow-up required ?
                    </Label>
                  </div>
                  <div style={{ minWidth: '250px' }}>
                <Select
                  placeholder="Select Note Type"
                  classNamePrefix="js-example-data-array"
                  isLoading={false}
                  options={noteType}
                  value={selectedAddNoteType}
                  onChange={handleAddNoteTypeChange}
                />
                </div>
                  {listening ? (
                    <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                  ) : (
                    <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                  )}

                  {/* <button type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button> */}
                  <Button color="primary" className="ms-2 btn btn-primary"
                    disabled={isAddNoteButtonDisabled || (!newNote || !selectedAddNoteType) && (!transcript || !selectedAddNoteType)}
                    onClick={handleAddNote}>{props.t("applications.add_note")}</Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Comments record={id} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing} />
            </Row>
          </ModalBody>
        </Modal>
      </Col>
    </>
  );
};

export default withTranslation()(NotesModal);
