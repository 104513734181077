import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import StudentListing from './Template';
import { withTranslation } from 'react-i18next';

const Students = (props:any) => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title="Templates" pageTitle="Templates"  icon={"ri-rocket-line"}/>
                    <Row>
                        <StudentListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Students);